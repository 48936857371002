module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-01",
    "endpointPrefix": "kms",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "KMS",
    "serviceFullName": "AWS Key Management Service",
    "signatureVersion": "v4",
    "targetPrefix": "TrentService",
    "uid": "kms-2014-11-01"
  },
  "operations": {
    "CancelKeyDeletion": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {}
        }
      }
    },
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName",
          "TargetKeyId"
        ],
        "members": {
          "AliasName": {},
          "TargetKeyId": {}
        }
      }
    },
    "CreateGrant": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "GranteePrincipal"
        ],
        "members": {
          "KeyId": {},
          "GranteePrincipal": {},
          "RetiringPrincipal": {},
          "Operations": {
            "shape": "S8"
          },
          "Constraints": {
            "shape": "Sa"
          },
          "GrantTokens": {
            "shape": "Se"
          },
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GrantToken": {},
          "GrantId": {}
        }
      }
    },
    "CreateKey": {
      "input": {
        "type": "structure",
        "members": {
          "Policy": {},
          "Description": {},
          "KeyUsage": {},
          "Origin": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyMetadata": {
            "shape": "Sq"
          }
        }
      }
    },
    "Decrypt": {
      "input": {
        "type": "structure",
        "required": [
          "CiphertextBlob"
        ],
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "EncryptionContext": {
            "shape": "Sb"
          },
          "GrantTokens": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "Plaintext": {
            "shape": "Sz"
          }
        }
      }
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName"
        ],
        "members": {
          "AliasName": {}
        }
      }
    },
    "DeleteImportedKeyMaterial": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DescribeKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "GrantTokens": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyMetadata": {
            "shape": "Sq"
          }
        }
      }
    },
    "DisableKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "DisableKeyRotation": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "EnableKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "EnableKeyRotation": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      }
    },
    "Encrypt": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Plaintext"
        ],
        "members": {
          "KeyId": {},
          "Plaintext": {
            "shape": "Sz"
          },
          "EncryptionContext": {
            "shape": "Sb"
          },
          "GrantTokens": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "KeyId": {}
        }
      }
    },
    "GenerateDataKey": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "EncryptionContext": {
            "shape": "Sb"
          },
          "NumberOfBytes": {
            "type": "integer"
          },
          "KeySpec": {},
          "GrantTokens": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "Plaintext": {
            "shape": "Sz"
          },
          "KeyId": {}
        }
      }
    },
    "GenerateDataKeyWithoutPlaintext": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "EncryptionContext": {
            "shape": "Sb"
          },
          "KeySpec": {},
          "NumberOfBytes": {
            "type": "integer"
          },
          "GrantTokens": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "KeyId": {}
        }
      }
    },
    "GenerateRandom": {
      "input": {
        "type": "structure",
        "members": {
          "NumberOfBytes": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Plaintext": {
            "shape": "Sz"
          }
        }
      }
    },
    "GetKeyPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "PolicyName"
        ],
        "members": {
          "KeyId": {},
          "PolicyName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "GetKeyRotationStatus": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyRotationEnabled": {
            "type": "boolean"
          }
        }
      }
    },
    "GetParametersForImport": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "WrappingAlgorithm",
          "WrappingKeySpec"
        ],
        "members": {
          "KeyId": {},
          "WrappingAlgorithm": {},
          "WrappingKeySpec": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "ImportToken": {
            "type": "blob"
          },
          "PublicKey": {
            "shape": "Sz"
          },
          "ParametersValidTo": {
            "type": "timestamp"
          }
        }
      }
    },
    "ImportKeyMaterial": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "ImportToken",
          "EncryptedKeyMaterial"
        ],
        "members": {
          "KeyId": {},
          "ImportToken": {
            "type": "blob"
          },
          "EncryptedKeyMaterial": {
            "type": "blob"
          },
          "ValidTo": {
            "type": "timestamp"
          },
          "ExpirationModel": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AliasName": {},
                "AliasArn": {},
                "TargetKeyId": {}
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListGrants": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {},
          "KeyId": {}
        }
      },
      "output": {
        "shape": "S20"
      }
    },
    "ListKeyPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyNames": {
            "type": "list",
            "member": {}
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListKeys": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Keys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "KeyId": {},
                "KeyArn": {}
              }
            }
          },
          "NextMarker": {},
          "Truncated": {
            "type": "boolean"
          }
        }
      }
    },
    "ListRetirableGrants": {
      "input": {
        "type": "structure",
        "required": [
          "RetiringPrincipal"
        ],
        "members": {
          "Limit": {
            "type": "integer"
          },
          "Marker": {},
          "RetiringPrincipal": {}
        }
      },
      "output": {
        "shape": "S20"
      }
    },
    "PutKeyPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "PolicyName",
          "Policy"
        ],
        "members": {
          "KeyId": {},
          "PolicyName": {},
          "Policy": {},
          "BypassPolicyLockoutSafetyCheck": {
            "type": "boolean"
          }
        }
      }
    },
    "ReEncrypt": {
      "input": {
        "type": "structure",
        "required": [
          "CiphertextBlob",
          "DestinationKeyId"
        ],
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "SourceEncryptionContext": {
            "shape": "Sb"
          },
          "DestinationKeyId": {},
          "DestinationEncryptionContext": {
            "shape": "Sb"
          },
          "GrantTokens": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CiphertextBlob": {
            "type": "blob"
          },
          "SourceKeyId": {},
          "KeyId": {}
        }
      }
    },
    "RetireGrant": {
      "input": {
        "type": "structure",
        "members": {
          "GrantToken": {},
          "KeyId": {},
          "GrantId": {}
        }
      }
    },
    "RevokeGrant": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "GrantId"
        ],
        "members": {
          "KeyId": {},
          "GrantId": {}
        }
      }
    },
    "ScheduleKeyDeletion": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId"
        ],
        "members": {
          "KeyId": {},
          "PendingWindowInDays": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyId": {},
          "DeletionDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasName",
          "TargetKeyId"
        ],
        "members": {
          "AliasName": {},
          "TargetKeyId": {}
        }
      }
    },
    "UpdateKeyDescription": {
      "input": {
        "type": "structure",
        "required": [
          "KeyId",
          "Description"
        ],
        "members": {
          "KeyId": {},
          "Description": {}
        }
      }
    }
  },
  "shapes": {
    "S8": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "structure",
      "members": {
        "EncryptionContextSubset": {
          "shape": "Sb"
        },
        "EncryptionContextEquals": {
          "shape": "Sb"
        }
      }
    },
    "Sb": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Sq": {
      "type": "structure",
      "required": [
        "KeyId"
      ],
      "members": {
        "AWSAccountId": {},
        "KeyId": {},
        "Arn": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "Enabled": {
          "type": "boolean"
        },
        "Description": {},
        "KeyUsage": {},
        "KeyState": {},
        "DeletionDate": {
          "type": "timestamp"
        },
        "ValidTo": {
          "type": "timestamp"
        },
        "Origin": {},
        "ExpirationModel": {}
      }
    },
    "Sz": {
      "type": "blob",
      "sensitive": true
    },
    "S20": {
      "type": "structure",
      "members": {
        "Grants": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "KeyId": {},
              "GrantId": {},
              "Name": {},
              "CreationDate": {
                "type": "timestamp"
              },
              "GranteePrincipal": {},
              "RetiringPrincipal": {},
              "IssuingAccount": {},
              "Operations": {
                "shape": "S8"
              },
              "Constraints": {
                "shape": "Sa"
              }
            }
          }
        },
        "NextMarker": {},
        "Truncated": {
          "type": "boolean"
        }
      }
    }
  }
}