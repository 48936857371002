module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-03-31",
    "endpointPrefix": "lambda",
    "protocol": "rest-json",
    "serviceFullName": "AWS Lambda",
    "signatureVersion": "v4",
    "uid": "lambda-2015-03-31"
  },
  "operations": {
    "AddPermission": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId",
          "Action",
          "Principal"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {},
          "Action": {},
          "Principal": {},
          "SourceArn": {},
          "SourceAccount": {},
          "EventSourceToken": {},
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {}
        }
      }
    },
    "CreateAlias": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name",
          "FunctionVersion"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {},
          "FunctionVersion": {},
          "Description": {}
        }
      },
      "output": {
        "shape": "Sg"
      }
    },
    "CreateEventSourceMapping": {
      "http": {
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "EventSourceArn",
          "FunctionName",
          "StartingPosition"
        ],
        "members": {
          "EventSourceArn": {},
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          },
          "StartingPosition": {},
          "StartingPositionTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "shape": "Sn"
      }
    },
    "CreateFunction": {
      "http": {
        "requestUri": "/2015-03-31/functions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Runtime",
          "Role",
          "Handler",
          "Code"
        ],
        "members": {
          "FunctionName": {},
          "Runtime": {},
          "Role": {},
          "Handler": {},
          "Code": {
            "type": "structure",
            "members": {
              "ZipFile": {
                "shape": "St"
              },
              "S3Bucket": {},
              "S3Key": {},
              "S3ObjectVersion": {}
            }
          },
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "Publish": {
            "type": "boolean"
          },
          "VpcConfig": {
            "shape": "S10"
          },
          "DeadLetterConfig": {
            "shape": "S15"
          },
          "Environment": {
            "shape": "S17"
          },
          "KMSKeyArn": {}
        }
      },
      "output": {
        "shape": "S1c"
      }
    },
    "DeleteAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      }
    },
    "DeleteEventSourceMapping": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "Sn"
      }
    },
    "DeleteFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "GetAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-08-19/account-settings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountLimit": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "CodeSizeUnzipped": {
                "type": "long"
              },
              "CodeSizeZipped": {
                "type": "long"
              },
              "ConcurrentExecutions": {
                "type": "integer"
              }
            }
          },
          "AccountUsage": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "FunctionCount": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "GetAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "shape": "Sg"
      }
    },
    "GetEventSourceMapping": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "Sn"
      }
    },
    "GetFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S1c"
          },
          "Code": {
            "type": "structure",
            "members": {
              "RepositoryType": {},
              "Location": {}
            }
          }
        }
      }
    },
    "GetFunctionConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "shape": "S1c"
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        }
      }
    },
    "Invoke": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/invocations"
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvocationType": {
            "location": "header",
            "locationName": "X-Amz-Invocation-Type"
          },
          "LogType": {
            "location": "header",
            "locationName": "X-Amz-Log-Type"
          },
          "ClientContext": {
            "location": "header",
            "locationName": "X-Amz-Client-Context"
          },
          "Payload": {
            "shape": "St"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        },
        "payload": "Payload"
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "location": "statusCode",
            "type": "integer"
          },
          "FunctionError": {
            "location": "header",
            "locationName": "X-Amz-Function-Error"
          },
          "LogResult": {
            "location": "header",
            "locationName": "X-Amz-Log-Result"
          },
          "Payload": {
            "shape": "St"
          }
        },
        "payload": "Payload"
      }
    },
    "InvokeAsync": {
      "http": {
        "requestUri": "/2014-11-13/functions/{FunctionName}/invoke-async/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "InvokeArgs"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvokeArgs": {
            "type": "blob",
            "streaming": true
          }
        },
        "deprecated": true,
        "payload": "InvokeArgs"
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        },
        "deprecated": true
      },
      "deprecated": true
    },
    "ListAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "FunctionVersion": {
            "location": "querystring",
            "locationName": "FunctionVersion"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Aliases": {
            "type": "list",
            "member": {
              "shape": "Sg"
            }
          }
        }
      }
    },
    "ListEventSourceMappings": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "EventSourceArn": {
            "location": "querystring",
            "locationName": "EventSourceArn"
          },
          "FunctionName": {
            "location": "querystring",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "EventSourceMappings": {
            "type": "list",
            "member": {
              "shape": "Sn"
            }
          }
        }
      }
    },
    "ListFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Functions": {
            "shape": "S2h"
          }
        }
      }
    },
    "ListVersionsByFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Versions": {
            "shape": "S2h"
          }
        }
      }
    },
    "PublishVersion": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "CodeSha256": {},
          "Description": {}
        }
      },
      "output": {
        "shape": "S1c"
      }
    },
    "RemovePermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy/{StatementId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {
            "location": "uri",
            "locationName": "StatementId"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "UpdateAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "FunctionVersion": {},
          "Description": {}
        }
      },
      "output": {
        "shape": "Sg"
      }
    },
    "UpdateEventSourceMapping": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          },
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "Sn"
      }
    },
    "UpdateFunctionCode": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/code",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "ZipFile": {
            "shape": "St"
          },
          "S3Bucket": {},
          "S3Key": {},
          "S3ObjectVersion": {},
          "Publish": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S1c"
      }
    },
    "UpdateFunctionConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Role": {},
          "Handler": {},
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "VpcConfig": {
            "shape": "S10"
          },
          "Environment": {
            "shape": "S17"
          },
          "Runtime": {},
          "DeadLetterConfig": {
            "shape": "S15"
          },
          "KMSKeyArn": {}
        }
      },
      "output": {
        "shape": "S1c"
      }
    }
  },
  "shapes": {
    "Sg": {
      "type": "structure",
      "members": {
        "AliasArn": {},
        "Name": {},
        "FunctionVersion": {},
        "Description": {}
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "UUID": {},
        "BatchSize": {
          "type": "integer"
        },
        "EventSourceArn": {},
        "FunctionArn": {},
        "LastModified": {
          "type": "timestamp"
        },
        "LastProcessingResult": {},
        "State": {},
        "StateTransitionReason": {}
      }
    },
    "St": {
      "type": "blob",
      "sensitive": true
    },
    "S10": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "S11"
        },
        "SecurityGroupIds": {
          "shape": "S13"
        }
      }
    },
    "S11": {
      "type": "list",
      "member": {}
    },
    "S13": {
      "type": "list",
      "member": {}
    },
    "S15": {
      "type": "structure",
      "members": {
        "TargetArn": {}
      }
    },
    "S17": {
      "type": "structure",
      "members": {
        "Variables": {
          "shape": "S18"
        }
      }
    },
    "S18": {
      "type": "map",
      "key": {
        "type": "string",
        "sensitive": true
      },
      "value": {
        "type": "string",
        "sensitive": true
      },
      "sensitive": true
    },
    "S1c": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "FunctionArn": {},
        "Runtime": {},
        "Role": {},
        "Handler": {},
        "CodeSize": {
          "type": "long"
        },
        "Description": {},
        "Timeout": {
          "type": "integer"
        },
        "MemorySize": {
          "type": "integer"
        },
        "LastModified": {},
        "CodeSha256": {},
        "Version": {},
        "VpcConfig": {
          "type": "structure",
          "members": {
            "SubnetIds": {
              "shape": "S11"
            },
            "SecurityGroupIds": {
              "shape": "S13"
            },
            "VpcId": {}
          }
        },
        "DeadLetterConfig": {
          "shape": "S15"
        },
        "Environment": {
          "type": "structure",
          "members": {
            "Variables": {
              "shape": "S18"
            },
            "Error": {
              "type": "structure",
              "members": {
                "ErrorCode": {},
                "Message": {
                  "type": "string",
                  "sensitive": true
                }
              }
            }
          }
        },
        "KMSKeyArn": {}
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "shape": "S1c"
      }
    }
  }
}