module.exports={
	"pagination": {
		"ListClusters": {
			"input_token": "nextToken",
			"output_token": "nextToken",
			"limit_key": "maxResults",
			"result_key": "clusterArns"
		},
		"ListContainerInstances": {
			"input_token": "nextToken",
			"output_token": "nextToken",
			"limit_key": "maxResults",
			"result_key": "containerInstanceArns"
		},
		"ListTaskDefinitions": {
			"input_token": "nextToken",
			"output_token": "nextToken",
			"limit_key": "maxResults",
			"result_key": "taskDefinitionArns"
		},
		"ListTaskDefinitionFamilies": {
			"input_token": "nextToken",
			"output_token": "nextToken",
			"limit_key": "maxResults",
			"result_key": "families"
		},
		"ListTasks": {
			"input_token": "nextToken",
			"output_token": "nextToken",
			"limit_key": "maxResults",
			"result_key": "taskArns"
		},
		"ListServices": {
			"input_token": "nextToken",
			"output_token": "nextToken",
			"limit_key": "maxResults",
			"result_key": "serviceArns"
		}
	}
}
