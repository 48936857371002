module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-05-30",
    "endpointPrefix": "cloudhsm",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CloudHSM",
    "serviceFullName": "Amazon CloudHSM",
    "signatureVersion": "v4",
    "targetPrefix": "CloudHsmFrontendService",
    "uid": "cloudhsm-2014-05-30"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagList"
        ],
        "members": {
          "ResourceArn": {},
          "TagList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "CreateHapg": {
      "input": {
        "type": "structure",
        "required": [
          "Label"
        ],
        "members": {
          "Label": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HapgArn": {}
        }
      }
    },
    "CreateHsm": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId",
          "SshKey",
          "IamRoleArn",
          "SubscriptionType"
        ],
        "members": {
          "SubnetId": {
            "locationName": "SubnetId"
          },
          "SshKey": {
            "locationName": "SshKey"
          },
          "EniIp": {
            "locationName": "EniIp"
          },
          "IamRoleArn": {
            "locationName": "IamRoleArn"
          },
          "ExternalId": {
            "locationName": "ExternalId"
          },
          "SubscriptionType": {
            "locationName": "SubscriptionType"
          },
          "ClientToken": {
            "locationName": "ClientToken"
          },
          "SyslogIp": {
            "locationName": "SyslogIp"
          }
        },
        "locationName": "CreateHsmRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmArn": {}
        }
      }
    },
    "CreateLunaClient": {
      "input": {
        "type": "structure",
        "required": [
          "Certificate"
        ],
        "members": {
          "Label": {},
          "Certificate": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientArn": {}
        }
      }
    },
    "DeleteHapg": {
      "input": {
        "type": "structure",
        "required": [
          "HapgArn"
        ],
        "members": {
          "HapgArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "DeleteHsm": {
      "input": {
        "type": "structure",
        "required": [
          "HsmArn"
        ],
        "members": {
          "HsmArn": {
            "locationName": "HsmArn"
          }
        },
        "locationName": "DeleteHsmRequest"
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "DeleteLunaClient": {
      "input": {
        "type": "structure",
        "required": [
          "ClientArn"
        ],
        "members": {
          "ClientArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    },
    "DescribeHapg": {
      "input": {
        "type": "structure",
        "required": [
          "HapgArn"
        ],
        "members": {
          "HapgArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HapgArn": {},
          "HapgSerial": {},
          "HsmsLastActionFailed": {
            "shape": "Sz"
          },
          "HsmsPendingDeletion": {
            "shape": "Sz"
          },
          "HsmsPendingRegistration": {
            "shape": "Sz"
          },
          "Label": {},
          "LastModifiedTimestamp": {},
          "PartitionSerialList": {
            "shape": "S11"
          },
          "State": {}
        }
      }
    },
    "DescribeHsm": {
      "input": {
        "type": "structure",
        "members": {
          "HsmArn": {},
          "HsmSerialNumber": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmArn": {},
          "Status": {},
          "StatusDetails": {},
          "AvailabilityZone": {},
          "EniId": {},
          "EniIp": {},
          "SubscriptionType": {},
          "SubscriptionStartDate": {},
          "SubscriptionEndDate": {},
          "VpcId": {},
          "SubnetId": {},
          "IamRoleArn": {},
          "SerialNumber": {},
          "VendorName": {},
          "HsmType": {},
          "SoftwareVersion": {},
          "SshPublicKey": {},
          "SshKeyLastUpdated": {},
          "ServerCertUri": {},
          "ServerCertLastUpdated": {},
          "Partitions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "DescribeLunaClient": {
      "input": {
        "type": "structure",
        "members": {
          "ClientArn": {},
          "CertificateFingerprint": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientArn": {},
          "Certificate": {},
          "CertificateFingerprint": {},
          "LastModifiedTimestamp": {},
          "Label": {}
        }
      }
    },
    "GetConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ClientArn",
          "ClientVersion",
          "HapgList"
        ],
        "members": {
          "ClientArn": {},
          "ClientVersion": {},
          "HapgList": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigType": {},
          "ConfigFile": {},
          "ConfigCred": {}
        }
      }
    },
    "ListAvailableZones": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AZList": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListHapgs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HapgList"
        ],
        "members": {
          "HapgList": {
            "shape": "S1i"
          },
          "NextToken": {}
        }
      }
    },
    "ListHsms": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmList": {
            "shape": "Sz"
          },
          "NextToken": {}
        }
      }
    },
    "ListLunaClients": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ClientList"
        ],
        "members": {
          "ClientList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagList"
        ],
        "members": {
          "TagList": {
            "shape": "S3"
          }
        }
      }
    },
    "ModifyHapg": {
      "input": {
        "type": "structure",
        "required": [
          "HapgArn"
        ],
        "members": {
          "HapgArn": {},
          "Label": {},
          "PartitionSerialList": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HapgArn": {}
        }
      }
    },
    "ModifyHsm": {
      "input": {
        "type": "structure",
        "required": [
          "HsmArn"
        ],
        "members": {
          "HsmArn": {
            "locationName": "HsmArn"
          },
          "SubnetId": {
            "locationName": "SubnetId"
          },
          "EniIp": {
            "locationName": "EniIp"
          },
          "IamRoleArn": {
            "locationName": "IamRoleArn"
          },
          "ExternalId": {
            "locationName": "ExternalId"
          },
          "SyslogIp": {
            "locationName": "SyslogIp"
          }
        },
        "locationName": "ModifyHsmRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmArn": {}
        }
      }
    },
    "ModifyLunaClient": {
      "input": {
        "type": "structure",
        "required": [
          "ClientArn",
          "Certificate"
        ],
        "members": {
          "ClientArn": {},
          "Certificate": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientArn": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeyList"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeyList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {}
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S11": {
      "type": "list",
      "member": {}
    },
    "S1i": {
      "type": "list",
      "member": {}
    }
  }
}