module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-04-18",
    "endpointPrefix": "cognito-idp",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Cognito Identity Provider",
    "signatureVersion": "v4",
    "targetPrefix": "AWSCognitoIdentityProviderService",
    "uid": "cognito-idp-2016-04-18"
  },
  "operations": {
    "AddCustomAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "CustomAttributes"
        ],
        "members": {
          "UserPoolId": {},
          "CustomAttributes": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminAddUserToGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "GroupName": {}
        }
      }
    },
    "AdminConfirmSignUp": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminCreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "ValidationData": {
            "shape": "Si"
          },
          "TemporaryPassword": {
            "shape": "Sm"
          },
          "ForceAliasCreation": {
            "type": "boolean"
          },
          "MessageAction": {},
          "DesiredDeliveryMediums": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "Ss"
          }
        }
      }
    },
    "AdminDeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      }
    },
    "AdminDeleteUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "UserAttributeNames"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributeNames": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminDisableUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminEnableUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminForgetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "DeviceKey"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "DeviceKey": {}
        }
      }
    },
    "AdminGetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey",
          "UserPoolId",
          "Username"
        ],
        "members": {
          "DeviceKey": {},
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Device"
        ],
        "members": {
          "Device": {
            "shape": "S19"
          }
        }
      }
    },
    "AdminGetUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Username"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "UserCreateDate": {
            "type": "timestamp"
          },
          "UserLastModifiedDate": {
            "type": "timestamp"
          },
          "Enabled": {
            "type": "boolean"
          },
          "UserStatus": {},
          "MFAOptions": {
            "shape": "Sv"
          }
        }
      }
    },
    "AdminInitiateAuth": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId",
          "AuthFlow"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1d"
          },
          "AuthFlow": {},
          "AuthParameters": {
            "shape": "S1f"
          },
          "ClientMetadata": {
            "shape": "S1g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1k"
          },
          "AuthenticationResult": {
            "shape": "S1l"
          }
        }
      }
    },
    "AdminListDevices": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "shape": "S1t"
          },
          "PaginationToken": {}
        }
      }
    },
    "AdminListGroupsForUser": {
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "UserPoolId"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserPoolId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "S1x"
          },
          "NextToken": {}
        }
      }
    },
    "AdminRemoveUserFromGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "GroupName": {}
        }
      }
    },
    "AdminResetUserPassword": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminRespondToAuthChallenge": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId",
          "ChallengeName"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1d"
          },
          "ChallengeName": {},
          "ChallengeResponses": {
            "shape": "S26"
          },
          "Session": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1k"
          },
          "AuthenticationResult": {
            "shape": "S1l"
          }
        }
      }
    },
    "AdminSetUserSettings": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "MFAOptions"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "MFAOptions": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateDeviceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "DeviceKey"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "DeviceKey": {},
          "DeviceRememberedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "UserAttributes"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUserGlobalSignOut": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ChangePassword": {
      "input": {
        "type": "structure",
        "required": [
          "PreviousPassword",
          "ProposedPassword"
        ],
        "members": {
          "PreviousPassword": {
            "shape": "Sm"
          },
          "ProposedPassword": {
            "shape": "Sm"
          },
          "AccessToken": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "ConfirmDevice": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1m"
          },
          "DeviceKey": {},
          "DeviceSecretVerifierConfig": {
            "type": "structure",
            "members": {
              "PasswordVerifier": {},
              "Salt": {}
            }
          },
          "DeviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserConfirmationNecessary": {
            "type": "boolean"
          }
        }
      }
    },
    "ConfirmForgotPassword": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "ConfirmationCode",
          "Password"
        ],
        "members": {
          "ClientId": {
            "shape": "S1d"
          },
          "SecretHash": {
            "shape": "S2o"
          },
          "Username": {
            "shape": "Sd"
          },
          "ConfirmationCode": {},
          "Password": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "ConfirmSignUp": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "ConfirmationCode"
        ],
        "members": {
          "ClientId": {
            "shape": "S1d"
          },
          "SecretHash": {
            "shape": "S2o"
          },
          "Username": {
            "shape": "Sd"
          },
          "ConfirmationCode": {},
          "ForceAliasCreation": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "CreateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {},
          "Description": {},
          "RoleArn": {},
          "Precedence": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S1y"
          }
        }
      }
    },
    "CreateUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "UserPoolId",
          "CloudWatchLogsRoleArn"
        ],
        "members": {
          "JobName": {},
          "UserPoolId": {},
          "CloudWatchLogsRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S2y"
          }
        }
      }
    },
    "CreateUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "PoolName"
        ],
        "members": {
          "PoolName": {},
          "Policies": {
            "shape": "S36"
          },
          "LambdaConfig": {
            "shape": "S39"
          },
          "AutoVerifiedAttributes": {
            "shape": "S3a"
          },
          "AliasAttributes": {
            "shape": "S3c"
          },
          "SmsVerificationMessage": {},
          "EmailVerificationMessage": {},
          "EmailVerificationSubject": {},
          "SmsAuthenticationMessage": {},
          "MfaConfiguration": {},
          "DeviceConfiguration": {
            "shape": "S3i"
          },
          "EmailConfiguration": {
            "shape": "S3j"
          },
          "SmsConfiguration": {
            "shape": "S3l"
          },
          "UserPoolTags": {
            "shape": "S3m"
          },
          "AdminCreateUserConfig": {
            "shape": "S3n"
          },
          "Schema": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPool": {
            "shape": "S3s"
          }
        }
      }
    },
    "CreateUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientName"
        ],
        "members": {
          "UserPoolId": {},
          "ClientName": {},
          "GenerateSecret": {
            "type": "boolean"
          },
          "RefreshTokenValidity": {
            "type": "integer"
          },
          "ReadAttributes": {
            "shape": "S3y"
          },
          "WriteAttributes": {
            "shape": "S3y"
          },
          "ExplicitAuthFlows": {
            "shape": "S40"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S43"
          }
        }
      }
    },
    "DeleteGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {}
        }
      }
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "members": {
          "AccessToken": {
            "shape": "S1m"
          }
        }
      },
      "authtype": "none"
    },
    "DeleteUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserAttributeNames"
        ],
        "members": {
          "UserAttributeNames": {
            "shape": "Sz"
          },
          "AccessToken": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "DeleteUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      }
    },
    "DeleteUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1d"
          }
        }
      }
    },
    "DescribeUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S2y"
          }
        }
      }
    },
    "DescribeUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPool": {
            "shape": "S3s"
          }
        }
      }
    },
    "DescribeUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S43"
          }
        }
      }
    },
    "ForgetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1m"
          },
          "DeviceKey": {}
        }
      }
    },
    "ForgotPassword": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username"
        ],
        "members": {
          "ClientId": {
            "shape": "S1d"
          },
          "SecretHash": {
            "shape": "S2o"
          },
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S4k"
          }
        }
      },
      "authtype": "none"
    },
    "GetCSVHeader": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolId": {},
          "CSVHeader": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey"
        ],
        "members": {
          "DeviceKey": {},
          "AccessToken": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Device"
        ],
        "members": {
          "Device": {
            "shape": "S19"
          }
        }
      }
    },
    "GetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S1y"
          }
        }
      }
    },
    "GetUser": {
      "input": {
        "type": "structure",
        "members": {
          "AccessToken": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Username",
          "UserAttributes"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "MFAOptions": {
            "shape": "Sv"
          }
        }
      },
      "authtype": "none"
    },
    "GetUserAttributeVerificationCode": {
      "input": {
        "type": "structure",
        "required": [
          "AttributeName"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1m"
          },
          "AttributeName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S4k"
          }
        }
      },
      "authtype": "none"
    },
    "GlobalSignOut": {
      "input": {
        "type": "structure",
        "members": {
          "AccessToken": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "InitiateAuth": {
      "input": {
        "type": "structure",
        "required": [
          "AuthFlow",
          "ClientId"
        ],
        "members": {
          "AuthFlow": {},
          "AuthParameters": {
            "shape": "S1f"
          },
          "ClientMetadata": {
            "shape": "S1g"
          },
          "ClientId": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1k"
          },
          "AuthenticationResult": {
            "shape": "S1l"
          }
        }
      }
    },
    "ListDevices": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1m"
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "shape": "S1t"
          },
          "PaginationToken": {}
        }
      }
    },
    "ListGroups": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "S1x"
          },
          "NextToken": {}
        }
      }
    },
    "ListUserImportJobs": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "MaxResults"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJobs": {
            "type": "list",
            "member": {
              "shape": "S2y"
            }
          },
          "PaginationToken": {}
        }
      }
    },
    "ListUserPoolClients": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClients": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ClientId": {
                  "shape": "S1d"
                },
                "UserPoolId": {},
                "ClientName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUserPools": {
      "input": {
        "type": "structure",
        "required": [
          "MaxResults"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPools": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "LambdaConfig": {
                  "shape": "S39"
                },
                "Status": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "AttributesToGet": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {},
          "Filter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "shape": "S5m"
          },
          "PaginationToken": {}
        }
      }
    },
    "ListUsersInGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "GroupName": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "shape": "S5m"
          },
          "NextToken": {}
        }
      }
    },
    "ResendConfirmationCode": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username"
        ],
        "members": {
          "ClientId": {
            "shape": "S1d"
          },
          "SecretHash": {
            "shape": "S2o"
          },
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S4k"
          }
        }
      },
      "authtype": "none"
    },
    "RespondToAuthChallenge": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "ChallengeName"
        ],
        "members": {
          "ClientId": {
            "shape": "S1d"
          },
          "ChallengeName": {},
          "Session": {},
          "ChallengeResponses": {
            "shape": "S26"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1k"
          },
          "AuthenticationResult": {
            "shape": "S1l"
          }
        }
      }
    },
    "SetUserSettings": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "MFAOptions"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1m"
          },
          "MFAOptions": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "SignUp": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "Password"
        ],
        "members": {
          "ClientId": {
            "shape": "S1d"
          },
          "SecretHash": {
            "shape": "S2o"
          },
          "Username": {
            "shape": "Sd"
          },
          "Password": {
            "shape": "Sm"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "ValidationData": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserConfirmed": {
            "type": "boolean"
          },
          "CodeDeliveryDetails": {
            "shape": "S4k"
          }
        }
      },
      "authtype": "none"
    },
    "StartUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S2y"
          }
        }
      }
    },
    "StopUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S2y"
          }
        }
      }
    },
    "UpdateDeviceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1m"
          },
          "DeviceKey": {},
          "DeviceRememberedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {},
          "Description": {},
          "RoleArn": {},
          "Precedence": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S1y"
          }
        }
      }
    },
    "UpdateUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserAttributes"
        ],
        "members": {
          "UserAttributes": {
            "shape": "Si"
          },
          "AccessToken": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetailsList": {
            "type": "list",
            "member": {
              "shape": "S4k"
            }
          }
        }
      },
      "authtype": "none"
    },
    "UpdateUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "Policies": {
            "shape": "S36"
          },
          "LambdaConfig": {
            "shape": "S39"
          },
          "AutoVerifiedAttributes": {
            "shape": "S3a"
          },
          "SmsVerificationMessage": {},
          "EmailVerificationMessage": {},
          "EmailVerificationSubject": {},
          "SmsAuthenticationMessage": {},
          "MfaConfiguration": {},
          "DeviceConfiguration": {
            "shape": "S3i"
          },
          "EmailConfiguration": {
            "shape": "S3j"
          },
          "SmsConfiguration": {
            "shape": "S3l"
          },
          "UserPoolTags": {
            "shape": "S3m"
          },
          "AdminCreateUserConfig": {
            "shape": "S3n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1d"
          },
          "ClientName": {},
          "RefreshTokenValidity": {
            "type": "integer"
          },
          "ReadAttributes": {
            "shape": "S3y"
          },
          "WriteAttributes": {
            "shape": "S3y"
          },
          "ExplicitAuthFlows": {
            "shape": "S40"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S43"
          }
        }
      }
    },
    "VerifyUserAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "AttributeName",
          "Code"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1m"
          },
          "AttributeName": {},
          "Code": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "Name": {},
        "AttributeDataType": {},
        "DeveloperOnlyAttribute": {
          "type": "boolean"
        },
        "Mutable": {
          "type": "boolean"
        },
        "Required": {
          "type": "boolean"
        },
        "NumberAttributeConstraints": {
          "type": "structure",
          "members": {
            "MinValue": {},
            "MaxValue": {}
          }
        },
        "StringAttributeConstraints": {
          "type": "structure",
          "members": {
            "MinLength": {},
            "MaxLength": {}
          }
        }
      }
    },
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "Sm": {
      "type": "string",
      "sensitive": true
    },
    "Ss": {
      "type": "structure",
      "members": {
        "Username": {
          "shape": "Sd"
        },
        "Attributes": {
          "shape": "Si"
        },
        "UserCreateDate": {
          "type": "timestamp"
        },
        "UserLastModifiedDate": {
          "type": "timestamp"
        },
        "Enabled": {
          "type": "boolean"
        },
        "UserStatus": {},
        "MFAOptions": {
          "shape": "Sv"
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DeliveryMedium": {},
          "AttributeName": {}
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S19": {
      "type": "structure",
      "members": {
        "DeviceKey": {},
        "DeviceAttributes": {
          "shape": "Si"
        },
        "DeviceCreateDate": {
          "type": "timestamp"
        },
        "DeviceLastModifiedDate": {
          "type": "timestamp"
        },
        "DeviceLastAuthenticatedDate": {
          "type": "timestamp"
        }
      }
    },
    "S1d": {
      "type": "string",
      "sensitive": true
    },
    "S1f": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1g": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1k": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1l": {
      "type": "structure",
      "members": {
        "AccessToken": {
          "shape": "S1m"
        },
        "ExpiresIn": {
          "type": "integer"
        },
        "TokenType": {},
        "RefreshToken": {
          "shape": "S1m"
        },
        "IdToken": {
          "shape": "S1m"
        },
        "NewDeviceMetadata": {
          "type": "structure",
          "members": {
            "DeviceKey": {},
            "DeviceGroupKey": {}
          }
        }
      }
    },
    "S1m": {
      "type": "string",
      "sensitive": true
    },
    "S1t": {
      "type": "list",
      "member": {
        "shape": "S19"
      }
    },
    "S1x": {
      "type": "list",
      "member": {
        "shape": "S1y"
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "GroupName": {},
        "UserPoolId": {},
        "Description": {},
        "RoleArn": {},
        "Precedence": {
          "type": "integer"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        }
      }
    },
    "S26": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2o": {
      "type": "string",
      "sensitive": true
    },
    "S2y": {
      "type": "structure",
      "members": {
        "JobName": {},
        "JobId": {},
        "UserPoolId": {},
        "PreSignedUrl": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "StartDate": {
          "type": "timestamp"
        },
        "CompletionDate": {
          "type": "timestamp"
        },
        "Status": {},
        "CloudWatchLogsRoleArn": {},
        "ImportedUsers": {
          "type": "long"
        },
        "SkippedUsers": {
          "type": "long"
        },
        "FailedUsers": {
          "type": "long"
        },
        "CompletionMessage": {}
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "PasswordPolicy": {
          "type": "structure",
          "members": {
            "MinimumLength": {
              "type": "integer"
            },
            "RequireUppercase": {
              "type": "boolean"
            },
            "RequireLowercase": {
              "type": "boolean"
            },
            "RequireNumbers": {
              "type": "boolean"
            },
            "RequireSymbols": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "PreSignUp": {},
        "CustomMessage": {},
        "PostConfirmation": {},
        "PreAuthentication": {},
        "PostAuthentication": {},
        "DefineAuthChallenge": {},
        "CreateAuthChallenge": {},
        "VerifyAuthChallengeResponse": {}
      }
    },
    "S3a": {
      "type": "list",
      "member": {}
    },
    "S3c": {
      "type": "list",
      "member": {}
    },
    "S3i": {
      "type": "structure",
      "members": {
        "ChallengeRequiredOnNewDevice": {
          "type": "boolean"
        },
        "DeviceOnlyRememberedOnUserPrompt": {
          "type": "boolean"
        }
      }
    },
    "S3j": {
      "type": "structure",
      "members": {
        "SourceArn": {},
        "ReplyToEmailAddress": {}
      }
    },
    "S3l": {
      "type": "structure",
      "required": [
        "SnsCallerArn"
      ],
      "members": {
        "SnsCallerArn": {},
        "ExternalId": {}
      }
    },
    "S3m": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S3n": {
      "type": "structure",
      "members": {
        "AllowAdminCreateUserOnly": {
          "type": "boolean"
        },
        "UnusedAccountValidityDays": {
          "type": "integer"
        },
        "InviteMessageTemplate": {
          "type": "structure",
          "members": {
            "SMSMessage": {},
            "EmailMessage": {},
            "EmailSubject": {}
          }
        }
      }
    },
    "S3q": {
      "type": "list",
      "member": {
        "shape": "S4"
      }
    },
    "S3s": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Policies": {
          "shape": "S36"
        },
        "LambdaConfig": {
          "shape": "S39"
        },
        "Status": {},
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        },
        "SchemaAttributes": {
          "shape": "S3q"
        },
        "AutoVerifiedAttributes": {
          "shape": "S3a"
        },
        "AliasAttributes": {
          "shape": "S3c"
        },
        "SmsVerificationMessage": {},
        "EmailVerificationMessage": {},
        "EmailVerificationSubject": {},
        "SmsAuthenticationMessage": {},
        "MfaConfiguration": {},
        "DeviceConfiguration": {
          "shape": "S3i"
        },
        "EstimatedNumberOfUsers": {
          "type": "integer"
        },
        "EmailConfiguration": {
          "shape": "S3j"
        },
        "SmsConfiguration": {
          "shape": "S3l"
        },
        "UserPoolTags": {
          "shape": "S3m"
        },
        "SmsConfigurationFailure": {},
        "EmailConfigurationFailure": {},
        "AdminCreateUserConfig": {
          "shape": "S3n"
        }
      }
    },
    "S3y": {
      "type": "list",
      "member": {}
    },
    "S40": {
      "type": "list",
      "member": {}
    },
    "S43": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ClientName": {},
        "ClientId": {
          "shape": "S1d"
        },
        "ClientSecret": {
          "type": "string",
          "sensitive": true
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        },
        "RefreshTokenValidity": {
          "type": "integer"
        },
        "ReadAttributes": {
          "shape": "S3y"
        },
        "WriteAttributes": {
          "shape": "S3y"
        },
        "ExplicitAuthFlows": {
          "shape": "S40"
        }
      }
    },
    "S4k": {
      "type": "structure",
      "members": {
        "Destination": {},
        "DeliveryMedium": {},
        "AttributeName": {}
      }
    },
    "S5m": {
      "type": "list",
      "member": {
        "shape": "Ss"
      }
    }
  }
}