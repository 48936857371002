
import * as randomBytes from 'randombytes';
import {SpotSerializableObject} from './base';
import {SignConfig} from './signconfig';
import {LocaleText} from './localeText';

const randomChars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lenChars = randomChars.length;

export interface ListResult<T> {
    items: T[];
    lastKey: string;
}

export interface AttributeSerializer {
    fromInteger(val:number, name:string) : Object;
    setInteger(obj:any, setter: (val:number) => void);
    fromFloat(val:number, name:string) : Object;
    setFloat(obj:any, setter: (val:number) => void);
    fromBool(val:boolean, name:string) : Object;
    setBool(obj:any, setter: (val:boolean) => void);
    fromString(val:string, name:string) : Object;
    setString(obj:any, setter: (val:string) => void);
    fromStringArray(val:string[], name:string) : Object;
    setStringArray(obj:any, setter: (val:string[]) => void);
    fromDate(val:Date, name:string) : Object;
    setDate(obj:any, setter: (val:Date) => void);
    fromObject<T extends SpotSerializableObject>(val:T, name:string) : Object;
    setObject<T extends SpotSerializableObject>(obj:any, creator:ObjectSerializer<T>, setter: (val:T) => void);
    fromObjectArray<T extends SpotSerializableObject>(val:T[], name:string) : Object;
    setObjectArray<T extends SpotSerializableObject>(obj:any, creator:ObjectSerializer<T>, setter: (val:T[]) => void);
    fromObjectMap<T extends SpotSerializableObject>(val:Map<string,T>, name:string) : Object;
    setObjectMap<T extends SpotSerializableObject>(obj:any, creator:ObjectSerializer<T>, setter: (val:Map<string,T>) => void);
    fromLocaleText(val:LocaleText | string, name:string) : Object;
    setLocaleText(obj:any, setter: (val:LocaleText | string) => void);
}

export interface ObjectSerializer<T extends SpotSerializableObject> {
    fromAttr(s:AttributeSerializer, a:any) : T;
}

export interface FilterExpression {
    expression: string; // Example '#s = :st'
    attributeValues?: { [v:string]:string }; // Attributes keyed by the name referenced in expression
    filterValues: { [v:string]:string }; // Values keyed by the name referenced in expression
}

export interface SpotDB {
    readonly serializer : AttributeSerializer;
    readonly proxy : SpotDBProxy;
    put(collection:string, id:string, attrs:any) : Promise<any>;
    get(collection:string, id:string) : Promise<any>;
    delete(collection:string, id:string) : Promise<{}>;
    listIds(collection:string, startAfter?:string, limit?: number) : Promise<ListResult<string>>;
    list(collection:string, startAfter?:string, limit?: number) : Promise<ListResult<any>>;
    listWithFilter(collection:string, filter: FilterExpression, startAfter?:string, limit?: number) : Promise<ListResult<any>>;
    listByIndex(collection:string, index:string, attributeName: string, value:any, startAfter?:string, limit?: number) : Promise<ListResult<any>>;
    batchDelete(collection:string, ids:string[]) : Promise<void>;
}

export interface SpotDBProxy extends SpotDB {
    proxyGetCategories(jurisdictionId:string) : Promise<string[]>;
    proxyGetSignConfigByJurisdiction(jurisdictionId:string, category:string) : Promise<SignConfig>;
    proxyGetSignedPutUrl(imageHash:string) : Promise<string>;
}

/** generate a new object id */
export function generateID() : string {
    return String.fromCharCode( ...randomBytes(32).map(
        c => randomChars.charCodeAt(c % lenChars)
    ));
}
