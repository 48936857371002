module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-06-23",
    "endpointPrefix": "devicefarm",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Device Farm",
    "signatureVersion": "v4",
    "targetPrefix": "DeviceFarm_20150623",
    "uid": "devicefarm-2015-06-23"
  },
  "operations": {
    "CreateDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "name",
          "rules"
        ],
        "members": {
          "projectArn": {},
          "name": {},
          "description": {},
          "rules": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePool": {
            "shape": "Sb"
          }
        }
      }
    },
    "CreateProject": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "Sf"
          }
        }
      }
    },
    "CreateRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "deviceArn"
        ],
        "members": {
          "projectArn": {},
          "deviceArn": {},
          "name": {},
          "configuration": {
            "type": "structure",
            "members": {
              "billingMethod": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSession": {
            "shape": "Sl"
          }
        }
      }
    },
    "CreateUpload": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "name",
          "type"
        ],
        "members": {
          "projectArn": {},
          "name": {},
          "type": {},
          "contentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "upload": {
            "shape": "S12"
          }
        }
      }
    },
    "DeleteDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProject": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRun": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteUpload": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetAccountSettings": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "accountSettings": {
            "type": "structure",
            "members": {
              "awsAccountNumber": {},
              "unmeteredDevices": {
                "shape": "S1k"
              },
              "unmeteredRemoteAccessDevices": {
                "shape": "S1k"
              }
            }
          }
        }
      }
    },
    "GetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "device": {
            "shape": "So"
          }
        }
      }
    },
    "GetDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePool": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetDevicePoolCompatibility": {
      "input": {
        "type": "structure",
        "required": [
          "devicePoolArn"
        ],
        "members": {
          "devicePoolArn": {},
          "appArn": {},
          "testType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "compatibleDevices": {
            "shape": "S1s"
          },
          "incompatibleDevices": {
            "shape": "S1s"
          }
        }
      }
    },
    "GetJob": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "job": {
            "shape": "S1y"
          }
        }
      }
    },
    "GetOfferingStatus": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "current": {
            "shape": "S23"
          },
          "nextPeriod": {
            "shape": "S23"
          },
          "nextToken": {}
        }
      }
    },
    "GetProject": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "Sf"
          }
        }
      }
    },
    "GetRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSession": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetRun": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "run": {
            "shape": "S2k"
          }
        }
      }
    },
    "GetSuite": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "suite": {
            "shape": "S2n"
          }
        }
      }
    },
    "GetTest": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "test": {
            "shape": "S2q"
          }
        }
      }
    },
    "GetUpload": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "upload": {
            "shape": "S12"
          }
        }
      }
    },
    "InstallToRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "remoteAccessSessionArn",
          "appArn"
        ],
        "members": {
          "remoteAccessSessionArn": {},
          "appArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "appUpload": {
            "shape": "S12"
          }
        }
      }
    },
    "ListArtifacts": {
      "input": {
        "type": "structure",
        "required": [
          "arn",
          "type"
        ],
        "members": {
          "arn": {},
          "type": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "artifacts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "type": {},
                "extension": {},
                "url": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDevicePools": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "type": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePools": {
            "type": "list",
            "member": {
              "shape": "Sb"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDevices": {
      "input": {
        "type": "structure",
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devices": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobs": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "shape": "S1y"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOfferingTransactions": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offeringTransactions": {
            "type": "list",
            "member": {
              "shape": "S3d"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offerings": {
            "type": "list",
            "member": {
              "shape": "S27"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListProjects": {
      "input": {
        "type": "structure",
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "projects": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRemoteAccessSessions": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSessions": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRuns": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "runs": {
            "type": "list",
            "member": {
              "shape": "S2k"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSamples": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "samples": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "type": {},
                "url": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSuites": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "suites": {
            "type": "list",
            "member": {
              "shape": "S2n"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTests": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tests": {
            "type": "list",
            "member": {
              "shape": "S2q"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListUniqueProblems": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "uniqueProblems": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "message": {},
                  "problems": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "run": {
                          "shape": "S49"
                        },
                        "job": {
                          "shape": "S49"
                        },
                        "suite": {
                          "shape": "S49"
                        },
                        "test": {
                          "shape": "S49"
                        },
                        "device": {
                          "shape": "So"
                        },
                        "result": {},
                        "message": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListUploads": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "uploads": {
            "type": "list",
            "member": {
              "shape": "S12"
            }
          },
          "nextToken": {}
        }
      }
    },
    "PurchaseOffering": {
      "input": {
        "type": "structure",
        "members": {
          "offeringId": {},
          "quantity": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offeringTransaction": {
            "shape": "S3d"
          }
        }
      }
    },
    "RenewOffering": {
      "input": {
        "type": "structure",
        "members": {
          "offeringId": {},
          "quantity": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "offeringTransaction": {
            "shape": "S3d"
          }
        }
      }
    },
    "ScheduleRun": {
      "input": {
        "type": "structure",
        "required": [
          "projectArn",
          "devicePoolArn",
          "test"
        ],
        "members": {
          "projectArn": {},
          "appArn": {},
          "devicePoolArn": {},
          "name": {},
          "test": {
            "type": "structure",
            "required": [
              "type"
            ],
            "members": {
              "type": {},
              "testPackageArn": {},
              "filter": {},
              "parameters": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          },
          "configuration": {
            "type": "structure",
            "members": {
              "extraDataPackageArn": {},
              "networkProfileArn": {},
              "locale": {},
              "location": {
                "type": "structure",
                "required": [
                  "latitude",
                  "longitude"
                ],
                "members": {
                  "latitude": {
                    "type": "double"
                  },
                  "longitude": {
                    "type": "double"
                  }
                }
              },
              "radios": {
                "type": "structure",
                "members": {
                  "wifi": {
                    "type": "boolean"
                  },
                  "bluetooth": {
                    "type": "boolean"
                  },
                  "nfc": {
                    "type": "boolean"
                  },
                  "gps": {
                    "type": "boolean"
                  }
                }
              },
              "auxiliaryApps": {
                "type": "list",
                "member": {}
              },
              "billingMethod": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "run": {
            "shape": "S2k"
          }
        }
      }
    },
    "StopRemoteAccessSession": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "remoteAccessSession": {
            "shape": "Sl"
          }
        }
      }
    },
    "StopRun": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "run": {
            "shape": "S2k"
          }
        }
      }
    },
    "UpdateDevicePool": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {},
          "description": {},
          "rules": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "devicePool": {
            "shape": "Sb"
          }
        }
      }
    },
    "UpdateProject": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {},
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "Sf"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "attribute": {},
          "operator": {},
          "value": {}
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "description": {},
        "type": {},
        "rules": {
          "shape": "S5"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "created": {
          "type": "timestamp"
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "message": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "device": {
          "shape": "So"
        },
        "billingMethod": {},
        "deviceMinutes": {
          "shape": "Sx"
        },
        "endpoint": {}
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "manufacturer": {},
        "model": {},
        "formFactor": {},
        "platform": {},
        "os": {},
        "cpu": {
          "type": "structure",
          "members": {
            "frequency": {},
            "architecture": {},
            "clock": {
              "type": "double"
            }
          }
        },
        "resolution": {
          "type": "structure",
          "members": {
            "width": {
              "type": "integer"
            },
            "height": {
              "type": "integer"
            }
          }
        },
        "heapSize": {
          "type": "long"
        },
        "memory": {
          "type": "long"
        },
        "image": {},
        "carrier": {},
        "radio": {},
        "remoteAccessEnabled": {
          "type": "boolean"
        },
        "fleetType": {},
        "fleetName": {}
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "total": {
          "type": "double"
        },
        "metered": {
          "type": "double"
        },
        "unmetered": {
          "type": "double"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "created": {
          "type": "timestamp"
        },
        "type": {},
        "status": {},
        "url": {},
        "metadata": {},
        "contentType": {},
        "message": {}
      }
    },
    "S1k": {
      "type": "map",
      "key": {},
      "value": {
        "type": "integer"
      }
    },
    "S1s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "device": {
            "shape": "So"
          },
          "compatible": {
            "type": "boolean"
          },
          "incompatibilityMessages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "message": {},
                "type": {}
              }
            }
          }
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S1z"
        },
        "message": {},
        "device": {
          "shape": "So"
        },
        "deviceMinutes": {
          "shape": "Sx"
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "total": {
          "type": "integer"
        },
        "passed": {
          "type": "integer"
        },
        "failed": {
          "type": "integer"
        },
        "warned": {
          "type": "integer"
        },
        "errored": {
          "type": "integer"
        },
        "stopped": {
          "type": "integer"
        },
        "skipped": {
          "type": "integer"
        }
      }
    },
    "S23": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S25"
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "type": {},
        "offering": {
          "shape": "S27"
        },
        "quantity": {
          "type": "integer"
        },
        "effectiveOn": {
          "type": "timestamp"
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "id": {},
        "description": {},
        "type": {},
        "platform": {},
        "recurringCharges": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "cost": {
                "shape": "S2b"
              },
              "frequency": {}
            }
          }
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "amount": {
          "type": "double"
        },
        "currencyCode": {}
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "platform": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S1z"
        },
        "message": {},
        "totalJobs": {
          "type": "integer"
        },
        "completedJobs": {
          "type": "integer"
        },
        "billingMethod": {},
        "deviceMinutes": {
          "shape": "Sx"
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S1z"
        },
        "message": {},
        "deviceMinutes": {
          "shape": "Sx"
        }
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {},
        "type": {},
        "created": {
          "type": "timestamp"
        },
        "status": {},
        "result": {},
        "started": {
          "type": "timestamp"
        },
        "stopped": {
          "type": "timestamp"
        },
        "counters": {
          "shape": "S1z"
        },
        "message": {},
        "deviceMinutes": {
          "shape": "Sx"
        }
      }
    },
    "S3d": {
      "type": "structure",
      "members": {
        "offeringStatus": {
          "shape": "S25"
        },
        "transactionId": {},
        "createdOn": {
          "type": "timestamp"
        },
        "cost": {
          "shape": "S2b"
        }
      }
    },
    "S49": {
      "type": "structure",
      "members": {
        "arn": {},
        "name": {}
      }
    }
  }
}