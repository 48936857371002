module.exports={
  "pagination": {
    "DescribeAccountAttributes": {
      "result_key": "AccountAttributes"
    },
    "DescribeAddresses": {
      "result_key": "Addresses"
    },
    "DescribeAvailabilityZones": {
      "result_key": "AvailabilityZones"
    },
    "DescribeBundleTasks": {
      "result_key": "BundleTasks"
    },
    "DescribeConversionTasks": {
      "result_key": "ConversionTasks"
    },
    "DescribeCustomerGateways": {
      "result_key": "CustomerGateways"
    },
    "DescribeDhcpOptions": {
      "result_key": "DhcpOptions"
    },
    "DescribeExportTasks": {
      "result_key": "ExportTasks"
    },
    "DescribeImages": {
      "result_key": "Images"
    },
    "DescribeInstanceStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "InstanceStatuses"
    },
    "DescribeInstances": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Reservations"
    },
    "DescribeInternetGateways": {
      "result_key": "InternetGateways"
    },
    "DescribeKeyPairs": {
      "result_key": "KeyPairs"
    },
    "DescribeNetworkAcls": {
      "result_key": "NetworkAcls"
    },
    "DescribeNetworkInterfaces": {
      "result_key": "NetworkInterfaces"
    },
    "DescribePlacementGroups": {
      "result_key": "PlacementGroups"
    },
    "DescribeRegions": {
      "result_key": "Regions"
    },
    "DescribeReservedInstances": {
      "result_key": "ReservedInstances"
    },
    "DescribeReservedInstancesListings": {
      "result_key": "ReservedInstancesListings"
    },
    "DescribeReservedInstancesModifications": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "ReservedInstancesModifications"
    },
    "DescribeReservedInstancesOfferings": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ReservedInstancesOfferings"
    },
    "DescribeRouteTables": {
      "result_key": "RouteTables"
    },
    "DescribeSecurityGroups": {
      "result_key": "SecurityGroups"
    },
    "DescribeSnapshots": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Snapshots"
    },
    "DescribeSpotFleetRequests": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotFleetRequestConfigs"
    },
    "DescribeSpotInstanceRequests": {
      "result_key": "SpotInstanceRequests"
    },
    "DescribeSpotPriceHistory": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "SpotPriceHistory"
    },
    "DescribeSubnets": {
      "result_key": "Subnets"
    },
    "DescribeTags": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Tags"
    },
    "DescribeVolumeStatus": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "VolumeStatuses"
    },
    "DescribeVolumes": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Volumes"
    },
    "DescribeVpcPeeringConnections": {
      "result_key": "VpcPeeringConnections"
    },
    "DescribeVpcs": {
      "result_key": "Vpcs"
    },
    "DescribeVpnConnections": {
      "result_key": "VpnConnections"
    },
    "DescribeVpnGateways": {
      "result_key": "VpnGateways"
    }
  }
}