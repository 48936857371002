module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "elasticmapreduce-2009-03-31",
    "apiVersion": "2009-03-31",
    "endpointPrefix": "elasticmapreduce",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon EMR",
    "serviceFullName": "Amazon Elastic MapReduce",
    "signatureVersion": "v4",
    "targetPrefix": "ElasticMapReduce",
    "timestampFormat": "unixTimestamp"
  },
  "operations": {
    "AddInstanceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceGroups",
          "JobFlowId"
        ],
        "members": {
          "InstanceGroups": {
            "shape": "S2"
          },
          "JobFlowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlowId": {},
          "InstanceGroupIds": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "AddJobFlowSteps": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowId",
          "Steps"
        ],
        "members": {
          "JobFlowId": {},
          "Steps": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StepIds": {
            "shape": "S19"
          }
        }
      }
    },
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceId": {},
          "Tags": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelSteps": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "StepIds": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancelStepsInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StepId": {},
                "Status": {},
                "Reason": {}
              }
            }
          }
        }
      }
    },
    "CreateSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SecurityConfiguration"
        ],
        "members": {
          "Name": {},
          "SecurityConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name",
          "CreationDateTime"
        ],
        "members": {
          "Name": {},
          "CreationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DeleteSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Status": {
                "shape": "S1u"
              },
              "Ec2InstanceAttributes": {
                "type": "structure",
                "members": {
                  "Ec2KeyName": {},
                  "Ec2SubnetId": {},
                  "Ec2AvailabilityZone": {},
                  "IamInstanceProfile": {},
                  "EmrManagedMasterSecurityGroup": {},
                  "EmrManagedSlaveSecurityGroup": {},
                  "ServiceAccessSecurityGroup": {},
                  "AdditionalMasterSecurityGroups": {
                    "shape": "S20"
                  },
                  "AdditionalSlaveSecurityGroups": {
                    "shape": "S20"
                  }
                }
              },
              "LogUri": {},
              "RequestedAmiVersion": {},
              "RunningAmiVersion": {},
              "ReleaseLabel": {},
              "AutoTerminate": {
                "type": "boolean"
              },
              "TerminationProtected": {
                "type": "boolean"
              },
              "VisibleToAllUsers": {
                "type": "boolean"
              },
              "Applications": {
                "shape": "S22"
              },
              "Tags": {
                "shape": "S1c"
              },
              "ServiceRole": {},
              "NormalizedInstanceHours": {
                "type": "integer"
              },
              "MasterPublicDnsName": {},
              "Configurations": {
                "shape": "S9"
              },
              "SecurityConfiguration": {},
              "AutoScalingRole": {},
              "ScaleDownBehavior": {}
            }
          }
        }
      }
    },
    "DescribeJobFlows": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "JobFlowIds": {
            "shape": "S17"
          },
          "JobFlowStates": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlows": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "JobFlowId",
                "Name",
                "ExecutionStatusDetail",
                "Instances"
              ],
              "members": {
                "JobFlowId": {},
                "Name": {},
                "LogUri": {},
                "AmiVersion": {},
                "ExecutionStatusDetail": {
                  "type": "structure",
                  "required": [
                    "State",
                    "CreationDateTime"
                  ],
                  "members": {
                    "State": {},
                    "CreationDateTime": {
                      "type": "timestamp"
                    },
                    "StartDateTime": {
                      "type": "timestamp"
                    },
                    "ReadyDateTime": {
                      "type": "timestamp"
                    },
                    "EndDateTime": {
                      "type": "timestamp"
                    },
                    "LastStateChangeReason": {}
                  }
                },
                "Instances": {
                  "type": "structure",
                  "required": [
                    "MasterInstanceType",
                    "SlaveInstanceType",
                    "InstanceCount"
                  ],
                  "members": {
                    "MasterInstanceType": {},
                    "MasterPublicDnsName": {},
                    "MasterInstanceId": {},
                    "SlaveInstanceType": {},
                    "InstanceCount": {
                      "type": "integer"
                    },
                    "InstanceGroups": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "Market",
                          "InstanceRole",
                          "InstanceType",
                          "InstanceRequestCount",
                          "InstanceRunningCount",
                          "State",
                          "CreationDateTime"
                        ],
                        "members": {
                          "InstanceGroupId": {},
                          "Name": {},
                          "Market": {},
                          "InstanceRole": {},
                          "BidPrice": {},
                          "InstanceType": {},
                          "InstanceRequestCount": {
                            "type": "integer"
                          },
                          "InstanceRunningCount": {
                            "type": "integer"
                          },
                          "State": {},
                          "LastStateChangeReason": {},
                          "CreationDateTime": {
                            "type": "timestamp"
                          },
                          "StartDateTime": {
                            "type": "timestamp"
                          },
                          "ReadyDateTime": {
                            "type": "timestamp"
                          },
                          "EndDateTime": {
                            "type": "timestamp"
                          }
                        }
                      }
                    },
                    "NormalizedInstanceHours": {
                      "type": "integer"
                    },
                    "Ec2KeyName": {},
                    "Ec2SubnetId": {},
                    "Placement": {
                      "shape": "S2g"
                    },
                    "KeepJobFlowAliveWhenNoSteps": {
                      "type": "boolean"
                    },
                    "TerminationProtected": {
                      "type": "boolean"
                    },
                    "HadoopVersion": {}
                  }
                },
                "Steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "StepConfig",
                      "ExecutionStatusDetail"
                    ],
                    "members": {
                      "StepConfig": {
                        "shape": "S11"
                      },
                      "ExecutionStatusDetail": {
                        "type": "structure",
                        "required": [
                          "State",
                          "CreationDateTime"
                        ],
                        "members": {
                          "State": {},
                          "CreationDateTime": {
                            "type": "timestamp"
                          },
                          "StartDateTime": {
                            "type": "timestamp"
                          },
                          "EndDateTime": {
                            "type": "timestamp"
                          },
                          "LastStateChangeReason": {}
                        }
                      }
                    }
                  }
                },
                "BootstrapActions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "BootstrapActionConfig": {
                        "shape": "S2n"
                      }
                    }
                  }
                },
                "SupportedProducts": {
                  "shape": "S2p"
                },
                "VisibleToAllUsers": {
                  "type": "boolean"
                },
                "JobFlowRole": {},
                "ServiceRole": {},
                "AutoScalingRole": {},
                "ScaleDownBehavior": {}
              }
            }
          }
        }
      },
      "deprecated": true
    },
    "DescribeSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "SecurityConfiguration": {},
          "CreationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStep": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "StepId"
        ],
        "members": {
          "ClusterId": {},
          "StepId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Step": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Config": {
                "shape": "S2v"
              },
              "ActionOnFailure": {},
              "Status": {
                "shape": "S2w"
              }
            }
          }
        }
      }
    },
    "ListBootstrapActions": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BootstrapActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "ScriptPath": {},
                "Args": {
                  "shape": "S20"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListClusters": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "ClusterStates": {
            "type": "list",
            "member": {}
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Clusters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {
                  "shape": "S1u"
                },
                "NormalizedInstanceHours": {
                  "type": "integer"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstanceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Market": {},
                "InstanceGroupType": {},
                "BidPrice": {},
                "InstanceType": {},
                "RequestedInstanceCount": {
                  "type": "integer"
                },
                "RunningInstanceCount": {
                  "type": "integer"
                },
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "Configurations": {
                  "shape": "S9"
                },
                "EbsBlockDevices": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "VolumeSpecification": {
                        "shape": "Sg"
                      },
                      "Device": {}
                    }
                  }
                },
                "EbsOptimized": {
                  "type": "boolean"
                },
                "ShrinkPolicy": {
                  "shape": "S3o"
                },
                "AutoScalingPolicy": {
                  "shape": "S3s"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "InstanceGroupTypes": {
            "type": "list",
            "member": {}
          },
          "InstanceStates": {
            "type": "list",
            "member": {}
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Ec2InstanceId": {},
                "PublicDnsName": {},
                "PublicIpAddress": {},
                "PrivateDnsName": {},
                "PrivateIpAddress": {},
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "InstanceGroupId": {},
                "EbsVolumes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Device": {},
                      "VolumeId": {}
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListSecurityConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "CreationDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListSteps": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "StepStates": {
            "type": "list",
            "member": {}
          },
          "StepIds": {
            "shape": "S17"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Steps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Config": {
                  "shape": "S2v"
                },
                "ActionOnFailure": {},
                "Status": {
                  "shape": "S2w"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ModifyInstanceGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceGroupId"
              ],
              "members": {
                "InstanceGroupId": {},
                "InstanceCount": {
                  "type": "integer"
                },
                "EC2InstanceIdsToTerminate": {
                  "type": "list",
                  "member": {}
                },
                "ShrinkPolicy": {
                  "shape": "S3o"
                }
              }
            }
          }
        }
      }
    },
    "PutAutoScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceGroupId",
          "AutoScalingPolicy"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "AutoScalingPolicy": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "AutoScalingPolicy": {
            "shape": "S3s"
          }
        }
      }
    },
    "RemoveAutoScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceGroupId"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceId": {},
          "TagKeys": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RunJobFlow": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Instances"
        ],
        "members": {
          "Name": {},
          "LogUri": {},
          "AdditionalInfo": {},
          "AmiVersion": {},
          "ReleaseLabel": {},
          "Instances": {
            "type": "structure",
            "members": {
              "MasterInstanceType": {},
              "SlaveInstanceType": {},
              "InstanceCount": {
                "type": "integer"
              },
              "InstanceGroups": {
                "shape": "S2"
              },
              "Ec2KeyName": {},
              "Placement": {
                "shape": "S2g"
              },
              "KeepJobFlowAliveWhenNoSteps": {
                "type": "boolean"
              },
              "TerminationProtected": {
                "type": "boolean"
              },
              "HadoopVersion": {},
              "Ec2SubnetId": {},
              "EmrManagedMasterSecurityGroup": {},
              "EmrManagedSlaveSecurityGroup": {},
              "ServiceAccessSecurityGroup": {},
              "AdditionalMasterSecurityGroups": {
                "shape": "S4v"
              },
              "AdditionalSlaveSecurityGroups": {
                "shape": "S4v"
              }
            }
          },
          "Steps": {
            "shape": "S10"
          },
          "BootstrapActions": {
            "type": "list",
            "member": {
              "shape": "S2n"
            }
          },
          "SupportedProducts": {
            "shape": "S2p"
          },
          "NewSupportedProducts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Args": {
                  "shape": "S17"
                }
              }
            }
          },
          "Applications": {
            "shape": "S22"
          },
          "Configurations": {
            "shape": "S9"
          },
          "VisibleToAllUsers": {
            "type": "boolean"
          },
          "JobFlowRole": {},
          "ServiceRole": {},
          "Tags": {
            "shape": "S1c"
          },
          "SecurityConfiguration": {},
          "AutoScalingRole": {},
          "ScaleDownBehavior": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlowId": {}
        }
      }
    },
    "SetTerminationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds",
          "TerminationProtected"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S17"
          },
          "TerminationProtected": {
            "type": "boolean"
          }
        }
      }
    },
    "SetVisibleToAllUsers": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds",
          "VisibleToAllUsers"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S17"
          },
          "VisibleToAllUsers": {
            "type": "boolean"
          }
        }
      }
    },
    "TerminateJobFlows": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S17"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "InstanceRole",
          "InstanceType",
          "InstanceCount"
        ],
        "members": {
          "Name": {},
          "Market": {},
          "InstanceRole": {},
          "BidPrice": {},
          "InstanceType": {},
          "InstanceCount": {
            "type": "integer"
          },
          "Configurations": {
            "shape": "S9"
          },
          "EbsConfiguration": {
            "type": "structure",
            "members": {
              "EbsBlockDeviceConfigs": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "VolumeSpecification"
                  ],
                  "members": {
                    "VolumeSpecification": {
                      "shape": "Sg"
                    },
                    "VolumesPerInstance": {
                      "type": "integer"
                    }
                  }
                }
              },
              "EbsOptimized": {
                "type": "boolean"
              }
            }
          },
          "AutoScalingPolicy": {
            "shape": "Si"
          }
        }
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Classification": {},
          "Configurations": {
            "shape": "S9"
          },
          "Properties": {
            "shape": "Sc"
          }
        }
      }
    },
    "Sc": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sg": {
      "type": "structure",
      "required": [
        "VolumeType",
        "SizeInGB"
      ],
      "members": {
        "VolumeType": {},
        "Iops": {
          "type": "integer"
        },
        "SizeInGB": {
          "type": "integer"
        }
      }
    },
    "Si": {
      "type": "structure",
      "required": [
        "Constraints",
        "Rules"
      ],
      "members": {
        "Constraints": {
          "shape": "Sj"
        },
        "Rules": {
          "shape": "Sk"
        }
      }
    },
    "Sj": {
      "type": "structure",
      "required": [
        "MinCapacity",
        "MaxCapacity"
      ],
      "members": {
        "MinCapacity": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "integer"
        }
      }
    },
    "Sk": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Action",
          "Trigger"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Action": {
            "type": "structure",
            "required": [
              "SimpleScalingPolicyConfiguration"
            ],
            "members": {
              "Market": {},
              "SimpleScalingPolicyConfiguration": {
                "type": "structure",
                "required": [
                  "ScalingAdjustment"
                ],
                "members": {
                  "AdjustmentType": {},
                  "ScalingAdjustment": {
                    "type": "integer"
                  },
                  "CoolDown": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "Trigger": {
            "type": "structure",
            "required": [
              "CloudWatchAlarmDefinition"
            ],
            "members": {
              "CloudWatchAlarmDefinition": {
                "type": "structure",
                "required": [
                  "ComparisonOperator",
                  "MetricName",
                  "Period",
                  "Threshold"
                ],
                "members": {
                  "ComparisonOperator": {},
                  "EvaluationPeriods": {
                    "type": "integer"
                  },
                  "MetricName": {},
                  "Namespace": {},
                  "Period": {
                    "type": "integer"
                  },
                  "Statistic": {},
                  "Threshold": {
                    "type": "double"
                  },
                  "Unit": {},
                  "Dimensions": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Key": {},
                        "Value": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S10": {
      "type": "list",
      "member": {
        "shape": "S11"
      }
    },
    "S11": {
      "type": "structure",
      "required": [
        "Name",
        "HadoopJarStep"
      ],
      "members": {
        "Name": {},
        "ActionOnFailure": {},
        "HadoopJarStep": {
          "type": "structure",
          "required": [
            "Jar"
          ],
          "members": {
            "Properties": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Key": {},
                  "Value": {}
                }
              }
            },
            "Jar": {},
            "MainClass": {},
            "Args": {
              "shape": "S17"
            }
          }
        }
      }
    },
    "S17": {
      "type": "list",
      "member": {}
    },
    "S19": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "State": {},
        "StateChangeReason": {
          "type": "structure",
          "members": {
            "Code": {},
            "Message": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "CreationDateTime": {
              "type": "timestamp"
            },
            "ReadyDateTime": {
              "type": "timestamp"
            },
            "EndDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {}
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "Args": {
            "shape": "S20"
          },
          "AdditionalInfo": {
            "shape": "Sc"
          }
        }
      }
    },
    "S2g": {
      "type": "structure",
      "required": [
        "AvailabilityZone"
      ],
      "members": {
        "AvailabilityZone": {}
      }
    },
    "S2n": {
      "type": "structure",
      "required": [
        "Name",
        "ScriptBootstrapAction"
      ],
      "members": {
        "Name": {},
        "ScriptBootstrapAction": {
          "type": "structure",
          "required": [
            "Path"
          ],
          "members": {
            "Path": {},
            "Args": {
              "shape": "S17"
            }
          }
        }
      }
    },
    "S2p": {
      "type": "list",
      "member": {}
    },
    "S2v": {
      "type": "structure",
      "members": {
        "Jar": {},
        "Properties": {
          "shape": "Sc"
        },
        "MainClass": {},
        "Args": {
          "shape": "S20"
        }
      }
    },
    "S2w": {
      "type": "structure",
      "members": {
        "State": {},
        "StateChangeReason": {
          "type": "structure",
          "members": {
            "Code": {},
            "Message": {}
          }
        },
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Reason": {},
            "Message": {},
            "LogFile": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "CreationDateTime": {
              "type": "timestamp"
            },
            "StartDateTime": {
              "type": "timestamp"
            },
            "EndDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S3o": {
      "type": "structure",
      "members": {
        "DecommissionTimeout": {
          "type": "integer"
        },
        "InstanceResizePolicy": {
          "type": "structure",
          "members": {
            "InstancesToTerminate": {
              "shape": "S3q"
            },
            "InstancesToProtect": {
              "shape": "S3q"
            },
            "InstanceTerminationTimeout": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S3q": {
      "type": "list",
      "member": {}
    },
    "S3s": {
      "type": "structure",
      "members": {
        "Status": {
          "type": "structure",
          "members": {
            "State": {},
            "StateChangeReason": {
              "type": "structure",
              "members": {
                "Code": {},
                "Message": {}
              }
            }
          }
        },
        "Constraints": {
          "shape": "Sj"
        },
        "Rules": {
          "shape": "Sk"
        }
      }
    },
    "S4v": {
      "type": "list",
      "member": {}
    }
  }
}