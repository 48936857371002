module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-12",
    "endpointPrefix": "config",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Config Service",
    "serviceFullName": "AWS Config",
    "signatureVersion": "v4",
    "targetPrefix": "StarlingDoveService",
    "uid": "config-2014-11-12"
  },
  "operations": {
    "DeleteConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {}
        }
      }
    },
    "DeleteConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "DeleteDeliveryChannel": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryChannelName"
        ],
        "members": {
          "DeliveryChannelName": {}
        }
      }
    },
    "DeleteEvaluationResults": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeliverConfigSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "deliveryChannelName"
        ],
        "members": {
          "deliveryChannelName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configSnapshotId": {}
        }
      }
    },
    "DescribeComplianceByConfigRule": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "Sd"
          },
          "ComplianceTypes": {
            "shape": "Se"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceByConfigRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "Compliance": {
                  "shape": "Sj"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeComplianceByResource": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "ComplianceTypes": {
            "shape": "Se"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceByResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "ResourceId": {},
                "Compliance": {
                  "shape": "Sj"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigRuleEvaluationStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "Sd"
          },
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigRulesEvaluationStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "ConfigRuleArn": {},
                "ConfigRuleId": {},
                "LastSuccessfulInvocationTime": {
                  "type": "timestamp"
                },
                "LastFailedInvocationTime": {
                  "type": "timestamp"
                },
                "LastSuccessfulEvaluationTime": {
                  "type": "timestamp"
                },
                "LastFailedEvaluationTime": {
                  "type": "timestamp"
                },
                "FirstActivatedTime": {
                  "type": "timestamp"
                },
                "LastErrorCode": {},
                "LastErrorMessage": {},
                "FirstEvaluationStarted": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigRules": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "Sd"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigRules": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigurationRecorderStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationRecorderNames": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationRecordersStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "lastStartTime": {
                  "type": "timestamp"
                },
                "lastStopTime": {
                  "type": "timestamp"
                },
                "recording": {
                  "type": "boolean"
                },
                "lastStatus": {},
                "lastErrorCode": {},
                "lastErrorMessage": {},
                "lastStatusChangeTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeConfigurationRecorders": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationRecorderNames": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationRecorders": {
            "type": "list",
            "member": {
              "shape": "S1q"
            }
          }
        }
      }
    },
    "DescribeDeliveryChannelStatus": {
      "input": {
        "type": "structure",
        "members": {
          "DeliveryChannelNames": {
            "shape": "S1x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryChannelsStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "configSnapshotDeliveryInfo": {
                  "shape": "S21"
                },
                "configHistoryDeliveryInfo": {
                  "shape": "S21"
                },
                "configStreamDeliveryInfo": {
                  "type": "structure",
                  "members": {
                    "lastStatus": {},
                    "lastErrorCode": {},
                    "lastErrorMessage": {},
                    "lastStatusChangeTime": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDeliveryChannels": {
      "input": {
        "type": "structure",
        "members": {
          "DeliveryChannelNames": {
            "shape": "S1x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryChannels": {
            "type": "list",
            "member": {
              "shape": "S27"
            }
          }
        }
      }
    },
    "GetComplianceDetailsByConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ComplianceTypes": {
            "shape": "Se"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationResults": {
            "shape": "S2b"
          },
          "NextToken": {}
        }
      }
    },
    "GetComplianceDetailsByResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "ComplianceTypes": {
            "shape": "Se"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationResults": {
            "shape": "S2b"
          },
          "NextToken": {}
        }
      }
    },
    "GetComplianceSummaryByConfigRule": {
      "output": {
        "type": "structure",
        "members": {
          "ComplianceSummary": {
            "shape": "S2i"
          }
        }
      }
    },
    "GetComplianceSummaryByResourceType": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceTypes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceSummariesByResourceType": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "ComplianceSummary": {
                  "shape": "S2i"
                }
              }
            }
          }
        }
      }
    },
    "GetResourceConfigHistory": {
      "input": {
        "type": "structure",
        "required": [
          "resourceType",
          "resourceId"
        ],
        "members": {
          "resourceType": {},
          "resourceId": {},
          "laterTime": {
            "type": "timestamp"
          },
          "earlierTime": {
            "type": "timestamp"
          },
          "chronologicalOrder": {},
          "limit": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configurationItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "version": {},
                "accountId": {},
                "configurationItemCaptureTime": {
                  "type": "timestamp"
                },
                "configurationItemStatus": {},
                "configurationStateId": {},
                "configurationItemMD5Hash": {},
                "arn": {},
                "resourceType": {},
                "resourceId": {},
                "resourceName": {},
                "awsRegion": {},
                "availabilityZone": {},
                "resourceCreationTime": {
                  "type": "timestamp"
                },
                "tags": {
                  "type": "map",
                  "key": {},
                  "value": {}
                },
                "relatedEvents": {
                  "type": "list",
                  "member": {}
                },
                "relationships": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "resourceType": {},
                      "resourceId": {},
                      "resourceName": {},
                      "relationshipName": {}
                    }
                  }
                },
                "configuration": {},
                "supplementaryConfiguration": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDiscoveredResources": {
      "input": {
        "type": "structure",
        "required": [
          "resourceType"
        ],
        "members": {
          "resourceType": {},
          "resourceIds": {
            "type": "list",
            "member": {}
          },
          "resourceName": {},
          "limit": {
            "type": "integer"
          },
          "includeDeletedResources": {
            "type": "boolean"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceType": {},
                "resourceId": {},
                "resourceName": {},
                "resourceDeletionTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "PutConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRule"
        ],
        "members": {
          "ConfigRule": {
            "shape": "S13"
          }
        }
      }
    },
    "PutConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorder"
        ],
        "members": {
          "ConfigurationRecorder": {
            "shape": "S1q"
          }
        }
      }
    },
    "PutDeliveryChannel": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryChannel"
        ],
        "members": {
          "DeliveryChannel": {
            "shape": "S27"
          }
        }
      }
    },
    "PutEvaluations": {
      "input": {
        "type": "structure",
        "required": [
          "ResultToken"
        ],
        "members": {
          "Evaluations": {
            "shape": "S3t"
          },
          "ResultToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEvaluations": {
            "shape": "S3t"
          }
        }
      }
    },
    "StartConfigRulesEvaluation": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "StopConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "structure",
      "members": {
        "ComplianceType": {},
        "ComplianceContributorCount": {
          "shape": "Sk"
        }
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "CappedCount": {
          "type": "integer"
        },
        "CapExceeded": {
          "type": "boolean"
        }
      }
    },
    "S13": {
      "type": "structure",
      "required": [
        "Source"
      ],
      "members": {
        "ConfigRuleName": {},
        "ConfigRuleArn": {},
        "ConfigRuleId": {},
        "Description": {},
        "Scope": {
          "type": "structure",
          "members": {
            "ComplianceResourceTypes": {
              "type": "list",
              "member": {}
            },
            "TagKey": {},
            "TagValue": {},
            "ComplianceResourceId": {}
          }
        },
        "Source": {
          "type": "structure",
          "required": [
            "Owner",
            "SourceIdentifier"
          ],
          "members": {
            "Owner": {},
            "SourceIdentifier": {},
            "SourceDetails": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "EventSource": {},
                  "MessageType": {},
                  "MaximumExecutionFrequency": {}
                }
              }
            }
          }
        },
        "InputParameters": {},
        "MaximumExecutionFrequency": {},
        "ConfigRuleState": {}
      }
    },
    "S1i": {
      "type": "list",
      "member": {}
    },
    "S1q": {
      "type": "structure",
      "members": {
        "name": {},
        "roleARN": {},
        "recordingGroup": {
          "type": "structure",
          "members": {
            "allSupported": {
              "type": "boolean"
            },
            "includeGlobalResourceTypes": {
              "type": "boolean"
            },
            "resourceTypes": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S1x": {
      "type": "list",
      "member": {}
    },
    "S21": {
      "type": "structure",
      "members": {
        "lastStatus": {},
        "lastErrorCode": {},
        "lastErrorMessage": {},
        "lastAttemptTime": {
          "type": "timestamp"
        },
        "lastSuccessfulTime": {
          "type": "timestamp"
        },
        "nextDeliveryTime": {
          "type": "timestamp"
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "name": {},
        "s3BucketName": {},
        "s3KeyPrefix": {},
        "snsTopicARN": {},
        "configSnapshotDeliveryProperties": {
          "type": "structure",
          "members": {
            "deliveryFrequency": {}
          }
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "EvaluationResultIdentifier": {
            "type": "structure",
            "members": {
              "EvaluationResultQualifier": {
                "type": "structure",
                "members": {
                  "ConfigRuleName": {},
                  "ResourceType": {},
                  "ResourceId": {}
                }
              },
              "OrderingTimestamp": {
                "type": "timestamp"
              }
            }
          },
          "ComplianceType": {},
          "ResultRecordedTime": {
            "type": "timestamp"
          },
          "ConfigRuleInvokedTime": {
            "type": "timestamp"
          },
          "Annotation": {},
          "ResultToken": {}
        }
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "CompliantResourceCount": {
          "shape": "Sk"
        },
        "NonCompliantResourceCount": {
          "shape": "Sk"
        },
        "ComplianceSummaryTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S3t": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ComplianceResourceType",
          "ComplianceResourceId",
          "ComplianceType",
          "OrderingTimestamp"
        ],
        "members": {
          "ComplianceResourceType": {},
          "ComplianceResourceId": {},
          "ComplianceType": {},
          "Annotation": {},
          "OrderingTimestamp": {
            "type": "timestamp"
          }
        }
      }
    }
  }
}