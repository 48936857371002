module.exports={
  "pagination": {
    "DescribeCacheClusters": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "CacheClusters"
    },
    "DescribeCacheEngineVersions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "CacheEngineVersions"
    },
    "DescribeCacheParameterGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "CacheParameterGroups"
    },
    "DescribeCacheParameters": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "Parameters"
    },
    "DescribeCacheSecurityGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "CacheSecurityGroups"
    },
    "DescribeCacheSubnetGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "CacheSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "output_token": "EngineDefaults.Marker",
      "limit_key": "MaxRecords",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "Events"
    },
    "DescribeReservedCacheNodes": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "ReservedCacheNodes"
    },
    "DescribeReservedCacheNodesOfferings": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "ReservedCacheNodesOfferings"
    },
    "DescribeReplicationGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "ReplicationGroups"
    },
    "DescribeSnapshots": {
      "input_token": "Marker",
      "output_token": "Marker",
      "limit_key": "MaxRecords",
      "result_key": "Snapshots"
    }
  }
}
