module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "servicecatalog-2015-12-10",
    "apiVersion": "2015-12-10",
    "endpointPrefix": "servicecatalog",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Service Catalog",
    "signatureVersion": "v4",
    "targetPrefix": "AWS242ServiceCatalogService"
  },
  "operations": {
    "AcceptPortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociatePrincipalWithPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "PrincipalARN",
          "PrincipalType"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PrincipalARN": {},
          "PrincipalType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateProductWithPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PortfolioId": {},
          "SourcePortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "ProductId",
          "Parameters",
          "Type",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "ProductId": {},
          "Parameters": {},
          "Type": {},
          "Description": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "Sh"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "CreatePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "DisplayName",
          "ProviderName",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "DisplayName": {},
          "Description": {},
          "ProviderName": {},
          "Tags": {
            "shape": "So"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "St"
          },
          "Tags": {
            "shape": "Sw"
          }
        }
      }
    },
    "CreatePortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "AccountId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Owner",
          "ProductType",
          "ProvisioningArtifactParameters",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "Name": {},
          "Owner": {},
          "Description": {},
          "Distributor": {},
          "SupportDescription": {},
          "SupportEmail": {},
          "SupportUrl": {},
          "ProductType": {},
          "Tags": {
            "shape": "So"
          },
          "ProvisioningArtifactParameters": {
            "shape": "S17"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S1f"
          },
          "ProvisioningArtifactDetail": {
            "shape": "S1k"
          },
          "Tags": {
            "shape": "Sw"
          }
        }
      }
    },
    "CreateProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "Parameters",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "Parameters": {
            "shape": "S17"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S1k"
          },
          "Info": {
            "shape": "S1a"
          },
          "Status": {}
        }
      }
    },
    "DeleteConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "AccountId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "Sh"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "DescribePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "St"
          },
          "Tags": {
            "shape": "Sw"
          }
        }
      }
    },
    "DescribeProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummary": {
            "shape": "S1g"
          },
          "ProvisioningArtifacts": {
            "shape": "S23"
          }
        }
      }
    },
    "DescribeProductAsAdmin": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S1f"
          },
          "Tags": {
            "shape": "Sw"
          }
        }
      }
    },
    "DescribeProductView": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummary": {
            "shape": "S1g"
          },
          "ProvisioningArtifacts": {
            "shape": "S23"
          }
        }
      }
    },
    "DescribeProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisioningArtifactId",
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisioningArtifactId": {},
          "ProductId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S1k"
          },
          "Info": {
            "shape": "S1a"
          },
          "Status": {}
        }
      }
    },
    "DescribeProvisioningParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "ParameterType": {},
                "IsNoEcho": {
                  "type": "boolean"
                },
                "Description": {},
                "ParameterConstraints": {
                  "type": "structure",
                  "members": {
                    "AllowedValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "ConstraintSummaries": {
            "shape": "S2o"
          },
          "UsageInstructions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Value": {}
              }
            }
          }
        }
      }
    },
    "DescribeRecord": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S2y"
          },
          "RecordOutputs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OutputKey": {},
                "OutputValue": {},
                "Description": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "DisassociatePrincipalFromPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "PrincipalARN"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PrincipalARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateProductFromPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListAcceptedPortfolioShares": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S3m"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListConstraintsForPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "ProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetails": {
            "type": "list",
            "member": {
              "shape": "Sh"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListLaunchPaths": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchPathSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ConstraintSummaries": {
                  "shape": "S2o"
                },
                "Tags": {
                  "shape": "Sw"
                },
                "Name": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfolioAccess": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "type": "list",
            "member": {}
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfolios": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S3m"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfoliosForProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S3m"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPrincipalsForPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Principals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PrincipalARN": {},
                "PrincipalType": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListProvisioningArtifacts": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetails": {
            "type": "list",
            "member": {
              "shape": "S1k"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListRecordHistory": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S4a"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetails": {
            "type": "list",
            "member": {
              "shape": "S2y"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ProvisionProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId",
          "ProvisionedProductName",
          "ProvisionToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {},
          "ProvisionedProductName": {},
          "ProvisioningParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "Tags": {
            "shape": "Sw"
          },
          "NotificationArns": {
            "type": "list",
            "member": {}
          },
          "ProvisionToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S2y"
          }
        }
      }
    },
    "RejectPortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ScanProvisionedProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S4a"
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProducts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "Type": {},
                "Id": {},
                "Status": {},
                "StatusMessage": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "IdempotencyToken": {},
                "LastRecordId": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "Filters": {
            "shape": "S50"
          },
          "PageSize": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummaries": {
            "type": "list",
            "member": {
              "shape": "S1g"
            }
          },
          "ProductViewAggregations": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Value": {},
                  "ApproximateCount": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProductsAsAdmin": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "Filters": {
            "shape": "S50"
          },
          "SortBy": {},
          "SortOrder": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          },
          "ProductSource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetails": {
            "type": "list",
            "member": {
              "shape": "S1f"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "TerminateProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "TerminateToken"
        ],
        "members": {
          "ProvisionedProductName": {},
          "ProvisionedProductId": {},
          "TerminateToken": {
            "idempotencyToken": true
          },
          "IgnoreErrors": {
            "type": "boolean"
          },
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S2y"
          }
        }
      }
    },
    "UpdateConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "Sh"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "UpdatePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "DisplayName": {},
          "Description": {},
          "ProviderName": {},
          "AddTags": {
            "shape": "So"
          },
          "RemoveTags": {
            "shape": "S5o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "St"
          },
          "Tags": {
            "shape": "Sw"
          }
        }
      }
    },
    "UpdateProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Name": {},
          "Owner": {},
          "Description": {},
          "Distributor": {},
          "SupportDescription": {},
          "SupportEmail": {},
          "SupportUrl": {},
          "AddTags": {
            "shape": "So"
          },
          "RemoveTags": {
            "shape": "S5o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S1f"
          },
          "Tags": {
            "shape": "Sw"
          }
        }
      }
    },
    "UpdateProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "UpdateToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisionedProductName": {},
          "ProvisionedProductId": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {},
          "ProvisioningParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {},
                "UsePreviousValue": {
                  "type": "boolean"
                }
              }
            }
          },
          "UpdateToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S2y"
          }
        }
      }
    },
    "UpdateProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S1k"
          },
          "Info": {
            "shape": "S1a"
          },
          "Status": {}
        }
      }
    }
  },
  "shapes": {
    "Sh": {
      "type": "structure",
      "members": {
        "ConstraintId": {},
        "Type": {},
        "Description": {},
        "Owner": {}
      }
    },
    "So": {
      "type": "list",
      "member": {
        "shape": "Sp"
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "St": {
      "type": "structure",
      "members": {
        "Id": {},
        "ARN": {},
        "DisplayName": {},
        "Description": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "ProviderName": {}
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "shape": "Sp"
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "Info"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Info": {
          "shape": "S1a"
        },
        "Type": {}
      }
    },
    "S1a": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1f": {
      "type": "structure",
      "members": {
        "ProductViewSummary": {
          "shape": "S1g"
        },
        "Status": {},
        "ProductARN": {},
        "CreatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S1g": {
      "type": "structure",
      "members": {
        "Id": {},
        "ProductId": {},
        "Name": {},
        "Owner": {},
        "ShortDescription": {},
        "Type": {},
        "Distributor": {},
        "HasDefaultPath": {
          "type": "boolean"
        },
        "SupportEmail": {},
        "SupportDescription": {},
        "SupportUrl": {}
      }
    },
    "S1k": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "Type": {},
        "CreatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {},
          "Name": {},
          "Description": {},
          "CreatedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S2o": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Description": {}
        }
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "RecordId": {},
        "ProvisionedProductName": {},
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "UpdatedTime": {
          "type": "timestamp"
        },
        "ProvisionedProductType": {},
        "RecordType": {},
        "ProvisionedProductId": {},
        "ProductId": {},
        "ProvisioningArtifactId": {},
        "PathId": {},
        "RecordErrors": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Code": {},
              "Description": {}
            }
          }
        },
        "RecordTags": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S3m": {
      "type": "list",
      "member": {
        "shape": "St"
      }
    },
    "S4a": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S50": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "S5o": {
      "type": "list",
      "member": {}
    }
  }
}