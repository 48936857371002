module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-10-06",
    "endpointPrefix": "codedeploy",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CodeDeploy",
    "serviceFullName": "AWS CodeDeploy",
    "signatureVersion": "v4",
    "targetPrefix": "CodeDeploy_20141006",
    "timestampFormat": "unixTimestamp",
    "uid": "codedeploy-2014-10-06"
  },
  "operations": {
    "AddTagsToOnPremisesInstances": {
      "input": {
        "type": "structure",
        "required": [
          "tags",
          "instanceNames"
        ],
        "members": {
          "tags": {
            "shape": "S2"
          },
          "instanceNames": {
            "shape": "S6"
          }
        }
      }
    },
    "BatchGetApplicationRevisions": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "revisions"
        ],
        "members": {
          "applicationName": {},
          "revisions": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "errorMessage": {},
          "revisions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "revisionLocation": {
                  "shape": "Sb"
                },
                "genericRevisionInfo": {
                  "shape": "Sq"
                }
              }
            }
          }
        }
      }
    },
    "BatchGetApplications": {
      "input": {
        "type": "structure",
        "members": {
          "applicationNames": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationsInfo": {
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          }
        }
      }
    },
    "BatchGetDeploymentGroups": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupNames"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupNames": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentGroupsInfo": {
            "type": "list",
            "member": {
              "shape": "S15"
            }
          },
          "errorMessage": {}
        }
      }
    },
    "BatchGetDeploymentInstances": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId",
          "instanceIds"
        ],
        "members": {
          "deploymentId": {},
          "instanceIds": {
            "shape": "S2d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instancesSummary": {
            "type": "list",
            "member": {
              "shape": "S2h"
            }
          },
          "errorMessage": {}
        }
      }
    },
    "BatchGetDeployments": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentIds": {
            "shape": "S2u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentsInfo": {
            "type": "list",
            "member": {
              "shape": "S2x"
            }
          }
        }
      }
    },
    "BatchGetOnPremisesInstances": {
      "input": {
        "type": "structure",
        "members": {
          "instanceNames": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceInfos": {
            "type": "list",
            "member": {
              "shape": "S3b"
            }
          }
        }
      }
    },
    "ContinueDeployment": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {}
        }
      }
    },
    "CreateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationId": {}
        }
      }
    },
    "CreateDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {},
          "revision": {
            "shape": "Sb"
          },
          "deploymentConfigName": {},
          "description": {},
          "ignoreApplicationStopFailures": {
            "type": "boolean"
          },
          "targetInstances": {
            "shape": "S35"
          },
          "autoRollbackConfiguration": {
            "shape": "S1t"
          },
          "updateOutdatedInstancesOnly": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentId": {}
        }
      }
    },
    "CreateDeploymentConfig": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentConfigName"
        ],
        "members": {
          "deploymentConfigName": {},
          "minimumHealthyHosts": {
            "shape": "S3l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentConfigId": {}
        }
      }
    },
    "CreateDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupName",
          "serviceRoleArn"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {},
          "deploymentConfigName": {},
          "ec2TagFilters": {
            "shape": "S18"
          },
          "onPremisesInstanceTagFilters": {
            "shape": "S1b"
          },
          "autoScalingGroups": {
            "shape": "S36"
          },
          "serviceRoleArn": {},
          "triggerConfigurations": {
            "shape": "S1j"
          },
          "alarmConfiguration": {
            "shape": "S1p"
          },
          "autoRollbackConfiguration": {
            "shape": "S1t"
          },
          "deploymentStyle": {
            "shape": "S1w"
          },
          "blueGreenDeploymentConfiguration": {
            "shape": "S1z"
          },
          "loadBalancerInfo": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentGroupId": {}
        }
      }
    },
    "DeleteApplication": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {}
        }
      }
    },
    "DeleteDeploymentConfig": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentConfigName"
        ],
        "members": {
          "deploymentConfigName": {}
        }
      }
    },
    "DeleteDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupName"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "hooksNotCleanedUp": {
            "shape": "S1e"
          }
        }
      }
    },
    "DeregisterOnPremisesInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      }
    },
    "GetApplication": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "application": {
            "shape": "Sz"
          }
        }
      }
    },
    "GetApplicationRevision": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "revision"
        ],
        "members": {
          "applicationName": {},
          "revision": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "revision": {
            "shape": "Sb"
          },
          "revisionInfo": {
            "shape": "Sq"
          }
        }
      }
    },
    "GetDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentInfo": {
            "shape": "S2x"
          }
        }
      }
    },
    "GetDeploymentConfig": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentConfigName"
        ],
        "members": {
          "deploymentConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentConfigInfo": {
            "type": "structure",
            "members": {
              "deploymentConfigId": {},
              "deploymentConfigName": {},
              "minimumHealthyHosts": {
                "shape": "S3l"
              },
              "createTime": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "GetDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "deploymentGroupName"
        ],
        "members": {
          "applicationName": {},
          "deploymentGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentGroupInfo": {
            "shape": "S15"
          }
        }
      }
    },
    "GetDeploymentInstance": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId",
          "instanceId"
        ],
        "members": {
          "deploymentId": {},
          "instanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceSummary": {
            "shape": "S2h"
          }
        }
      }
    },
    "GetOnPremisesInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceInfo": {
            "shape": "S3b"
          }
        }
      }
    },
    "ListApplicationRevisions": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {},
          "sortBy": {},
          "sortOrder": {},
          "s3Bucket": {},
          "s3KeyPrefix": {},
          "deployed": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "revisions": {
            "shape": "Sa"
          },
          "nextToken": {}
        }
      }
    },
    "ListApplications": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applications": {
            "shape": "Sw"
          },
          "nextToken": {}
        }
      }
    },
    "ListDeploymentConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentConfigsList": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListDeploymentGroups": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName"
        ],
        "members": {
          "applicationName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "deploymentGroups": {
            "shape": "Ss"
          },
          "nextToken": {}
        }
      }
    },
    "ListDeploymentInstances": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {},
          "nextToken": {},
          "instanceStatusFilter": {
            "type": "list",
            "member": {}
          },
          "instanceTypeFilter": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instancesList": {
            "shape": "S2d"
          },
          "nextToken": {}
        }
      }
    },
    "ListDeployments": {
      "input": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "deploymentGroupName": {},
          "includeOnlyStatuses": {
            "type": "list",
            "member": {}
          },
          "createTimeRange": {
            "type": "structure",
            "members": {
              "start": {
                "type": "timestamp"
              },
              "end": {
                "type": "timestamp"
              }
            }
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deployments": {
            "shape": "S2u"
          },
          "nextToken": {}
        }
      }
    },
    "ListOnPremisesInstances": {
      "input": {
        "type": "structure",
        "members": {
          "registrationStatus": {},
          "tagFilters": {
            "shape": "S1b"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceNames": {
            "shape": "S6"
          },
          "nextToken": {}
        }
      }
    },
    "RegisterApplicationRevision": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "revision"
        ],
        "members": {
          "applicationName": {},
          "description": {},
          "revision": {
            "shape": "Sb"
          }
        }
      }
    },
    "RegisterOnPremisesInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {},
          "iamSessionArn": {},
          "iamUserArn": {}
        }
      }
    },
    "RemoveTagsFromOnPremisesInstances": {
      "input": {
        "type": "structure",
        "required": [
          "tags",
          "instanceNames"
        ],
        "members": {
          "tags": {
            "shape": "S2"
          },
          "instanceNames": {
            "shape": "S6"
          }
        }
      }
    },
    "SkipWaitTimeForInstanceTermination": {
      "input": {
        "type": "structure",
        "members": {
          "deploymentId": {}
        }
      }
    },
    "StopDeployment": {
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {},
          "autoRollbackEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "statusMessage": {}
        }
      }
    },
    "UpdateApplication": {
      "input": {
        "type": "structure",
        "members": {
          "applicationName": {},
          "newApplicationName": {}
        }
      }
    },
    "UpdateDeploymentGroup": {
      "input": {
        "type": "structure",
        "required": [
          "applicationName",
          "currentDeploymentGroupName"
        ],
        "members": {
          "applicationName": {},
          "currentDeploymentGroupName": {},
          "newDeploymentGroupName": {},
          "deploymentConfigName": {},
          "ec2TagFilters": {
            "shape": "S18"
          },
          "onPremisesInstanceTagFilters": {
            "shape": "S1b"
          },
          "autoScalingGroups": {
            "shape": "S36"
          },
          "serviceRoleArn": {},
          "triggerConfigurations": {
            "shape": "S1j"
          },
          "alarmConfiguration": {
            "shape": "S1p"
          },
          "autoRollbackConfiguration": {
            "shape": "S1t"
          },
          "deploymentStyle": {
            "shape": "S1w"
          },
          "blueGreenDeploymentConfiguration": {
            "shape": "S1z"
          },
          "loadBalancerInfo": {
            "shape": "S27"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "hooksNotCleanedUp": {
            "shape": "S1e"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "list",
      "member": {
        "shape": "Sb"
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "revisionType": {},
        "s3Location": {
          "type": "structure",
          "members": {
            "bucket": {},
            "key": {},
            "bundleType": {},
            "version": {},
            "eTag": {}
          }
        },
        "gitHubLocation": {
          "type": "structure",
          "members": {
            "repository": {},
            "commitId": {}
          }
        }
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "description": {},
        "deploymentGroups": {
          "shape": "Ss"
        },
        "firstUsedTime": {
          "type": "timestamp"
        },
        "lastUsedTime": {
          "type": "timestamp"
        },
        "registerTime": {
          "type": "timestamp"
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "list",
      "member": {}
    },
    "Sz": {
      "type": "structure",
      "members": {
        "applicationId": {},
        "applicationName": {},
        "createTime": {
          "type": "timestamp"
        },
        "linkedToGitHub": {
          "type": "boolean"
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "applicationName": {},
        "deploymentGroupId": {},
        "deploymentGroupName": {},
        "deploymentConfigName": {},
        "ec2TagFilters": {
          "shape": "S18"
        },
        "onPremisesInstanceTagFilters": {
          "shape": "S1b"
        },
        "autoScalingGroups": {
          "shape": "S1e"
        },
        "serviceRoleArn": {},
        "targetRevision": {
          "shape": "Sb"
        },
        "triggerConfigurations": {
          "shape": "S1j"
        },
        "alarmConfiguration": {
          "shape": "S1p"
        },
        "autoRollbackConfiguration": {
          "shape": "S1t"
        },
        "deploymentStyle": {
          "shape": "S1w"
        },
        "blueGreenDeploymentConfiguration": {
          "shape": "S1z"
        },
        "loadBalancerInfo": {
          "shape": "S27"
        }
      }
    },
    "S18": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "Type": {}
        }
      }
    },
    "S1b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "Type": {}
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "hook": {}
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "triggerName": {},
          "triggerTargetArn": {},
          "triggerEvents": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "ignorePollAlarmFailure": {
          "type": "boolean"
        },
        "alarms": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {}
            }
          }
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "enabled": {
          "type": "boolean"
        },
        "events": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "deploymentType": {},
        "deploymentOption": {}
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "terminateBlueInstancesOnDeploymentSuccess": {
          "type": "structure",
          "members": {
            "action": {},
            "terminationWaitTimeInMinutes": {
              "type": "integer"
            }
          }
        },
        "deploymentReadyOption": {
          "type": "structure",
          "members": {
            "actionOnTimeout": {},
            "waitTimeInMinutes": {
              "type": "integer"
            }
          }
        },
        "greenFleetProvisioningOption": {
          "type": "structure",
          "members": {
            "action": {}
          }
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "elbInfoList": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {}
            }
          }
        }
      }
    },
    "S2d": {
      "type": "list",
      "member": {}
    },
    "S2h": {
      "type": "structure",
      "members": {
        "deploymentId": {},
        "instanceId": {},
        "status": {},
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "lifecycleEvents": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "lifecycleEventName": {},
              "diagnostics": {
                "type": "structure",
                "members": {
                  "errorCode": {},
                  "scriptName": {},
                  "message": {},
                  "logTail": {}
                }
              },
              "startTime": {
                "type": "timestamp"
              },
              "endTime": {
                "type": "timestamp"
              },
              "status": {}
            }
          }
        },
        "instanceType": {}
      }
    },
    "S2u": {
      "type": "list",
      "member": {}
    },
    "S2x": {
      "type": "structure",
      "members": {
        "applicationName": {},
        "deploymentGroupName": {},
        "deploymentConfigName": {},
        "deploymentId": {},
        "revision": {
          "shape": "Sb"
        },
        "status": {},
        "errorInformation": {
          "type": "structure",
          "members": {
            "code": {},
            "message": {}
          }
        },
        "createTime": {
          "type": "timestamp"
        },
        "startTime": {
          "type": "timestamp"
        },
        "completeTime": {
          "type": "timestamp"
        },
        "deploymentOverview": {
          "type": "structure",
          "members": {
            "Pending": {
              "type": "long"
            },
            "InProgress": {
              "type": "long"
            },
            "Succeeded": {
              "type": "long"
            },
            "Failed": {
              "type": "long"
            },
            "Skipped": {
              "type": "long"
            },
            "Ready": {
              "type": "long"
            }
          }
        },
        "description": {},
        "creator": {},
        "ignoreApplicationStopFailures": {
          "type": "boolean"
        },
        "autoRollbackConfiguration": {
          "shape": "S1t"
        },
        "updateOutdatedInstancesOnly": {
          "type": "boolean"
        },
        "rollbackInfo": {
          "type": "structure",
          "members": {
            "rollbackDeploymentId": {},
            "rollbackTriggeringDeploymentId": {},
            "rollbackMessage": {}
          }
        },
        "deploymentStyle": {
          "shape": "S1w"
        },
        "targetInstances": {
          "shape": "S35"
        },
        "instanceTerminationWaitTimeStarted": {
          "type": "boolean"
        },
        "blueGreenDeploymentConfiguration": {
          "shape": "S1z"
        },
        "loadBalancerInfo": {
          "shape": "S27"
        },
        "additionalDeploymentStatusInfo": {}
      }
    },
    "S35": {
      "type": "structure",
      "members": {
        "tagFilters": {
          "shape": "S18"
        },
        "autoScalingGroups": {
          "shape": "S36"
        }
      }
    },
    "S36": {
      "type": "list",
      "member": {}
    },
    "S3b": {
      "type": "structure",
      "members": {
        "instanceName": {},
        "iamSessionArn": {},
        "iamUserArn": {},
        "instanceArn": {},
        "registerTime": {
          "type": "timestamp"
        },
        "deregisterTime": {
          "type": "timestamp"
        },
        "tags": {
          "shape": "S2"
        }
      }
    },
    "S3l": {
      "type": "structure",
      "members": {
        "value": {
          "type": "integer"
        },
        "type": {}
      }
    }
  }
}