module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-10-25",
    "endpointPrefix": "directconnect",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Direct Connect",
    "signatureVersion": "v4",
    "targetPrefix": "OvertureService",
    "uid": "directconnect-2012-10-25"
  },
  "operations": {
    "AllocateConnectionOnInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "bandwidth",
          "connectionName",
          "ownerAccount",
          "interconnectId",
          "vlan"
        ],
        "members": {
          "bandwidth": {},
          "connectionName": {},
          "ownerAccount": {},
          "interconnectId": {},
          "vlan": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "AllocatePrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newPrivateVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newPrivateVirtualInterfaceAllocation": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "addressFamily": {},
              "customerAddress": {}
            }
          }
        }
      },
      "output": {
        "shape": "Sm"
      }
    },
    "AllocatePublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newPublicVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newPublicVirtualInterfaceAllocation": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "routeFilterPrefixes": {
                "shape": "Ss"
              }
            }
          }
        }
      },
      "output": {
        "shape": "Sm"
      }
    },
    "ConfirmConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectionState": {}
        }
      }
    },
    "ConfirmPrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId",
          "virtualGatewayId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "virtualGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "ConfirmPublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "CreateBGPPeer": {
      "input": {
        "type": "structure",
        "members": {
          "virtualInterfaceId": {},
          "newBGPPeer": {
            "type": "structure",
            "members": {
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "addressFamily": {},
              "amazonAddress": {},
              "customerAddress": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "Sm"
          }
        }
      }
    },
    "CreateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "location",
          "bandwidth",
          "connectionName"
        ],
        "members": {
          "location": {},
          "bandwidth": {},
          "connectionName": {}
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "CreateInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectName",
          "bandwidth",
          "location"
        ],
        "members": {
          "interconnectName": {},
          "bandwidth": {},
          "location": {}
        }
      },
      "output": {
        "shape": "S1d"
      }
    },
    "CreatePrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newPrivateVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newPrivateVirtualInterface": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn",
              "virtualGatewayId"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "virtualGatewayId": {}
            }
          }
        }
      },
      "output": {
        "shape": "Sm"
      }
    },
    "CreatePublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newPublicVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newPublicVirtualInterface": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "routeFilterPrefixes": {
                "shape": "Ss"
              }
            }
          }
        }
      },
      "output": {
        "shape": "Sm"
      }
    },
    "DeleteBGPPeer": {
      "input": {
        "type": "structure",
        "members": {
          "virtualInterfaceId": {},
          "asn": {
            "type": "integer"
          },
          "customerAddress": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "Sm"
          }
        }
      }
    },
    "DeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S7"
      }
    },
    "DeleteInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "interconnectState": {}
        }
      }
    },
    "DeleteVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "DescribeConnectionLoa": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loa": {
            "shape": "S1u"
          }
        }
      }
    },
    "DescribeConnections": {
      "input": {
        "type": "structure",
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "DescribeConnectionsOnInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "shape": "S1x"
      }
    },
    "DescribeInterconnectLoa": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loa": {
            "shape": "S1u"
          }
        }
      }
    },
    "DescribeInterconnects": {
      "input": {
        "type": "structure",
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "interconnects": {
            "type": "list",
            "member": {
              "shape": "S1d"
            }
          }
        }
      }
    },
    "DescribeLocations": {
      "output": {
        "type": "structure",
        "members": {
          "locations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "locationCode": {},
                "locationName": {}
              }
            }
          }
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArns"
        ],
        "members": {
          "resourceArns": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceTags": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceArn": {},
                "tags": {
                  "shape": "S2f"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVirtualGateways": {
      "output": {
        "type": "structure",
        "members": {
          "virtualGateways": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "virtualGatewayId": {},
                "virtualGatewayState": {}
              }
            }
          }
        }
      }
    },
    "DescribeVirtualInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "connectionId": {},
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaces": {
            "type": "list",
            "member": {
              "shape": "Sm"
            }
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S2f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "members": {
        "ownerAccount": {},
        "connectionId": {},
        "connectionName": {},
        "connectionState": {},
        "region": {},
        "location": {},
        "bandwidth": {},
        "vlan": {
          "type": "integer"
        },
        "partnerName": {},
        "loaIssueTime": {
          "type": "timestamp"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "ownerAccount": {},
        "virtualInterfaceId": {},
        "location": {},
        "connectionId": {},
        "virtualInterfaceType": {},
        "virtualInterfaceName": {},
        "vlan": {
          "type": "integer"
        },
        "asn": {
          "type": "integer"
        },
        "authKey": {},
        "amazonAddress": {},
        "customerAddress": {},
        "addressFamily": {},
        "virtualInterfaceState": {},
        "customerRouterConfig": {},
        "virtualGatewayId": {},
        "routeFilterPrefixes": {
          "shape": "Ss"
        },
        "bgpPeers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "addressFamily": {},
              "amazonAddress": {},
              "customerAddress": {},
              "bgpPeerState": {},
              "bgpStatus": {}
            }
          }
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "cidr": {}
        }
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "interconnectId": {},
        "interconnectName": {},
        "interconnectState": {},
        "region": {},
        "location": {},
        "bandwidth": {},
        "loaIssueTime": {
          "type": "timestamp"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "loaContent": {
          "type": "blob"
        },
        "loaContentType": {}
      }
    },
    "S1x": {
      "type": "structure",
      "members": {
        "connections": {
          "type": "list",
          "member": {
            "shape": "S7"
          }
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    }
  }
}