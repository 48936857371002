module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-06-10",
    "endpointPrefix": "polly",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Polly",
    "signatureVersion": "v4",
    "uid": "polly-2016-06-10"
  },
  "operations": {
    "DeleteLexicon": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/lexicons/{LexiconName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "shape": "S2",
            "location": "uri",
            "locationName": "LexiconName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeVoices": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/voices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "LanguageCode": {
            "location": "querystring",
            "locationName": "LanguageCode"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Voices": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Gender": {},
                "Id": {},
                "LanguageCode": {},
                "LanguageName": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetLexicon": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/lexicons/{LexiconName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {
            "shape": "S2",
            "location": "uri",
            "locationName": "LexiconName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lexicon": {
            "type": "structure",
            "members": {
              "Content": {},
              "Name": {
                "shape": "S2"
              }
            }
          },
          "LexiconAttributes": {
            "shape": "Si"
          }
        }
      }
    },
    "ListLexicons": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/lexicons",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Lexicons": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {
                  "shape": "S2"
                },
                "Attributes": {
                  "shape": "Si"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutLexicon": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/lexicons/{LexiconName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Content"
        ],
        "members": {
          "Name": {
            "shape": "S2",
            "location": "uri",
            "locationName": "LexiconName"
          },
          "Content": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SynthesizeSpeech": {
      "http": {
        "requestUri": "/v1/speech",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "OutputFormat",
          "Text",
          "VoiceId"
        ],
        "members": {
          "LexiconNames": {
            "type": "list",
            "member": {
              "shape": "S2"
            }
          },
          "OutputFormat": {},
          "SampleRate": {},
          "Text": {},
          "TextType": {},
          "VoiceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AudioStream": {
            "type": "blob",
            "streaming": true
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "RequestCharacters": {
            "location": "header",
            "locationName": "x-amzn-RequestCharacters",
            "type": "integer"
          }
        },
        "payload": "AudioStream"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "string",
      "sensitive": true
    },
    "Si": {
      "type": "structure",
      "members": {
        "Alphabet": {},
        "LanguageCode": {},
        "LastModified": {
          "type": "timestamp"
        },
        "LexiconArn": {},
        "LexemesCount": {
          "type": "integer"
        },
        "Size": {
          "type": "integer"
        }
      }
    }
  }
}