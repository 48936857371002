module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-15",
    "endpointPrefix": "ec2",
    "protocol": "ec2",
    "serviceAbbreviation": "Amazon EC2",
    "serviceFullName": "Amazon Elastic Compute Cloud",
    "signatureVersion": "v4",
    "uid": "ec2-2016-11-15",
    "xmlNamespace": "http://ec2.amazonaws.com/doc/2016-11-15"
  },
  "operations": {
    "AcceptReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "S3",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "S5",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExchangeId": {
            "locationName": "exchangeId"
          }
        }
      }
    },
    "AcceptVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "Sb",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "AllocateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Domain": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIp": {
            "locationName": "publicIp"
          },
          "Domain": {
            "locationName": "domain"
          },
          "AllocationId": {
            "locationName": "allocationId"
          }
        }
      }
    },
    "AllocateHosts": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "Quantity",
          "AvailabilityZone"
        ],
        "members": {
          "AutoPlacement": {
            "locationName": "autoPlacement"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "Quantity": {
            "locationName": "quantity",
            "type": "integer"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostIds": {
            "shape": "Sr",
            "locationName": "hostIdSet"
          }
        }
      }
    },
    "AssignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "Ipv6Addresses": {
            "shape": "St",
            "locationName": "ipv6Addresses"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "AssignedIpv6Addresses": {
            "shape": "St",
            "locationName": "assignedIpv6Addresses"
          }
        }
      }
    },
    "AssignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "Sw",
            "locationName": "privateIpAddress"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "AllowReassignment": {
            "locationName": "allowReassignment",
            "type": "boolean"
          }
        }
      }
    },
    "AssociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {},
          "PublicIp": {},
          "AllocationId": {},
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "AllowReassociation": {
            "locationName": "allowReassociation",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      }
    },
    "AssociateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "DhcpOptionsId": {},
          "VpcId": {}
        }
      }
    },
    "AssociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "InstanceId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S11"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S13",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "AssociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId",
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      }
    },
    "AssociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId",
          "Ipv6CidrBlock"
        ],
        "members": {
          "SubnetId": {
            "locationName": "subnetId"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SubnetId": {
            "locationName": "subnetId"
          },
          "Ipv6CidrBlockAssociation": {
            "shape": "S1a",
            "locationName": "ipv6CidrBlockAssociation"
          }
        }
      }
    },
    "AssociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "Ipv6CidrBlockAssociation": {
            "shape": "S1f",
            "locationName": "ipv6CidrBlockAssociation"
          }
        }
      }
    },
    "AttachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VpcId",
          "Groups"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "Groups": {
            "shape": "S1j",
            "locationName": "SecurityGroupId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "AttachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "AttachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "InstanceId",
          "DeviceIndex"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AttachmentId": {
            "locationName": "attachmentId"
          }
        }
      }
    },
    "AttachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId",
          "InstanceId",
          "Device"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {},
          "InstanceId": {},
          "Device": {}
        }
      },
      "output": {
        "shape": "S1p"
      }
    },
    "AttachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpnGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpnGatewayId": {},
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcAttachment": {
            "shape": "S1t",
            "locationName": "attachment"
          }
        }
      }
    },
    "AuthorizeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "IpPermissions": {
            "shape": "S1w",
            "locationName": "ipPermissions"
          }
        }
      }
    },
    "AuthorizeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {},
          "GroupId": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "IpProtocol": {},
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          },
          "CidrIp": {},
          "IpPermissions": {
            "shape": "S1w"
          }
        }
      }
    },
    "BundleInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Storage"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {},
          "Storage": {
            "shape": "S28"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S2c",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelBundleTask": {
      "input": {
        "type": "structure",
        "required": [
          "BundleId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "BundleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S2c",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelConversionTask": {
      "input": {
        "type": "structure",
        "required": [
          "ConversionTaskId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ConversionTaskId": {
            "locationName": "conversionTaskId"
          },
          "ReasonMessage": {
            "locationName": "reasonMessage"
          }
        }
      }
    },
    "CancelExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "ExportTaskId"
        ],
        "members": {
          "ExportTaskId": {
            "locationName": "exportTaskId"
          }
        }
      }
    },
    "CancelImportTask": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ImportTaskId": {},
          "CancelReason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "State": {
            "locationName": "state"
          },
          "PreviousState": {
            "locationName": "previousState"
          }
        }
      }
    },
    "CancelReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesListingId"
        ],
        "members": {
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S2n",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CancelSpotFleetRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestIds",
          "TerminateInstances"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestIds": {
            "shape": "S2z",
            "locationName": "spotFleetRequestId"
          },
          "TerminateInstances": {
            "locationName": "terminateInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnsuccessfulFleetRequests": {
            "locationName": "unsuccessfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "SpotFleetRequestId",
                "Error"
              ],
              "members": {
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                },
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "required": [
                    "Code",
                    "Message"
                  ],
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          },
          "SuccessfulFleetRequests": {
            "locationName": "successfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "SpotFleetRequestId",
                "CurrentSpotFleetRequestState",
                "PreviousSpotFleetRequestState"
              ],
              "members": {
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                },
                "CurrentSpotFleetRequestState": {
                  "locationName": "currentSpotFleetRequestState"
                },
                "PreviousSpotFleetRequestState": {
                  "locationName": "previousSpotFleetRequestState"
                }
              }
            }
          }
        }
      }
    },
    "CancelSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotInstanceRequestIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S39",
            "locationName": "SpotInstanceRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancelledSpotInstanceRequests": {
            "locationName": "spotInstanceRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SpotInstanceRequestId": {
                  "locationName": "spotInstanceRequestId"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          }
        }
      }
    },
    "ConfirmProductInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ProductCode",
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ProductCode": {},
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OwnerId": {
            "locationName": "ownerId"
          },
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CopyImage": {
      "input": {
        "type": "structure",
        "required": [
          "SourceRegion",
          "SourceImageId",
          "Name"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SourceRegion": {},
          "SourceImageId": {},
          "Name": {},
          "Description": {},
          "ClientToken": {},
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceRegion",
          "SourceSnapshotId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SourceRegion": {},
          "SourceSnapshotId": {},
          "Description": {},
          "DestinationRegion": {
            "locationName": "destinationRegion"
          },
          "PresignedUrl": {
            "locationName": "presignedUrl"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          }
        }
      }
    },
    "CreateCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "Type",
          "PublicIp",
          "BgpAsn"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Type": {},
          "PublicIp": {
            "locationName": "IpAddress"
          },
          "BgpAsn": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateway": {
            "shape": "S3n",
            "locationName": "customerGateway"
          }
        }
      }
    },
    "CreateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpConfigurations"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "DhcpConfigurations": {
            "locationName": "dhcpConfiguration",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {
                  "locationName": "key"
                },
                "Values": {
                  "shape": "S2z",
                  "locationName": "Value"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "shape": "S3s",
            "locationName": "dhcpOptions"
          }
        }
      }
    },
    "CreateEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcId": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EgressOnlyInternetGateway": {
            "shape": "S3z",
            "locationName": "egressOnlyInternetGateway"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIds",
          "ResourceType",
          "TrafficType",
          "LogGroupName",
          "DeliverLogsPermissionArn"
        ],
        "members": {
          "ResourceIds": {
            "shape": "S2z",
            "locationName": "ResourceId"
          },
          "ResourceType": {},
          "TrafficType": {},
          "LogGroupName": {},
          "DeliverLogsPermissionArn": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowLogIds": {
            "shape": "S2z",
            "locationName": "flowLogIdSet"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "Unsuccessful": {
            "shape": "S47",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "CreateImage": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Name": {
            "locationName": "name"
          },
          "Description": {
            "locationName": "description"
          },
          "NoReboot": {
            "locationName": "noReboot",
            "type": "boolean"
          },
          "BlockDeviceMappings": {
            "shape": "S4b",
            "locationName": "blockDeviceMapping"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CreateInstanceExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "TargetEnvironment": {
            "locationName": "targetEnvironment"
          },
          "ExportToS3Task": {
            "locationName": "exportToS3",
            "type": "structure",
            "members": {
              "DiskImageFormat": {
                "locationName": "diskImageFormat"
              },
              "ContainerFormat": {
                "locationName": "containerFormat"
              },
              "S3Bucket": {
                "locationName": "s3Bucket"
              },
              "S3Prefix": {
                "locationName": "s3Prefix"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTask": {
            "shape": "S4m",
            "locationName": "exportTask"
          }
        }
      }
    },
    "CreateInternetGateway": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateway": {
            "shape": "S4s",
            "locationName": "internetGateway"
          }
        }
      }
    },
    "CreateKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyName": {
            "locationName": "keyName"
          },
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          },
          "KeyMaterial": {
            "locationName": "keyMaterial"
          }
        }
      }
    },
    "CreateNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId",
          "AllocationId"
        ],
        "members": {
          "SubnetId": {},
          "AllocationId": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGateway": {
            "shape": "S4x",
            "locationName": "natGateway"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcl": {
            "shape": "S54",
            "locationName": "networkAcl"
          }
        }
      }
    },
    "CreateNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkAclId",
          "RuleNumber",
          "Protocol",
          "RuleAction",
          "Egress"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "IcmpTypeCode": {
            "shape": "S58",
            "locationName": "Icmp"
          },
          "PortRange": {
            "shape": "S59",
            "locationName": "portRange"
          }
        }
      }
    },
    "CreateNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "SubnetId": {
            "locationName": "subnetId"
          },
          "Description": {
            "locationName": "description"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "Groups": {
            "shape": "S5e",
            "locationName": "SecurityGroupId"
          },
          "PrivateIpAddresses": {
            "shape": "S5f",
            "locationName": "privateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "S5h",
            "locationName": "ipv6Addresses"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterface": {
            "shape": "S5k",
            "locationName": "networkInterface"
          }
        }
      }
    },
    "CreatePlacementGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "Strategy"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          },
          "Strategy": {
            "locationName": "strategy"
          }
        }
      }
    },
    "CreateReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesId",
          "InstanceCount",
          "PriceSchedules",
          "ClientToken"
        ],
        "members": {
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Term": {
                  "locationName": "term",
                  "type": "long"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                }
              }
            }
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S2n",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CreateRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CreateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTable": {
            "shape": "S65",
            "locationName": "routeTable"
          }
        }
      }
    },
    "CreateSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "Description"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {},
          "Description": {
            "locationName": "GroupDescription"
          },
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {
            "locationName": "groupId"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {},
          "Description": {}
        }
      },
      "output": {
        "shape": "S6h"
      }
    },
    "CreateSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Bucket": {
            "locationName": "bucket"
          },
          "Prefix": {
            "locationName": "prefix"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "S6l",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "CreateSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "CidrBlock"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {},
          "CidrBlock": {},
          "Ipv6CidrBlock": {},
          "AvailabilityZone": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnet": {
            "shape": "S6q",
            "locationName": "subnet"
          }
        }
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources",
          "Tags"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "S6u",
            "locationName": "ResourceId"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "Tag"
          }
        }
      }
    },
    "CreateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Size": {
            "type": "integer"
          },
          "SnapshotId": {},
          "AvailabilityZone": {},
          "VolumeType": {},
          "Iops": {
            "type": "integer"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {}
        }
      },
      "output": {
        "shape": "S6w"
      }
    },
    "CreateVpc": {
      "input": {
        "type": "structure",
        "required": [
          "CidrBlock"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "CidrBlock": {},
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          },
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpc": {
            "shape": "S72",
            "locationName": "vpc"
          }
        }
      }
    },
    "CreateVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "ServiceName"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcId": {},
          "ServiceName": {},
          "PolicyDocument": {},
          "RouteTableIds": {
            "shape": "S2z",
            "locationName": "RouteTableId"
          },
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoint": {
            "shape": "S77",
            "locationName": "vpcEndpoint"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "PeerVpcId": {
            "locationName": "peerVpcId"
          },
          "PeerOwnerId": {
            "locationName": "peerOwnerId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "Sb",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "CreateVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "Type",
          "CustomerGatewayId",
          "VpnGatewayId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Type": {},
          "CustomerGatewayId": {},
          "VpnGatewayId": {},
          "Options": {
            "locationName": "options",
            "type": "structure",
            "members": {
              "StaticRoutesOnly": {
                "locationName": "staticRoutesOnly",
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "S7e",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "CreateVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "DestinationCidrBlock"
        ],
        "members": {
          "VpnConnectionId": {},
          "DestinationCidrBlock": {}
        }
      }
    },
    "CreateVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Type": {},
          "AvailabilityZone": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateway": {
            "shape": "S7q",
            "locationName": "vpnGateway"
          }
        }
      }
    },
    "DeleteCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "CustomerGatewayId": {}
        }
      }
    },
    "DeleteDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "DhcpOptionsId": {}
        }
      }
    },
    "DeleteEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "EgressOnlyInternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnCode": {
            "locationName": "returnCode",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "FlowLogIds"
        ],
        "members": {
          "FlowLogIds": {
            "shape": "S2z",
            "locationName": "FlowLogId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S47",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          }
        }
      }
    },
    "DeleteKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyName": {}
        }
      }
    },
    "DeleteNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId"
        ],
        "members": {
          "NatGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          }
        }
      }
    },
    "DeleteNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkAclId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      }
    },
    "DeleteNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkAclId",
          "RuleNumber",
          "Egress"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      }
    },
    "DeletePlacementGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          }
        }
      }
    },
    "DeleteRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          }
        }
      }
    },
    "DeleteRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      }
    },
    "DeleteSecurityGroup": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {},
          "GroupId": {}
        }
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SnapshotId": {}
        }
      }
    },
    "DeleteSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SubnetId": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "S6u",
            "locationName": "resourceId"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tag"
          }
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {}
        }
      }
    },
    "DeleteVpc": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {}
        }
      }
    },
    "DeleteVpcEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "S2z",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "S47",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpnConnectionId": {}
        }
      }
    },
    "DeleteVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "DestinationCidrBlock"
        ],
        "members": {
          "VpnConnectionId": {},
          "DestinationCidrBlock": {}
        }
      }
    },
    "DeleteVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpnGatewayId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpnGatewayId": {}
        }
      }
    },
    "DeregisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ImageId": {}
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AttributeNames": {
            "locationName": "attributeName",
            "type": "list",
            "member": {
              "locationName": "attributeName"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountAttributes": {
            "locationName": "accountAttributeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AttributeName": {
                  "locationName": "attributeName"
                },
                "AttributeValues": {
                  "locationName": "attributeValueSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AttributeValue": {
                        "locationName": "attributeValue"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIps": {
            "locationName": "PublicIp",
            "type": "list",
            "member": {
              "locationName": "PublicIp"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "AllocationIds": {
            "locationName": "AllocationId",
            "type": "list",
            "member": {
              "locationName": "AllocationId"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Addresses": {
            "locationName": "addressesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "PublicIp": {
                  "locationName": "publicIp"
                },
                "AllocationId": {
                  "locationName": "allocationId"
                },
                "AssociationId": {
                  "locationName": "associationId"
                },
                "Domain": {
                  "locationName": "domain"
                },
                "NetworkInterfaceId": {
                  "locationName": "networkInterfaceId"
                },
                "NetworkInterfaceOwnerId": {
                  "locationName": "networkInterfaceOwnerId"
                },
                "PrivateIpAddress": {
                  "locationName": "privateIpAddress"
                }
              }
            }
          }
        }
      }
    },
    "DescribeAvailabilityZones": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ZoneNames": {
            "locationName": "ZoneName",
            "type": "list",
            "member": {
              "locationName": "ZoneName"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AvailabilityZones": {
            "locationName": "availabilityZoneInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ZoneName": {
                  "locationName": "zoneName"
                },
                "State": {
                  "locationName": "zoneState"
                },
                "RegionName": {
                  "locationName": "regionName"
                },
                "Messages": {
                  "locationName": "messageSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Message": {
                        "locationName": "message"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeBundleTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "BundleIds": {
            "locationName": "BundleId",
            "type": "list",
            "member": {
              "locationName": "BundleId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTasks": {
            "locationName": "bundleInstanceTasksSet",
            "type": "list",
            "member": {
              "shape": "S2c",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeClassicLinkInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "locationName": "instancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "Groups": {
                  "shape": "S5m",
                  "locationName": "groupSet"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeConversionTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ConversionTaskIds": {
            "locationName": "conversionTaskId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTasks": {
            "locationName": "conversionTasks",
            "type": "list",
            "member": {
              "shape": "S9o",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeCustomerGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "CustomerGatewayIds": {
            "locationName": "CustomerGatewayId",
            "type": "list",
            "member": {
              "locationName": "CustomerGatewayId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateways": {
            "locationName": "customerGatewaySet",
            "type": "list",
            "member": {
              "shape": "S3n",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeDhcpOptions": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "DhcpOptionsIds": {
            "locationName": "DhcpOptionsId",
            "type": "list",
            "member": {
              "locationName": "DhcpOptionsId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "locationName": "dhcpOptionsSet",
            "type": "list",
            "member": {
              "shape": "S3s",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeEgressOnlyInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayIds": {
            "locationName": "EgressOnlyInternetGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EgressOnlyInternetGateways": {
            "locationName": "egressOnlyInternetGatewaySet",
            "type": "list",
            "member": {
              "shape": "S3z",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeExportTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ExportTaskIds": {
            "locationName": "exportTaskId",
            "type": "list",
            "member": {
              "locationName": "ExportTaskId"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTasks": {
            "locationName": "exportTaskSet",
            "type": "list",
            "member": {
              "shape": "S4m",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeFlowLogs": {
      "input": {
        "type": "structure",
        "members": {
          "FlowLogIds": {
            "shape": "S2z",
            "locationName": "FlowLogId"
          },
          "Filter": {
            "shape": "S8x"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowLogs": {
            "locationName": "flowLogSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CreationTime": {
                  "locationName": "creationTime",
                  "type": "timestamp"
                },
                "FlowLogId": {
                  "locationName": "flowLogId"
                },
                "FlowLogStatus": {
                  "locationName": "flowLogStatus"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "TrafficType": {
                  "locationName": "trafficType"
                },
                "LogGroupName": {
                  "locationName": "logGroupName"
                },
                "DeliverLogsStatus": {
                  "locationName": "deliverLogsStatus"
                },
                "DeliverLogsErrorMessage": {
                  "locationName": "deliverLogsErrorMessage"
                },
                "DeliverLogsPermissionArn": {
                  "locationName": "deliverLogsPermissionArn"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHostReservationOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "OfferingId": {},
          "MinDuration": {
            "type": "integer"
          },
          "MaxDuration": {
            "type": "integer"
          },
          "Filter": {
            "shape": "S8x"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OfferingSet": {
            "locationName": "offeringSet",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHostReservations": {
      "input": {
        "type": "structure",
        "members": {
          "HostReservationIdSet": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filter": {
            "shape": "S8x"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostReservationSet": {
            "locationName": "hostReservationSet",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "HostIdSet": {
                  "shape": "Sar",
                  "locationName": "hostIdSet"
                },
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Count": {
                  "locationName": "count",
                  "type": "integer"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHosts": {
      "input": {
        "type": "structure",
        "members": {
          "HostIds": {
            "shape": "Sau",
            "locationName": "hostId"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "Filter": {
            "shape": "S8x",
            "locationName": "filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Hosts": {
            "locationName": "hostSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "HostId": {
                  "locationName": "hostId"
                },
                "AutoPlacement": {
                  "locationName": "autoPlacement"
                },
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "HostProperties": {
                  "locationName": "hostProperties",
                  "type": "structure",
                  "members": {
                    "Sockets": {
                      "locationName": "sockets",
                      "type": "integer"
                    },
                    "Cores": {
                      "locationName": "cores",
                      "type": "integer"
                    },
                    "TotalVCpus": {
                      "locationName": "totalVCpus",
                      "type": "integer"
                    },
                    "InstanceType": {
                      "locationName": "instanceType"
                    }
                  }
                },
                "State": {
                  "locationName": "state"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Instances": {
                  "locationName": "instances",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "InstanceId": {
                        "locationName": "instanceId"
                      },
                      "InstanceType": {
                        "locationName": "instanceType"
                      }
                    }
                  }
                },
                "AvailableCapacity": {
                  "locationName": "availableCapacity",
                  "type": "structure",
                  "members": {
                    "AvailableInstanceCapacity": {
                      "locationName": "availableInstanceCapacity",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "InstanceType": {
                            "locationName": "instanceType"
                          },
                          "AvailableCapacity": {
                            "locationName": "availableCapacity",
                            "type": "integer"
                          },
                          "TotalCapacity": {
                            "locationName": "totalCapacity",
                            "type": "integer"
                          }
                        }
                      }
                    },
                    "AvailableVCpus": {
                      "locationName": "availableVCpus",
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIamInstanceProfileAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationIds": {
            "locationName": "AssociationId",
            "type": "list",
            "member": {
              "locationName": "AssociationId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociations": {
            "locationName": "iamInstanceProfileAssociationSet",
            "type": "list",
            "member": {
              "shape": "S13",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "Resource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "Sbd",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "PrincipalArn"
        ],
        "members": {
          "Resource": {
            "locationName": "resource"
          },
          "PrincipalArn": {
            "locationName": "principalArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "Sbd",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ImageId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          },
          "LaunchPermissions": {
            "shape": "Sbk",
            "locationName": "launchPermission"
          },
          "ProductCodes": {
            "shape": "Sbn",
            "locationName": "productCodes"
          },
          "KernelId": {
            "shape": "S3w",
            "locationName": "kernel"
          },
          "RamdiskId": {
            "shape": "S3w",
            "locationName": "ramdisk"
          },
          "Description": {
            "shape": "S3w",
            "locationName": "description"
          },
          "SriovNetSupport": {
            "shape": "S3w",
            "locationName": "sriovNetSupport"
          },
          "BlockDeviceMappings": {
            "shape": "Sbq",
            "locationName": "blockDeviceMapping"
          }
        }
      }
    },
    "DescribeImages": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ImageIds": {
            "locationName": "ImageId",
            "type": "list",
            "member": {
              "locationName": "ImageId"
            }
          },
          "Owners": {
            "shape": "Sbt",
            "locationName": "Owner"
          },
          "ExecutableUsers": {
            "locationName": "ExecutableBy",
            "type": "list",
            "member": {
              "locationName": "ExecutableBy"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "locationName": "imagesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ImageId": {
                  "locationName": "imageId"
                },
                "ImageLocation": {
                  "locationName": "imageLocation"
                },
                "State": {
                  "locationName": "imageState"
                },
                "OwnerId": {
                  "locationName": "imageOwnerId"
                },
                "CreationDate": {
                  "locationName": "creationDate"
                },
                "Public": {
                  "locationName": "isPublic",
                  "type": "boolean"
                },
                "ProductCodes": {
                  "shape": "Sbn",
                  "locationName": "productCodes"
                },
                "Architecture": {
                  "locationName": "architecture"
                },
                "ImageType": {
                  "locationName": "imageType"
                },
                "KernelId": {
                  "locationName": "kernelId"
                },
                "RamdiskId": {
                  "locationName": "ramdiskId"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "SriovNetSupport": {
                  "locationName": "sriovNetSupport"
                },
                "EnaSupport": {
                  "locationName": "enaSupport",
                  "type": "boolean"
                },
                "StateReason": {
                  "shape": "Sc1",
                  "locationName": "stateReason"
                },
                "ImageOwnerAlias": {
                  "locationName": "imageOwnerAlias"
                },
                "Name": {
                  "locationName": "name"
                },
                "Description": {
                  "locationName": "description"
                },
                "RootDeviceType": {
                  "locationName": "rootDeviceType"
                },
                "RootDeviceName": {
                  "locationName": "rootDeviceName"
                },
                "BlockDeviceMappings": {
                  "shape": "Sbq",
                  "locationName": "blockDeviceMapping"
                },
                "VirtualizationType": {
                  "locationName": "virtualizationType"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                }
              }
            }
          }
        }
      }
    },
    "DescribeImportImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ImportTaskIds": {
            "shape": "Sc6",
            "locationName": "ImportTaskId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S8x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportImageTasks": {
            "locationName": "importImageTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "Architecture": {
                  "locationName": "architecture"
                },
                "LicenseType": {
                  "locationName": "licenseType"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "Description": {
                  "locationName": "description"
                },
                "SnapshotDetails": {
                  "shape": "Sca",
                  "locationName": "snapshotDetailSet"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "Status": {
                  "locationName": "status"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeImportSnapshotTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ImportTaskIds": {
            "shape": "Sc6",
            "locationName": "ImportTaskId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S8x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportSnapshotTasks": {
            "locationName": "importSnapshotTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "SnapshotTaskDetail": {
                  "shape": "Sch",
                  "locationName": "snapshotTaskDetail"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Attribute": {
            "locationName": "attribute"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceType": {
            "shape": "S3w",
            "locationName": "instanceType"
          },
          "KernelId": {
            "shape": "S3w",
            "locationName": "kernel"
          },
          "RamdiskId": {
            "shape": "S3w",
            "locationName": "ramdisk"
          },
          "UserData": {
            "shape": "S3w",
            "locationName": "userData"
          },
          "DisableApiTermination": {
            "shape": "Scl",
            "locationName": "disableApiTermination"
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "S3w",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "RootDeviceName": {
            "shape": "S3w",
            "locationName": "rootDeviceName"
          },
          "BlockDeviceMappings": {
            "shape": "Scm",
            "locationName": "blockDeviceMapping"
          },
          "ProductCodes": {
            "shape": "Sbn",
            "locationName": "productCodes"
          },
          "EbsOptimized": {
            "shape": "Scl",
            "locationName": "ebsOptimized"
          },
          "SriovNetSupport": {
            "shape": "S3w",
            "locationName": "sriovNetSupport"
          },
          "EnaSupport": {
            "shape": "Scl",
            "locationName": "enaSupport"
          },
          "SourceDestCheck": {
            "shape": "Scl",
            "locationName": "sourceDestCheck"
          },
          "Groups": {
            "shape": "S5m",
            "locationName": "groupSet"
          }
        }
      }
    },
    "DescribeInstanceStatus": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "IncludeAllInstances": {
            "locationName": "includeAllInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceStatuses": {
            "locationName": "instanceStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Code": {
                        "locationName": "code"
                      },
                      "Description": {
                        "locationName": "description"
                      },
                      "NotBefore": {
                        "locationName": "notBefore",
                        "type": "timestamp"
                      },
                      "NotAfter": {
                        "locationName": "notAfter",
                        "type": "timestamp"
                      }
                    }
                  }
                },
                "InstanceState": {
                  "shape": "Scw",
                  "locationName": "instanceState"
                },
                "SystemStatus": {
                  "shape": "Scy",
                  "locationName": "systemStatus"
                },
                "InstanceStatus": {
                  "shape": "Scy",
                  "locationName": "instanceStatus"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Reservations": {
            "locationName": "reservationSet",
            "type": "list",
            "member": {
              "shape": "Sd7",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayIds": {
            "shape": "S2z",
            "locationName": "internetGatewayId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateways": {
            "locationName": "internetGatewaySet",
            "type": "list",
            "member": {
              "shape": "S4s",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeKeyPairs": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyNames": {
            "locationName": "KeyName",
            "type": "list",
            "member": {
              "locationName": "KeyName"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPairs": {
            "locationName": "keySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "KeyName": {
                  "locationName": "keyName"
                },
                "KeyFingerprint": {
                  "locationName": "keyFingerprint"
                }
              }
            }
          }
        }
      }
    },
    "DescribeMovingAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIps": {
            "shape": "S2z",
            "locationName": "publicIp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "filter"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MovingAddressStatuses": {
            "locationName": "movingAddressStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PublicIp": {
                  "locationName": "publicIp"
                },
                "MoveStatus": {
                  "locationName": "moveStatus"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNatGateways": {
      "input": {
        "type": "structure",
        "members": {
          "NatGatewayIds": {
            "shape": "S2z",
            "locationName": "NatGatewayId"
          },
          "Filter": {
            "shape": "S8x"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGateways": {
            "locationName": "natGatewaySet",
            "type": "list",
            "member": {
              "shape": "S4x",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkAcls": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclIds": {
            "shape": "S2z",
            "locationName": "NetworkAclId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcls": {
            "locationName": "networkAclSet",
            "type": "list",
            "member": {
              "shape": "S54",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "Attribute": {
            "locationName": "attribute"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "Description": {
            "shape": "S3w",
            "locationName": "description"
          },
          "SourceDestCheck": {
            "shape": "Scl",
            "locationName": "sourceDestCheck"
          },
          "Groups": {
            "shape": "S5m",
            "locationName": "groupSet"
          },
          "Attachment": {
            "shape": "S5o",
            "locationName": "attachment"
          }
        }
      }
    },
    "DescribeNetworkInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceIds": {
            "locationName": "NetworkInterfaceId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaces": {
            "locationName": "networkInterfaceSet",
            "type": "list",
            "member": {
              "shape": "S5k",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribePlacementGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupNames": {
            "locationName": "groupName",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlacementGroups": {
            "locationName": "placementGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupName": {
                  "locationName": "groupName"
                },
                "Strategy": {
                  "locationName": "strategy"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          }
        }
      }
    },
    "DescribePrefixLists": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "PrefixListIds": {
            "shape": "S2z",
            "locationName": "PrefixListId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrefixLists": {
            "locationName": "prefixListSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrefixListId": {
                  "locationName": "prefixListId"
                },
                "PrefixListName": {
                  "locationName": "prefixListName"
                },
                "Cidrs": {
                  "shape": "S2z",
                  "locationName": "cidrSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRegions": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RegionNames": {
            "locationName": "RegionName",
            "type": "list",
            "member": {
              "locationName": "RegionName"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Regions": {
            "locationName": "regionInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "RegionName": {
                  "locationName": "regionName"
                },
                "Endpoint": {
                  "locationName": "regionEndpoint"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ReservedInstancesIds": {
            "shape": "Ser",
            "locationName": "ReservedInstancesId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "OfferingType": {
            "locationName": "offeringType"
          },
          "OfferingClass": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstances": {
            "locationName": "reservedInstancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "State": {
                  "locationName": "state"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "RecurringCharges": {
                  "shape": "Sf0",
                  "locationName": "recurringCharges"
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "Scope": {
                  "locationName": "scope"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstancesListings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S2n",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "DescribeReservedInstancesModifications": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedInstancesModificationIds": {
            "locationName": "ReservedInstancesModificationId",
            "type": "list",
            "member": {
              "locationName": "ReservedInstancesModificationId"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesModifications": {
            "locationName": "reservedInstancesModificationsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservedInstancesModificationId": {
                  "locationName": "reservedInstancesModificationId"
                },
                "ReservedInstancesIds": {
                  "locationName": "reservedInstancesSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      }
                    }
                  }
                },
                "ModificationResults": {
                  "locationName": "modificationResultSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      },
                      "TargetConfiguration": {
                        "shape": "Sff",
                        "locationName": "targetConfiguration"
                      }
                    }
                  }
                },
                "CreateDate": {
                  "locationName": "createDate",
                  "type": "timestamp"
                },
                "UpdateDate": {
                  "locationName": "updateDate",
                  "type": "timestamp"
                },
                "EffectiveDate": {
                  "locationName": "effectiveDate",
                  "type": "timestamp"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "ClientToken": {
                  "locationName": "clientToken"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeReservedInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ReservedInstancesOfferingIds": {
            "locationName": "ReservedInstancesOfferingId",
            "type": "list",
            "member": {}
          },
          "InstanceType": {},
          "AvailabilityZone": {},
          "ProductDescription": {},
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          },
          "OfferingType": {
            "locationName": "offeringType"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "IncludeMarketplace": {
            "type": "boolean"
          },
          "MinDuration": {
            "type": "long"
          },
          "MaxDuration": {
            "type": "long"
          },
          "MaxInstanceCount": {
            "type": "integer"
          },
          "OfferingClass": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesOfferings": {
            "locationName": "reservedInstancesOfferingsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservedInstancesOfferingId": {
                  "locationName": "reservedInstancesOfferingId"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "RecurringCharges": {
                  "shape": "Sf0",
                  "locationName": "recurringCharges"
                },
                "Marketplace": {
                  "locationName": "marketplace",
                  "type": "boolean"
                },
                "PricingDetails": {
                  "locationName": "pricingDetailsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Price": {
                        "locationName": "price",
                        "type": "double"
                      },
                      "Count": {
                        "locationName": "count",
                        "type": "integer"
                      }
                    }
                  }
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "Scope": {
                  "locationName": "scope"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableIds": {
            "shape": "S2z",
            "locationName": "RouteTableId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTables": {
            "locationName": "routeTableSet",
            "type": "list",
            "member": {
              "shape": "S65",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeScheduledInstanceAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "Recurrence",
          "FirstSlotStartTimeRange"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Recurrence": {
            "type": "structure",
            "members": {
              "Frequency": {},
              "Interval": {
                "type": "integer"
              },
              "OccurrenceDays": {
                "locationName": "OccurrenceDay",
                "type": "list",
                "member": {
                  "locationName": "OccurenceDay",
                  "type": "integer"
                }
              },
              "OccurrenceRelativeToEnd": {
                "type": "boolean"
              },
              "OccurrenceUnit": {}
            }
          },
          "FirstSlotStartTimeRange": {
            "type": "structure",
            "required": [
              "EarliestTime",
              "LatestTime"
            ],
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          },
          "MinSlotDurationInHours": {
            "type": "integer"
          },
          "MaxSlotDurationInHours": {
            "type": "integer"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceAvailabilitySet": {
            "locationName": "scheduledInstanceAvailabilitySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "NetworkPlatform": {
                  "locationName": "networkPlatform"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "PurchaseToken": {
                  "locationName": "purchaseToken"
                },
                "SlotDurationInHours": {
                  "locationName": "slotDurationInHours",
                  "type": "integer"
                },
                "Recurrence": {
                  "shape": "Sfx",
                  "locationName": "recurrence"
                },
                "FirstSlotStartTime": {
                  "locationName": "firstSlotStartTime",
                  "type": "timestamp"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "TotalScheduledInstanceHours": {
                  "locationName": "totalScheduledInstanceHours",
                  "type": "integer"
                },
                "AvailableInstanceCount": {
                  "locationName": "availableInstanceCount",
                  "type": "integer"
                },
                "MinTermDurationInDays": {
                  "locationName": "minTermDurationInDays",
                  "type": "integer"
                },
                "MaxTermDurationInDays": {
                  "locationName": "maxTermDurationInDays",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeScheduledInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ScheduledInstanceIds": {
            "locationName": "ScheduledInstanceId",
            "type": "list",
            "member": {
              "locationName": "ScheduledInstanceId"
            }
          },
          "SlotStartTimeRange": {
            "type": "structure",
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "Sg4",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeSecurityGroupReferences": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupReferenceSet": {
            "locationName": "securityGroupReferenceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "GroupId",
                "ReferencingVpcId"
              ],
              "members": {
                "GroupId": {
                  "locationName": "groupId"
                },
                "ReferencingVpcId": {
                  "locationName": "referencingVpcId"
                },
                "VpcPeeringConnectionId": {
                  "locationName": "vpcPeeringConnectionId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupNames": {
            "shape": "Sgb",
            "locationName": "GroupName"
          },
          "GroupIds": {
            "shape": "S1j",
            "locationName": "GroupId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroups": {
            "locationName": "securityGroupInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "Description": {
                  "locationName": "groupDescription"
                },
                "IpPermissions": {
                  "shape": "S1w",
                  "locationName": "ipPermissions"
                },
                "IpPermissionsEgress": {
                  "shape": "S1w",
                  "locationName": "ipPermissionsEgress"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SnapshotId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "CreateVolumePermissions": {
            "shape": "Sgi",
            "locationName": "createVolumePermission"
          },
          "ProductCodes": {
            "shape": "Sbn",
            "locationName": "productCodes"
          }
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SnapshotIds": {
            "locationName": "SnapshotId",
            "type": "list",
            "member": {
              "locationName": "SnapshotId"
            }
          },
          "OwnerIds": {
            "shape": "Sbt",
            "locationName": "Owner"
          },
          "RestorableByUserIds": {
            "locationName": "RestorableBy",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "shape": "S6h",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "S6l",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "DescribeSpotFleetInstances": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId",
          "ActiveInstances"
        ],
        "members": {
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "ActiveInstances": {
            "locationName": "activeInstanceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "SpotInstanceRequestId": {
                  "locationName": "spotInstanceRequestId"
                },
                "InstanceHealth": {
                  "locationName": "instanceHealth"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotFleetRequestHistory": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId",
          "StartTime"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "EventType": {
            "locationName": "eventType"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId",
          "StartTime",
          "LastEvaluatedTime",
          "HistoryRecords"
        ],
        "members": {
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "LastEvaluatedTime": {
            "locationName": "lastEvaluatedTime",
            "type": "timestamp"
          },
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "Timestamp",
                "EventType",
                "EventInformation"
              ],
              "members": {
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                },
                "EventType": {
                  "locationName": "eventType"
                },
                "EventInformation": {
                  "locationName": "eventInformation",
                  "type": "structure",
                  "members": {
                    "InstanceId": {
                      "locationName": "instanceId"
                    },
                    "EventSubType": {
                      "locationName": "eventSubType"
                    },
                    "EventDescription": {
                      "locationName": "eventDescription"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotFleetRequests": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestIds": {
            "shape": "S2z",
            "locationName": "spotFleetRequestId"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SpotFleetRequestConfigs"
        ],
        "members": {
          "SpotFleetRequestConfigs": {
            "locationName": "spotFleetRequestConfigSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "SpotFleetRequestId",
                "SpotFleetRequestState",
                "SpotFleetRequestConfig",
                "CreateTime"
              ],
              "members": {
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                },
                "SpotFleetRequestState": {
                  "locationName": "spotFleetRequestState"
                },
                "SpotFleetRequestConfig": {
                  "shape": "Sh6",
                  "locationName": "spotFleetRequestConfig"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "ActivityStatus": {
                  "locationName": "activityStatus"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S39",
            "locationName": "SpotInstanceRequestId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "Shj",
            "locationName": "spotInstanceRequestSet"
          }
        }
      }
    },
    "DescribeSpotPriceHistory": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "InstanceTypes": {
            "locationName": "InstanceType",
            "type": "list",
            "member": {}
          },
          "ProductDescriptions": {
            "locationName": "ProductDescription",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotPriceHistory": {
            "locationName": "spotPriceHistorySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "SpotPrice": {
                  "locationName": "spotPrice"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeStaleSecurityGroups": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StaleSecurityGroupSet": {
            "locationName": "staleSecurityGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "GroupId"
              ],
              "members": {
                "GroupId": {
                  "locationName": "groupId"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "Description": {
                  "locationName": "description"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "StaleIpPermissions": {
                  "shape": "Si0",
                  "locationName": "staleIpPermissions"
                },
                "StaleIpPermissionsEgress": {
                  "shape": "Si0",
                  "locationName": "staleIpPermissionsEgress"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSubnets": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SubnetIds": {
            "locationName": "SubnetId",
            "type": "list",
            "member": {
              "locationName": "SubnetId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnets": {
            "locationName": "subnetSet",
            "type": "list",
            "member": {
              "shape": "S6q",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "locationName": "tagSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "Key": {
                  "locationName": "key"
                },
                "Value": {
                  "locationName": "value"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeId": {
            "locationName": "volumeId"
          },
          "AutoEnableIO": {
            "shape": "Scl",
            "locationName": "autoEnableIO"
          },
          "ProductCodes": {
            "shape": "Sbn",
            "locationName": "productCodes"
          }
        }
      }
    },
    "DescribeVolumeStatus": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeIds": {
            "shape": "Sii",
            "locationName": "VolumeId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeStatuses": {
            "locationName": "volumeStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "VolumeStatus": {
                  "locationName": "volumeStatus",
                  "type": "structure",
                  "members": {
                    "Status": {
                      "locationName": "status"
                    },
                    "Details": {
                      "locationName": "details",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Status": {
                            "locationName": "status"
                          }
                        }
                      }
                    }
                  }
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "EventType": {
                        "locationName": "eventType"
                      },
                      "Description": {
                        "locationName": "description"
                      },
                      "NotBefore": {
                        "locationName": "notBefore",
                        "type": "timestamp"
                      },
                      "NotAfter": {
                        "locationName": "notAfter",
                        "type": "timestamp"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      }
                    }
                  }
                },
                "Actions": {
                  "locationName": "actionsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Code": {
                        "locationName": "code"
                      },
                      "Description": {
                        "locationName": "description"
                      },
                      "EventType": {
                        "locationName": "eventType"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeIds": {
            "shape": "Sii",
            "locationName": "VolumeId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "locationName": "volumeSet",
            "type": "list",
            "member": {
              "shape": "S6w",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "EnableDnsSupport": {
            "shape": "Scl",
            "locationName": "enableDnsSupport"
          },
          "EnableDnsHostnames": {
            "shape": "Scl",
            "locationName": "enableDnsHostnames"
          }
        }
      }
    },
    "DescribeVpcClassicLink": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcIds": {
            "shape": "Sj2",
            "locationName": "VpcId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "VpcId": {
                  "locationName": "vpcId"
                },
                "ClassicLinkEnabled": {
                  "locationName": "classicLinkEnabled",
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "Sj",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcIds": {
            "shape": "Sj2"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcs",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "VpcId": {
                  "locationName": "vpcId"
                },
                "ClassicLinkDnsSupported": {
                  "locationName": "classicLinkDnsSupported",
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServices": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceNames": {
            "shape": "S2z",
            "locationName": "serviceNameSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "S2z",
            "locationName": "VpcEndpointId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoints": {
            "locationName": "vpcEndpointSet",
            "type": "list",
            "member": {
              "shape": "S77",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcPeeringConnections": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionIds": {
            "shape": "S2z",
            "locationName": "VpcPeeringConnectionId"
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnections": {
            "locationName": "vpcPeeringConnectionSet",
            "type": "list",
            "member": {
              "shape": "Sb",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeVpcs": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcIds": {
            "locationName": "VpcId",
            "type": "list",
            "member": {
              "locationName": "VpcId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "shape": "S72",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeVpnConnections": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpnConnectionIds": {
            "locationName": "VpnConnectionId",
            "type": "list",
            "member": {
              "locationName": "VpnConnectionId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnections": {
            "locationName": "vpnConnectionSet",
            "type": "list",
            "member": {
              "shape": "S7e",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeVpnGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpnGatewayIds": {
            "locationName": "VpnGatewayId",
            "type": "list",
            "member": {
              "locationName": "VpnGatewayId"
            }
          },
          "Filters": {
            "shape": "S8x",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateways": {
            "locationName": "vpnGatewaySet",
            "type": "list",
            "member": {
              "shape": "S7q",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DetachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DetachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "DetachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "AttachmentId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AttachmentId": {
            "locationName": "attachmentId"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DetachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {},
          "InstanceId": {},
          "Device": {},
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S1p"
      }
    },
    "DetachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpnGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpnGatewayId": {},
          "VpcId": {}
        }
      }
    },
    "DisableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId",
          "GatewayId"
        ],
        "members": {
          "RouteTableId": {},
          "GatewayId": {}
        }
      }
    },
    "DisableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {},
          "AssociationId": {}
        }
      }
    },
    "DisassociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S13",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "DisassociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      }
    },
    "DisassociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SubnetId": {
            "locationName": "subnetId"
          },
          "Ipv6CidrBlockAssociation": {
            "shape": "S1a",
            "locationName": "ipv6CidrBlockAssociation"
          }
        }
      }
    },
    "DisassociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "Ipv6CidrBlockAssociation": {
            "shape": "S1f",
            "locationName": "ipv6CidrBlockAssociation"
          }
        }
      }
    },
    "EnableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId",
          "GatewayId"
        ],
        "members": {
          "RouteTableId": {},
          "GatewayId": {}
        }
      }
    },
    "EnableVolumeIO": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {
            "locationName": "volumeId"
          }
        }
      }
    },
    "EnableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "EnableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "GetConsoleOutput": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          },
          "Output": {
            "locationName": "output"
          }
        }
      }
    },
    "GetConsoleScreenshot": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "WakeUp": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "ImageData": {
            "locationName": "imageData"
          }
        }
      }
    },
    "GetHostReservationPurchasePreview": {
      "input": {
        "type": "structure",
        "required": [
          "OfferingId",
          "HostIdSet"
        ],
        "members": {
          "OfferingId": {},
          "HostIdSet": {
            "shape": "Sko"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Purchase": {
            "shape": "Skq",
            "locationName": "purchase"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "CurrencyCode": {
            "locationName": "currencyCode"
          }
        }
      }
    },
    "GetPasswordData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          },
          "PasswordData": {
            "locationName": "passwordData"
          }
        }
      }
    },
    "GetReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "S3",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "S5",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstanceValueSet": {
            "locationName": "reservedInstanceValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservedInstanceId": {
                  "locationName": "reservedInstanceId"
                },
                "ReservationValue": {
                  "shape": "Sky",
                  "locationName": "reservationValue"
                }
              }
            }
          },
          "ReservedInstanceValueRollup": {
            "shape": "Sky",
            "locationName": "reservedInstanceValueRollup"
          },
          "TargetConfigurationValueSet": {
            "locationName": "targetConfigurationValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TargetConfiguration": {
                  "locationName": "targetConfiguration",
                  "type": "structure",
                  "members": {
                    "OfferingId": {
                      "locationName": "offeringId"
                    },
                    "InstanceCount": {
                      "locationName": "instanceCount",
                      "type": "integer"
                    }
                  }
                },
                "ReservationValue": {
                  "shape": "Sky",
                  "locationName": "reservationValue"
                }
              }
            }
          },
          "TargetConfigurationValueRollup": {
            "shape": "Sky",
            "locationName": "targetConfigurationValueRollup"
          },
          "PaymentDue": {
            "locationName": "paymentDue"
          },
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "OutputReservedInstancesWillExpireAt": {
            "locationName": "outputReservedInstancesWillExpireAt",
            "type": "timestamp"
          },
          "IsValidExchange": {
            "locationName": "isValidExchange",
            "type": "boolean"
          },
          "ValidationFailureReason": {
            "locationName": "validationFailureReason"
          }
        }
      }
    },
    "ImportImage": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Description": {},
          "DiskContainers": {
            "locationName": "DiskContainer",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {},
                "Format": {},
                "Url": {},
                "UserBucket": {
                  "shape": "Sl5"
                },
                "DeviceName": {},
                "SnapshotId": {}
              }
            }
          },
          "LicenseType": {},
          "Hypervisor": {},
          "Architecture": {},
          "Platform": {},
          "ClientData": {
            "shape": "Sl6"
          },
          "ClientToken": {},
          "RoleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "Architecture": {
            "locationName": "architecture"
          },
          "LicenseType": {
            "locationName": "licenseType"
          },
          "Platform": {
            "locationName": "platform"
          },
          "Hypervisor": {
            "locationName": "hypervisor"
          },
          "Description": {
            "locationName": "description"
          },
          "SnapshotDetails": {
            "shape": "Sca",
            "locationName": "snapshotDetailSet"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "Progress": {
            "locationName": "progress"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "ImportInstance": {
      "input": {
        "type": "structure",
        "required": [
          "Platform"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Description": {
            "locationName": "description"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "Architecture": {
                "locationName": "architecture"
              },
              "GroupNames": {
                "shape": "Sla",
                "locationName": "GroupName"
              },
              "GroupIds": {
                "shape": "S5e",
                "locationName": "GroupId"
              },
              "AdditionalInfo": {
                "locationName": "additionalInfo"
              },
              "UserData": {
                "locationName": "userData",
                "type": "structure",
                "members": {
                  "Data": {
                    "locationName": "data"
                  }
                }
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "Placement": {
                "shape": "Sdb",
                "locationName": "placement"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "boolean"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "InstanceInitiatedShutdownBehavior": {
                "locationName": "instanceInitiatedShutdownBehavior"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              }
            }
          },
          "DiskImages": {
            "locationName": "diskImage",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Image": {
                  "shape": "Slf"
                },
                "Description": {},
                "Volume": {
                  "shape": "Slg"
                }
              }
            }
          },
          "Platform": {
            "locationName": "platform"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "S9o",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ImportKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName",
          "PublicKeyMaterial"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "PublicKeyMaterial": {
            "locationName": "publicKeyMaterial",
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyName": {
            "locationName": "keyName"
          },
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          }
        }
      }
    },
    "ImportSnapshot": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Description": {},
          "DiskContainer": {
            "type": "structure",
            "members": {
              "Description": {},
              "Format": {},
              "Url": {},
              "UserBucket": {
                "shape": "Sl5"
              }
            }
          },
          "ClientData": {
            "shape": "Sl6"
          },
          "ClientToken": {},
          "RoleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "SnapshotTaskDetail": {
            "shape": "Sch",
            "locationName": "snapshotTaskDetail"
          },
          "Description": {
            "locationName": "description"
          }
        }
      }
    },
    "ImportVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone",
          "Image",
          "Volume"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "Image": {
            "shape": "Slf",
            "locationName": "image"
          },
          "Description": {
            "locationName": "description"
          },
          "Volume": {
            "shape": "Slg",
            "locationName": "volume"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "S9o",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ModifyHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds",
          "AutoPlacement"
        ],
        "members": {
          "HostIds": {
            "shape": "Sau",
            "locationName": "hostId"
          },
          "AutoPlacement": {
            "locationName": "autoPlacement"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "Sr",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "Slr",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ModifyIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "UseLongIds"
        ],
        "members": {
          "Resource": {},
          "UseLongIds": {
            "type": "boolean"
          }
        }
      }
    },
    "ModifyIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "UseLongIds",
          "PrincipalArn"
        ],
        "members": {
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          },
          "PrincipalArn": {
            "locationName": "principalArn"
          }
        }
      }
    },
    "ModifyImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ImageId": {},
          "Attribute": {},
          "OperationType": {},
          "UserIds": {
            "shape": "Slw",
            "locationName": "UserId"
          },
          "UserGroups": {
            "locationName": "UserGroup",
            "type": "list",
            "member": {
              "locationName": "UserGroup"
            }
          },
          "ProductCodes": {
            "locationName": "ProductCode",
            "type": "list",
            "member": {
              "locationName": "ProductCode"
            }
          },
          "Value": {},
          "LaunchPermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "Sbk"
              },
              "Remove": {
                "shape": "Sbk"
              }
            }
          },
          "Description": {
            "shape": "S3w"
          }
        }
      }
    },
    "ModifyInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Attribute": {
            "locationName": "attribute"
          },
          "Value": {
            "locationName": "value"
          },
          "BlockDeviceMappings": {
            "locationName": "blockDeviceMapping",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "DeviceName": {
                  "locationName": "deviceName"
                },
                "Ebs": {
                  "locationName": "ebs",
                  "type": "structure",
                  "members": {
                    "VolumeId": {
                      "locationName": "volumeId"
                    },
                    "DeleteOnTermination": {
                      "locationName": "deleteOnTermination",
                      "type": "boolean"
                    }
                  }
                },
                "VirtualName": {
                  "locationName": "virtualName"
                },
                "NoDevice": {
                  "locationName": "noDevice"
                }
              }
            }
          },
          "SourceDestCheck": {
            "shape": "Scl"
          },
          "DisableApiTermination": {
            "shape": "Scl",
            "locationName": "disableApiTermination"
          },
          "InstanceType": {
            "shape": "S3w",
            "locationName": "instanceType"
          },
          "Kernel": {
            "shape": "S3w",
            "locationName": "kernel"
          },
          "Ramdisk": {
            "shape": "S3w",
            "locationName": "ramdisk"
          },
          "UserData": {
            "locationName": "userData",
            "type": "structure",
            "members": {
              "Value": {
                "locationName": "value",
                "type": "blob"
              }
            }
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "S3w",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "Groups": {
            "shape": "S1j",
            "locationName": "GroupId"
          },
          "EbsOptimized": {
            "shape": "Scl",
            "locationName": "ebsOptimized"
          },
          "SriovNetSupport": {
            "shape": "S3w",
            "locationName": "sriovNetSupport"
          },
          "EnaSupport": {
            "shape": "Scl",
            "locationName": "enaSupport"
          }
        }
      }
    },
    "ModifyInstancePlacement": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Tenancy": {
            "locationName": "tenancy"
          },
          "Affinity": {
            "locationName": "affinity"
          },
          "HostId": {
            "locationName": "hostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "Description": {
            "shape": "S3w",
            "locationName": "description"
          },
          "SourceDestCheck": {
            "shape": "Scl",
            "locationName": "sourceDestCheck"
          },
          "Groups": {
            "shape": "S5e",
            "locationName": "SecurityGroupId"
          },
          "Attachment": {
            "locationName": "attachment",
            "type": "structure",
            "members": {
              "AttachmentId": {
                "locationName": "attachmentId"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "ModifyReservedInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesIds",
          "TargetConfigurations"
        ],
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "ReservedInstancesIds": {
            "shape": "Ser",
            "locationName": "ReservedInstancesId"
          },
          "TargetConfigurations": {
            "locationName": "ReservedInstancesConfigurationSetItemType",
            "type": "list",
            "member": {
              "shape": "Sff",
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesModificationId": {
            "locationName": "reservedInstancesModificationId"
          }
        }
      }
    },
    "ModifySnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SnapshotId": {},
          "Attribute": {},
          "OperationType": {},
          "UserIds": {
            "shape": "Slw",
            "locationName": "UserId"
          },
          "GroupNames": {
            "shape": "Sgb",
            "locationName": "UserGroup"
          },
          "CreateVolumePermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "Sgi"
              },
              "Remove": {
                "shape": "Sgi"
              }
            }
          }
        }
      }
    },
    "ModifySpotFleetRequest": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "TargetCapacity": {
            "locationName": "targetCapacity",
            "type": "integer"
          },
          "ExcessCapacityTerminationPolicy": {
            "locationName": "excessCapacityTerminationPolicy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySubnetAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "SubnetId": {
            "locationName": "subnetId"
          },
          "MapPublicIpOnLaunch": {
            "shape": "Scl"
          },
          "AssignIpv6AddressOnCreation": {
            "shape": "Scl"
          }
        }
      }
    },
    "ModifyVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {},
          "AutoEnableIO": {
            "shape": "Scl"
          }
        }
      }
    },
    "ModifyVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "EnableDnsSupport": {
            "shape": "Scl"
          },
          "EnableDnsHostnames": {
            "shape": "Scl"
          }
        }
      }
    },
    "ModifyVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointId": {},
          "ResetPolicy": {
            "type": "boolean"
          },
          "PolicyDocument": {},
          "AddRouteTableIds": {
            "shape": "S2z",
            "locationName": "AddRouteTableId"
          },
          "RemoveRouteTableIds": {
            "shape": "S2z",
            "locationName": "RemoveRouteTableId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcPeeringConnectionOptions": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {},
          "RequesterPeeringConnectionOptions": {
            "shape": "Smo"
          },
          "AccepterPeeringConnectionOptions": {
            "shape": "Smo"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequesterPeeringConnectionOptions": {
            "shape": "Smq",
            "locationName": "requesterPeeringConnectionOptions"
          },
          "AccepterPeeringConnectionOptions": {
            "shape": "Smq",
            "locationName": "accepterPeeringConnectionOptions"
          }
        }
      }
    },
    "MonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "Smt",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "MoveAddressToVpc": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AllocationId": {
            "locationName": "allocationId"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "PurchaseHostReservation": {
      "input": {
        "type": "structure",
        "required": [
          "OfferingId",
          "HostIdSet"
        ],
        "members": {
          "OfferingId": {},
          "HostIdSet": {
            "shape": "Sko"
          },
          "LimitPrice": {},
          "CurrencyCode": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Purchase": {
            "shape": "Skq",
            "locationName": "purchase"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "PurchaseReservedInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesOfferingId",
          "InstanceCount"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ReservedInstancesOfferingId": {},
          "InstanceCount": {
            "type": "integer"
          },
          "LimitPrice": {
            "locationName": "limitPrice",
            "type": "structure",
            "members": {
              "Amount": {
                "locationName": "amount",
                "type": "double"
              },
              "CurrencyCode": {
                "locationName": "currencyCode"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          }
        }
      }
    },
    "PurchaseScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "PurchaseRequests"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "PurchaseRequests": {
            "locationName": "PurchaseRequest",
            "type": "list",
            "member": {
              "locationName": "PurchaseRequest",
              "type": "structure",
              "required": [
                "PurchaseToken",
                "InstanceCount"
              ],
              "members": {
                "PurchaseToken": {},
                "InstanceCount": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "Sg4",
              "locationName": "item"
            }
          }
        }
      }
    },
    "RebootInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "RegisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ImageLocation": {},
          "Name": {
            "locationName": "name"
          },
          "Description": {
            "locationName": "description"
          },
          "Architecture": {
            "locationName": "architecture"
          },
          "KernelId": {
            "locationName": "kernelId"
          },
          "RamdiskId": {
            "locationName": "ramdiskId"
          },
          "RootDeviceName": {
            "locationName": "rootDeviceName"
          },
          "BlockDeviceMappings": {
            "shape": "S4b",
            "locationName": "BlockDeviceMapping"
          },
          "VirtualizationType": {
            "locationName": "virtualizationType"
          },
          "SriovNetSupport": {
            "locationName": "sriovNetSupport"
          },
          "EnaSupport": {
            "locationName": "enaSupport",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "RejectVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ReleaseAddress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {},
          "AllocationId": {}
        }
      }
    },
    "ReleaseHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds"
        ],
        "members": {
          "HostIds": {
            "shape": "Sau",
            "locationName": "hostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "Sr",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "Slr",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ReplaceIamInstanceProfileAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "AssociationId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S11"
          },
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S13",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "ReplaceNetworkAclAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "NetworkAclId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AssociationId": {
            "locationName": "associationId"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          }
        }
      }
    },
    "ReplaceNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkAclId",
          "RuleNumber",
          "Protocol",
          "RuleAction",
          "Egress"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "IcmpTypeCode": {
            "shape": "S58",
            "locationName": "Icmp"
          },
          "PortRange": {
            "shape": "S59",
            "locationName": "portRange"
          }
        }
      }
    },
    "ReplaceRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          }
        }
      }
    },
    "ReplaceRouteTableAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AssociationId": {
            "locationName": "associationId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          }
        }
      }
    },
    "ReportInstanceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Instances",
          "Status",
          "ReasonCodes"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Instances": {
            "shape": "S9g",
            "locationName": "instanceId"
          },
          "Status": {
            "locationName": "status"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "ReasonCodes": {
            "locationName": "reasonCode",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Description": {
            "locationName": "description"
          }
        }
      }
    },
    "RequestSpotFleet": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestConfig"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestConfig": {
            "shape": "Sh6",
            "locationName": "spotFleetRequestConfig"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      }
    },
    "RequestSpotInstances": {
      "input": {
        "type": "structure",
        "required": [
          "SpotPrice"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "Type": {
            "locationName": "type"
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "LaunchSpecification": {
            "type": "structure",
            "members": {
              "ImageId": {
                "locationName": "imageId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "SecurityGroups": {
                "shape": "S2z",
                "locationName": "SecurityGroup"
              },
              "UserData": {
                "locationName": "userData"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "Placement": {
                "shape": "Sh9",
                "locationName": "placement"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "BlockDeviceMappings": {
                "shape": "Sbq",
                "locationName": "blockDeviceMapping"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "NetworkInterfaces": {
                "shape": "Shb",
                "locationName": "NetworkInterface"
              },
              "IamInstanceProfile": {
                "shape": "S11",
                "locationName": "iamInstanceProfile"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "Monitoring": {
                "shape": "Shp",
                "locationName": "monitoring"
              },
              "SecurityGroupIds": {
                "shape": "S2z",
                "locationName": "SecurityGroupId"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "Shj",
            "locationName": "spotInstanceRequestSet"
          }
        }
      }
    },
    "ResetImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ImageId": {},
          "Attribute": {}
        }
      }
    },
    "ResetInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Attribute": {
            "locationName": "attribute"
          }
        }
      }
    },
    "ResetNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "ResetSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SnapshotId": {},
          "Attribute": {}
        }
      }
    },
    "RestoreAddressToClassic": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "locationName": "status"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      }
    },
    "RevokeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "IpPermissions": {
            "shape": "S1w",
            "locationName": "ipPermissions"
          }
        }
      }
    },
    "RevokeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {},
          "GroupId": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "IpProtocol": {},
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          },
          "CidrIp": {},
          "IpPermissions": {
            "shape": "S1w"
          }
        }
      }
    },
    "RunInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId",
          "MinCount",
          "MaxCount"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ImageId": {},
          "MinCount": {
            "type": "integer"
          },
          "MaxCount": {
            "type": "integer"
          },
          "KeyName": {},
          "SecurityGroups": {
            "shape": "Sla",
            "locationName": "SecurityGroup"
          },
          "SecurityGroupIds": {
            "shape": "S5e",
            "locationName": "SecurityGroupId"
          },
          "UserData": {},
          "InstanceType": {},
          "Placement": {
            "shape": "Sdb"
          },
          "KernelId": {},
          "RamdiskId": {},
          "BlockDeviceMappings": {
            "shape": "S4b",
            "locationName": "BlockDeviceMapping"
          },
          "Monitoring": {
            "shape": "Shp"
          },
          "SubnetId": {},
          "DisableApiTermination": {
            "locationName": "disableApiTermination",
            "type": "boolean"
          },
          "InstanceInitiatedShutdownBehavior": {
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "Ipv6Addresses": {
            "shape": "S5h",
            "locationName": "Ipv6Address"
          },
          "Ipv6AddressCount": {
            "type": "integer"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "NetworkInterfaces": {
            "shape": "Shb",
            "locationName": "networkInterface"
          },
          "IamInstanceProfile": {
            "shape": "S11",
            "locationName": "iamInstanceProfile"
          },
          "EbsOptimized": {
            "locationName": "ebsOptimized",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Sd7"
      }
    },
    "RunScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ScheduledInstanceId",
          "LaunchSpecification"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "InstanceCount": {
            "type": "integer"
          },
          "ScheduledInstanceId": {},
          "LaunchSpecification": {
            "type": "structure",
            "required": [
              "ImageId"
            ],
            "members": {
              "ImageId": {},
              "KeyName": {},
              "SecurityGroupIds": {
                "shape": "So9",
                "locationName": "SecurityGroupId"
              },
              "UserData": {},
              "Placement": {
                "type": "structure",
                "members": {
                  "AvailabilityZone": {},
                  "GroupName": {}
                }
              },
              "KernelId": {},
              "InstanceType": {},
              "RamdiskId": {},
              "BlockDeviceMappings": {
                "locationName": "BlockDeviceMapping",
                "type": "list",
                "member": {
                  "locationName": "BlockDeviceMapping",
                  "type": "structure",
                  "members": {
                    "DeviceName": {},
                    "NoDevice": {},
                    "VirtualName": {},
                    "Ebs": {
                      "type": "structure",
                      "members": {
                        "SnapshotId": {},
                        "VolumeSize": {
                          "type": "integer"
                        },
                        "DeleteOnTermination": {
                          "type": "boolean"
                        },
                        "VolumeType": {},
                        "Iops": {
                          "type": "integer"
                        },
                        "Encrypted": {
                          "type": "boolean"
                        }
                      }
                    }
                  }
                }
              },
              "Monitoring": {
                "type": "structure",
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "SubnetId": {},
              "NetworkInterfaces": {
                "locationName": "NetworkInterface",
                "type": "list",
                "member": {
                  "locationName": "NetworkInterface",
                  "type": "structure",
                  "members": {
                    "NetworkInterfaceId": {},
                    "DeviceIndex": {
                      "type": "integer"
                    },
                    "SubnetId": {},
                    "Description": {},
                    "PrivateIpAddress": {},
                    "PrivateIpAddressConfigs": {
                      "locationName": "PrivateIpAddressConfig",
                      "type": "list",
                      "member": {
                        "locationName": "PrivateIpAddressConfigSet",
                        "type": "structure",
                        "members": {
                          "PrivateIpAddress": {},
                          "Primary": {
                            "type": "boolean"
                          }
                        }
                      }
                    },
                    "SecondaryPrivateIpAddressCount": {
                      "type": "integer"
                    },
                    "AssociatePublicIpAddress": {
                      "type": "boolean"
                    },
                    "Groups": {
                      "shape": "So9",
                      "locationName": "Group"
                    },
                    "DeleteOnTermination": {
                      "type": "boolean"
                    },
                    "Ipv6Addresses": {
                      "locationName": "Ipv6Address",
                      "type": "list",
                      "member": {
                        "locationName": "Ipv6Address",
                        "type": "structure",
                        "members": {
                          "Ipv6Address": {}
                        }
                      }
                    },
                    "Ipv6AddressCount": {
                      "type": "integer"
                    }
                  }
                }
              },
              "IamInstanceProfile": {
                "type": "structure",
                "members": {
                  "Arn": {},
                  "Name": {}
                }
              },
              "EbsOptimized": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceIdSet": {
            "locationName": "instanceIdSet",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      }
    },
    "StartInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          },
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StartingInstances": {
            "shape": "Sor",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "StopInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StoppingInstances": {
            "shape": "Sor",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "TerminateInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminatingInstances": {
            "shape": "Sor",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "UnassignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "Ipv6Addresses"
        ],
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "Ipv6Addresses": {
            "shape": "St",
            "locationName": "ipv6Addresses"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "UnassignedIpv6Addresses": {
            "shape": "St",
            "locationName": "unassignedIpv6Addresses"
          }
        }
      }
    },
    "UnassignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "PrivateIpAddresses"
        ],
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "Sw",
            "locationName": "privateIpAddress"
          }
        }
      }
    },
    "UnmonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "S9g",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "Smt",
            "locationName": "instancesSet"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstanceId"
      }
    },
    "S5": {
      "type": "list",
      "member": {
        "locationName": "TargetConfigurationRequest",
        "type": "structure",
        "required": [
          "OfferingId"
        ],
        "members": {
          "OfferingId": {},
          "InstanceCount": {
            "type": "integer"
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "AccepterVpcInfo": {
          "shape": "Sc",
          "locationName": "accepterVpcInfo"
        },
        "ExpirationTime": {
          "locationName": "expirationTime",
          "type": "timestamp"
        },
        "RequesterVpcInfo": {
          "shape": "Sc",
          "locationName": "requesterVpcInfo"
        },
        "Status": {
          "locationName": "status",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        }
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Ipv6CidrBlockSet": {
          "locationName": "ipv6CidrBlockSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              }
            }
          }
        },
        "PeeringOptions": {
          "locationName": "peeringOptions",
          "type": "structure",
          "members": {
            "AllowEgressFromLocalClassicLinkToRemoteVpc": {
              "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
              "type": "boolean"
            },
            "AllowEgressFromLocalVpcToRemoteClassicLink": {
              "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
              "type": "boolean"
            },
            "AllowDnsResolutionFromRemoteVpc": {
              "locationName": "allowDnsResolutionFromRemoteVpc",
              "type": "boolean"
            }
          }
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Key": {
            "locationName": "key"
          },
          "Value": {
            "locationName": "value"
          }
        }
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "St": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "locationName": "PrivateIpAddress"
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "IamInstanceProfile": {
          "shape": "S14",
          "locationName": "iamInstanceProfile"
        },
        "State": {
          "locationName": "state"
        },
        "Timestamp": {
          "locationName": "timestamp",
          "type": "timestamp"
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Id": {
          "locationName": "id"
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "locationName": "ipv6CidrBlockState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        },
        "AssociationId": {
          "locationName": "associationId"
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "locationName": "ipv6CidrBlockState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        },
        "AssociationId": {
          "locationName": "associationId"
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "locationName": "groupId"
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "VolumeId": {
          "locationName": "volumeId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "Device": {
          "locationName": "device"
        },
        "State": {
          "locationName": "status"
        },
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "VpcId": {
          "locationName": "vpcId"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S1w": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S1z",
              "locationName": "item"
            }
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIp": {
                  "locationName": "cidrIp"
                }
              }
            }
          },
          "Ipv6Ranges": {
            "locationName": "ipv6Ranges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIpv6": {
                  "locationName": "cidrIpv6"
                }
              }
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrefixListId": {
                  "locationName": "prefixListId"
                }
              }
            }
          }
        }
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "UserId": {
          "locationName": "userId"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "GroupId": {
          "locationName": "groupId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        },
        "PeeringStatus": {
          "locationName": "peeringStatus"
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "S3": {
          "type": "structure",
          "members": {
            "Bucket": {
              "locationName": "bucket"
            },
            "Prefix": {
              "locationName": "prefix"
            },
            "AWSAccessKeyId": {},
            "UploadPolicy": {
              "locationName": "uploadPolicy",
              "type": "blob"
            },
            "UploadPolicySignature": {
              "locationName": "uploadPolicySignature"
            }
          }
        }
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "InstanceId": {
          "locationName": "instanceId"
        },
        "BundleId": {
          "locationName": "bundleId"
        },
        "State": {
          "locationName": "state"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "UpdateTime": {
          "locationName": "updateTime",
          "type": "timestamp"
        },
        "Storage": {
          "shape": "S28",
          "locationName": "storage"
        },
        "Progress": {
          "locationName": "progress"
        },
        "BundleTaskError": {
          "locationName": "error",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        }
      }
    },
    "S2n": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "CreateDate": {
            "locationName": "createDate",
            "type": "timestamp"
          },
          "UpdateDate": {
            "locationName": "updateDate",
            "type": "timestamp"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "InstanceCounts": {
            "locationName": "instanceCounts",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "State": {
                  "locationName": "state"
                },
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                }
              }
            }
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Term": {
                  "locationName": "term",
                  "type": "long"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Active": {
                  "locationName": "active",
                  "type": "boolean"
                }
              }
            }
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "S2z": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S39": {
      "type": "list",
      "member": {
        "locationName": "SpotInstanceRequestId"
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "IpAddress": {
          "locationName": "ipAddress"
        },
        "BgpAsn": {
          "locationName": "bgpAsn"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S3s": {
      "type": "structure",
      "members": {
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "DhcpConfigurations": {
          "locationName": "dhcpConfigurationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Key": {
                "locationName": "key"
              },
              "Values": {
                "locationName": "valueSet",
                "type": "list",
                "member": {
                  "shape": "S3w",
                  "locationName": "item"
                }
              }
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S3w": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value"
        }
      }
    },
    "S3z": {
      "type": "structure",
      "members": {
        "EgressOnlyInternetGatewayId": {
          "locationName": "egressOnlyInternetGatewayId"
        },
        "Attachments": {
          "shape": "S41",
          "locationName": "attachmentSet"
        }
      }
    },
    "S41": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "S47": {
      "type": "list",
      "member": {
        "shape": "S48",
        "locationName": "item"
      }
    },
    "S48": {
      "type": "structure",
      "required": [
        "Error"
      ],
      "members": {
        "ResourceId": {
          "locationName": "resourceId"
        },
        "Error": {
          "locationName": "error",
          "type": "structure",
          "required": [
            "Code",
            "Message"
          ],
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        }
      }
    },
    "S4b": {
      "type": "list",
      "member": {
        "shape": "S4c",
        "locationName": "BlockDeviceMapping"
      }
    },
    "S4c": {
      "type": "structure",
      "members": {
        "VirtualName": {
          "locationName": "virtualName"
        },
        "DeviceName": {
          "locationName": "deviceName"
        },
        "Ebs": {
          "locationName": "ebs",
          "type": "structure",
          "members": {
            "SnapshotId": {
              "locationName": "snapshotId"
            },
            "VolumeSize": {
              "locationName": "volumeSize",
              "type": "integer"
            },
            "DeleteOnTermination": {
              "locationName": "deleteOnTermination",
              "type": "boolean"
            },
            "VolumeType": {
              "locationName": "volumeType"
            },
            "Iops": {
              "locationName": "iops",
              "type": "integer"
            },
            "Encrypted": {
              "locationName": "encrypted",
              "type": "boolean"
            }
          }
        },
        "NoDevice": {
          "locationName": "noDevice"
        }
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "ExportTaskId": {
          "locationName": "exportTaskId"
        },
        "Description": {
          "locationName": "description"
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "InstanceExportDetails": {
          "locationName": "instanceExport",
          "type": "structure",
          "members": {
            "InstanceId": {
              "locationName": "instanceId"
            },
            "TargetEnvironment": {
              "locationName": "targetEnvironment"
            }
          }
        },
        "ExportToS3Task": {
          "locationName": "exportToS3",
          "type": "structure",
          "members": {
            "DiskImageFormat": {
              "locationName": "diskImageFormat"
            },
            "ContainerFormat": {
              "locationName": "containerFormat"
            },
            "S3Bucket": {
              "locationName": "s3Bucket"
            },
            "S3Key": {
              "locationName": "s3Key"
            }
          }
        }
      }
    },
    "S4s": {
      "type": "structure",
      "members": {
        "InternetGatewayId": {
          "locationName": "internetGatewayId"
        },
        "Attachments": {
          "shape": "S41",
          "locationName": "attachmentSet"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S4x": {
      "type": "structure",
      "members": {
        "VpcId": {
          "locationName": "vpcId"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "NatGatewayId": {
          "locationName": "natGatewayId"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "DeleteTime": {
          "locationName": "deleteTime",
          "type": "timestamp"
        },
        "NatGatewayAddresses": {
          "locationName": "natGatewayAddressSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "PublicIp": {
                "locationName": "publicIp"
              },
              "AllocationId": {
                "locationName": "allocationId"
              },
              "PrivateIp": {
                "locationName": "privateIp"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              }
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "FailureCode": {
          "locationName": "failureCode"
        },
        "FailureMessage": {
          "locationName": "failureMessage"
        },
        "ProvisionedBandwidth": {
          "locationName": "provisionedBandwidth",
          "type": "structure",
          "members": {
            "Provisioned": {
              "locationName": "provisioned"
            },
            "Requested": {
              "locationName": "requested"
            },
            "RequestTime": {
              "locationName": "requestTime",
              "type": "timestamp"
            },
            "ProvisionTime": {
              "locationName": "provisionTime",
              "type": "timestamp"
            },
            "Status": {
              "locationName": "status"
            }
          }
        }
      }
    },
    "S54": {
      "type": "structure",
      "members": {
        "NetworkAclId": {
          "locationName": "networkAclId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "IsDefault": {
          "locationName": "default",
          "type": "boolean"
        },
        "Entries": {
          "locationName": "entrySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "RuleNumber": {
                "locationName": "ruleNumber",
                "type": "integer"
              },
              "Protocol": {
                "locationName": "protocol"
              },
              "RuleAction": {
                "locationName": "ruleAction"
              },
              "Egress": {
                "locationName": "egress",
                "type": "boolean"
              },
              "CidrBlock": {
                "locationName": "cidrBlock"
              },
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              },
              "IcmpTypeCode": {
                "shape": "S58",
                "locationName": "icmpTypeCode"
              },
              "PortRange": {
                "shape": "S59",
                "locationName": "portRange"
              }
            }
          }
        },
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "NetworkAclAssociationId": {
                "locationName": "networkAclAssociationId"
              },
              "NetworkAclId": {
                "locationName": "networkAclId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              }
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S58": {
      "type": "structure",
      "members": {
        "Type": {
          "locationName": "type",
          "type": "integer"
        },
        "Code": {
          "locationName": "code",
          "type": "integer"
        }
      }
    },
    "S59": {
      "type": "structure",
      "members": {
        "From": {
          "locationName": "from",
          "type": "integer"
        },
        "To": {
          "locationName": "to",
          "type": "integer"
        }
      }
    },
    "S5e": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S5f": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "required": [
          "PrivateIpAddress"
        ],
        "members": {
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "Primary": {
            "locationName": "primary",
            "type": "boolean"
          }
        }
      }
    },
    "S5h": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Ipv6Address": {
            "locationName": "ipv6Address"
          }
        }
      }
    },
    "S5k": {
      "type": "structure",
      "members": {
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Description": {
          "locationName": "description"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "RequesterManaged": {
          "locationName": "requesterManaged",
          "type": "boolean"
        },
        "Status": {
          "locationName": "status"
        },
        "MacAddress": {
          "locationName": "macAddress"
        },
        "PrivateIpAddress": {
          "locationName": "privateIpAddress"
        },
        "PrivateDnsName": {
          "locationName": "privateDnsName"
        },
        "SourceDestCheck": {
          "locationName": "sourceDestCheck",
          "type": "boolean"
        },
        "Groups": {
          "shape": "S5m",
          "locationName": "groupSet"
        },
        "Attachment": {
          "shape": "S5o",
          "locationName": "attachment"
        },
        "Association": {
          "shape": "S5p",
          "locationName": "association"
        },
        "TagSet": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "PrivateIpAddresses": {
          "locationName": "privateIpAddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "Primary": {
                "locationName": "primary",
                "type": "boolean"
              },
              "Association": {
                "shape": "S5p",
                "locationName": "association"
              }
            }
          }
        },
        "Ipv6Addresses": {
          "locationName": "ipv6AddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6Address": {
                "locationName": "ipv6Address"
              }
            }
          }
        },
        "InterfaceType": {
          "locationName": "interfaceType"
        }
      }
    },
    "S5m": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "GroupName": {
            "locationName": "groupName"
          },
          "GroupId": {
            "locationName": "groupId"
          }
        }
      }
    },
    "S5o": {
      "type": "structure",
      "members": {
        "AttachmentId": {
          "locationName": "attachmentId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "InstanceOwnerId": {
          "locationName": "instanceOwnerId"
        },
        "DeviceIndex": {
          "locationName": "deviceIndex",
          "type": "integer"
        },
        "Status": {
          "locationName": "status"
        },
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        }
      }
    },
    "S5p": {
      "type": "structure",
      "members": {
        "PublicIp": {
          "locationName": "publicIp"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        },
        "AllocationId": {
          "locationName": "allocationId"
        },
        "AssociationId": {
          "locationName": "associationId"
        }
      }
    },
    "S65": {
      "type": "structure",
      "members": {
        "RouteTableId": {
          "locationName": "routeTableId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Routes": {
          "locationName": "routeSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "DestinationPrefixListId": {
                "locationName": "destinationPrefixListId"
              },
              "GatewayId": {
                "locationName": "gatewayId"
              },
              "InstanceId": {
                "locationName": "instanceId"
              },
              "InstanceOwnerId": {
                "locationName": "instanceOwnerId"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "VpcPeeringConnectionId": {
                "locationName": "vpcPeeringConnectionId"
              },
              "NatGatewayId": {
                "locationName": "natGatewayId"
              },
              "State": {
                "locationName": "state"
              },
              "Origin": {
                "locationName": "origin"
              },
              "DestinationIpv6CidrBlock": {
                "locationName": "destinationIpv6CidrBlock"
              },
              "EgressOnlyInternetGatewayId": {
                "locationName": "egressOnlyInternetGatewayId"
              }
            }
          }
        },
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "RouteTableAssociationId": {
                "locationName": "routeTableAssociationId"
              },
              "RouteTableId": {
                "locationName": "routeTableId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "Main": {
                "locationName": "main",
                "type": "boolean"
              }
            }
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "PropagatingVgws": {
          "locationName": "propagatingVgwSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "GatewayId": {
                "locationName": "gatewayId"
              }
            }
          }
        }
      }
    },
    "S6h": {
      "type": "structure",
      "members": {
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "State": {
          "locationName": "status"
        },
        "StateMessage": {
          "locationName": "statusMessage"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "Progress": {
          "locationName": "progress"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Description": {
          "locationName": "description"
        },
        "VolumeSize": {
          "locationName": "volumeSize",
          "type": "integer"
        },
        "OwnerAlias": {
          "locationName": "ownerAlias"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "DataEncryptionKeyId": {
          "locationName": "dataEncryptionKeyId"
        }
      }
    },
    "S6l": {
      "type": "structure",
      "members": {
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Bucket": {
          "locationName": "bucket"
        },
        "Prefix": {
          "locationName": "prefix"
        },
        "State": {
          "locationName": "state"
        },
        "Fault": {
          "shape": "S6n",
          "locationName": "fault"
        }
      }
    },
    "S6n": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S6q": {
      "type": "structure",
      "members": {
        "SubnetId": {
          "locationName": "subnetId"
        },
        "State": {
          "locationName": "state"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S1a",
            "locationName": "item"
          }
        },
        "AssignIpv6AddressOnCreation": {
          "locationName": "assignIpv6AddressOnCreation",
          "type": "boolean"
        },
        "AvailableIpAddressCount": {
          "locationName": "availableIpAddressCount",
          "type": "integer"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "DefaultForAz": {
          "locationName": "defaultForAz",
          "type": "boolean"
        },
        "MapPublicIpOnLaunch": {
          "locationName": "mapPublicIpOnLaunch",
          "type": "boolean"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S6u": {
      "type": "list",
      "member": {}
    },
    "S6w": {
      "type": "structure",
      "members": {
        "VolumeId": {
          "locationName": "volumeId"
        },
        "Size": {
          "locationName": "size",
          "type": "integer"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "State": {
          "locationName": "status"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "Attachments": {
          "locationName": "attachmentSet",
          "type": "list",
          "member": {
            "shape": "S1p",
            "locationName": "item"
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VolumeType": {
          "locationName": "volumeType"
        },
        "Iops": {
          "locationName": "iops",
          "type": "integer"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        }
      }
    },
    "S72": {
      "type": "structure",
      "members": {
        "VpcId": {
          "locationName": "vpcId"
        },
        "State": {
          "locationName": "state"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "InstanceTenancy": {
          "locationName": "instanceTenancy"
        },
        "IsDefault": {
          "locationName": "isDefault",
          "type": "boolean"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S1f",
            "locationName": "item"
          }
        }
      }
    },
    "S77": {
      "type": "structure",
      "members": {
        "VpcEndpointId": {
          "locationName": "vpcEndpointId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "ServiceName": {
          "locationName": "serviceName"
        },
        "State": {
          "locationName": "state"
        },
        "PolicyDocument": {
          "locationName": "policyDocument"
        },
        "RouteTableIds": {
          "shape": "S2z",
          "locationName": "routeTableIdSet"
        },
        "CreationTimestamp": {
          "locationName": "creationTimestamp",
          "type": "timestamp"
        }
      }
    },
    "S7e": {
      "type": "structure",
      "members": {
        "VpnConnectionId": {
          "locationName": "vpnConnectionId"
        },
        "State": {
          "locationName": "state"
        },
        "CustomerGatewayConfiguration": {
          "locationName": "customerGatewayConfiguration"
        },
        "Type": {
          "locationName": "type"
        },
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        },
        "VgwTelemetry": {
          "locationName": "vgwTelemetry",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "OutsideIpAddress": {
                "locationName": "outsideIpAddress"
              },
              "Status": {
                "locationName": "status"
              },
              "LastStatusChange": {
                "locationName": "lastStatusChange",
                "type": "timestamp"
              },
              "StatusMessage": {
                "locationName": "statusMessage"
              },
              "AcceptedRouteCount": {
                "locationName": "acceptedRouteCount",
                "type": "integer"
              }
            }
          }
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "StaticRoutesOnly": {
              "locationName": "staticRoutesOnly",
              "type": "boolean"
            }
          }
        },
        "Routes": {
          "locationName": "routes",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "Source": {
                "locationName": "source"
              },
              "State": {
                "locationName": "state"
              }
            }
          }
        }
      }
    },
    "S7q": {
      "type": "structure",
      "members": {
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "VpcAttachments": {
          "locationName": "attachments",
          "type": "list",
          "member": {
            "shape": "S1t",
            "locationName": "item"
          }
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S8x": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "shape": "S2z",
            "locationName": "Value"
          }
        }
      }
    },
    "S9g": {
      "type": "list",
      "member": {
        "locationName": "InstanceId"
      }
    },
    "S9o": {
      "type": "structure",
      "required": [
        "ConversionTaskId",
        "State"
      ],
      "members": {
        "ConversionTaskId": {
          "locationName": "conversionTaskId"
        },
        "ExpirationTime": {
          "locationName": "expirationTime"
        },
        "ImportInstance": {
          "locationName": "importInstance",
          "type": "structure",
          "required": [
            "Volumes"
          ],
          "members": {
            "Volumes": {
              "locationName": "volumes",
              "type": "list",
              "member": {
                "locationName": "item",
                "type": "structure",
                "required": [
                  "BytesConverted",
                  "AvailabilityZone",
                  "Image",
                  "Volume",
                  "Status"
                ],
                "members": {
                  "BytesConverted": {
                    "locationName": "bytesConverted",
                    "type": "long"
                  },
                  "AvailabilityZone": {
                    "locationName": "availabilityZone"
                  },
                  "Image": {
                    "shape": "S9s",
                    "locationName": "image"
                  },
                  "Volume": {
                    "shape": "S9t",
                    "locationName": "volume"
                  },
                  "Status": {
                    "locationName": "status"
                  },
                  "StatusMessage": {
                    "locationName": "statusMessage"
                  },
                  "Description": {
                    "locationName": "description"
                  }
                }
              }
            },
            "InstanceId": {
              "locationName": "instanceId"
            },
            "Platform": {
              "locationName": "platform"
            },
            "Description": {
              "locationName": "description"
            }
          }
        },
        "ImportVolume": {
          "locationName": "importVolume",
          "type": "structure",
          "required": [
            "BytesConverted",
            "AvailabilityZone",
            "Image",
            "Volume"
          ],
          "members": {
            "BytesConverted": {
              "locationName": "bytesConverted",
              "type": "long"
            },
            "AvailabilityZone": {
              "locationName": "availabilityZone"
            },
            "Description": {
              "locationName": "description"
            },
            "Image": {
              "shape": "S9s",
              "locationName": "image"
            },
            "Volume": {
              "shape": "S9t",
              "locationName": "volume"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Tags": {
          "shape": "Sj",
          "locationName": "tagSet"
        }
      }
    },
    "S9s": {
      "type": "structure",
      "required": [
        "Format",
        "Size",
        "ImportManifestUrl"
      ],
      "members": {
        "Format": {
          "locationName": "format"
        },
        "Size": {
          "locationName": "size",
          "type": "long"
        },
        "ImportManifestUrl": {
          "locationName": "importManifestUrl"
        },
        "Checksum": {
          "locationName": "checksum"
        }
      }
    },
    "S9t": {
      "type": "structure",
      "required": [
        "Id"
      ],
      "members": {
        "Size": {
          "locationName": "size",
          "type": "long"
        },
        "Id": {
          "locationName": "id"
        }
      }
    },
    "Sar": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sau": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sbd": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          },
          "Deadline": {
            "locationName": "deadline",
            "type": "timestamp"
          }
        }
      }
    },
    "Sbk": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "UserId": {
            "locationName": "userId"
          },
          "Group": {
            "locationName": "group"
          }
        }
      }
    },
    "Sbn": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ProductCodeId": {
            "locationName": "productCode"
          },
          "ProductCodeType": {
            "locationName": "type"
          }
        }
      }
    },
    "Sbq": {
      "type": "list",
      "member": {
        "shape": "S4c",
        "locationName": "item"
      }
    },
    "Sbt": {
      "type": "list",
      "member": {
        "locationName": "Owner"
      }
    },
    "Sc1": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sc6": {
      "type": "list",
      "member": {
        "locationName": "ImportTaskId"
      }
    },
    "Sca": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DiskImageSize": {
            "locationName": "diskImageSize",
            "type": "double"
          },
          "Description": {
            "locationName": "description"
          },
          "Format": {
            "locationName": "format"
          },
          "Url": {
            "locationName": "url"
          },
          "UserBucket": {
            "shape": "Scc",
            "locationName": "userBucket"
          },
          "DeviceName": {
            "locationName": "deviceName"
          },
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "Progress": {
            "locationName": "progress"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "Scc": {
      "type": "structure",
      "members": {
        "S3Bucket": {
          "locationName": "s3Bucket"
        },
        "S3Key": {
          "locationName": "s3Key"
        }
      }
    },
    "Sch": {
      "type": "structure",
      "members": {
        "DiskImageSize": {
          "locationName": "diskImageSize",
          "type": "double"
        },
        "Description": {
          "locationName": "description"
        },
        "Format": {
          "locationName": "format"
        },
        "Url": {
          "locationName": "url"
        },
        "UserBucket": {
          "shape": "Scc",
          "locationName": "userBucket"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Status": {
          "locationName": "status"
        }
      }
    },
    "Scl": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value",
          "type": "boolean"
        }
      }
    },
    "Scm": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DeviceName": {
            "locationName": "deviceName"
          },
          "Ebs": {
            "locationName": "ebs",
            "type": "structure",
            "members": {
              "VolumeId": {
                "locationName": "volumeId"
              },
              "Status": {
                "locationName": "status"
              },
              "AttachTime": {
                "locationName": "attachTime",
                "type": "timestamp"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "Scw": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code",
          "type": "integer"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "Scy": {
      "type": "structure",
      "members": {
        "Status": {
          "locationName": "status"
        },
        "Details": {
          "locationName": "details",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Name": {
                "locationName": "name"
              },
              "Status": {
                "locationName": "status"
              },
              "ImpairedSince": {
                "locationName": "impairedSince",
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "Sd7": {
      "type": "structure",
      "members": {
        "ReservationId": {
          "locationName": "reservationId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "Groups": {
          "shape": "S5m",
          "locationName": "groupSet"
        },
        "Instances": {
          "locationName": "instancesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "InstanceId": {
                "locationName": "instanceId"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "State": {
                "shape": "Scw",
                "locationName": "instanceState"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "PublicDnsName": {
                "locationName": "dnsName"
              },
              "StateTransitionReason": {
                "locationName": "reason"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "AmiLaunchIndex": {
                "locationName": "amiLaunchIndex",
                "type": "integer"
              },
              "ProductCodes": {
                "shape": "Sbn",
                "locationName": "productCodes"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "LaunchTime": {
                "locationName": "launchTime",
                "type": "timestamp"
              },
              "Placement": {
                "shape": "Sdb",
                "locationName": "placement"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "Platform": {
                "locationName": "platform"
              },
              "Monitoring": {
                "shape": "Sdc",
                "locationName": "monitoring"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "VpcId": {
                "locationName": "vpcId"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "PublicIpAddress": {
                "locationName": "ipAddress"
              },
              "StateReason": {
                "shape": "Sc1",
                "locationName": "stateReason"
              },
              "Architecture": {
                "locationName": "architecture"
              },
              "RootDeviceType": {
                "locationName": "rootDeviceType"
              },
              "RootDeviceName": {
                "locationName": "rootDeviceName"
              },
              "BlockDeviceMappings": {
                "shape": "Scm",
                "locationName": "blockDeviceMapping"
              },
              "VirtualizationType": {
                "locationName": "virtualizationType"
              },
              "InstanceLifecycle": {
                "locationName": "instanceLifecycle"
              },
              "SpotInstanceRequestId": {
                "locationName": "spotInstanceRequestId"
              },
              "ClientToken": {
                "locationName": "clientToken"
              },
              "Tags": {
                "shape": "Sj",
                "locationName": "tagSet"
              },
              "SecurityGroups": {
                "shape": "S5m",
                "locationName": "groupSet"
              },
              "SourceDestCheck": {
                "locationName": "sourceDestCheck",
                "type": "boolean"
              },
              "Hypervisor": {
                "locationName": "hypervisor"
              },
              "NetworkInterfaces": {
                "locationName": "networkInterfaceSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "NetworkInterfaceId": {
                      "locationName": "networkInterfaceId"
                    },
                    "SubnetId": {
                      "locationName": "subnetId"
                    },
                    "VpcId": {
                      "locationName": "vpcId"
                    },
                    "Description": {
                      "locationName": "description"
                    },
                    "OwnerId": {
                      "locationName": "ownerId"
                    },
                    "Status": {
                      "locationName": "status"
                    },
                    "MacAddress": {
                      "locationName": "macAddress"
                    },
                    "PrivateIpAddress": {
                      "locationName": "privateIpAddress"
                    },
                    "PrivateDnsName": {
                      "locationName": "privateDnsName"
                    },
                    "SourceDestCheck": {
                      "locationName": "sourceDestCheck",
                      "type": "boolean"
                    },
                    "Groups": {
                      "shape": "S5m",
                      "locationName": "groupSet"
                    },
                    "Attachment": {
                      "locationName": "attachment",
                      "type": "structure",
                      "members": {
                        "AttachmentId": {
                          "locationName": "attachmentId"
                        },
                        "DeviceIndex": {
                          "locationName": "deviceIndex",
                          "type": "integer"
                        },
                        "Status": {
                          "locationName": "status"
                        },
                        "AttachTime": {
                          "locationName": "attachTime",
                          "type": "timestamp"
                        },
                        "DeleteOnTermination": {
                          "locationName": "deleteOnTermination",
                          "type": "boolean"
                        }
                      }
                    },
                    "Association": {
                      "shape": "Sdi",
                      "locationName": "association"
                    },
                    "PrivateIpAddresses": {
                      "locationName": "privateIpAddressesSet",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "PrivateIpAddress": {
                            "locationName": "privateIpAddress"
                          },
                          "PrivateDnsName": {
                            "locationName": "privateDnsName"
                          },
                          "Primary": {
                            "locationName": "primary",
                            "type": "boolean"
                          },
                          "Association": {
                            "shape": "Sdi",
                            "locationName": "association"
                          }
                        }
                      }
                    },
                    "Ipv6Addresses": {
                      "shape": "S5h",
                      "locationName": "ipv6AddressesSet"
                    }
                  }
                }
              },
              "IamInstanceProfile": {
                "shape": "S14",
                "locationName": "iamInstanceProfile"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "SriovNetSupport": {
                "locationName": "sriovNetSupport"
              },
              "EnaSupport": {
                "locationName": "enaSupport",
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "Sdb": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "Tenancy": {
          "locationName": "tenancy"
        },
        "HostId": {
          "locationName": "hostId"
        },
        "Affinity": {
          "locationName": "affinity"
        }
      }
    },
    "Sdc": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sdi": {
      "type": "structure",
      "members": {
        "PublicIp": {
          "locationName": "publicIp"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        }
      }
    },
    "Ser": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstancesId"
      }
    },
    "Sf0": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Frequency": {
            "locationName": "frequency"
          },
          "Amount": {
            "locationName": "amount",
            "type": "double"
          }
        }
      }
    },
    "Sff": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Platform": {
          "locationName": "platform"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "Scope": {
          "locationName": "scope"
        }
      }
    },
    "Sfx": {
      "type": "structure",
      "members": {
        "Frequency": {
          "locationName": "frequency"
        },
        "Interval": {
          "locationName": "interval",
          "type": "integer"
        },
        "OccurrenceDaySet": {
          "locationName": "occurrenceDaySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "integer"
          }
        },
        "OccurrenceRelativeToEnd": {
          "locationName": "occurrenceRelativeToEnd",
          "type": "boolean"
        },
        "OccurrenceUnit": {
          "locationName": "occurrenceUnit"
        }
      }
    },
    "Sg4": {
      "type": "structure",
      "members": {
        "ScheduledInstanceId": {
          "locationName": "scheduledInstanceId"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "Platform": {
          "locationName": "platform"
        },
        "NetworkPlatform": {
          "locationName": "networkPlatform"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "SlotDurationInHours": {
          "locationName": "slotDurationInHours",
          "type": "integer"
        },
        "Recurrence": {
          "shape": "Sfx",
          "locationName": "recurrence"
        },
        "PreviousSlotEndTime": {
          "locationName": "previousSlotEndTime",
          "type": "timestamp"
        },
        "NextSlotStartTime": {
          "locationName": "nextSlotStartTime",
          "type": "timestamp"
        },
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        },
        "TotalScheduledInstanceHours": {
          "locationName": "totalScheduledInstanceHours",
          "type": "integer"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "TermStartDate": {
          "locationName": "termStartDate",
          "type": "timestamp"
        },
        "TermEndDate": {
          "locationName": "termEndDate",
          "type": "timestamp"
        },
        "CreateDate": {
          "locationName": "createDate",
          "type": "timestamp"
        }
      }
    },
    "Sgb": {
      "type": "list",
      "member": {
        "locationName": "GroupName"
      }
    },
    "Sgi": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "UserId": {
            "locationName": "userId"
          },
          "Group": {
            "locationName": "group"
          }
        }
      }
    },
    "Sh6": {
      "type": "structure",
      "required": [
        "SpotPrice",
        "TargetCapacity",
        "IamFleetRole",
        "LaunchSpecifications"
      ],
      "members": {
        "ClientToken": {
          "locationName": "clientToken"
        },
        "SpotPrice": {
          "locationName": "spotPrice"
        },
        "TargetCapacity": {
          "locationName": "targetCapacity",
          "type": "integer"
        },
        "ValidFrom": {
          "locationName": "validFrom",
          "type": "timestamp"
        },
        "ValidUntil": {
          "locationName": "validUntil",
          "type": "timestamp"
        },
        "TerminateInstancesWithExpiration": {
          "locationName": "terminateInstancesWithExpiration",
          "type": "boolean"
        },
        "IamFleetRole": {
          "locationName": "iamFleetRole"
        },
        "LaunchSpecifications": {
          "locationName": "launchSpecifications",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ImageId": {
                "locationName": "imageId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "SecurityGroups": {
                "shape": "S5m",
                "locationName": "groupSet"
              },
              "UserData": {
                "locationName": "userData"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "Placement": {
                "shape": "Sh9",
                "locationName": "placement"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "BlockDeviceMappings": {
                "shape": "Sbq",
                "locationName": "blockDeviceMapping"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "structure",
                "members": {
                  "Enabled": {
                    "locationName": "enabled",
                    "type": "boolean"
                  }
                }
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "NetworkInterfaces": {
                "shape": "Shb",
                "locationName": "networkInterfaceSet"
              },
              "IamInstanceProfile": {
                "shape": "S11",
                "locationName": "iamInstanceProfile"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "WeightedCapacity": {
                "locationName": "weightedCapacity",
                "type": "double"
              },
              "SpotPrice": {
                "locationName": "spotPrice"
              }
            }
          }
        },
        "ExcessCapacityTerminationPolicy": {
          "locationName": "excessCapacityTerminationPolicy"
        },
        "AllocationStrategy": {
          "locationName": "allocationStrategy"
        },
        "FulfilledCapacity": {
          "locationName": "fulfilledCapacity",
          "type": "double"
        },
        "Type": {
          "locationName": "type"
        },
        "ReplaceUnhealthyInstances": {
          "locationName": "replaceUnhealthyInstances",
          "type": "boolean"
        }
      }
    },
    "Sh9": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "Tenancy": {
          "locationName": "tenancy"
        }
      }
    },
    "Shb": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "Description": {
            "locationName": "description"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "Groups": {
            "shape": "S5e",
            "locationName": "SecurityGroupId"
          },
          "DeleteOnTermination": {
            "locationName": "deleteOnTermination",
            "type": "boolean"
          },
          "PrivateIpAddresses": {
            "shape": "S5f",
            "locationName": "privateIpAddressesSet",
            "queryName": "PrivateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "AssociatePublicIpAddress": {
            "locationName": "associatePublicIpAddress",
            "type": "boolean"
          },
          "Ipv6Addresses": {
            "shape": "S5h",
            "locationName": "ipv6AddressesSet",
            "queryName": "Ipv6Addresses"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          }
        }
      }
    },
    "Shj": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "SpotInstanceRequestId": {
            "locationName": "spotInstanceRequestId"
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "Type": {
            "locationName": "type"
          },
          "State": {
            "locationName": "state"
          },
          "Fault": {
            "shape": "S6n",
            "locationName": "fault"
          },
          "Status": {
            "locationName": "status",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "UpdateTime": {
                "locationName": "updateTime",
                "type": "timestamp"
              },
              "Message": {
                "locationName": "message"
              }
            }
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "ImageId": {
                "locationName": "imageId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "SecurityGroups": {
                "shape": "S5m",
                "locationName": "groupSet"
              },
              "UserData": {
                "locationName": "userData"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "Placement": {
                "shape": "Sh9",
                "locationName": "placement"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "BlockDeviceMappings": {
                "shape": "Sbq",
                "locationName": "blockDeviceMapping"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "NetworkInterfaces": {
                "shape": "Shb",
                "locationName": "networkInterfaceSet"
              },
              "IamInstanceProfile": {
                "shape": "S11",
                "locationName": "iamInstanceProfile"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "Monitoring": {
                "shape": "Shp",
                "locationName": "monitoring"
              }
            }
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "CreateTime": {
            "locationName": "createTime",
            "type": "timestamp"
          },
          "ProductDescription": {
            "locationName": "productDescription"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "ActualBlockHourlyPrice": {
            "locationName": "actualBlockHourlyPrice"
          },
          "Tags": {
            "shape": "Sj",
            "locationName": "tagSet"
          },
          "LaunchedAvailabilityZone": {
            "locationName": "launchedAvailabilityZone"
          }
        }
      }
    },
    "Shp": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "locationName": "enabled",
          "type": "boolean"
        }
      }
    },
    "Si0": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S1z",
              "locationName": "item"
            }
          }
        }
      }
    },
    "Sii": {
      "type": "list",
      "member": {
        "locationName": "VolumeId"
      }
    },
    "Sj2": {
      "type": "list",
      "member": {
        "locationName": "VpcId"
      }
    },
    "Sko": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Skq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "HostReservationId": {
            "locationName": "hostReservationId"
          },
          "HostIdSet": {
            "shape": "Sar",
            "locationName": "hostIdSet"
          },
          "InstanceFamily": {
            "locationName": "instanceFamily"
          },
          "PaymentOption": {
            "locationName": "paymentOption"
          },
          "UpfrontPrice": {
            "locationName": "upfrontPrice"
          },
          "HourlyPrice": {
            "locationName": "hourlyPrice"
          },
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Duration": {
            "locationName": "duration",
            "type": "integer"
          }
        }
      }
    },
    "Sky": {
      "type": "structure",
      "members": {
        "RemainingTotalValue": {
          "locationName": "remainingTotalValue"
        },
        "RemainingUpfrontValue": {
          "locationName": "remainingUpfrontValue"
        },
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        }
      }
    },
    "Sl5": {
      "type": "structure",
      "members": {
        "S3Bucket": {},
        "S3Key": {}
      }
    },
    "Sl6": {
      "type": "structure",
      "members": {
        "UploadStart": {
          "type": "timestamp"
        },
        "UploadEnd": {
          "type": "timestamp"
        },
        "UploadSize": {
          "type": "double"
        },
        "Comment": {}
      }
    },
    "Sla": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroup"
      }
    },
    "Slf": {
      "type": "structure",
      "required": [
        "Format",
        "Bytes",
        "ImportManifestUrl"
      ],
      "members": {
        "Format": {
          "locationName": "format"
        },
        "Bytes": {
          "locationName": "bytes",
          "type": "long"
        },
        "ImportManifestUrl": {
          "locationName": "importManifestUrl"
        }
      }
    },
    "Slg": {
      "type": "structure",
      "required": [
        "Size"
      ],
      "members": {
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "Slr": {
      "type": "list",
      "member": {
        "shape": "S48",
        "locationName": "item"
      }
    },
    "Slw": {
      "type": "list",
      "member": {
        "locationName": "UserId"
      }
    },
    "Smo": {
      "type": "structure",
      "members": {
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "type": "boolean"
        },
        "AllowDnsResolutionFromRemoteVpc": {
          "type": "boolean"
        }
      }
    },
    "Smq": {
      "type": "structure",
      "members": {
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
          "type": "boolean"
        },
        "AllowDnsResolutionFromRemoteVpc": {
          "locationName": "allowDnsResolutionFromRemoteVpc",
          "type": "boolean"
        }
      }
    },
    "Smt": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Monitoring": {
            "shape": "Sdc",
            "locationName": "monitoring"
          }
        }
      }
    },
    "So9": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "Sor": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "CurrentState": {
            "shape": "Scw",
            "locationName": "currentState"
          },
          "PreviousState": {
            "shape": "Scw",
            "locationName": "previousState"
          }
        }
      }
    }
  }
}