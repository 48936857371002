module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2006-03-01",
    "checksumFormat": "md5",
    "endpointPrefix": "s3",
    "globalEndpoint": "s3.amazonaws.com",
    "protocol": "rest-xml",
    "serviceAbbreviation": "Amazon S3",
    "serviceFullName": "Amazon Simple Storage Service",
    "signatureVersion": "s3",
    "timestampFormat": "rfc822",
    "uid": "s3-2006-03-01"
  },
  "operations": {
    "AbortMultipartUpload": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "CompleteMultipartUpload": {
      "http": {
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "MultipartUpload": {
            "locationName": "CompleteMultipartUpload",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "Parts": {
                "locationName": "Part",
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ETag": {},
                    "PartNumber": {
                      "type": "integer"
                    }
                  }
                },
                "flattened": true
              }
            }
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        },
        "payload": "MultipartUpload"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {},
          "Bucket": {},
          "Key": {},
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "ETag": {},
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "CopyObject": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "CopySource",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "CopySource": {
            "location": "header",
            "locationName": "x-amz-copy-source"
          },
          "CopySourceIfMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-match"
          },
          "CopySourceIfModifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-modified-since",
            "type": "timestamp"
          },
          "CopySourceIfNoneMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-none-match"
          },
          "CopySourceIfUnmodifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-unmodified-since",
            "type": "timestamp"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "MetadataDirective": {
            "location": "header",
            "locationName": "x-amz-metadata-directive"
          },
          "TaggingDirective": {
            "location": "header",
            "locationName": "x-amz-tagging-directive"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "CopySourceSSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-algorithm"
          },
          "CopySourceSSECustomerKey": {
            "shape": "S1c",
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key"
          },
          "CopySourceSSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "Tagging": {
            "location": "header",
            "locationName": "x-amz-tagging"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyObjectResult": {
            "type": "structure",
            "members": {
              "ETag": {},
              "LastModified": {
                "type": "timestamp"
              }
            }
          },
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "CopySourceVersionId": {
            "location": "header",
            "locationName": "x-amz-copy-source-version-id"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        },
        "payload": "CopyObjectResult"
      },
      "alias": "PutObjectCopy"
    },
    "CreateBucket": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CreateBucketConfiguration": {
            "locationName": "CreateBucketConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "LocationConstraint": {}
            }
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWrite": {
            "location": "header",
            "locationName": "x-amz-grant-write"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          }
        },
        "payload": "CreateBucketConfiguration"
      },
      "output": {
        "type": "structure",
        "members": {
          "Location": {
            "location": "header",
            "locationName": "Location"
          }
        }
      },
      "alias": "PutBucket"
    },
    "CreateMultipartUpload": {
      "http": {
        "requestUri": "/{Bucket}/{Key+}?uploads"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AbortDate": {
            "location": "header",
            "locationName": "x-amz-abort-date",
            "type": "timestamp"
          },
          "AbortRuleId": {
            "location": "header",
            "locationName": "x-amz-abort-rule-id"
          },
          "Bucket": {
            "locationName": "Bucket"
          },
          "Key": {},
          "UploadId": {},
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      },
      "alias": "InitiateMultipartUpload"
    },
    "DeleteBucket": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketAnalyticsConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?analytics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      }
    },
    "DeleteBucketCors": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?cors"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketInventoryConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?inventory"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      }
    },
    "DeleteBucketLifecycle": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketMetricsConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?metrics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      }
    },
    "DeleteBucketPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketReplication": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?replication"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketTagging": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteBucketWebsite": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}?website"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "DeleteObject": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "MFA": {
            "location": "header",
            "locationName": "x-amz-mfa"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeleteMarker": {
            "location": "header",
            "locationName": "x-amz-delete-marker",
            "type": "boolean"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "DeleteObjectTagging": {
      "http": {
        "method": "DELETE",
        "requestUri": "/{Bucket}/{Key+}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          }
        }
      }
    },
    "DeleteObjects": {
      "http": {
        "requestUri": "/{Bucket}?delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Delete"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delete": {
            "locationName": "Delete",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Objects"
            ],
            "members": {
              "Objects": {
                "locationName": "Object",
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Key"
                  ],
                  "members": {
                    "Key": {},
                    "VersionId": {}
                  }
                },
                "flattened": true
              },
              "Quiet": {
                "type": "boolean"
              }
            }
          },
          "MFA": {
            "location": "header",
            "locationName": "x-amz-mfa"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        },
        "payload": "Delete"
      },
      "output": {
        "type": "structure",
        "members": {
          "Deleted": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "VersionId": {},
                "DeleteMarker": {
                  "type": "boolean"
                },
                "DeleteMarkerVersionId": {}
              }
            },
            "flattened": true
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          },
          "Errors": {
            "locationName": "Error",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "VersionId": {},
                "Code": {},
                "Message": {}
              }
            },
            "flattened": true
          }
        }
      },
      "alias": "DeleteMultipleObjects"
    },
    "GetBucketAccelerateConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?accelerate"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {}
        }
      }
    },
    "GetBucketAcl": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Owner": {
            "shape": "S2u"
          },
          "Grants": {
            "shape": "S2x",
            "locationName": "AccessControlList"
          }
        }
      }
    },
    "GetBucketAnalyticsConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?analytics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalyticsConfiguration": {
            "shape": "S36"
          }
        },
        "payload": "AnalyticsConfiguration"
      }
    },
    "GetBucketCors": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?cors"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CORSRules": {
            "shape": "S3m",
            "locationName": "CORSRule"
          }
        }
      }
    },
    "GetBucketInventoryConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?inventory"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InventoryConfiguration": {
            "shape": "S3z"
          }
        },
        "payload": "InventoryConfiguration"
      }
    },
    "GetBucketLifecycle": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S4c",
            "locationName": "Rule"
          }
        }
      },
      "deprecated": true
    },
    "GetBucketLifecycleConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "shape": "S4r",
            "locationName": "Rule"
          }
        }
      }
    },
    "GetBucketLocation": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?location"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LocationConstraint": {}
        }
      }
    },
    "GetBucketLogging": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?logging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LoggingEnabled": {
            "shape": "S51"
          }
        }
      }
    },
    "GetBucketMetricsConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?metrics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MetricsConfiguration": {
            "shape": "S59"
          }
        },
        "payload": "MetricsConfiguration"
      }
    },
    "GetBucketNotification": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "shape": "S5c"
      },
      "output": {
        "shape": "S5d"
      },
      "deprecated": true
    },
    "GetBucketNotificationConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "shape": "S5c"
      },
      "output": {
        "shape": "S5o"
      }
    },
    "GetBucketPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {}
        },
        "payload": "Policy"
      }
    },
    "GetBucketReplication": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?replication"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationConfiguration": {
            "shape": "S67"
          }
        },
        "payload": "ReplicationConfiguration"
      }
    },
    "GetBucketRequestPayment": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?requestPayment"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Payer": {}
        }
      }
    },
    "GetBucketTagging": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagSet"
        ],
        "members": {
          "TagSet": {
            "shape": "S3c"
          }
        }
      }
    },
    "GetBucketVersioning": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?versioning"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "MFADelete": {
            "locationName": "MfaDelete"
          }
        }
      }
    },
    "GetBucketWebsite": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?website"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RedirectAllRequestsTo": {
            "shape": "S6o"
          },
          "IndexDocument": {
            "shape": "S6r"
          },
          "ErrorDocument": {
            "shape": "S6t"
          },
          "RoutingRules": {
            "shape": "S6u"
          }
        }
      }
    },
    "GetObject": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          },
          "IfModifiedSince": {
            "location": "header",
            "locationName": "If-Modified-Since",
            "type": "timestamp"
          },
          "IfNoneMatch": {
            "location": "header",
            "locationName": "If-None-Match"
          },
          "IfUnmodifiedSince": {
            "location": "header",
            "locationName": "If-Unmodified-Since",
            "type": "timestamp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Range": {
            "location": "header",
            "locationName": "Range"
          },
          "ResponseCacheControl": {
            "location": "querystring",
            "locationName": "response-cache-control"
          },
          "ResponseContentDisposition": {
            "location": "querystring",
            "locationName": "response-content-disposition"
          },
          "ResponseContentEncoding": {
            "location": "querystring",
            "locationName": "response-content-encoding"
          },
          "ResponseContentLanguage": {
            "location": "querystring",
            "locationName": "response-content-language"
          },
          "ResponseContentType": {
            "location": "querystring",
            "locationName": "response-content-type"
          },
          "ResponseExpires": {
            "location": "querystring",
            "locationName": "response-expires",
            "type": "timestamp"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "DeleteMarker": {
            "location": "header",
            "locationName": "x-amz-delete-marker",
            "type": "boolean"
          },
          "AcceptRanges": {
            "location": "header",
            "locationName": "accept-ranges"
          },
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "Restore": {
            "location": "header",
            "locationName": "x-amz-restore"
          },
          "LastModified": {
            "location": "header",
            "locationName": "Last-Modified",
            "type": "timestamp"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "MissingMeta": {
            "location": "header",
            "locationName": "x-amz-missing-meta",
            "type": "integer"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentRange": {
            "location": "header",
            "locationName": "Content-Range"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          },
          "ReplicationStatus": {
            "location": "header",
            "locationName": "x-amz-replication-status"
          },
          "PartsCount": {
            "location": "header",
            "locationName": "x-amz-mp-parts-count",
            "type": "integer"
          },
          "TagCount": {
            "location": "header",
            "locationName": "x-amz-tagging-count",
            "type": "integer"
          }
        },
        "payload": "Body"
      }
    },
    "GetObjectAcl": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Owner": {
            "shape": "S2u"
          },
          "Grants": {
            "shape": "S2x",
            "locationName": "AccessControlList"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "GetObjectTagging": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagSet"
        ],
        "members": {
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "TagSet": {
            "shape": "S3c"
          }
        }
      }
    },
    "GetObjectTorrent": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}?torrent"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        },
        "payload": "Body"
      }
    },
    "HeadBucket": {
      "http": {
        "method": "HEAD",
        "requestUri": "/{Bucket}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          }
        }
      }
    },
    "HeadObject": {
      "http": {
        "method": "HEAD",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "IfMatch": {
            "location": "header",
            "locationName": "If-Match"
          },
          "IfModifiedSince": {
            "location": "header",
            "locationName": "If-Modified-Since",
            "type": "timestamp"
          },
          "IfNoneMatch": {
            "location": "header",
            "locationName": "If-None-Match"
          },
          "IfUnmodifiedSince": {
            "location": "header",
            "locationName": "If-Unmodified-Since",
            "type": "timestamp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Range": {
            "location": "header",
            "locationName": "Range"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeleteMarker": {
            "location": "header",
            "locationName": "x-amz-delete-marker",
            "type": "boolean"
          },
          "AcceptRanges": {
            "location": "header",
            "locationName": "accept-ranges"
          },
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "Restore": {
            "location": "header",
            "locationName": "x-amz-restore"
          },
          "LastModified": {
            "location": "header",
            "locationName": "Last-Modified",
            "type": "timestamp"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "MissingMeta": {
            "location": "header",
            "locationName": "x-amz-missing-meta",
            "type": "integer"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          },
          "ReplicationStatus": {
            "location": "header",
            "locationName": "x-amz-replication-status"
          },
          "PartsCount": {
            "location": "header",
            "locationName": "x-amz-mp-parts-count",
            "type": "integer"
          }
        }
      }
    },
    "ListBucketAnalyticsConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?analytics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "ContinuationToken": {},
          "NextContinuationToken": {},
          "AnalyticsConfigurationList": {
            "locationName": "AnalyticsConfiguration",
            "type": "list",
            "member": {
              "shape": "S36"
            },
            "flattened": true
          }
        }
      }
    },
    "ListBucketInventoryConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?inventory"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContinuationToken": {},
          "InventoryConfigurationList": {
            "locationName": "InventoryConfiguration",
            "type": "list",
            "member": {
              "shape": "S3z"
            },
            "flattened": true
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "NextContinuationToken": {}
        }
      }
    },
    "ListBucketMetricsConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?metrics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "ContinuationToken": {},
          "NextContinuationToken": {},
          "MetricsConfigurationList": {
            "locationName": "MetricsConfiguration",
            "type": "list",
            "member": {
              "shape": "S59"
            },
            "flattened": true
          }
        }
      }
    },
    "ListBuckets": {
      "http": {
        "method": "GET"
      },
      "output": {
        "type": "structure",
        "members": {
          "Buckets": {
            "type": "list",
            "member": {
              "locationName": "Bucket",
              "type": "structure",
              "members": {
                "Name": {},
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "Owner": {
            "shape": "S2u"
          }
        }
      },
      "alias": "GetService"
    },
    "ListMultipartUploads": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?uploads"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "KeyMarker": {
            "location": "querystring",
            "locationName": "key-marker"
          },
          "MaxUploads": {
            "location": "querystring",
            "locationName": "max-uploads",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "UploadIdMarker": {
            "location": "querystring",
            "locationName": "upload-id-marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bucket": {},
          "KeyMarker": {},
          "UploadIdMarker": {},
          "NextKeyMarker": {},
          "Prefix": {},
          "Delimiter": {},
          "NextUploadIdMarker": {},
          "MaxUploads": {
            "type": "integer"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Uploads": {
            "locationName": "Upload",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UploadId": {},
                "Key": {},
                "Initiated": {
                  "type": "timestamp"
                },
                "StorageClass": {},
                "Owner": {
                  "shape": "S2u"
                },
                "Initiator": {
                  "shape": "S8q"
                }
              }
            },
            "flattened": true
          },
          "CommonPrefixes": {
            "shape": "S8r"
          },
          "EncodingType": {}
        }
      }
    },
    "ListObjectVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "KeyMarker": {
            "location": "querystring",
            "locationName": "key-marker"
          },
          "MaxKeys": {
            "location": "querystring",
            "locationName": "max-keys",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "VersionIdMarker": {
            "location": "querystring",
            "locationName": "version-id-marker"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "KeyMarker": {},
          "VersionIdMarker": {},
          "NextKeyMarker": {},
          "NextVersionIdMarker": {},
          "Versions": {
            "locationName": "Version",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ETag": {},
                "Size": {
                  "type": "integer"
                },
                "StorageClass": {},
                "Key": {},
                "VersionId": {},
                "IsLatest": {
                  "type": "boolean"
                },
                "LastModified": {
                  "type": "timestamp"
                },
                "Owner": {
                  "shape": "S2u"
                }
              }
            },
            "flattened": true
          },
          "DeleteMarkers": {
            "locationName": "DeleteMarker",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Owner": {
                  "shape": "S2u"
                },
                "Key": {},
                "VersionId": {},
                "IsLatest": {
                  "type": "boolean"
                },
                "LastModified": {
                  "type": "timestamp"
                }
              }
            },
            "flattened": true
          },
          "Name": {},
          "Prefix": {},
          "Delimiter": {},
          "MaxKeys": {
            "type": "integer"
          },
          "CommonPrefixes": {
            "shape": "S8r"
          },
          "EncodingType": {}
        }
      },
      "alias": "GetBucketObjectVersions"
    },
    "ListObjects": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "MaxKeys": {
            "location": "querystring",
            "locationName": "max-keys",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "Marker": {},
          "NextMarker": {},
          "Contents": {
            "shape": "S99"
          },
          "Name": {},
          "Prefix": {},
          "Delimiter": {},
          "MaxKeys": {
            "type": "integer"
          },
          "CommonPrefixes": {
            "shape": "S8r"
          },
          "EncodingType": {}
        }
      },
      "alias": "GetBucket"
    },
    "ListObjectsV2": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}?list-type=2"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Delimiter": {
            "location": "querystring",
            "locationName": "delimiter"
          },
          "EncodingType": {
            "location": "querystring",
            "locationName": "encoding-type"
          },
          "MaxKeys": {
            "location": "querystring",
            "locationName": "max-keys",
            "type": "integer"
          },
          "Prefix": {
            "location": "querystring",
            "locationName": "prefix"
          },
          "ContinuationToken": {
            "location": "querystring",
            "locationName": "continuation-token"
          },
          "FetchOwner": {
            "location": "querystring",
            "locationName": "fetch-owner",
            "type": "boolean"
          },
          "StartAfter": {
            "location": "querystring",
            "locationName": "start-after"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsTruncated": {
            "type": "boolean"
          },
          "Contents": {
            "shape": "S99"
          },
          "Name": {},
          "Prefix": {},
          "Delimiter": {},
          "MaxKeys": {
            "type": "integer"
          },
          "CommonPrefixes": {
            "shape": "S8r"
          },
          "EncodingType": {},
          "KeyCount": {
            "type": "integer"
          },
          "ContinuationToken": {},
          "NextContinuationToken": {},
          "StartAfter": {}
        }
      }
    },
    "ListParts": {
      "http": {
        "method": "GET",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "MaxParts": {
            "location": "querystring",
            "locationName": "max-parts",
            "type": "integer"
          },
          "PartNumberMarker": {
            "location": "querystring",
            "locationName": "part-number-marker",
            "type": "integer"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AbortDate": {
            "location": "header",
            "locationName": "x-amz-abort-date",
            "type": "timestamp"
          },
          "AbortRuleId": {
            "location": "header",
            "locationName": "x-amz-abort-rule-id"
          },
          "Bucket": {},
          "Key": {},
          "UploadId": {},
          "PartNumberMarker": {
            "type": "integer"
          },
          "NextPartNumberMarker": {
            "type": "integer"
          },
          "MaxParts": {
            "type": "integer"
          },
          "IsTruncated": {
            "type": "boolean"
          },
          "Parts": {
            "locationName": "Part",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PartNumber": {
                  "type": "integer"
                },
                "LastModified": {
                  "type": "timestamp"
                },
                "ETag": {},
                "Size": {
                  "type": "integer"
                }
              }
            },
            "flattened": true
          },
          "Initiator": {
            "shape": "S8q"
          },
          "Owner": {
            "shape": "S2u"
          },
          "StorageClass": {},
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "PutBucketAccelerateConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?accelerate"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "AccelerateConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "AccelerateConfiguration": {
            "locationName": "AccelerateConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "Status": {}
            }
          }
        },
        "payload": "AccelerateConfiguration"
      }
    },
    "PutBucketAcl": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "AccessControlPolicy": {
            "shape": "S9r",
            "locationName": "AccessControlPolicy",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWrite": {
            "location": "header",
            "locationName": "x-amz-grant-write"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          }
        },
        "payload": "AccessControlPolicy"
      }
    },
    "PutBucketAnalyticsConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?analytics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id",
          "AnalyticsConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          },
          "AnalyticsConfiguration": {
            "shape": "S36",
            "locationName": "AnalyticsConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "AnalyticsConfiguration"
      }
    },
    "PutBucketCors": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?cors"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "CORSConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CORSConfiguration": {
            "locationName": "CORSConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "CORSRules"
            ],
            "members": {
              "CORSRules": {
                "shape": "S3m",
                "locationName": "CORSRule"
              }
            }
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          }
        },
        "payload": "CORSConfiguration"
      }
    },
    "PutBucketInventoryConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?inventory"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id",
          "InventoryConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          },
          "InventoryConfiguration": {
            "shape": "S3z",
            "locationName": "InventoryConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "InventoryConfiguration"
      }
    },
    "PutBucketLifecycle": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "LifecycleConfiguration": {
            "locationName": "LifecycleConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Rules"
            ],
            "members": {
              "Rules": {
                "shape": "S4c",
                "locationName": "Rule"
              }
            }
          }
        },
        "payload": "LifecycleConfiguration"
      },
      "deprecated": true
    },
    "PutBucketLifecycleConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?lifecycle"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "LifecycleConfiguration": {
            "locationName": "LifecycleConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Rules"
            ],
            "members": {
              "Rules": {
                "shape": "S4r",
                "locationName": "Rule"
              }
            }
          }
        },
        "payload": "LifecycleConfiguration"
      }
    },
    "PutBucketLogging": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?logging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "BucketLoggingStatus"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "BucketLoggingStatus": {
            "locationName": "BucketLoggingStatus",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "LoggingEnabled": {
                "shape": "S51"
              }
            }
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          }
        },
        "payload": "BucketLoggingStatus"
      }
    },
    "PutBucketMetricsConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?metrics"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Id",
          "MetricsConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Id": {
            "location": "querystring",
            "locationName": "id"
          },
          "MetricsConfiguration": {
            "shape": "S59",
            "locationName": "MetricsConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "MetricsConfiguration"
      }
    },
    "PutBucketNotification": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "NotificationConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "NotificationConfiguration": {
            "shape": "S5d",
            "locationName": "NotificationConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "NotificationConfiguration"
      },
      "deprecated": true
    },
    "PutBucketNotificationConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?notification"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "NotificationConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "NotificationConfiguration": {
            "shape": "S5o",
            "locationName": "NotificationConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "NotificationConfiguration"
      }
    },
    "PutBucketPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?policy"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Policy"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "Policy": {}
        },
        "payload": "Policy"
      }
    },
    "PutBucketReplication": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?replication"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "ReplicationConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "ReplicationConfiguration": {
            "shape": "S67",
            "locationName": "ReplicationConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "ReplicationConfiguration"
      }
    },
    "PutBucketRequestPayment": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?requestPayment"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "RequestPaymentConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "RequestPaymentConfiguration": {
            "locationName": "RequestPaymentConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Payer"
            ],
            "members": {
              "Payer": {}
            }
          }
        },
        "payload": "RequestPaymentConfiguration"
      }
    },
    "PutBucketTagging": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Tagging"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "Tagging": {
            "shape": "Sab",
            "locationName": "Tagging",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "Tagging"
      }
    },
    "PutBucketVersioning": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?versioning"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "VersioningConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "MFA": {
            "location": "header",
            "locationName": "x-amz-mfa"
          },
          "VersioningConfiguration": {
            "locationName": "VersioningConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "MFADelete": {
                "locationName": "MfaDelete"
              },
              "Status": {}
            }
          }
        },
        "payload": "VersioningConfiguration"
      }
    },
    "PutBucketWebsite": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}?website"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "WebsiteConfiguration"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "WebsiteConfiguration": {
            "locationName": "WebsiteConfiguration",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "members": {
              "ErrorDocument": {
                "shape": "S6t"
              },
              "IndexDocument": {
                "shape": "S6r"
              },
              "RedirectAllRequestsTo": {
                "shape": "S6o"
              },
              "RoutingRules": {
                "shape": "S6u"
              }
            }
          }
        },
        "payload": "WebsiteConfiguration"
      }
    },
    "PutObject": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CacheControl": {
            "location": "header",
            "locationName": "Cache-Control"
          },
          "ContentDisposition": {
            "location": "header",
            "locationName": "Content-Disposition"
          },
          "ContentEncoding": {
            "location": "header",
            "locationName": "Content-Encoding"
          },
          "ContentLanguage": {
            "location": "header",
            "locationName": "Content-Language"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Expires": {
            "location": "header",
            "locationName": "Expires",
            "type": "timestamp"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "Metadata": {
            "shape": "S11",
            "location": "headers",
            "locationName": "x-amz-meta-"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "StorageClass": {
            "location": "header",
            "locationName": "x-amz-storage-class"
          },
          "WebsiteRedirectLocation": {
            "location": "header",
            "locationName": "x-amz-website-redirect-location"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "Tagging": {
            "location": "header",
            "locationName": "x-amz-tagging"
          }
        },
        "payload": "Body"
      },
      "output": {
        "type": "structure",
        "members": {
          "Expiration": {
            "location": "header",
            "locationName": "x-amz-expiration"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "PutObjectAcl": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}?acl"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "ACL": {
            "location": "header",
            "locationName": "x-amz-acl"
          },
          "AccessControlPolicy": {
            "shape": "S9r",
            "locationName": "AccessControlPolicy",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "GrantFullControl": {
            "location": "header",
            "locationName": "x-amz-grant-full-control"
          },
          "GrantRead": {
            "location": "header",
            "locationName": "x-amz-grant-read"
          },
          "GrantReadACP": {
            "location": "header",
            "locationName": "x-amz-grant-read-acp"
          },
          "GrantWrite": {
            "location": "header",
            "locationName": "x-amz-grant-write"
          },
          "GrantWriteACP": {
            "location": "header",
            "locationName": "x-amz-grant-write-acp"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          }
        },
        "payload": "AccessControlPolicy"
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "PutObjectTagging": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}?tagging"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "Tagging"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "Tagging": {
            "shape": "Sab",
            "locationName": "Tagging",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            }
          }
        },
        "payload": "Tagging"
      },
      "output": {
        "type": "structure",
        "members": {
          "VersionId": {
            "location": "header",
            "locationName": "x-amz-version-id"
          }
        }
      }
    },
    "RestoreObject": {
      "http": {
        "requestUri": "/{Bucket}/{Key+}?restore"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "VersionId": {
            "location": "querystring",
            "locationName": "versionId"
          },
          "RestoreRequest": {
            "locationName": "RestoreRequest",
            "xmlNamespace": {
              "uri": "http://s3.amazonaws.com/doc/2006-03-01/"
            },
            "type": "structure",
            "required": [
              "Days"
            ],
            "members": {
              "Days": {
                "type": "integer"
              },
              "GlacierJobParameters": {
                "type": "structure",
                "required": [
                  "Tier"
                ],
                "members": {
                  "Tier": {}
                }
              }
            }
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        },
        "payload": "RestoreRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      },
      "alias": "PostObjectRestore"
    },
    "UploadPart": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "Key",
          "PartNumber",
          "UploadId"
        ],
        "members": {
          "Body": {
            "streaming": true,
            "type": "blob"
          },
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "ContentLength": {
            "location": "header",
            "locationName": "Content-Length",
            "type": "long"
          },
          "ContentMD5": {
            "location": "header",
            "locationName": "Content-MD5"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        },
        "payload": "Body"
      },
      "output": {
        "type": "structure",
        "members": {
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "ETag": {
            "location": "header",
            "locationName": "ETag"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        }
      }
    },
    "UploadPartCopy": {
      "http": {
        "method": "PUT",
        "requestUri": "/{Bucket}/{Key+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Bucket",
          "CopySource",
          "Key",
          "PartNumber",
          "UploadId"
        ],
        "members": {
          "Bucket": {
            "location": "uri",
            "locationName": "Bucket"
          },
          "CopySource": {
            "location": "header",
            "locationName": "x-amz-copy-source"
          },
          "CopySourceIfMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-match"
          },
          "CopySourceIfModifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-modified-since",
            "type": "timestamp"
          },
          "CopySourceIfNoneMatch": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-none-match"
          },
          "CopySourceIfUnmodifiedSince": {
            "location": "header",
            "locationName": "x-amz-copy-source-if-unmodified-since",
            "type": "timestamp"
          },
          "CopySourceRange": {
            "location": "header",
            "locationName": "x-amz-copy-source-range"
          },
          "Key": {
            "location": "uri",
            "locationName": "Key"
          },
          "PartNumber": {
            "location": "querystring",
            "locationName": "partNumber",
            "type": "integer"
          },
          "UploadId": {
            "location": "querystring",
            "locationName": "uploadId"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKey": {
            "shape": "S19",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "CopySourceSSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-algorithm"
          },
          "CopySourceSSECustomerKey": {
            "shape": "S1c",
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key"
          },
          "CopySourceSSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-copy-source-server-side-encryption-customer-key-MD5"
          },
          "RequestPayer": {
            "location": "header",
            "locationName": "x-amz-request-payer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopySourceVersionId": {
            "location": "header",
            "locationName": "x-amz-copy-source-version-id"
          },
          "CopyPartResult": {
            "type": "structure",
            "members": {
              "ETag": {},
              "LastModified": {
                "type": "timestamp"
              }
            }
          },
          "ServerSideEncryption": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption"
          },
          "SSECustomerAlgorithm": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-algorithm"
          },
          "SSECustomerKeyMD5": {
            "location": "header",
            "locationName": "x-amz-server-side-encryption-customer-key-MD5"
          },
          "SSEKMSKeyId": {
            "shape": "Sj",
            "location": "header",
            "locationName": "x-amz-server-side-encryption-aws-kms-key-id"
          },
          "RequestCharged": {
            "location": "header",
            "locationName": "x-amz-request-charged"
          }
        },
        "payload": "CopyPartResult"
      }
    }
  },
  "shapes": {
    "Sj": {
      "type": "string",
      "sensitive": true
    },
    "S11": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S19": {
      "type": "blob",
      "sensitive": true
    },
    "S1c": {
      "type": "blob",
      "sensitive": true
    },
    "S2u": {
      "type": "structure",
      "members": {
        "DisplayName": {},
        "ID": {}
      }
    },
    "S2x": {
      "type": "list",
      "member": {
        "locationName": "Grant",
        "type": "structure",
        "members": {
          "Grantee": {
            "shape": "S2z"
          },
          "Permission": {}
        }
      }
    },
    "S2z": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "DisplayName": {},
        "EmailAddress": {},
        "ID": {},
        "Type": {
          "locationName": "xsi:type",
          "xmlAttribute": true
        },
        "URI": {}
      },
      "xmlNamespace": {
        "prefix": "xsi",
        "uri": "http://www.w3.org/2001/XMLSchema-instance"
      }
    },
    "S36": {
      "type": "structure",
      "required": [
        "Id",
        "StorageClassAnalysis"
      ],
      "members": {
        "Id": {},
        "Filter": {
          "type": "structure",
          "members": {
            "Prefix": {},
            "Tag": {
              "shape": "S39"
            },
            "And": {
              "type": "structure",
              "members": {
                "Prefix": {},
                "Tags": {
                  "shape": "S3c",
                  "flattened": true,
                  "locationName": "Tag"
                }
              }
            }
          }
        },
        "StorageClassAnalysis": {
          "type": "structure",
          "members": {
            "DataExport": {
              "type": "structure",
              "required": [
                "OutputSchemaVersion",
                "Destination"
              ],
              "members": {
                "OutputSchemaVersion": {},
                "Destination": {
                  "type": "structure",
                  "required": [
                    "S3BucketDestination"
                  ],
                  "members": {
                    "S3BucketDestination": {
                      "type": "structure",
                      "required": [
                        "Format",
                        "Bucket"
                      ],
                      "members": {
                        "Format": {},
                        "BucketAccountId": {},
                        "Bucket": {},
                        "Prefix": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S39": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S3c": {
      "type": "list",
      "member": {
        "shape": "S39",
        "locationName": "Tag"
      }
    },
    "S3m": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AllowedMethods",
          "AllowedOrigins"
        ],
        "members": {
          "AllowedHeaders": {
            "locationName": "AllowedHeader",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "AllowedMethods": {
            "locationName": "AllowedMethod",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "AllowedOrigins": {
            "locationName": "AllowedOrigin",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "ExposeHeaders": {
            "locationName": "ExposeHeader",
            "type": "list",
            "member": {},
            "flattened": true
          },
          "MaxAgeSeconds": {
            "type": "integer"
          }
        }
      },
      "flattened": true
    },
    "S3z": {
      "type": "structure",
      "required": [
        "Destination",
        "IsEnabled",
        "Id",
        "IncludedObjectVersions",
        "Schedule"
      ],
      "members": {
        "Destination": {
          "type": "structure",
          "required": [
            "S3BucketDestination"
          ],
          "members": {
            "S3BucketDestination": {
              "type": "structure",
              "required": [
                "Bucket",
                "Format"
              ],
              "members": {
                "AccountId": {},
                "Bucket": {},
                "Format": {},
                "Prefix": {}
              }
            }
          }
        },
        "IsEnabled": {
          "type": "boolean"
        },
        "Filter": {
          "type": "structure",
          "required": [
            "Prefix"
          ],
          "members": {
            "Prefix": {}
          }
        },
        "Id": {},
        "IncludedObjectVersions": {},
        "OptionalFields": {
          "type": "list",
          "member": {
            "locationName": "Field"
          }
        },
        "Schedule": {
          "type": "structure",
          "required": [
            "Frequency"
          ],
          "members": {
            "Frequency": {}
          }
        }
      }
    },
    "S4c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Prefix",
          "Status"
        ],
        "members": {
          "Expiration": {
            "shape": "S4e"
          },
          "ID": {},
          "Prefix": {},
          "Status": {},
          "Transition": {
            "shape": "S4j"
          },
          "NoncurrentVersionTransition": {
            "shape": "S4l"
          },
          "NoncurrentVersionExpiration": {
            "shape": "S4m"
          },
          "AbortIncompleteMultipartUpload": {
            "shape": "S4n"
          }
        }
      },
      "flattened": true
    },
    "S4e": {
      "type": "structure",
      "members": {
        "Date": {
          "shape": "S4f"
        },
        "Days": {
          "type": "integer"
        },
        "ExpiredObjectDeleteMarker": {
          "type": "boolean"
        }
      }
    },
    "S4f": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S4j": {
      "type": "structure",
      "members": {
        "Date": {
          "shape": "S4f"
        },
        "Days": {
          "type": "integer"
        },
        "StorageClass": {}
      }
    },
    "S4l": {
      "type": "structure",
      "members": {
        "NoncurrentDays": {
          "type": "integer"
        },
        "StorageClass": {}
      }
    },
    "S4m": {
      "type": "structure",
      "members": {
        "NoncurrentDays": {
          "type": "integer"
        }
      }
    },
    "S4n": {
      "type": "structure",
      "members": {
        "DaysAfterInitiation": {
          "type": "integer"
        }
      }
    },
    "S4r": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Expiration": {
            "shape": "S4e"
          },
          "ID": {},
          "Prefix": {
            "deprecated": true
          },
          "Filter": {
            "type": "structure",
            "members": {
              "Prefix": {},
              "Tag": {
                "shape": "S39"
              },
              "And": {
                "type": "structure",
                "members": {
                  "Prefix": {},
                  "Tags": {
                    "shape": "S3c",
                    "flattened": true,
                    "locationName": "Tag"
                  }
                }
              }
            }
          },
          "Status": {},
          "Transitions": {
            "locationName": "Transition",
            "type": "list",
            "member": {
              "shape": "S4j"
            },
            "flattened": true
          },
          "NoncurrentVersionTransitions": {
            "locationName": "NoncurrentVersionTransition",
            "type": "list",
            "member": {
              "shape": "S4l"
            },
            "flattened": true
          },
          "NoncurrentVersionExpiration": {
            "shape": "S4m"
          },
          "AbortIncompleteMultipartUpload": {
            "shape": "S4n"
          }
        }
      },
      "flattened": true
    },
    "S51": {
      "type": "structure",
      "members": {
        "TargetBucket": {},
        "TargetGrants": {
          "type": "list",
          "member": {
            "locationName": "Grant",
            "type": "structure",
            "members": {
              "Grantee": {
                "shape": "S2z"
              },
              "Permission": {}
            }
          }
        },
        "TargetPrefix": {}
      }
    },
    "S59": {
      "type": "structure",
      "required": [
        "Id"
      ],
      "members": {
        "Id": {},
        "Filter": {
          "type": "structure",
          "members": {
            "Prefix": {},
            "Tag": {
              "shape": "S39"
            },
            "And": {
              "type": "structure",
              "members": {
                "Prefix": {},
                "Tags": {
                  "shape": "S3c",
                  "flattened": true,
                  "locationName": "Tag"
                }
              }
            }
          }
        }
      }
    },
    "S5c": {
      "type": "structure",
      "required": [
        "Bucket"
      ],
      "members": {
        "Bucket": {
          "location": "uri",
          "locationName": "Bucket"
        }
      }
    },
    "S5d": {
      "type": "structure",
      "members": {
        "TopicConfiguration": {
          "type": "structure",
          "members": {
            "Id": {},
            "Events": {
              "shape": "S5g",
              "locationName": "Event"
            },
            "Event": {
              "deprecated": true
            },
            "Topic": {}
          }
        },
        "QueueConfiguration": {
          "type": "structure",
          "members": {
            "Id": {},
            "Event": {
              "deprecated": true
            },
            "Events": {
              "shape": "S5g",
              "locationName": "Event"
            },
            "Queue": {}
          }
        },
        "CloudFunctionConfiguration": {
          "type": "structure",
          "members": {
            "Id": {},
            "Event": {
              "deprecated": true
            },
            "Events": {
              "shape": "S5g",
              "locationName": "Event"
            },
            "CloudFunction": {},
            "InvocationRole": {}
          }
        }
      }
    },
    "S5g": {
      "type": "list",
      "member": {},
      "flattened": true
    },
    "S5o": {
      "type": "structure",
      "members": {
        "TopicConfigurations": {
          "locationName": "TopicConfiguration",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "TopicArn",
              "Events"
            ],
            "members": {
              "Id": {},
              "TopicArn": {
                "locationName": "Topic"
              },
              "Events": {
                "shape": "S5g",
                "locationName": "Event"
              },
              "Filter": {
                "shape": "S5r"
              }
            }
          },
          "flattened": true
        },
        "QueueConfigurations": {
          "locationName": "QueueConfiguration",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "QueueArn",
              "Events"
            ],
            "members": {
              "Id": {},
              "QueueArn": {
                "locationName": "Queue"
              },
              "Events": {
                "shape": "S5g",
                "locationName": "Event"
              },
              "Filter": {
                "shape": "S5r"
              }
            }
          },
          "flattened": true
        },
        "LambdaFunctionConfigurations": {
          "locationName": "CloudFunctionConfiguration",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "LambdaFunctionArn",
              "Events"
            ],
            "members": {
              "Id": {},
              "LambdaFunctionArn": {
                "locationName": "CloudFunction"
              },
              "Events": {
                "shape": "S5g",
                "locationName": "Event"
              },
              "Filter": {
                "shape": "S5r"
              }
            }
          },
          "flattened": true
        }
      }
    },
    "S5r": {
      "type": "structure",
      "members": {
        "Key": {
          "locationName": "S3Key",
          "type": "structure",
          "members": {
            "FilterRules": {
              "locationName": "FilterRule",
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Name": {},
                  "Value": {}
                }
              },
              "flattened": true
            }
          }
        }
      }
    },
    "S67": {
      "type": "structure",
      "required": [
        "Role",
        "Rules"
      ],
      "members": {
        "Role": {},
        "Rules": {
          "locationName": "Rule",
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Prefix",
              "Status",
              "Destination"
            ],
            "members": {
              "ID": {},
              "Prefix": {},
              "Status": {},
              "Destination": {
                "type": "structure",
                "required": [
                  "Bucket"
                ],
                "members": {
                  "Bucket": {},
                  "StorageClass": {}
                }
              }
            }
          },
          "flattened": true
        }
      }
    },
    "S6o": {
      "type": "structure",
      "required": [
        "HostName"
      ],
      "members": {
        "HostName": {},
        "Protocol": {}
      }
    },
    "S6r": {
      "type": "structure",
      "required": [
        "Suffix"
      ],
      "members": {
        "Suffix": {}
      }
    },
    "S6t": {
      "type": "structure",
      "required": [
        "Key"
      ],
      "members": {
        "Key": {}
      }
    },
    "S6u": {
      "type": "list",
      "member": {
        "locationName": "RoutingRule",
        "type": "structure",
        "required": [
          "Redirect"
        ],
        "members": {
          "Condition": {
            "type": "structure",
            "members": {
              "HttpErrorCodeReturnedEquals": {},
              "KeyPrefixEquals": {}
            }
          },
          "Redirect": {
            "type": "structure",
            "members": {
              "HostName": {},
              "HttpRedirectCode": {},
              "Protocol": {},
              "ReplaceKeyPrefixWith": {},
              "ReplaceKeyWith": {}
            }
          }
        }
      }
    },
    "S8q": {
      "type": "structure",
      "members": {
        "ID": {},
        "DisplayName": {}
      }
    },
    "S8r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Prefix": {}
        }
      },
      "flattened": true
    },
    "S99": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "LastModified": {
            "type": "timestamp"
          },
          "ETag": {},
          "Size": {
            "type": "integer"
          },
          "StorageClass": {},
          "Owner": {
            "shape": "S2u"
          }
        }
      },
      "flattened": true
    },
    "S9r": {
      "type": "structure",
      "members": {
        "Grants": {
          "shape": "S2x",
          "locationName": "AccessControlList"
        },
        "Owner": {
          "shape": "S2u"
        }
      }
    },
    "Sab": {
      "type": "structure",
      "required": [
        "TagSet"
      ],
      "members": {
        "TagSet": {
          "shape": "S3c"
        }
      }
    }
  }
}