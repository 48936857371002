
import {SpotDB,ListResult,AttributeSerializer,FilterExpression} from './db';
import {SpotBaseObject} from './base';

export type VehicleType = "NONE" | "CAR" | "MOTORBIKE" | "BUS";
export type VehicleClassification = "NONE" | "ELECTRIC" | "SMALL";
export type VehicleRegisteredUse = "NONE" | "DOMESTIC" | "COMMERCIAL" | "TAXI";

/** UserProfile defines a user-specific context.  Disabled / area permits, type of vehicle,
 * type of vehicle registration (eg. Domestic/Commercial) are all important attributes that
 * needs to be understood to determine the context of whether a parking zone is valid.
 *
 * The UserProfile is an important component necessary for establishing context of the suitability
 *  of a parking zone.
 *
 * An user can have multiple vehicles and multiple permits.
 *
 * User profile information is optional.  If not defined the base case scenario is inferred, eg ‘DOMESTIC USE CAR’
 *
 * NOTE: there is likely to be many potential fields added to the user profile as new jurisdictions are added.
 * As this structure is a dictionary structure, additional information can be easily added with new ‘keys’.
 */
export class UserProfile extends SpotBaseObject {
    static TableName = 'userprofiles';

    constructor(db:SpotDB) {
        super(db, UserProfile.TableName);
    }

    /** type of vehicle. e.g. CAR, MOTORBIKE, BUS */
    vehicleType?: VehicleType;
    /** further description of car. e.g. ELECTRIC, SMALL */
    vehicleClassification?: VehicleClassification;
    /** vehicle registration number */
    vehicleRegistration?: string;
    /** use type. e.g. DOMESTIC, COMMERCIAL, TAXI */
    vehicleRegisteredUse?: VehicleRegisteredUse;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromString(this.id, "id"),
            ...s.fromString(this.vehicleType, "vehicleType"),
            ...s.fromString(this.vehicleClassification, "vehicleClassification"),
            ...s.fromString(this.vehicleRegistration, "vehicleRegistration"),
            ...s.fromString(this.vehicleRegisteredUse, "vehicleRegisteredUse"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.id, (v:string) => this.id = v);
        s.setString(a.vehicleType, (v:VehicleType) => this.vehicleType = v);
        s.setString(a.vehicleClassification, (v:VehicleClassification) => this.vehicleClassification = v);
        s.setString(a.vehicleRegistration, (v:string) => this.vehicleRegistration = v);
        s.setString(a.vehicleRegisteredUse, (v:VehicleRegisteredUse) => this.vehicleRegisteredUse = v);
    }

    static fromAttrs(db:SpotDB, a:any) : UserProfile {
        let z = new UserProfile(db);
        z.setAttrs(db.serializer, a);
        return z;
    }

    /** Load a single UserProfile instance from the database */
    static async get(db:SpotDB, id:string) : Promise<UserProfile> {
        let data = await db.get(this.TableName, id);
        if (!data) {
            return null;
        }
        let j = new this(db);
        j.setAttrs(db.serializer, data);
        return j;
    }

    /** Returns the ids of UserProfiles that exist in the database. */
    static listIds(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<string>> {
        return db.listIds(this.TableName, startAfter, limit);
    }

    /** Returns the UserProfile instances that exist in the database. */
    static list(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<UserProfile>> {
        return db.list(this.TableName, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => UserProfile.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    static listWithFilter(db:SpotDB, filter:FilterExpression, startAfter?:string, limit?: number) : Promise<ListResult<UserProfile>> {
        return db.listWithFilter(this.TableName, filter, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => UserProfile.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    static listByIndex(db:SpotDB, indexName:string, attributeName:string, value:string, startAfter?:string, limit?: number) : Promise<ListResult<UserProfile>> {
        return db.listByIndex(this.TableName, indexName, attributeName, value, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => this.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Delete a group of UserProfile instances from the database. */
    static bulkDelete(db:SpotDB, ids:string[]) : Promise<void> {
        return db.batchDelete(this.TableName, ids);
    }
}