module.exports={
  "pagination": {
    "DescribeConnections": {
      "result_key": "connections"
    },
    "DescribeConnectionsOnInterconnect": {
      "result_key": "connections"
    },
    "DescribeInterconnects": {
      "result_key": "interconnects"
    },
    "DescribeLocations": {
      "result_key": "locations"
    },
    "DescribeVirtualGateways": {
      "result_key": "virtualGateways"
    },
    "DescribeVirtualInterfaces": {
      "result_key": "virtualInterfaces"
    }
  }
}
