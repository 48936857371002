module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "ecr-2015-09-21",
    "apiVersion": "2015-09-21",
    "endpointPrefix": "ecr",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon ECR",
    "serviceFullName": "Amazon EC2 Container Registry",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonEC2ContainerRegistry_V20150921"
  },
  "operations": {
    "BatchCheckLayerAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "layerDigests"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "layerDigests": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "layers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "layerDigest": {},
                "layerAvailability": {},
                "layerSize": {
                  "type": "long"
                },
                "mediaType": {}
              }
            }
          },
          "failures": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "layerDigest": {},
                "failureCode": {},
                "failureReason": {}
              }
            }
          }
        }
      }
    },
    "BatchDeleteImage": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageIds"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageIds": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "imageIds": {
            "shape": "Si"
          },
          "failures": {
            "shape": "Sn"
          }
        }
      }
    },
    "BatchGetImage": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageIds"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageIds": {
            "shape": "Si"
          },
          "acceptedMediaTypes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "images": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "failures": {
            "shape": "Sn"
          }
        }
      }
    },
    "CompleteLayerUpload": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "uploadId",
          "layerDigests"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "layerDigests": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "layerDigest": {}
        }
      }
    },
    "CreateRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S13"
          }
        }
      }
    },
    "DeleteRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repository": {
            "shape": "S13"
          }
        }
      }
    },
    "DeleteRepositoryPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {}
        }
      }
    },
    "DescribeImages": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageIds": {
            "shape": "Si"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "tagStatus": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "imageDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "registryId": {},
                "repositoryName": {},
                "imageDigest": {},
                "imageTags": {
                  "type": "list",
                  "member": {}
                },
                "imageSizeInBytes": {
                  "type": "long"
                },
                "imagePushedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeRepositories": {
      "input": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetAuthorizationToken": {
      "input": {
        "type": "structure",
        "members": {
          "registryIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizationData": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authorizationToken": {},
                "expiresAt": {
                  "type": "timestamp"
                },
                "proxyEndpoint": {}
              }
            }
          }
        }
      }
    },
    "GetDownloadUrlForLayer": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "layerDigest"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "layerDigest": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "downloadUrl": {},
          "layerDigest": {}
        }
      }
    },
    "GetRepositoryPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {}
        }
      }
    },
    "InitiateLayerUpload": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "uploadId": {},
          "partSize": {
            "type": "long"
          }
        }
      }
    },
    "ListImages": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "tagStatus": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "imageIds": {
            "shape": "Si"
          },
          "nextToken": {}
        }
      }
    },
    "PutImage": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "imageManifest"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "imageManifest": {},
          "imageTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "image": {
            "shape": "Sv"
          }
        }
      }
    },
    "SetRepositoryPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "policyText"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "policyText": {}
        }
      }
    },
    "UploadLayerPart": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "uploadId",
          "partFirstByte",
          "partLastByte",
          "layerPartBlob"
        ],
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "partFirstByte": {
            "type": "long"
          },
          "partLastByte": {
            "type": "long"
          },
          "layerPartBlob": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "registryId": {},
          "repositoryName": {},
          "uploadId": {},
          "lastByteReceived": {
            "type": "long"
          }
        }
      }
    }
  },
  "shapes": {
    "Si": {
      "type": "list",
      "member": {
        "shape": "Sj"
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "imageDigest": {},
        "imageTag": {}
      }
    },
    "Sn": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "imageId": {
            "shape": "Sj"
          },
          "failureCode": {},
          "failureReason": {}
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "registryId": {},
        "repositoryName": {},
        "imageId": {
          "shape": "Sj"
        },
        "imageManifest": {}
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "repositoryArn": {},
        "registryId": {},
        "repositoryName": {},
        "repositoryUri": {},
        "createdAt": {
          "type": "timestamp"
        }
      }
    }
  }
}