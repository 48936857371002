module.exports={
  "pagination": {
    "GetDifferences": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "ListBranches": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "branches"
    },
    "ListRepositories": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "repositories"
    }
  }
}