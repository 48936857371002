module.exports={
  "pagination": {
    "ListCloudFrontOriginAccessIdentities": {
      "input_token": "Marker",
      "output_token": "CloudFrontOriginAccessIdentityList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "CloudFrontOriginAccessIdentityList.IsTruncated",
      "result_key": "CloudFrontOriginAccessIdentityList.Items"
    },
    "ListDistributions": {
      "input_token": "Marker",
      "output_token": "DistributionList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "DistributionList.IsTruncated",
      "result_key": "DistributionList.Items"
    },
    "ListInvalidations": {
      "input_token": "Marker",
      "output_token": "InvalidationList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "InvalidationList.IsTruncated",
      "result_key": "InvalidationList.Items"
    },
    "ListStreamingDistributions": {
      "input_token": "Marker",
      "output_token": "StreamingDistributionList.NextMarker",
      "limit_key": "MaxItems",
      "more_results": "StreamingDistributionList.IsTruncated",
      "result_key": "StreamingDistributionList.Items"
    }
  }
}
