module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-02-02",
    "endpointPrefix": "elasticache",
    "protocol": "query",
    "serviceFullName": "Amazon ElastiCache",
    "signatureVersion": "v4",
    "uid": "elasticache-2015-02-02",
    "xmlNamespace": "http://elasticache.amazonaws.com/doc/2015-02-02/"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "AddTagsToResourceResult"
      }
    },
    "AuthorizeCacheSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "EC2SecurityGroupName",
          "EC2SecurityGroupOwnerId"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeCacheSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceSnapshotName",
          "TargetSnapshotName"
        ],
        "members": {
          "SourceSnapshotName": {},
          "TargetSnapshotName": {},
          "TargetBucket": {}
        }
      },
      "output": {
        "resultWrapper": "CopySnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "ReplicationGroupId": {},
          "AZMode": {},
          "PreferredAvailabilityZone": {},
          "PreferredAvailabilityZones": {
            "shape": "So"
          },
          "NumCacheNodes": {
            "type": "integer"
          },
          "CacheNodeType": {},
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupName": {},
          "CacheSubnetGroupName": {},
          "CacheSecurityGroupNames": {
            "shape": "Sp"
          },
          "SecurityGroupIds": {
            "shape": "Sq"
          },
          "Tags": {
            "shape": "S3"
          },
          "SnapshotArns": {
            "shape": "Sr"
          },
          "SnapshotName": {},
          "PreferredMaintenanceWindow": {},
          "Port": {
            "type": "integer"
          },
          "NotificationTopicArn": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "AuthToken": {}
        }
      },
      "output": {
        "resultWrapper": "CreateCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "Su"
          }
        }
      }
    },
    "CreateCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName",
          "CacheParameterGroupFamily",
          "Description"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "CacheParameterGroupFamily": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "CreateCacheParameterGroupResult",
        "type": "structure",
        "members": {
          "CacheParameterGroup": {
            "shape": "S19"
          }
        }
      }
    },
    "CreateCacheSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "Description"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "Description": {}
        }
      },
      "output": {
        "resultWrapper": "CreateCacheSecurityGroupResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    },
    "CreateCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName",
          "CacheSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "CacheSubnetGroupName": {},
          "CacheSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateCacheSubnetGroupResult",
        "type": "structure",
        "members": {
          "CacheSubnetGroup": {
            "shape": "S1f"
          }
        }
      }
    },
    "CreateReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId",
          "ReplicationGroupDescription"
        ],
        "members": {
          "ReplicationGroupId": {},
          "ReplicationGroupDescription": {},
          "PrimaryClusterId": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          },
          "NumCacheClusters": {
            "type": "integer"
          },
          "PreferredCacheClusterAZs": {
            "shape": "Sl"
          },
          "NumNodeGroups": {
            "type": "integer"
          },
          "ReplicasPerNodeGroup": {
            "type": "integer"
          },
          "NodeGroupConfiguration": {
            "type": "list",
            "member": {
              "shape": "Sk",
              "locationName": "NodeGroupConfiguration"
            }
          },
          "CacheNodeType": {},
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupName": {},
          "CacheSubnetGroupName": {},
          "CacheSecurityGroupNames": {
            "shape": "Sp"
          },
          "SecurityGroupIds": {
            "shape": "Sq"
          },
          "Tags": {
            "shape": "S3"
          },
          "SnapshotArns": {
            "shape": "Sr"
          },
          "SnapshotName": {},
          "PreferredMaintenanceWindow": {},
          "Port": {
            "type": "integer"
          },
          "NotificationTopicArn": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "AuthToken": {}
        }
      },
      "output": {
        "resultWrapper": "CreateReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "S1m"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotName"
        ],
        "members": {
          "ReplicationGroupId": {},
          "CacheClusterId": {},
          "SnapshotName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "DeleteCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "FinalSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "Su"
          }
        }
      }
    },
    "DeleteCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {}
        }
      }
    },
    "DeleteCacheSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName"
        ],
        "members": {
          "CacheSecurityGroupName": {}
        }
      }
    },
    "DeleteCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName"
        ],
        "members": {
          "CacheSubnetGroupName": {}
        }
      }
    },
    "DeleteReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "RetainPrimaryCluster": {
            "type": "boolean"
          },
          "FinalSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "S1m"
          }
        }
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotName"
        ],
        "members": {
          "SnapshotName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "DescribeCacheClusters": {
      "input": {
        "type": "structure",
        "members": {
          "CacheClusterId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "ShowCacheNodeInfo": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheClusters": {
            "type": "list",
            "member": {
              "shape": "Su",
              "locationName": "CacheCluster"
            }
          }
        }
      }
    },
    "DescribeCacheEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "CacheParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "CacheEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "CacheParameterGroupFamily": {},
                "CacheEngineDescription": {},
                "CacheEngineVersionDescription": {}
              }
            }
          }
        }
      }
    },
    "DescribeCacheParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheParameterGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S19",
              "locationName": "CacheParameterGroup"
            }
          }
        }
      }
    },
    "DescribeCacheParameters": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "Source": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheParametersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Parameters": {
            "shape": "S2h"
          },
          "CacheNodeTypeSpecificParameters": {
            "shape": "S2k"
          }
        }
      }
    },
    "DescribeCacheSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheSecurityGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "S8",
              "locationName": "CacheSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeCacheSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CacheSubnetGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCacheSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "CacheSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S1f",
              "locationName": "CacheSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupFamily"
        ],
        "members": {
          "CacheParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "type": "structure",
            "members": {
              "CacheParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S2h"
              },
              "CacheNodeTypeSpecificParameters": {
                "shape": "S2k"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReplicationGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationGroupId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReplicationGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationGroups": {
            "type": "list",
            "member": {
              "shape": "S1m",
              "locationName": "ReplicationGroup"
            }
          }
        }
      }
    },
    "DescribeReservedCacheNodes": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedCacheNodeId": {},
          "ReservedCacheNodesOfferingId": {},
          "CacheNodeType": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedCacheNodesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedCacheNodes": {
            "type": "list",
            "member": {
              "shape": "S38",
              "locationName": "ReservedCacheNode"
            }
          }
        }
      }
    },
    "DescribeReservedCacheNodesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedCacheNodesOfferingId": {},
          "CacheNodeType": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedCacheNodesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedCacheNodesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedCacheNodesOffering",
              "type": "structure",
              "members": {
                "ReservedCacheNodesOfferingId": {},
                "CacheNodeType": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "ProductDescription": {},
                "OfferingType": {},
                "RecurringCharges": {
                  "shape": "S3a"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationGroupId": {},
          "CacheClusterId": {},
          "SnapshotName": {},
          "SnapshotSource": {},
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "ShowNodeGroupConfig": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Snapshots": {
            "type": "list",
            "member": {
              "shape": "Sd",
              "locationName": "Snapshot"
            }
          }
        }
      }
    },
    "ListAllowedNodeTypeModifications": {
      "input": {
        "type": "structure",
        "members": {
          "CacheClusterId": {},
          "ReplicationGroupId": {}
        }
      },
      "output": {
        "resultWrapper": "ListAllowedNodeTypeModificationsResult",
        "type": "structure",
        "members": {
          "ScaleUpModifications": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {}
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "ListTagsForResourceResult"
      }
    },
    "ModifyCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId"
        ],
        "members": {
          "CacheClusterId": {},
          "NumCacheNodes": {
            "type": "integer"
          },
          "CacheNodeIdsToRemove": {
            "shape": "Sy"
          },
          "AZMode": {},
          "NewAvailabilityZones": {
            "shape": "So"
          },
          "CacheSecurityGroupNames": {
            "shape": "Sp"
          },
          "SecurityGroupIds": {
            "shape": "Sq"
          },
          "PreferredMaintenanceWindow": {},
          "NotificationTopicArn": {},
          "CacheParameterGroupName": {},
          "NotificationTopicStatus": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "CacheNodeType": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "Su"
          }
        }
      }
    },
    "ModifyCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName",
          "ParameterNameValues"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "ParameterNameValues": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "shape": "S3s",
        "resultWrapper": "ModifyCacheParameterGroupResult"
      }
    },
    "ModifyCacheSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSubnetGroupName"
        ],
        "members": {
          "CacheSubnetGroupName": {},
          "CacheSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyCacheSubnetGroupResult",
        "type": "structure",
        "members": {
          "CacheSubnetGroup": {
            "shape": "S1f"
          }
        }
      }
    },
    "ModifyReplicationGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationGroupId"
        ],
        "members": {
          "ReplicationGroupId": {},
          "ReplicationGroupDescription": {},
          "PrimaryClusterId": {},
          "SnapshottingClusterId": {},
          "AutomaticFailoverEnabled": {
            "type": "boolean"
          },
          "CacheSecurityGroupNames": {
            "shape": "Sp"
          },
          "SecurityGroupIds": {
            "shape": "Sq"
          },
          "PreferredMaintenanceWindow": {},
          "NotificationTopicArn": {},
          "CacheParameterGroupName": {},
          "NotificationTopicStatus": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "SnapshotRetentionLimit": {
            "type": "integer"
          },
          "SnapshotWindow": {},
          "CacheNodeType": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyReplicationGroupResult",
        "type": "structure",
        "members": {
          "ReplicationGroup": {
            "shape": "S1m"
          }
        }
      }
    },
    "PurchaseReservedCacheNodesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedCacheNodesOfferingId"
        ],
        "members": {
          "ReservedCacheNodesOfferingId": {},
          "ReservedCacheNodeId": {},
          "CacheNodeCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedCacheNodesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedCacheNode": {
            "shape": "S38"
          }
        }
      }
    },
    "RebootCacheCluster": {
      "input": {
        "type": "structure",
        "required": [
          "CacheClusterId",
          "CacheNodeIdsToReboot"
        ],
        "members": {
          "CacheClusterId": {},
          "CacheNodeIdsToReboot": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootCacheClusterResult",
        "type": "structure",
        "members": {
          "CacheCluster": {
            "shape": "Su"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "shape": "S5",
        "resultWrapper": "RemoveTagsFromResourceResult"
      }
    },
    "ResetCacheParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "CacheParameterGroupName"
        ],
        "members": {
          "CacheParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "ParameterNameValues": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "shape": "S3s",
        "resultWrapper": "ResetCacheParameterGroupResult"
      }
    },
    "RevokeCacheSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "CacheSecurityGroupName",
          "EC2SecurityGroupName",
          "EC2SecurityGroupOwnerId"
        ],
        "members": {
          "CacheSecurityGroupName": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeCacheSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "CacheSecurityGroup": {
            "shape": "S8"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S5": {
      "type": "structure",
      "members": {
        "TagList": {
          "shape": "S3"
        }
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CacheSecurityGroupName": {},
        "Description": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sd": {
      "type": "structure",
      "members": {
        "SnapshotName": {},
        "ReplicationGroupId": {},
        "ReplicationGroupDescription": {},
        "CacheClusterId": {},
        "SnapshotStatus": {},
        "SnapshotSource": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "NumCacheNodes": {
          "type": "integer"
        },
        "PreferredAvailabilityZone": {},
        "CacheClusterCreateTime": {
          "type": "timestamp"
        },
        "PreferredMaintenanceWindow": {},
        "TopicArn": {},
        "Port": {
          "type": "integer"
        },
        "CacheParameterGroupName": {},
        "CacheSubnetGroupName": {},
        "VpcId": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {},
        "NumNodeGroups": {
          "type": "integer"
        },
        "AutomaticFailover": {},
        "NodeSnapshots": {
          "type": "list",
          "member": {
            "locationName": "NodeSnapshot",
            "type": "structure",
            "members": {
              "CacheClusterId": {},
              "NodeGroupId": {},
              "CacheNodeId": {},
              "NodeGroupConfiguration": {
                "shape": "Sk"
              },
              "CacheSize": {},
              "CacheNodeCreateTime": {
                "type": "timestamp"
              },
              "SnapshotCreateTime": {
                "type": "timestamp"
              }
            },
            "wrapper": true
          }
        }
      },
      "wrapper": true
    },
    "Sk": {
      "type": "structure",
      "members": {
        "Slots": {},
        "ReplicaCount": {
          "type": "integer"
        },
        "PrimaryAvailabilityZone": {},
        "ReplicaAvailabilityZones": {
          "shape": "Sl"
        }
      }
    },
    "Sl": {
      "type": "list",
      "member": {
        "locationName": "AvailabilityZone"
      }
    },
    "So": {
      "type": "list",
      "member": {
        "locationName": "PreferredAvailabilityZone"
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "locationName": "CacheSecurityGroupName"
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "locationName": "SnapshotArn"
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "CacheClusterId": {},
        "ConfigurationEndpoint": {
          "shape": "Sv"
        },
        "ClientDownloadLandingPage": {},
        "CacheNodeType": {},
        "Engine": {},
        "EngineVersion": {},
        "CacheClusterStatus": {},
        "NumCacheNodes": {
          "type": "integer"
        },
        "PreferredAvailabilityZone": {},
        "CacheClusterCreateTime": {
          "type": "timestamp"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "NumCacheNodes": {
              "type": "integer"
            },
            "CacheNodeIdsToRemove": {
              "shape": "Sy"
            },
            "EngineVersion": {},
            "CacheNodeType": {}
          }
        },
        "NotificationConfiguration": {
          "type": "structure",
          "members": {
            "TopicArn": {},
            "TopicStatus": {}
          }
        },
        "CacheSecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "CacheSecurityGroup",
            "type": "structure",
            "members": {
              "CacheSecurityGroupName": {},
              "Status": {}
            }
          }
        },
        "CacheParameterGroup": {
          "type": "structure",
          "members": {
            "CacheParameterGroupName": {},
            "ParameterApplyStatus": {},
            "CacheNodeIdsToReboot": {
              "shape": "Sy"
            }
          }
        },
        "CacheSubnetGroupName": {},
        "CacheNodes": {
          "type": "list",
          "member": {
            "locationName": "CacheNode",
            "type": "structure",
            "members": {
              "CacheNodeId": {},
              "CacheNodeStatus": {},
              "CacheNodeCreateTime": {
                "type": "timestamp"
              },
              "Endpoint": {
                "shape": "Sv"
              },
              "ParameterGroupStatus": {},
              "SourceCacheNodeId": {},
              "CustomerAvailabilityZone": {}
            }
          }
        },
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "SecurityGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SecurityGroupId": {},
              "Status": {}
            }
          }
        },
        "ReplicationGroupId": {},
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {}
      },
      "wrapper": true
    },
    "Sv": {
      "type": "structure",
      "members": {
        "Address": {},
        "Port": {
          "type": "integer"
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {
        "locationName": "CacheNodeId"
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "CacheParameterGroupName": {},
        "CacheParameterGroupFamily": {},
        "Description": {}
      },
      "wrapper": true
    },
    "S1d": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "CacheSubnetGroupName": {},
        "CacheSubnetGroupDescription": {},
        "VpcId": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "type": "structure",
                "members": {
                  "Name": {}
                },
                "wrapper": true
              }
            }
          }
        }
      },
      "wrapper": true
    },
    "S1m": {
      "type": "structure",
      "members": {
        "ReplicationGroupId": {},
        "Description": {},
        "Status": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "PrimaryClusterId": {},
            "AutomaticFailoverStatus": {}
          }
        },
        "MemberClusters": {
          "type": "list",
          "member": {
            "locationName": "ClusterId"
          }
        },
        "NodeGroups": {
          "type": "list",
          "member": {
            "locationName": "NodeGroup",
            "type": "structure",
            "members": {
              "NodeGroupId": {},
              "Status": {},
              "PrimaryEndpoint": {
                "shape": "Sv"
              },
              "Slots": {},
              "NodeGroupMembers": {
                "type": "list",
                "member": {
                  "locationName": "NodeGroupMember",
                  "type": "structure",
                  "members": {
                    "CacheClusterId": {},
                    "CacheNodeId": {},
                    "ReadEndpoint": {
                      "shape": "Sv"
                    },
                    "PreferredAvailabilityZone": {},
                    "CurrentRole": {}
                  }
                }
              }
            }
          }
        },
        "SnapshottingClusterId": {},
        "AutomaticFailover": {},
        "ConfigurationEndpoint": {
          "shape": "Sv"
        },
        "SnapshotRetentionLimit": {
          "type": "integer"
        },
        "SnapshotWindow": {}
      },
      "wrapper": true
    },
    "S2h": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ChangeType": {}
        }
      }
    },
    "S2k": {
      "type": "list",
      "member": {
        "locationName": "CacheNodeTypeSpecificParameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "CacheNodeTypeSpecificValues": {
            "type": "list",
            "member": {
              "locationName": "CacheNodeTypeSpecificValue",
              "type": "structure",
              "members": {
                "CacheNodeType": {},
                "Value": {}
              }
            }
          },
          "ChangeType": {}
        }
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "ReservedCacheNodeId": {},
        "ReservedCacheNodesOfferingId": {},
        "CacheNodeType": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CacheNodeCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "State": {},
        "RecurringCharges": {
          "shape": "S3a"
        }
      },
      "wrapper": true
    },
    "S3a": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S3q": {
      "type": "list",
      "member": {
        "locationName": "ParameterNameValue",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {}
        }
      }
    },
    "S3s": {
      "type": "structure",
      "members": {
        "CacheParameterGroupName": {}
      }
    }
  }
}