module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-03-28",
    "endpointPrefix": "logs",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon CloudWatch Logs",
    "signatureVersion": "v4",
    "targetPrefix": "Logs_20140328",
    "uid": "logs-2014-03-28"
  },
  "operations": {
    "CancelExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {}
        }
      }
    },
    "CreateExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "from",
          "to",
          "destination"
        ],
        "members": {
          "taskName": {},
          "logGroupName": {},
          "logStreamNamePrefix": {},
          "from": {
            "type": "long"
          },
          "to": {
            "type": "long"
          },
          "destination": {},
          "destinationPrefix": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "CreateLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "CreateLogStream": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {}
        }
      }
    },
    "DeleteDestination": {
      "input": {
        "type": "structure",
        "required": [
          "destinationName"
        ],
        "members": {
          "destinationName": {}
        }
      }
    },
    "DeleteLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {}
        }
      }
    },
    "DeleteLogStream": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {}
        }
      }
    },
    "DeleteMetricFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {}
        }
      }
    },
    "DeleteRetentionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {}
        }
      }
    },
    "DeleteSubscriptionFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {}
        }
      }
    },
    "DescribeDestinations": {
      "input": {
        "type": "structure",
        "members": {
          "DestinationNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "destinations": {
            "type": "list",
            "member": {
              "shape": "St"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeExportTasks": {
      "input": {
        "type": "structure",
        "members": {
          "taskId": {},
          "statusCode": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "exportTasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "taskName": {},
                "logGroupName": {},
                "from": {
                  "type": "long"
                },
                "to": {
                  "type": "long"
                },
                "destination": {},
                "destinationPrefix": {},
                "status": {
                  "type": "structure",
                  "members": {
                    "code": {},
                    "message": {}
                  }
                },
                "executionInfo": {
                  "type": "structure",
                  "members": {
                    "creationTime": {
                      "type": "long"
                    },
                    "completionTime": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeLogGroups": {
      "input": {
        "type": "structure",
        "members": {
          "logGroupNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logGroupName": {},
                "creationTime": {
                  "type": "long"
                },
                "retentionInDays": {
                  "type": "integer"
                },
                "metricFilterCount": {
                  "type": "integer"
                },
                "arn": {},
                "storedBytes": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeLogStreams": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamNamePrefix": {},
          "orderBy": {},
          "descending": {
            "type": "boolean"
          },
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logStreams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logStreamName": {},
                "creationTime": {
                  "type": "long"
                },
                "firstEventTimestamp": {
                  "type": "long"
                },
                "lastEventTimestamp": {
                  "type": "long"
                },
                "lastIngestionTime": {
                  "type": "long"
                },
                "uploadSequenceToken": {},
                "arn": {},
                "storedBytes": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeMetricFilters": {
      "input": {
        "type": "structure",
        "members": {
          "logGroupName": {},
          "filterNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          },
          "metricName": {},
          "metricNamespace": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "filterName": {},
                "filterPattern": {},
                "metricTransformations": {
                  "shape": "S1r"
                },
                "creationTime": {
                  "type": "long"
                },
                "logGroupName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeSubscriptionFilters": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "filterNamePrefix": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "subscriptionFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "filterName": {},
                "logGroupName": {},
                "filterPattern": {},
                "destinationArn": {},
                "roleArn": {},
                "distribution": {},
                "creationTime": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "FilterLogEvents": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamNames": {
            "type": "list",
            "member": {}
          },
          "startTime": {
            "type": "long"
          },
          "endTime": {
            "type": "long"
          },
          "filterPattern": {},
          "nextToken": {},
          "limit": {
            "type": "integer"
          },
          "interleaved": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logStreamName": {},
                "timestamp": {
                  "type": "long"
                },
                "message": {},
                "ingestionTime": {
                  "type": "long"
                },
                "eventId": {}
              }
            }
          },
          "searchedLogStreams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logStreamName": {},
                "searchedCompletely": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetLogEvents": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {},
          "startTime": {
            "type": "long"
          },
          "endTime": {
            "type": "long"
          },
          "nextToken": {},
          "limit": {
            "type": "integer"
          },
          "startFromHead": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "timestamp": {
                  "type": "long"
                },
                "message": {},
                "ingestionTime": {
                  "type": "long"
                }
              }
            }
          },
          "nextForwardToken": {},
          "nextBackwardToken": {}
        }
      }
    },
    "ListTagsLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName"
        ],
        "members": {
          "logGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "PutDestination": {
      "input": {
        "type": "structure",
        "required": [
          "destinationName",
          "targetArn",
          "roleArn"
        ],
        "members": {
          "destinationName": {},
          "targetArn": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "destination": {
            "shape": "St"
          }
        }
      }
    },
    "PutDestinationPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "destinationName",
          "accessPolicy"
        ],
        "members": {
          "destinationName": {},
          "accessPolicy": {}
        }
      }
    },
    "PutLogEvents": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "logStreamName",
          "logEvents"
        ],
        "members": {
          "logGroupName": {},
          "logStreamName": {},
          "logEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "timestamp",
                "message"
              ],
              "members": {
                "timestamp": {
                  "type": "long"
                },
                "message": {}
              }
            }
          },
          "sequenceToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextSequenceToken": {},
          "rejectedLogEventsInfo": {
            "type": "structure",
            "members": {
              "tooNewLogEventStartIndex": {
                "type": "integer"
              },
              "tooOldLogEventEndIndex": {
                "type": "integer"
              },
              "expiredLogEventEndIndex": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "PutMetricFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName",
          "filterPattern",
          "metricTransformations"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {},
          "filterPattern": {},
          "metricTransformations": {
            "shape": "S1r"
          }
        }
      }
    },
    "PutRetentionPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "retentionInDays"
        ],
        "members": {
          "logGroupName": {},
          "retentionInDays": {
            "type": "integer"
          }
        }
      }
    },
    "PutSubscriptionFilter": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "filterName",
          "filterPattern",
          "destinationArn"
        ],
        "members": {
          "logGroupName": {},
          "filterName": {},
          "filterPattern": {},
          "destinationArn": {},
          "roleArn": {},
          "distribution": {}
        }
      }
    },
    "TagLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "tags"
        ],
        "members": {
          "logGroupName": {},
          "tags": {
            "shape": "Sc"
          }
        }
      }
    },
    "TestMetricFilter": {
      "input": {
        "type": "structure",
        "required": [
          "filterPattern",
          "logEventMessages"
        ],
        "members": {
          "filterPattern": {},
          "logEventMessages": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "matches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "eventNumber": {
                  "type": "long"
                },
                "eventMessage": {},
                "extractedValues": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          }
        }
      }
    },
    "UntagLogGroup": {
      "input": {
        "type": "structure",
        "required": [
          "logGroupName",
          "tags"
        ],
        "members": {
          "logGroupName": {},
          "tags": {
            "type": "list",
            "member": {}
          }
        }
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "St": {
      "type": "structure",
      "members": {
        "destinationName": {},
        "targetArn": {},
        "roleArn": {},
        "accessPolicy": {},
        "arn": {},
        "creationTime": {
          "type": "long"
        }
      }
    },
    "S1r": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "metricName",
          "metricNamespace",
          "metricValue"
        ],
        "members": {
          "metricName": {},
          "metricNamespace": {},
          "metricValue": {},
          "defaultValue": {
            "type": "double"
          }
        }
      }
    }
  }
}