module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-06-27",
    "endpointPrefix": "rekognition",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Rekognition",
    "signatureVersion": "v4",
    "targetPrefix": "RekognitionService",
    "uid": "rekognition-2016-06-27"
  },
  "operations": {
    "CompareFaces": {
      "input": {
        "type": "structure",
        "required": [
          "SourceImage",
          "TargetImage"
        ],
        "members": {
          "SourceImage": {
            "shape": "S2"
          },
          "TargetImage": {
            "shape": "S2"
          },
          "SimilarityThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceImageFace": {
            "type": "structure",
            "members": {
              "BoundingBox": {
                "shape": "Sb"
              },
              "Confidence": {
                "type": "float"
              }
            }
          },
          "FaceMatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Similarity": {
                  "type": "float"
                },
                "Face": {
                  "type": "structure",
                  "members": {
                    "BoundingBox": {
                      "shape": "Sb"
                    },
                    "Confidence": {
                      "type": "float"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "CreateCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "type": "integer"
          },
          "CollectionArn": {}
        }
      }
    },
    "DeleteCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "type": "integer"
          }
        }
      }
    },
    "DeleteFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "FaceIds"
        ],
        "members": {
          "CollectionId": {},
          "FaceIds": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeletedFaces": {
            "shape": "So"
          }
        }
      }
    },
    "DetectFaces": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "Attributes": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceDetails": {
            "type": "list",
            "member": {
              "shape": "Sw"
            }
          },
          "OrientationCorrection": {}
        }
      }
    },
    "DetectLabels": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "MaxLabels": {
            "type": "integer"
          },
          "MinConfidence": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Labels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Confidence": {
                  "type": "float"
                }
              }
            }
          },
          "OrientationCorrection": {}
        }
      }
    },
    "IndexFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "Image"
        ],
        "members": {
          "CollectionId": {},
          "Image": {
            "shape": "S2"
          },
          "ExternalImageId": {},
          "DetectionAttributes": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Face": {
                  "shape": "S1r"
                },
                "FaceDetail": {
                  "shape": "Sw"
                }
              }
            }
          },
          "OrientationCorrection": {}
        }
      }
    },
    "ListCollections": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CollectionIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Faces": {
            "type": "list",
            "member": {
              "shape": "S1r"
            }
          },
          "NextToken": {}
        }
      }
    },
    "SearchFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "FaceId"
        ],
        "members": {
          "CollectionId": {},
          "FaceId": {},
          "MaxFaces": {
            "type": "integer"
          },
          "FaceMatchThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SearchedFaceId": {},
          "FaceMatches": {
            "shape": "S24"
          }
        }
      }
    },
    "SearchFacesByImage": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "Image"
        ],
        "members": {
          "CollectionId": {},
          "Image": {
            "shape": "S2"
          },
          "MaxFaces": {
            "type": "integer"
          },
          "FaceMatchThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SearchedFaceBoundingBox": {
            "shape": "Sb"
          },
          "SearchedFaceConfidence": {
            "type": "float"
          },
          "FaceMatches": {
            "shape": "S24"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "Bytes": {
          "type": "blob"
        },
        "S3Object": {
          "type": "structure",
          "members": {
            "Bucket": {},
            "Name": {},
            "Version": {}
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "Width": {
          "type": "float"
        },
        "Height": {
          "type": "float"
        },
        "Left": {
          "type": "float"
        },
        "Top": {
          "type": "float"
        }
      }
    },
    "So": {
      "type": "list",
      "member": {}
    },
    "Ss": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "shape": "Sb"
        },
        "AgeRange": {
          "type": "structure",
          "members": {
            "Low": {
              "type": "integer"
            },
            "High": {
              "type": "integer"
            }
          }
        },
        "Smile": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Eyeglasses": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Sunglasses": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Gender": {
          "type": "structure",
          "members": {
            "Value": {},
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Beard": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Mustache": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "EyesOpen": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "MouthOpen": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Emotions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Type": {},
              "Confidence": {
                "type": "float"
              }
            }
          }
        },
        "Landmarks": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Type": {},
              "X": {
                "type": "float"
              },
              "Y": {
                "type": "float"
              }
            }
          }
        },
        "Pose": {
          "type": "structure",
          "members": {
            "Roll": {
              "type": "float"
            },
            "Yaw": {
              "type": "float"
            },
            "Pitch": {
              "type": "float"
            }
          }
        },
        "Quality": {
          "type": "structure",
          "members": {
            "Brightness": {
              "type": "float"
            },
            "Sharpness": {
              "type": "float"
            }
          }
        },
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "FaceId": {},
        "BoundingBox": {
          "shape": "Sb"
        },
        "ImageId": {},
        "ExternalImageId": {},
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Similarity": {
            "type": "float"
          },
          "Face": {
            "shape": "S1r"
          }
        }
      }
    }
  }
}