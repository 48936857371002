import {AttributeSerializer} from './db';

/** Represents a collection of text messages that are equivalent and mapped to a set of locales. */
export class LocaleText {

    _textMap: Map<string,string> = new Map<string,string>();

    /** get the message for a specified locale, if it exists */
    get(locale:string) : string {
        return this._textMap.get(locale);
    }

    /** set the message for a particular locale */
    set(locale:string, value?: string) {
        this._textMap.set(locale, value);
    }

    /** create a new localeText from an object mapping. */
    static fromObject(a:Object) : LocaleText {
        let z = new LocaleText();
        for (let k of Object.keys(a)) {
            z._textMap.set(k, a[k]);
        }
        return z;
    }

}
