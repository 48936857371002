import {ListResult,AttributeSerializer,SpotDBProxy,FilterExpression,SignConfig} from '../spotdb';
import {ApiSerializer} from './api-serializer';
import * as url from 'url';

require('isomorphic-fetch');

/** A SpotDB instance represents a connection to a DynamoDB service. */
export class SpotApi {

    /** return an instance of SpotDB that is connected to a DynamoDB service. */
    static connect(serverUrl: url.Url) : SpotApi {
        serverUrl.pathname = "/api/v1";

        let sdb = new SpotApi();
        sdb._baseUrl = url.format(serverUrl);
        return sdb;
    }
    private _baseUrl:string;

    get serializer() : AttributeSerializer {
        return ApiSerializer;
    }

    get proxy() : SpotDBProxy { return this; }

    memberUrl(collection:string, id:string) : string {
        return `${this._baseUrl}/${collection}/${id}`;
    }

    collectionUrl(collection:string) : string {
        return `${this._baseUrl}/${collection}`;
    }

    collectionFindByUrl(collection:string, attributeName:string, value:string) : string {
        return `${this._baseUrl}/${collection}/by/${attributeName}/${value}`;
    }

    collectionActionUrl(collection:string, action:string) : string {
        return `${this._baseUrl}/${collection}/${action}`;
    }

    async put(collection:string, id:string, attrs:any) : Promise<any> {
        let resp = await fetch(this.memberUrl(collection, id), {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(attrs),
        });
        return await resp.json();
    }

    async get(collection:string, id:string) : Promise<any> {
        let resp = await fetch(this.memberUrl(collection, id));
        if (resp.status === 200) {
            return await resp.json();
        } else {
            return null;
        }
    }

    async delete(collection:string, id:string) : Promise<{}> {
        let resp = await fetch(this.memberUrl(collection, id), {
            method: 'DELETE',
        });
        return {};
    }

    async listIds(collection:string, startAfter?:string, limit?: number) : Promise<ListResult<string>> {
        let resp = await fetch(this.collectionActionUrl(collection,'ids'));
        if (resp.status === 200) {
            return await resp.json();
        } else {
            return null;
        }
    }

    async list(collection:string, startAfter?:string, limit?: number) : Promise<ListResult<any>> {
        let resp = await fetch(this.collectionUrl(collection));
        if (resp.status === 200) {
            return await resp.json();
        } else {
            return null;
        }
    }

    async listWithFilter(collection:string, filter: FilterExpression, startAfter?:string, limit?: number) : Promise<ListResult<any>> {
        let qs = `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
        let resp = await fetch(this.collectionUrl(collection)+qs);
        if (resp.status === 200) {
            return await resp.json();
        } else {
            return null;
        }
    }

    async listByIndex(collection:string, index: string, attributeName: string, value:any, startAfter?:string, limit?: number) : Promise<ListResult<any>> {
        let resp = await fetch(this.collectionFindByUrl(collection, attributeName, value));
        if (resp.status === 200) {
            return await resp.json();
        } else {
            return null;
        }
    }

    async batchDelete(collection:string, ids:string[]) : Promise<void> {
        let resp = await fetch(this.collectionActionUrl(collection, "delete"), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ids}),
        });
        return;
    }

    async proxyGetCategories(jurisdictionId:string) : Promise<string[]> {
        let resp = await fetch(`${this._baseUrl}/jurisdictions/${jurisdictionId}/signconfig-categories`);
        if (resp.status === 200) {
            return await resp.json();
        } else {
            return null;
        }
    }

    async proxyGetSignConfigByJurisdiction(jurisdictionId:string, category:string) : Promise<SignConfig> {
        let resp = await fetch(`${this._baseUrl}/jurisdictions/${jurisdictionId}/signconfigs/${category}`);
        if (resp.status === 200) {
            return await resp.json();
        } else {
            return null;
        }
    }

    async proxyGetSignedPutUrl(imageHash:string) : Promise<string> {
        let resp = await fetch(`${this._baseUrl}/signs/upload-url?imageHash=${encodeURIComponent(imageHash)}`);
        if (resp.status === 200) {
            let j = await resp.json();
            return j.signedImagePutUrl;
        } else {
            return null;
        }
    }

}