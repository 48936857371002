module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-13",
    "endpointPrefix": "ecs",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon ECS",
    "serviceFullName": "Amazon EC2 Container Service",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonEC2ContainerServiceV20141113",
    "uid": "ecs-2014-11-13"
  },
  "operations": {
    "CreateCluster": {
      "input": {
        "type": "structure",
        "members": {
          "clusterName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateService": {
      "input": {
        "type": "structure",
        "required": [
          "serviceName",
          "taskDefinition",
          "desiredCount"
        ],
        "members": {
          "cluster": {},
          "serviceName": {},
          "taskDefinition": {},
          "loadBalancers": {
            "shape": "S7"
          },
          "desiredCount": {
            "type": "integer"
          },
          "clientToken": {},
          "role": {},
          "deploymentConfiguration": {
            "shape": "Sa"
          },
          "placementConstraints": {
            "shape": "Sb"
          },
          "placementStrategy": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "Si"
          }
        }
      }
    },
    "DeleteAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "attributes"
        ],
        "members": {
          "cluster": {},
          "attributes": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "Sp"
          }
        }
      }
    },
    "DeleteCluster": {
      "input": {
        "type": "structure",
        "required": [
          "cluster"
        ],
        "members": {
          "cluster": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteService": {
      "input": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "Si"
          }
        }
      }
    },
    "DeregisterContainerInstance": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstance"
        ],
        "members": {
          "cluster": {},
          "containerInstance": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S10"
          }
        }
      }
    },
    "DeregisterTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S1b"
          }
        }
      }
    },
    "DescribeClusters": {
      "input": {
        "type": "structure",
        "members": {
          "clusters": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusters": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "failures": {
            "shape": "S28"
          }
        }
      }
    },
    "DescribeContainerInstances": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstances": {
            "shape": "S2c"
          },
          "failures": {
            "shape": "S28"
          }
        }
      }
    },
    "DescribeServices": {
      "input": {
        "type": "structure",
        "required": [
          "services"
        ],
        "members": {
          "cluster": {},
          "services": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "services": {
            "type": "list",
            "member": {
              "shape": "Si"
            }
          },
          "failures": {
            "shape": "S28"
          }
        }
      }
    },
    "DescribeTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "taskDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S1b"
          }
        }
      }
    },
    "DescribeTasks": {
      "input": {
        "type": "structure",
        "required": [
          "tasks"
        ],
        "members": {
          "cluster": {},
          "tasks": {
            "shape": "S16"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S2k"
          },
          "failures": {
            "shape": "S28"
          }
        }
      }
    },
    "DiscoverPollEndpoint": {
      "input": {
        "type": "structure",
        "members": {
          "containerInstance": {},
          "cluster": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpoint": {},
          "telemetryEndpoint": {}
        }
      }
    },
    "ListAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "targetType"
        ],
        "members": {
          "cluster": {},
          "targetType": {},
          "attributeName": {},
          "attributeValue": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "Sp"
          },
          "nextToken": {}
        }
      }
    },
    "ListClusters": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusterArns": {
            "shape": "S16"
          },
          "nextToken": {}
        }
      }
    },
    "ListContainerInstances": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "filter": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstanceArns": {
            "shape": "S16"
          },
          "nextToken": {}
        }
      }
    },
    "ListServices": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "serviceArns": {
            "shape": "S16"
          },
          "nextToken": {}
        }
      }
    },
    "ListTaskDefinitionFamilies": {
      "input": {
        "type": "structure",
        "members": {
          "familyPrefix": {},
          "status": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "families": {
            "shape": "S16"
          },
          "nextToken": {}
        }
      }
    },
    "ListTaskDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "familyPrefix": {},
          "status": {},
          "sort": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinitionArns": {
            "shape": "S16"
          },
          "nextToken": {}
        }
      }
    },
    "ListTasks": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "containerInstance": {},
          "family": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "startedBy": {},
          "serviceName": {},
          "desiredStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskArns": {
            "shape": "S16"
          },
          "nextToken": {}
        }
      }
    },
    "PutAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "attributes"
        ],
        "members": {
          "cluster": {},
          "attributes": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "attributes": {
            "shape": "Sp"
          }
        }
      }
    },
    "RegisterContainerInstance": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "instanceIdentityDocument": {},
          "instanceIdentityDocumentSignature": {},
          "totalResources": {
            "shape": "S13"
          },
          "versionInfo": {
            "shape": "S12"
          },
          "containerInstanceArn": {},
          "attributes": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S10"
          }
        }
      }
    },
    "RegisterTaskDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "family",
          "containerDefinitions"
        ],
        "members": {
          "family": {},
          "taskRoleArn": {},
          "networkMode": {},
          "containerDefinitions": {
            "shape": "S1c"
          },
          "volumes": {
            "shape": "S1x"
          },
          "placementConstraints": {
            "shape": "S22"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskDefinition": {
            "shape": "S1b"
          }
        }
      }
    },
    "RunTask": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition"
        ],
        "members": {
          "cluster": {},
          "taskDefinition": {},
          "overrides": {
            "shape": "S2m"
          },
          "count": {
            "type": "integer"
          },
          "startedBy": {},
          "group": {},
          "placementConstraints": {
            "shape": "Sb"
          },
          "placementStrategy": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S2k"
          },
          "failures": {
            "shape": "S28"
          }
        }
      }
    },
    "StartTask": {
      "input": {
        "type": "structure",
        "required": [
          "taskDefinition",
          "containerInstances"
        ],
        "members": {
          "cluster": {},
          "taskDefinition": {},
          "overrides": {
            "shape": "S2m"
          },
          "containerInstances": {
            "shape": "S16"
          },
          "startedBy": {},
          "group": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "shape": "S2k"
          },
          "failures": {
            "shape": "S28"
          }
        }
      }
    },
    "StopTask": {
      "input": {
        "type": "structure",
        "required": [
          "task"
        ],
        "members": {
          "cluster": {},
          "task": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "task": {
            "shape": "S2l"
          }
        }
      }
    },
    "SubmitContainerStateChange": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "task": {},
          "containerName": {},
          "status": {},
          "exitCode": {
            "type": "integer"
          },
          "reason": {},
          "networkBindings": {
            "shape": "S2r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "SubmitTaskStateChange": {
      "input": {
        "type": "structure",
        "members": {
          "cluster": {},
          "task": {},
          "status": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "acknowledgment": {}
        }
      }
    },
    "UpdateContainerAgent": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstance"
        ],
        "members": {
          "cluster": {},
          "containerInstance": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstance": {
            "shape": "S10"
          }
        }
      }
    },
    "UpdateContainerInstancesState": {
      "input": {
        "type": "structure",
        "required": [
          "containerInstances",
          "status"
        ],
        "members": {
          "cluster": {},
          "containerInstances": {
            "shape": "S16"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "containerInstances": {
            "shape": "S2c"
          },
          "failures": {
            "shape": "S28"
          }
        }
      }
    },
    "UpdateService": {
      "input": {
        "type": "structure",
        "required": [
          "service"
        ],
        "members": {
          "cluster": {},
          "service": {},
          "desiredCount": {
            "type": "integer"
          },
          "taskDefinition": {},
          "deploymentConfiguration": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "service": {
            "shape": "Si"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "clusterArn": {},
        "clusterName": {},
        "status": {},
        "registeredContainerInstancesCount": {
          "type": "integer"
        },
        "runningTasksCount": {
          "type": "integer"
        },
        "pendingTasksCount": {
          "type": "integer"
        },
        "activeServicesCount": {
          "type": "integer"
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "targetGroupArn": {},
          "loadBalancerName": {},
          "containerName": {},
          "containerPort": {
            "type": "integer"
          }
        }
      }
    },
    "Sa": {
      "type": "structure",
      "members": {
        "maximumPercent": {
          "type": "integer"
        },
        "minimumHealthyPercent": {
          "type": "integer"
        }
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "expression": {}
        }
      }
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "field": {}
        }
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "serviceArn": {},
        "serviceName": {},
        "clusterArn": {},
        "loadBalancers": {
          "shape": "S7"
        },
        "status": {},
        "desiredCount": {
          "type": "integer"
        },
        "runningCount": {
          "type": "integer"
        },
        "pendingCount": {
          "type": "integer"
        },
        "taskDefinition": {},
        "deploymentConfiguration": {
          "shape": "Sa"
        },
        "deployments": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "id": {},
              "status": {},
              "taskDefinition": {},
              "desiredCount": {
                "type": "integer"
              },
              "pendingCount": {
                "type": "integer"
              },
              "runningCount": {
                "type": "integer"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "updatedAt": {
                "type": "timestamp"
              }
            }
          }
        },
        "roleArn": {},
        "events": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "id": {},
              "createdAt": {
                "type": "timestamp"
              },
              "message": {}
            }
          }
        },
        "createdAt": {
          "type": "timestamp"
        },
        "placementConstraints": {
          "shape": "Sb"
        },
        "placementStrategy": {
          "shape": "Se"
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "shape": "Sq"
      }
    },
    "Sq": {
      "type": "structure",
      "required": [
        "name"
      ],
      "members": {
        "name": {},
        "value": {},
        "targetType": {},
        "targetId": {}
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "containerInstanceArn": {},
        "ec2InstanceId": {},
        "version": {
          "type": "long"
        },
        "versionInfo": {
          "shape": "S12"
        },
        "remainingResources": {
          "shape": "S13"
        },
        "registeredResources": {
          "shape": "S13"
        },
        "status": {},
        "agentConnected": {
          "type": "boolean"
        },
        "runningTasksCount": {
          "type": "integer"
        },
        "pendingTasksCount": {
          "type": "integer"
        },
        "agentUpdateStatus": {},
        "attributes": {
          "shape": "Sp"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "agentVersion": {},
        "agentHash": {},
        "dockerVersion": {}
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "type": {},
          "doubleValue": {
            "type": "double"
          },
          "longValue": {
            "type": "long"
          },
          "integerValue": {
            "type": "integer"
          },
          "stringSetValue": {
            "shape": "S16"
          }
        }
      }
    },
    "S16": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "structure",
      "members": {
        "taskDefinitionArn": {},
        "containerDefinitions": {
          "shape": "S1c"
        },
        "family": {},
        "taskRoleArn": {},
        "networkMode": {},
        "revision": {
          "type": "integer"
        },
        "volumes": {
          "shape": "S1x"
        },
        "status": {},
        "requiresAttributes": {
          "type": "list",
          "member": {
            "shape": "Sq"
          }
        },
        "placementConstraints": {
          "shape": "S22"
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "image": {},
          "cpu": {
            "type": "integer"
          },
          "memory": {
            "type": "integer"
          },
          "memoryReservation": {
            "type": "integer"
          },
          "links": {
            "shape": "S16"
          },
          "portMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "containerPort": {
                  "type": "integer"
                },
                "hostPort": {
                  "type": "integer"
                },
                "protocol": {}
              }
            }
          },
          "essential": {
            "type": "boolean"
          },
          "entryPoint": {
            "shape": "S16"
          },
          "command": {
            "shape": "S16"
          },
          "environment": {
            "shape": "S1h"
          },
          "mountPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "sourceVolume": {},
                "containerPath": {},
                "readOnly": {
                  "type": "boolean"
                }
              }
            }
          },
          "volumesFrom": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "sourceContainer": {},
                "readOnly": {
                  "type": "boolean"
                }
              }
            }
          },
          "hostname": {},
          "user": {},
          "workingDirectory": {},
          "disableNetworking": {
            "type": "boolean"
          },
          "privileged": {
            "type": "boolean"
          },
          "readonlyRootFilesystem": {
            "type": "boolean"
          },
          "dnsServers": {
            "shape": "S16"
          },
          "dnsSearchDomains": {
            "shape": "S16"
          },
          "extraHosts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "hostname",
                "ipAddress"
              ],
              "members": {
                "hostname": {},
                "ipAddress": {}
              }
            }
          },
          "dockerSecurityOptions": {
            "shape": "S16"
          },
          "dockerLabels": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "ulimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "softLimit",
                "hardLimit"
              ],
              "members": {
                "name": {},
                "softLimit": {
                  "type": "integer"
                },
                "hardLimit": {
                  "type": "integer"
                }
              }
            }
          },
          "logConfiguration": {
            "type": "structure",
            "required": [
              "logDriver"
            ],
            "members": {
              "logDriver": {},
              "options": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {}
        }
      }
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "host": {
            "type": "structure",
            "members": {
              "sourcePath": {}
            }
          }
        }
      }
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "type": {},
          "expression": {}
        }
      }
    },
    "S28": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "arn": {},
          "reason": {}
        }
      }
    },
    "S2c": {
      "type": "list",
      "member": {
        "shape": "S10"
      }
    },
    "S2k": {
      "type": "list",
      "member": {
        "shape": "S2l"
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "taskArn": {},
        "clusterArn": {},
        "taskDefinitionArn": {},
        "containerInstanceArn": {},
        "overrides": {
          "shape": "S2m"
        },
        "lastStatus": {},
        "desiredStatus": {},
        "containers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "containerArn": {},
              "taskArn": {},
              "name": {},
              "lastStatus": {},
              "exitCode": {
                "type": "integer"
              },
              "reason": {},
              "networkBindings": {
                "shape": "S2r"
              }
            }
          }
        },
        "startedBy": {},
        "version": {
          "type": "long"
        },
        "stoppedReason": {},
        "createdAt": {
          "type": "timestamp"
        },
        "startedAt": {
          "type": "timestamp"
        },
        "stoppedAt": {
          "type": "timestamp"
        },
        "group": {}
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "containerOverrides": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {},
              "command": {
                "shape": "S16"
              },
              "environment": {
                "shape": "S1h"
              }
            }
          }
        },
        "taskRoleArn": {}
      }
    },
    "S2r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "bindIP": {},
          "containerPort": {
            "type": "integer"
          },
          "hostPort": {
            "type": "integer"
          },
          "protocol": {}
        }
      }
    }
  }
}