
import {SpotDB,ListResult,AttributeSerializer,FilterExpression} from './db';
import {SpotBaseObject,SpotSerializableObject} from './base';
import {DatapointRef} from './datapoint';

export type ZoneStatus = "COMPLETE" | "INCOMPLETE";

export type ParkingRestriction = "UNRESTRICTED" | "NO_PARKING" | "RESTRICTED";

export class ScheduleInterval extends SpotSerializableObject {
    start: number;
    end: number;
    parking: ParkingRestriction;
    conditions?: string;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromInteger(this.start, "start"),
            ...s.fromInteger(this.end, "end"),
            ...s.fromString(this.parking, "parking"),
            ...s.fromString(this.conditions, "conditions"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setInteger(a.start, (v:number) => { this.start = v; });
        s.setInteger(a.end, (v:number) => { this.end = v; });
        s.setString(a.parking, (v:ParkingRestriction) => { this.parking = v; });
        s.setString(a.conditions, (v:string) => { this.conditions = v; });
    }

    static fromAttr(s:AttributeSerializer, a:any) : ScheduleInterval {
        let si = new ScheduleInterval();
        si.setAttrs(s,a);
        return si;
    }
}

export class ZoneDatapoints extends SpotSerializableObject {
    start?: DatapointRef;
    end?: DatapointRef;
    others?: DatapointRef[];

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromString(this.start && this.start.id, "start"),
            ...s.fromString(this.end && this.end.id, "end"),
            ...s.fromStringArray(this.others && this.others.map(o => o.id), "others"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.start, (v:string) => { this.start = new DatapointRef(v); });
        s.setString(a.end, (v:string) => { this.end = new DatapointRef(v); });
        s.setStringArray(a.others, (v:string[]) => {
             this.others = v.map(o => new DatapointRef(o));
        });
    }

    static fromAttr(s:AttributeSerializer, a:any) : ZoneDatapoints {
        let z = new ZoneDatapoints();
        z.setAttrs(s,a);
        return z;
    }
}

/** A Zone defines an area between two or more data points that can be used to determine
 * whether parking is possible and under what conditions.  It is defined as a grid construct.
 *
 * A zone will always have two or more datapoints associated with it.  Those datapoints are
 * referenced in the ’dataPoints’ dictionary.
 *
 * A zone schedule will always have a base date and a defined interval (in minutes).
 * The totalIntervals field indicates the total number of intervals considered in the schedule
 * (in this case a day)
 *
 * The schedule field is an array of objects which define an interval start and end ranges and
 * the associated parking conditions.
 */
export class Zone extends SpotBaseObject {
    static TableName = 'zones';

    constructor(db:SpotDB) {
        super(db, Zone.TableName);
    }

    /** list of the datapoints contained in this zone */
    datapoints?: ZoneDatapoints;
    /** either COMPLETE or INCOMPLETE. If INCOMPLETE, zone is missing some information that needs fixing */
    status?: ZoneStatus;
    /** Number of minutes between tests */
    interval?: number;
    /** the date from which the grid definition starts */
    baseDate?: Date;
    /** the number of intervals used to construct the grid */
    totalIntervals?: number;
    /** array of objects that define the parking conditions for a range of intervals.
     * The start and end fields define the number of intervals from the defined baseDate.
     */
    schedule?: ScheduleInterval[];

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromString(this.id, "id"),
            ...s.fromObject(this.datapoints, "datapoints"),
            ...s.fromString(this.status, "status"),
            ...s.fromInteger(this.interval, "interval"),
            ...s.fromDate(this.baseDate, "baseDate"),
            ...s.fromInteger(this.totalIntervals, "totalIntervals"),
            ...s.fromObjectArray(this.schedule, "schedule"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.id, (v:string) => this.id = v);
        s.setObject(a.datapoints, ZoneDatapoints,
                                  (v:ZoneDatapoints) => this.datapoints = v);
        s.setString(a.status, (v:ZoneStatus) => this.status = v);
        s.setInteger(a.interval, (v:number) => this.interval = v);
        s.setDate(a.baseDate, (v:Date) => this.baseDate = v);
        s.setInteger(a.totalIntervals, (v:number) => this.totalIntervals = v);
        s.setObjectArray(a.schedule, ScheduleInterval,
                                       (v:ScheduleInterval[]) => this.schedule = v);
    }

    static fromAttrs(db:SpotDB, a:any) : Zone {
        let z = new Zone(db);
        z.setAttrs(db.serializer, a);
        return z;
    }

    /** Load a single Zone instance from the database */
    static async get(db:SpotDB, id:string) : Promise<Zone> {
        let data = await db.get(this.TableName, id);
        if (!data) {
            return null;
        }
        let j = new this(db);
        j.setAttrs(db.serializer, data);
        return j;
    }

    /** Returns the ids of Zones that exist in the database. */
    static listIds(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<string>> {
        return db.listIds(this.TableName, startAfter, limit);
    }

    /** Returns the Zone instances that exist in the database. */
    static list(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<Zone>> {
        return db.list(this.TableName, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => Zone.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    static listWithFilter(db:SpotDB, filter:FilterExpression, startAfter?:string, limit?: number) : Promise<ListResult<Zone>> {
        return db.listWithFilter(this.TableName, filter, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => Zone.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    static listByIndex(db:SpotDB, indexName:string, attributeName:string, value:string, startAfter?:string, limit?: number) : Promise<ListResult<Zone>> {
        return db.listByIndex(this.TableName, indexName, attributeName, value, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => this.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Delete a group of Zone instances from the database. */
    static bulkDelete(db:SpotDB, ids:string[]) : Promise<void> {
        return db.batchDelete(this.TableName, ids);
    }
}