module.exports={
  "pagination": {
    "ListEndpointsByPlatformApplication": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Endpoints"
    },
    "ListPlatformApplications": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "PlatformApplications"
    },
    "ListSubscriptions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Subscriptions"
    },
    "ListSubscriptionsByTopic": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Subscriptions"
    },
    "ListTopics": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "result_key": "Topics"
    }
  }
}
