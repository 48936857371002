module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-02-06",
    "endpointPrefix": "autoscaling",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Application Auto Scaling",
    "signatureVersion": "v4",
    "signingName": "application-autoscaling",
    "targetPrefix": "AnyScaleFrontendService",
    "uid": "application-autoscaling-2016-02-06"
  },
  "operations": {
    "DeleteScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName",
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "PolicyName": {},
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterScalableTarget": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeScalableTargets": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceIds": {
            "shape": "S9"
          },
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalableTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "MinCapacity",
                "MaxCapacity",
                "RoleARN",
                "CreationTime"
              ],
              "members": {
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "MinCapacity": {
                  "type": "integer"
                },
                "MaxCapacity": {
                  "type": "integer"
                },
                "RoleARN": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingActivities": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingActivities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ActivityId",
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "Description",
                "Cause",
                "StartTime",
                "StatusCode"
              ],
              "members": {
                "ActivityId": {},
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "Description": {},
                "Cause": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "StatusCode": {},
                "StatusMessage": {},
                "Details": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "PolicyNames": {
            "shape": "S9"
          },
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "PolicyARN",
                "PolicyName",
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "PolicyType",
                "CreationTime"
              ],
              "members": {
                "PolicyARN": {},
                "PolicyName": {},
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "PolicyType": {},
                "StepScalingPolicyConfiguration": {
                  "shape": "St"
                },
                "Alarms": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "AlarmName",
                      "AlarmARN"
                    ],
                    "members": {
                      "AlarmName": {},
                      "AlarmARN": {}
                    }
                  }
                },
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName",
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "PolicyName": {},
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "PolicyType": {},
          "StepScalingPolicyConfiguration": {
            "shape": "St"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "PolicyARN"
        ],
        "members": {
          "PolicyARN": {}
        }
      }
    },
    "RegisterScalableTarget": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MinCapacity": {
            "type": "integer"
          },
          "MaxCapacity": {
            "type": "integer"
          },
          "RoleARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S9": {
      "type": "list",
      "member": {}
    },
    "St": {
      "type": "structure",
      "members": {
        "AdjustmentType": {},
        "StepAdjustments": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ScalingAdjustment"
            ],
            "members": {
              "MetricIntervalLowerBound": {
                "type": "double"
              },
              "MetricIntervalUpperBound": {
                "type": "double"
              },
              "ScalingAdjustment": {
                "type": "integer"
              }
            }
          }
        },
        "MinAdjustmentMagnitude": {
          "type": "integer"
        },
        "Cooldown": {
          "type": "integer"
        },
        "MetricAggregationType": {}
      }
    }
  }
}