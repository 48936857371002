module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "waf-2015-08-24",
    "apiVersion": "2015-08-24",
    "endpointPrefix": "waf",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "WAF",
    "serviceFullName": "AWS WAF",
    "signatureVersion": "v4",
    "targetPrefix": "AWSWAF_20150824"
  },
  "operations": {
    "CreateByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByteMatchSet": {
            "shape": "S5"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IPSet": {
            "shape": "Sh"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MetricName",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "MetricName": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "Sp"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SizeConstraintSet": {
            "shape": "Sw"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SqlInjectionMatchSet": {
            "shape": "S13"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "MetricName",
          "DefaultAction",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "MetricName": {},
          "DefaultAction": {
            "shape": "S17"
          },
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WebACL": {
            "shape": "S1a"
          },
          "ChangeToken": {}
        }
      }
    },
    "CreateXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ChangeToken"
        ],
        "members": {
          "Name": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "XssMatchSet": {
            "shape": "S1g"
          },
          "ChangeToken": {}
        }
      }
    },
    "DeleteByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "ByteMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "ByteMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "IPSetId",
          "ChangeToken"
        ],
        "members": {
          "IPSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId",
          "ChangeToken"
        ],
        "members": {
          "RuleId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "SizeConstraintSetId",
          "ChangeToken"
        ],
        "members": {
          "SizeConstraintSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "SqlInjectionMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "SqlInjectionMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "WebACLId",
          "ChangeToken"
        ],
        "members": {
          "WebACLId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "DeleteXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "XssMatchSetId",
          "ChangeToken"
        ],
        "members": {
          "XssMatchSetId": {},
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "GetByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "ByteMatchSetId"
        ],
        "members": {
          "ByteMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByteMatchSet": {
            "shape": "S5"
          }
        }
      }
    },
    "GetChangeToken": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "GetChangeTokenStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeToken"
        ],
        "members": {
          "ChangeToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeTokenStatus": {}
        }
      }
    },
    "GetIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "IPSetId"
        ],
        "members": {
          "IPSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IPSet": {
            "shape": "Sh"
          }
        }
      }
    },
    "GetRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId"
        ],
        "members": {
          "RuleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "Sp"
          }
        }
      }
    },
    "GetSampledRequests": {
      "input": {
        "type": "structure",
        "required": [
          "WebAclId",
          "RuleId",
          "TimeWindow",
          "MaxItems"
        ],
        "members": {
          "WebAclId": {},
          "RuleId": {},
          "TimeWindow": {
            "shape": "S29"
          },
          "MaxItems": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SampledRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Request",
                "Weight"
              ],
              "members": {
                "Request": {
                  "type": "structure",
                  "members": {
                    "ClientIP": {},
                    "Country": {},
                    "URI": {},
                    "Method": {},
                    "HTTPVersion": {},
                    "Headers": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Name": {},
                          "Value": {}
                        }
                      }
                    }
                  }
                },
                "Weight": {
                  "type": "long"
                },
                "Timestamp": {
                  "type": "timestamp"
                },
                "Action": {}
              }
            }
          },
          "PopulationSize": {
            "type": "long"
          },
          "TimeWindow": {
            "shape": "S29"
          }
        }
      }
    },
    "GetSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "SizeConstraintSetId"
        ],
        "members": {
          "SizeConstraintSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SizeConstraintSet": {
            "shape": "Sw"
          }
        }
      }
    },
    "GetSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "SqlInjectionMatchSetId"
        ],
        "members": {
          "SqlInjectionMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SqlInjectionMatchSet": {
            "shape": "S13"
          }
        }
      }
    },
    "GetWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "WebACLId"
        ],
        "members": {
          "WebACLId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WebACL": {
            "shape": "S1a"
          }
        }
      }
    },
    "GetXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "XssMatchSetId"
        ],
        "members": {
          "XssMatchSetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "XssMatchSet": {
            "shape": "S1g"
          }
        }
      }
    },
    "ListByteMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "ByteMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ByteMatchSetId",
                "Name"
              ],
              "members": {
                "ByteMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListIPSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "IPSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "IPSetId",
                "Name"
              ],
              "members": {
                "IPSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListRules": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RuleId",
                "Name"
              ],
              "members": {
                "RuleId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListSizeConstraintSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "SizeConstraintSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SizeConstraintSetId",
                "Name"
              ],
              "members": {
                "SizeConstraintSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListSqlInjectionMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "SqlInjectionMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SqlInjectionMatchSetId",
                "Name"
              ],
              "members": {
                "SqlInjectionMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListWebACLs": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "WebACLs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WebACLId",
                "Name"
              ],
              "members": {
                "WebACLId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "ListXssMatchSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "XssMatchSets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "XssMatchSetId",
                "Name"
              ],
              "members": {
                "XssMatchSetId": {},
                "Name": {}
              }
            }
          }
        }
      }
    },
    "UpdateByteMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "ByteMatchSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "ByteMatchSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "ByteMatchTuple"
              ],
              "members": {
                "Action": {},
                "ByteMatchTuple": {
                  "shape": "S8"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateIPSet": {
      "input": {
        "type": "structure",
        "required": [
          "IPSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "IPSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "IPSetDescriptor"
              ],
              "members": {
                "Action": {},
                "IPSetDescriptor": {
                  "shape": "Sj"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "RuleId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "Predicate"
              ],
              "members": {
                "Action": {},
                "Predicate": {
                  "shape": "Sr"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateSizeConstraintSet": {
      "input": {
        "type": "structure",
        "required": [
          "SizeConstraintSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "SizeConstraintSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "SizeConstraint"
              ],
              "members": {
                "Action": {},
                "SizeConstraint": {
                  "shape": "Sy"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateSqlInjectionMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "SqlInjectionMatchSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "SqlInjectionMatchSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "SqlInjectionMatchTuple"
              ],
              "members": {
                "Action": {},
                "SqlInjectionMatchTuple": {
                  "shape": "S15"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateWebACL": {
      "input": {
        "type": "structure",
        "required": [
          "WebACLId",
          "ChangeToken"
        ],
        "members": {
          "WebACLId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "ActivatedRule"
              ],
              "members": {
                "Action": {},
                "ActivatedRule": {
                  "shape": "S1c"
                }
              }
            }
          },
          "DefaultAction": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    },
    "UpdateXssMatchSet": {
      "input": {
        "type": "structure",
        "required": [
          "XssMatchSetId",
          "ChangeToken",
          "Updates"
        ],
        "members": {
          "XssMatchSetId": {},
          "ChangeToken": {},
          "Updates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Action",
                "XssMatchTuple"
              ],
              "members": {
                "Action": {},
                "XssMatchTuple": {
                  "shape": "S1i"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChangeToken": {}
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "required": [
        "ByteMatchSetId",
        "ByteMatchTuples"
      ],
      "members": {
        "ByteMatchSetId": {},
        "Name": {},
        "ByteMatchTuples": {
          "type": "list",
          "member": {
            "shape": "S8"
          }
        }
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TargetString",
        "TextTransformation",
        "PositionalConstraint"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TargetString": {
          "type": "blob"
        },
        "TextTransformation": {},
        "PositionalConstraint": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {},
        "Data": {}
      }
    },
    "Sh": {
      "type": "structure",
      "required": [
        "IPSetId",
        "IPSetDescriptors"
      ],
      "members": {
        "IPSetId": {},
        "Name": {},
        "IPSetDescriptors": {
          "type": "list",
          "member": {
            "shape": "Sj"
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "required": [
        "Type",
        "Value"
      ],
      "members": {
        "Type": {},
        "Value": {}
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "RuleId",
        "Predicates"
      ],
      "members": {
        "RuleId": {},
        "Name": {},
        "MetricName": {},
        "Predicates": {
          "type": "list",
          "member": {
            "shape": "Sr"
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "Negated",
        "Type",
        "DataId"
      ],
      "members": {
        "Negated": {
          "type": "boolean"
        },
        "Type": {},
        "DataId": {}
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "SizeConstraintSetId",
        "SizeConstraints"
      ],
      "members": {
        "SizeConstraintSetId": {},
        "Name": {},
        "SizeConstraints": {
          "type": "list",
          "member": {
            "shape": "Sy"
          }
        }
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TextTransformation",
        "ComparisonOperator",
        "Size"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TextTransformation": {},
        "ComparisonOperator": {},
        "Size": {
          "type": "long"
        }
      }
    },
    "S13": {
      "type": "structure",
      "required": [
        "SqlInjectionMatchSetId",
        "SqlInjectionMatchTuples"
      ],
      "members": {
        "SqlInjectionMatchSetId": {},
        "Name": {},
        "SqlInjectionMatchTuples": {
          "type": "list",
          "member": {
            "shape": "S15"
          }
        }
      }
    },
    "S15": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TextTransformation"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TextTransformation": {}
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {}
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "WebACLId",
        "DefaultAction",
        "Rules"
      ],
      "members": {
        "WebACLId": {},
        "Name": {},
        "MetricName": {},
        "DefaultAction": {
          "shape": "S17"
        },
        "Rules": {
          "type": "list",
          "member": {
            "shape": "S1c"
          }
        }
      }
    },
    "S1c": {
      "type": "structure",
      "required": [
        "Priority",
        "RuleId",
        "Action"
      ],
      "members": {
        "Priority": {
          "type": "integer"
        },
        "RuleId": {},
        "Action": {
          "shape": "S17"
        }
      }
    },
    "S1g": {
      "type": "structure",
      "required": [
        "XssMatchSetId",
        "XssMatchTuples"
      ],
      "members": {
        "XssMatchSetId": {},
        "Name": {},
        "XssMatchTuples": {
          "type": "list",
          "member": {
            "shape": "S1i"
          }
        }
      }
    },
    "S1i": {
      "type": "structure",
      "required": [
        "FieldToMatch",
        "TextTransformation"
      ],
      "members": {
        "FieldToMatch": {
          "shape": "S9"
        },
        "TextTransformation": {}
      }
    },
    "S29": {
      "type": "structure",
      "required": [
        "StartTime",
        "EndTime"
      ],
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    }
  }
}