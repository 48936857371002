module.exports={
  "pagination": {
    "ListIdentities": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxItems",
      "result_key": "Identities"
    },
    "ListVerifiedEmailAddresses": {
      "result_key": "VerifiedEmailAddresses"
    }
  }
}
