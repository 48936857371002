module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "redshift-2012-12-01",
    "apiVersion": "2012-12-01",
    "endpointPrefix": "redshift",
    "protocol": "query",
    "serviceFullName": "Amazon Redshift",
    "signatureVersion": "v4",
    "xmlNamespace": "http://redshift.amazonaws.com/doc/2012-12-01/"
  },
  "operations": {
    "AuthorizeClusterSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName"
        ],
        "members": {
          "ClusterSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeClusterSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "ClusterSecurityGroup": {
            "shape": "S4"
          }
        }
      }
    },
    "AuthorizeSnapshotAccess": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier",
          "AccountWithRestoreAccess"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "SnapshotClusterIdentifier": {},
          "AccountWithRestoreAccess": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeSnapshotAccessResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "CopyClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceSnapshotIdentifier",
          "TargetSnapshotIdentifier"
        ],
        "members": {
          "SourceSnapshotIdentifier": {},
          "SourceSnapshotClusterIdentifier": {},
          "TargetSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "CopyClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "NodeType",
          "MasterUsername",
          "MasterUserPassword"
        ],
        "members": {
          "DBName": {},
          "ClusterIdentifier": {},
          "ClusterType": {},
          "NodeType": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "ClusterSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "ClusterSubnetGroupName": {},
          "AvailabilityZone": {},
          "PreferredMaintenanceWindow": {},
          "ClusterParameterGroupName": {},
          "AutomatedSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "Port": {
            "type": "integer"
          },
          "ClusterVersion": {},
          "AllowVersionUpgrade": {
            "type": "boolean"
          },
          "NumberOfNodes": {
            "type": "integer"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "HsmClientCertificateIdentifier": {},
          "HsmConfigurationIdentifier": {},
          "ElasticIp": {},
          "Tags": {
            "shape": "S7"
          },
          "KmsKeyId": {},
          "EnhancedVpcRouting": {
            "type": "boolean"
          },
          "AdditionalInfo": {},
          "IamRoles": {
            "shape": "St"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "CreateClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName",
          "ParameterGroupFamily",
          "Description"
        ],
        "members": {
          "ParameterGroupName": {},
          "ParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterParameterGroupResult",
        "type": "structure",
        "members": {
          "ClusterParameterGroup": {
            "shape": "S1g"
          }
        }
      }
    },
    "CreateClusterSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName",
          "Description"
        ],
        "members": {
          "ClusterSecurityGroupName": {},
          "Description": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterSecurityGroupResult",
        "type": "structure",
        "members": {
          "ClusterSecurityGroup": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier",
          "ClusterIdentifier"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "ClusterIdentifier": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateClusterSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSubnetGroupName",
          "Description",
          "SubnetIds"
        ],
        "members": {
          "ClusterSubnetGroupName": {},
          "Description": {},
          "SubnetIds": {
            "shape": "S1m"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateClusterSubnetGroupResult",
        "type": "structure",
        "members": {
          "ClusterSubnetGroup": {
            "shape": "S1o"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "SourceIds": {
            "shape": "S1t"
          },
          "EventCategories": {
            "shape": "S1u"
          },
          "Severity": {},
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S1w"
          }
        }
      }
    },
    "CreateHsmClientCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "HsmClientCertificateIdentifier"
        ],
        "members": {
          "HsmClientCertificateIdentifier": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateHsmClientCertificateResult",
        "type": "structure",
        "members": {
          "HsmClientCertificate": {
            "shape": "S1z"
          }
        }
      }
    },
    "CreateHsmConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "HsmConfigurationIdentifier",
          "Description",
          "HsmIpAddress",
          "HsmPartitionName",
          "HsmPartitionPassword",
          "HsmServerPublicCertificate"
        ],
        "members": {
          "HsmConfigurationIdentifier": {},
          "Description": {},
          "HsmIpAddress": {},
          "HsmPartitionName": {},
          "HsmPartitionPassword": {},
          "HsmServerPublicCertificate": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateHsmConfigurationResult",
        "type": "structure",
        "members": {
          "HsmConfiguration": {
            "shape": "S22"
          }
        }
      }
    },
    "CreateSnapshotCopyGrant": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotCopyGrantName"
        ],
        "members": {
          "SnapshotCopyGrantName": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateSnapshotCopyGrantResult",
        "type": "structure",
        "members": {
          "SnapshotCopyGrant": {
            "shape": "S25"
          }
        }
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "DeleteCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "SkipFinalClusterSnapshot": {
            "type": "boolean"
          },
          "FinalClusterSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "DeleteClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName"
        ],
        "members": {
          "ParameterGroupName": {}
        }
      }
    },
    "DeleteClusterSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName"
        ],
        "members": {
          "ClusterSecurityGroupName": {}
        }
      }
    },
    "DeleteClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "SnapshotClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "DeleteClusterSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSubnetGroupName"
        ],
        "members": {
          "ClusterSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      }
    },
    "DeleteHsmClientCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "HsmClientCertificateIdentifier"
        ],
        "members": {
          "HsmClientCertificateIdentifier": {}
        }
      }
    },
    "DeleteHsmConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "HsmConfigurationIdentifier"
        ],
        "members": {
          "HsmConfigurationIdentifier": {}
        }
      }
    },
    "DeleteSnapshotCopyGrant": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotCopyGrantName"
        ],
        "members": {
          "SnapshotCopyGrantName": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "shape": "S2j"
          }
        }
      }
    },
    "DescribeClusterParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ParameterGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ParameterGroups": {
            "type": "list",
            "member": {
              "shape": "S1g",
              "locationName": "ClusterParameterGroup"
            }
          }
        }
      }
    },
    "DescribeClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName"
        ],
        "members": {
          "ParameterGroupName": {},
          "Source": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S2q"
          },
          "Marker": {}
        }
      }
    },
    "DescribeClusterSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterSecurityGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ClusterSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "S4",
              "locationName": "ClusterSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeClusterSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "SnapshotType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "OwnerAccount": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Snapshots": {
            "type": "list",
            "member": {
              "shape": "Sd",
              "locationName": "Snapshot"
            }
          }
        }
      }
    },
    "DescribeClusterSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterSubnetGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ClusterSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S1o",
              "locationName": "ClusterSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeClusterVersions": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterVersion": {},
          "ClusterParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeClusterVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ClusterVersions": {
            "type": "list",
            "member": {
              "locationName": "ClusterVersion",
              "type": "structure",
              "members": {
                "ClusterVersion": {},
                "ClusterParameterGroupFamily": {},
                "Description": {}
              }
            }
          }
        }
      }
    },
    "DescribeClusters": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Clusters": {
            "type": "list",
            "member": {
              "shape": "Sv",
              "locationName": "Cluster"
            }
          }
        }
      }
    },
    "DescribeDefaultClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupFamily"
        ],
        "members": {
          "ParameterGroupFamily": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDefaultClusterParametersResult",
        "type": "structure",
        "members": {
          "DefaultClusterParameters": {
            "type": "structure",
            "members": {
              "ParameterGroupFamily": {},
              "Marker": {},
              "Parameters": {
                "shape": "S2q"
              }
            },
            "wrapper": true
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "Events": {
                  "type": "list",
                  "member": {
                    "locationName": "EventInfoMap",
                    "type": "structure",
                    "members": {
                      "EventId": {},
                      "EventCategories": {
                        "shape": "S1u"
                      },
                      "EventDescription": {},
                      "Severity": {}
                    },
                    "wrapper": true
                  }
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S1w",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S1u"
                },
                "Severity": {},
                "Date": {
                  "type": "timestamp"
                },
                "EventId": {}
              }
            }
          }
        }
      }
    },
    "DescribeHsmClientCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "HsmClientCertificateIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeHsmClientCertificatesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "HsmClientCertificates": {
            "type": "list",
            "member": {
              "shape": "S1z",
              "locationName": "HsmClientCertificate"
            }
          }
        }
      }
    },
    "DescribeHsmConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "HsmConfigurationIdentifier": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeHsmConfigurationsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "HsmConfigurations": {
            "type": "list",
            "member": {
              "shape": "S22",
              "locationName": "HsmConfiguration"
            }
          }
        }
      }
    },
    "DescribeLoggingStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "shape": "S3x",
        "resultWrapper": "DescribeLoggingStatusResult"
      }
    },
    "DescribeOrderableClusterOptions": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterVersion": {},
          "NodeType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableClusterOptionsResult",
        "type": "structure",
        "members": {
          "OrderableClusterOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableClusterOption",
              "type": "structure",
              "members": {
                "ClusterVersion": {},
                "ClusterType": {},
                "NodeType": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S1r",
                    "locationName": "AvailabilityZone"
                  }
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedNodeOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedNodeOfferingId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedNodeOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedNodeOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedNodeOffering",
              "type": "structure",
              "members": {
                "ReservedNodeOfferingId": {},
                "NodeType": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "OfferingType": {},
                "RecurringCharges": {
                  "shape": "S47"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeReservedNodes": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedNodeId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedNodesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedNodes": {
            "type": "list",
            "member": {
              "shape": "S4c",
              "locationName": "ReservedNode"
            }
          }
        }
      }
    },
    "DescribeResize": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeResizeResult",
        "type": "structure",
        "members": {
          "TargetNodeType": {},
          "TargetNumberOfNodes": {
            "type": "integer"
          },
          "TargetClusterType": {},
          "Status": {},
          "ImportTablesCompleted": {
            "type": "list",
            "member": {}
          },
          "ImportTablesInProgress": {
            "type": "list",
            "member": {}
          },
          "ImportTablesNotStarted": {
            "type": "list",
            "member": {}
          },
          "AvgResizeRateInMegaBytesPerSecond": {
            "type": "double"
          },
          "TotalResizeDataInMegaBytes": {
            "type": "long"
          },
          "ProgressInMegaBytes": {
            "type": "long"
          },
          "ElapsedTimeInSeconds": {
            "type": "long"
          },
          "EstimatedTimeToCompletionInSeconds": {
            "type": "long"
          }
        }
      }
    },
    "DescribeSnapshotCopyGrants": {
      "input": {
        "type": "structure",
        "members": {
          "SnapshotCopyGrantName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSnapshotCopyGrantsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "SnapshotCopyGrants": {
            "type": "list",
            "member": {
              "shape": "S25",
              "locationName": "SnapshotCopyGrant"
            }
          }
        }
      }
    },
    "DescribeTableRestoreStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterIdentifier": {},
          "TableRestoreRequestId": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeTableRestoreStatusResult",
        "type": "structure",
        "members": {
          "TableRestoreStatusDetails": {
            "type": "list",
            "member": {
              "shape": "S4q",
              "locationName": "TableRestoreStatus"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceName": {},
          "ResourceType": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "TagKeys": {
            "shape": "S2j"
          },
          "TagValues": {
            "shape": "S2l"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTagsResult",
        "type": "structure",
        "members": {
          "TaggedResources": {
            "type": "list",
            "member": {
              "locationName": "TaggedResource",
              "type": "structure",
              "members": {
                "Tag": {
                  "shape": "S8"
                },
                "ResourceName": {},
                "ResourceType": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DisableLogging": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "shape": "S3x",
        "resultWrapper": "DisableLoggingResult"
      }
    },
    "DisableSnapshotCopy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DisableSnapshotCopyResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "EnableLogging": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "BucketName"
        ],
        "members": {
          "ClusterIdentifier": {},
          "BucketName": {},
          "S3KeyPrefix": {}
        }
      },
      "output": {
        "shape": "S3x",
        "resultWrapper": "EnableLoggingResult"
      }
    },
    "EnableSnapshotCopy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "DestinationRegion"
        ],
        "members": {
          "ClusterIdentifier": {},
          "DestinationRegion": {},
          "RetentionPeriod": {
            "type": "integer"
          },
          "SnapshotCopyGrantName": {}
        }
      },
      "output": {
        "resultWrapper": "EnableSnapshotCopyResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "ModifyCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "ClusterType": {},
          "NodeType": {},
          "NumberOfNodes": {
            "type": "integer"
          },
          "ClusterSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "MasterUserPassword": {},
          "ClusterParameterGroupName": {},
          "AutomatedSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "PreferredMaintenanceWindow": {},
          "ClusterVersion": {},
          "AllowVersionUpgrade": {
            "type": "boolean"
          },
          "HsmClientCertificateIdentifier": {},
          "HsmConfigurationIdentifier": {},
          "NewClusterIdentifier": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "ElasticIp": {},
          "EnhancedVpcRouting": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "ModifyClusterIamRoles": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "AddIamRoles": {
            "shape": "St"
          },
          "RemoveIamRoles": {
            "shape": "St"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterIamRolesResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "ModifyClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName",
          "Parameters"
        ],
        "members": {
          "ParameterGroupName": {},
          "Parameters": {
            "shape": "S2q"
          }
        }
      },
      "output": {
        "shape": "S57",
        "resultWrapper": "ModifyClusterParameterGroupResult"
      }
    },
    "ModifyClusterSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "ClusterSubnetGroupName": {},
          "Description": {},
          "SubnetIds": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyClusterSubnetGroupResult",
        "type": "structure",
        "members": {
          "ClusterSubnetGroup": {
            "shape": "S1o"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "SourceIds": {
            "shape": "S1t"
          },
          "EventCategories": {
            "shape": "S1u"
          },
          "Severity": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S1w"
          }
        }
      }
    },
    "ModifySnapshotCopyRetentionPeriod": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "RetentionPeriod"
        ],
        "members": {
          "ClusterIdentifier": {},
          "RetentionPeriod": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifySnapshotCopyRetentionPeriodResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "PurchaseReservedNodeOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedNodeOfferingId"
        ],
        "members": {
          "ReservedNodeOfferingId": {},
          "NodeCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedNodeOfferingResult",
        "type": "structure",
        "members": {
          "ReservedNode": {
            "shape": "S4c"
          }
        }
      }
    },
    "RebootCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RebootClusterResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "ResetClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ParameterGroupName"
        ],
        "members": {
          "ParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S2q"
          }
        }
      },
      "output": {
        "shape": "S57",
        "resultWrapper": "ResetClusterParameterGroupResult"
      }
    },
    "RestoreFromClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "SnapshotIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "SnapshotClusterIdentifier": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "AllowVersionUpgrade": {
            "type": "boolean"
          },
          "ClusterSubnetGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "OwnerAccount": {},
          "HsmClientCertificateIdentifier": {},
          "HsmConfigurationIdentifier": {},
          "ElasticIp": {},
          "ClusterParameterGroupName": {},
          "ClusterSecurityGroups": {
            "shape": "Sp"
          },
          "VpcSecurityGroupIds": {
            "shape": "Sq"
          },
          "PreferredMaintenanceWindow": {},
          "AutomatedSnapshotRetentionPeriod": {
            "type": "integer"
          },
          "KmsKeyId": {},
          "NodeType": {},
          "EnhancedVpcRouting": {
            "type": "boolean"
          },
          "AdditionalInfo": {},
          "IamRoles": {
            "shape": "St"
          }
        }
      },
      "output": {
        "resultWrapper": "RestoreFromClusterSnapshotResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    },
    "RestoreTableFromClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier",
          "SnapshotIdentifier",
          "SourceDatabaseName",
          "SourceTableName",
          "NewTableName"
        ],
        "members": {
          "ClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "SourceDatabaseName": {},
          "SourceSchemaName": {},
          "SourceTableName": {},
          "TargetDatabaseName": {},
          "TargetSchemaName": {},
          "NewTableName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreTableFromClusterSnapshotResult",
        "type": "structure",
        "members": {
          "TableRestoreStatus": {
            "shape": "S4q"
          }
        }
      }
    },
    "RevokeClusterSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterSecurityGroupName"
        ],
        "members": {
          "ClusterSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeClusterSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "ClusterSecurityGroup": {
            "shape": "S4"
          }
        }
      }
    },
    "RevokeSnapshotAccess": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotIdentifier",
          "AccountWithRestoreAccess"
        ],
        "members": {
          "SnapshotIdentifier": {},
          "SnapshotClusterIdentifier": {},
          "AccountWithRestoreAccess": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeSnapshotAccessResult",
        "type": "structure",
        "members": {
          "Snapshot": {
            "shape": "Sd"
          }
        }
      }
    },
    "RotateEncryptionKey": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterIdentifier"
        ],
        "members": {
          "ClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RotateEncryptionKeyResult",
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sv"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "ClusterSecurityGroupName": {},
        "Description": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupOwnerId": {},
              "Tags": {
                "shape": "S7"
              }
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {},
              "Tags": {
                "shape": "S7"
              }
            }
          }
        },
        "Tags": {
          "shape": "S7"
        }
      },
      "wrapper": true
    },
    "S7": {
      "type": "list",
      "member": {
        "shape": "S8",
        "locationName": "Tag"
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "SnapshotIdentifier": {},
        "ClusterIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "ClusterCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "ClusterVersion": {},
        "SnapshotType": {},
        "NodeType": {},
        "NumberOfNodes": {
          "type": "integer"
        },
        "DBName": {},
        "VpcId": {},
        "Encrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "EncryptedWithHSM": {
          "type": "boolean"
        },
        "AccountsWithRestoreAccess": {
          "type": "list",
          "member": {
            "locationName": "AccountWithRestoreAccess",
            "type": "structure",
            "members": {
              "AccountId": {}
            }
          }
        },
        "OwnerAccount": {},
        "TotalBackupSizeInMegaBytes": {
          "type": "double"
        },
        "ActualIncrementalBackupSizeInMegaBytes": {
          "type": "double"
        },
        "BackupProgressInMegaBytes": {
          "type": "double"
        },
        "CurrentBackupRateInMegaBytesPerSecond": {
          "type": "double"
        },
        "EstimatedSecondsToCompletion": {
          "type": "long"
        },
        "ElapsedTimeInSeconds": {
          "type": "long"
        },
        "SourceRegion": {},
        "Tags": {
          "shape": "S7"
        },
        "RestorableNodeTypes": {
          "type": "list",
          "member": {
            "locationName": "NodeType"
          }
        },
        "EnhancedVpcRouting": {
          "type": "boolean"
        }
      },
      "wrapper": true
    },
    "Sp": {
      "type": "list",
      "member": {
        "locationName": "ClusterSecurityGroupName"
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "St": {
      "type": "list",
      "member": {
        "locationName": "IamRoleArn"
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "ClusterIdentifier": {},
        "NodeType": {},
        "ClusterStatus": {},
        "ModifyStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "type": "structure",
          "members": {
            "Address": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "ClusterCreateTime": {
          "type": "timestamp"
        },
        "AutomatedSnapshotRetentionPeriod": {
          "type": "integer"
        },
        "ClusterSecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "ClusterSecurityGroup",
            "type": "structure",
            "members": {
              "ClusterSecurityGroupName": {},
              "Status": {}
            }
          }
        },
        "VpcSecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "VpcSecurityGroup",
            "type": "structure",
            "members": {
              "VpcSecurityGroupId": {},
              "Status": {}
            }
          }
        },
        "ClusterParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "ClusterParameterGroup",
            "type": "structure",
            "members": {
              "ParameterGroupName": {},
              "ParameterApplyStatus": {},
              "ClusterParameterStatusList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ParameterName": {},
                    "ParameterApplyStatus": {},
                    "ParameterApplyErrorDescription": {}
                  }
                }
              }
            }
          }
        },
        "ClusterSubnetGroupName": {},
        "VpcId": {},
        "AvailabilityZone": {},
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "MasterUserPassword": {},
            "NodeType": {},
            "NumberOfNodes": {
              "type": "integer"
            },
            "ClusterType": {},
            "ClusterVersion": {},
            "AutomatedSnapshotRetentionPeriod": {
              "type": "integer"
            },
            "ClusterIdentifier": {},
            "PubliclyAccessible": {
              "type": "boolean"
            },
            "EnhancedVpcRouting": {
              "type": "boolean"
            }
          }
        },
        "ClusterVersion": {},
        "AllowVersionUpgrade": {
          "type": "boolean"
        },
        "NumberOfNodes": {
          "type": "integer"
        },
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "Encrypted": {
          "type": "boolean"
        },
        "RestoreStatus": {
          "type": "structure",
          "members": {
            "Status": {},
            "CurrentRestoreRateInMegaBytesPerSecond": {
              "type": "double"
            },
            "SnapshotSizeInMegaBytes": {
              "type": "long"
            },
            "ProgressInMegaBytes": {
              "type": "long"
            },
            "ElapsedTimeInSeconds": {
              "type": "long"
            },
            "EstimatedTimeToCompletionInSeconds": {
              "type": "long"
            }
          }
        },
        "HsmStatus": {
          "type": "structure",
          "members": {
            "HsmClientCertificateIdentifier": {},
            "HsmConfigurationIdentifier": {},
            "Status": {}
          }
        },
        "ClusterSnapshotCopyStatus": {
          "type": "structure",
          "members": {
            "DestinationRegion": {},
            "RetentionPeriod": {
              "type": "long"
            },
            "SnapshotCopyGrantName": {}
          }
        },
        "ClusterPublicKey": {},
        "ClusterNodes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "NodeRole": {},
              "PrivateIPAddress": {},
              "PublicIPAddress": {}
            }
          }
        },
        "ElasticIpStatus": {
          "type": "structure",
          "members": {
            "ElasticIp": {},
            "Status": {}
          }
        },
        "ClusterRevisionNumber": {},
        "Tags": {
          "shape": "S7"
        },
        "KmsKeyId": {},
        "EnhancedVpcRouting": {
          "type": "boolean"
        },
        "IamRoles": {
          "type": "list",
          "member": {
            "locationName": "ClusterIamRole",
            "type": "structure",
            "members": {
              "IamRoleArn": {},
              "ApplyStatus": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "S1g": {
      "type": "structure",
      "members": {
        "ParameterGroupName": {},
        "ParameterGroupFamily": {},
        "Description": {},
        "Tags": {
          "shape": "S7"
        }
      },
      "wrapper": true
    },
    "S1m": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "ClusterSubnetGroupName": {},
        "Description": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S1r"
              },
              "SubnetStatus": {}
            }
          }
        },
        "Tags": {
          "shape": "S7"
        }
      },
      "wrapper": true
    },
    "S1r": {
      "type": "structure",
      "members": {
        "Name": {}
      },
      "wrapper": true
    },
    "S1t": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S1u": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {
          "type": "timestamp"
        },
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S1t"
        },
        "EventCategoriesList": {
          "shape": "S1u"
        },
        "Severity": {},
        "Enabled": {
          "type": "boolean"
        },
        "Tags": {
          "shape": "S7"
        }
      },
      "wrapper": true
    },
    "S1z": {
      "type": "structure",
      "members": {
        "HsmClientCertificateIdentifier": {},
        "HsmClientCertificatePublicKey": {},
        "Tags": {
          "shape": "S7"
        }
      },
      "wrapper": true
    },
    "S22": {
      "type": "structure",
      "members": {
        "HsmConfigurationIdentifier": {},
        "Description": {},
        "HsmIpAddress": {},
        "HsmPartitionName": {},
        "Tags": {
          "shape": "S7"
        }
      },
      "wrapper": true
    },
    "S25": {
      "type": "structure",
      "members": {
        "SnapshotCopyGrantName": {},
        "KmsKeyId": {},
        "Tags": {
          "shape": "S7"
        }
      },
      "wrapper": true
    },
    "S2j": {
      "type": "list",
      "member": {
        "locationName": "TagKey"
      }
    },
    "S2l": {
      "type": "list",
      "member": {
        "locationName": "TagValue"
      }
    },
    "S2q": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "DataType": {},
          "AllowedValues": {},
          "ApplyType": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {}
        }
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "LoggingEnabled": {
          "type": "boolean"
        },
        "BucketName": {},
        "S3KeyPrefix": {},
        "LastSuccessfulDeliveryTime": {
          "type": "timestamp"
        },
        "LastFailureTime": {
          "type": "timestamp"
        },
        "LastFailureMessage": {}
      }
    },
    "S47": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S4c": {
      "type": "structure",
      "members": {
        "ReservedNodeId": {},
        "ReservedNodeOfferingId": {},
        "NodeType": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "NodeCount": {
          "type": "integer"
        },
        "State": {},
        "OfferingType": {},
        "RecurringCharges": {
          "shape": "S47"
        }
      },
      "wrapper": true
    },
    "S4q": {
      "type": "structure",
      "members": {
        "TableRestoreRequestId": {},
        "Status": {},
        "Message": {},
        "RequestTime": {
          "type": "timestamp"
        },
        "ProgressInMegaBytes": {
          "type": "long"
        },
        "TotalDataInMegaBytes": {
          "type": "long"
        },
        "ClusterIdentifier": {},
        "SnapshotIdentifier": {},
        "SourceDatabaseName": {},
        "SourceSchemaName": {},
        "SourceTableName": {},
        "TargetDatabaseName": {},
        "TargetSchemaName": {},
        "NewTableName": {}
      },
      "wrapper": true
    },
    "S57": {
      "type": "structure",
      "members": {
        "ParameterGroupName": {},
        "ParameterGroupStatus": {}
      }
    }
  }
}