module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-10-31",
    "endpointPrefix": "rds",
    "protocol": "query",
    "serviceAbbreviation": "Amazon RDS",
    "serviceFullName": "Amazon Relational Database Service",
    "signatureVersion": "v4",
    "uid": "rds-2014-10-31",
    "xmlNamespace": "http://rds.amazonaws.com/doc/2014-10-31/"
  },
  "operations": {
    "AddRoleToDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "RoleArn"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "RoleArn": {}
        }
      }
    },
    "AddSourceIdentifierToSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "AddSourceIdentifierToSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S5"
          }
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "Tags"
        ],
        "members": {
          "ResourceName": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      }
    },
    "ApplyPendingMaintenanceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIdentifier",
          "ApplyAction",
          "OptInType"
        ],
        "members": {
          "ResourceIdentifier": {},
          "ApplyAction": {},
          "OptInType": {}
        }
      },
      "output": {
        "resultWrapper": "ApplyPendingMaintenanceActionResult",
        "type": "structure",
        "members": {
          "ResourcePendingMaintenanceActions": {
            "shape": "Se"
          }
        }
      }
    },
    "AuthorizeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "AuthorizeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sk"
          }
        }
      }
    },
    "CopyDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBClusterParameterGroupIdentifier",
          "TargetDBClusterParameterGroupIdentifier",
          "TargetDBClusterParameterGroupDescription"
        ],
        "members": {
          "SourceDBClusterParameterGroupIdentifier": {},
          "TargetDBClusterParameterGroupIdentifier": {},
          "TargetDBClusterParameterGroupDescription": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBClusterParameterGroupResult",
        "type": "structure",
        "members": {
          "DBClusterParameterGroup": {
            "shape": "Sr"
          }
        }
      }
    },
    "CopyDBClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBClusterSnapshotIdentifier",
          "TargetDBClusterSnapshotIdentifier"
        ],
        "members": {
          "SourceDBClusterSnapshotIdentifier": {},
          "TargetDBClusterSnapshotIdentifier": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBClusterSnapshotResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshot": {
            "shape": "Su"
          }
        }
      }
    },
    "CopyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBParameterGroupIdentifier",
          "TargetDBParameterGroupIdentifier",
          "TargetDBParameterGroupDescription"
        ],
        "members": {
          "SourceDBParameterGroupIdentifier": {},
          "TargetDBParameterGroupIdentifier": {},
          "TargetDBParameterGroupDescription": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "Sz"
          }
        }
      }
    },
    "CopyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBSnapshotIdentifier",
          "TargetDBSnapshotIdentifier"
        ],
        "members": {
          "SourceDBSnapshotIdentifier": {},
          "TargetDBSnapshotIdentifier": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "Sa"
          },
          "CopyTags": {
            "type": "boolean"
          },
          "PreSignedUrl": {},
          "SourceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "CopyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S13"
          }
        }
      }
    },
    "CopyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "SourceOptionGroupIdentifier",
          "TargetOptionGroupIdentifier",
          "TargetOptionGroupDescription"
        ],
        "members": {
          "SourceOptionGroupIdentifier": {},
          "TargetOptionGroupIdentifier": {},
          "TargetOptionGroupDescription": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CopyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S17"
          }
        }
      }
    },
    "CreateDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "Engine"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "Sv"
          },
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "CharacterSetName": {},
          "DatabaseName": {},
          "DBClusterIdentifier": {},
          "DBClusterParameterGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1h"
          },
          "DBSubnetGroupName": {},
          "Engine": {},
          "EngineVersion": {},
          "Port": {
            "type": "integer"
          },
          "MasterUsername": {},
          "MasterUserPassword": {},
          "OptionGroupName": {},
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "ReplicationSourceIdentifier": {},
          "Tags": {
            "shape": "Sa"
          },
          "StorageEncrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "CreateDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBClusterParameterGroupResult",
        "type": "structure",
        "members": {
          "DBClusterParameterGroup": {
            "shape": "Sr"
          }
        }
      }
    },
    "CreateDBClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier",
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {},
          "DBClusterIdentifier": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBClusterSnapshotResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshot": {
            "shape": "Su"
          }
        }
      }
    },
    "CreateDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBInstanceClass",
          "Engine"
        ],
        "members": {
          "DBName": {},
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "Engine": {},
          "MasterUsername": {},
          "MasterUserPassword": {},
          "DBSecurityGroups": {
            "shape": "S1w"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1h"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "PreferredMaintenanceWindow": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "Port": {
            "type": "integer"
          },
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CharacterSetName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sa"
          },
          "DBClusterIdentifier": {},
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "StorageEncrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "Domain": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "MonitoringInterval": {
            "type": "integer"
          },
          "MonitoringRoleArn": {},
          "DomainIAMRoleName": {},
          "PromotionTier": {
            "type": "integer"
          },
          "Timezone": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "CreateDBInstanceReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "SourceDBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SourceDBInstanceIdentifier": {},
          "DBInstanceClass": {},
          "AvailabilityZone": {},
          "Port": {
            "type": "integer"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sa"
          },
          "DBSubnetGroupName": {},
          "StorageType": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "MonitoringInterval": {
            "type": "integer"
          },
          "MonitoringRoleArn": {},
          "KmsKeyId": {},
          "PreSignedUrl": {}
        }
      },
      "output": {
        "resultWrapper": "CreateDBInstanceReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "CreateDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "DBParameterGroupFamily",
          "Description"
        ],
        "members": {
          "DBParameterGroupName": {},
          "DBParameterGroupFamily": {},
          "Description": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBParameterGroupResult",
        "type": "structure",
        "members": {
          "DBParameterGroup": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName",
          "DBSecurityGroupDescription"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "DBSecurityGroupDescription": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSecurityGroupResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sk"
          }
        }
      }
    },
    "CreateDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "DBInstanceIdentifier": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S13"
          }
        }
      }
    },
    "CreateDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "DBSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S2o"
          },
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S22"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S7"
          },
          "SourceIds": {
            "shape": "S6"
          },
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S5"
          }
        }
      }
    },
    "CreateOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName",
          "EngineName",
          "MajorEngineVersion",
          "OptionGroupDescription"
        ],
        "members": {
          "OptionGroupName": {},
          "EngineName": {},
          "MajorEngineVersion": {},
          "OptionGroupDescription": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S17"
          }
        }
      }
    },
    "DeleteDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "DeleteDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName"
        ],
        "members": {
          "DBClusterParameterGroupName": {}
        }
      }
    },
    "DeleteDBClusterSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBClusterSnapshotResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshot": {
            "shape": "Su"
          }
        }
      }
    },
    "DeleteDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "SkipFinalSnapshot": {
            "type": "boolean"
          },
          "FinalDBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "DeleteDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {}
        }
      }
    },
    "DeleteDBSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {}
        }
      }
    },
    "DeleteDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S13"
          }
        }
      }
    },
    "DeleteDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName"
        ],
        "members": {
          "DBSubnetGroupName": {}
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S5"
          }
        }
      }
    },
    "DeleteOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {}
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "DescribeAccountAttributesResult",
        "type": "structure",
        "members": {
          "AccountQuotas": {
            "type": "list",
            "member": {
              "locationName": "AccountQuota",
              "type": "structure",
              "members": {
                "AccountQuotaName": {},
                "Used": {
                  "type": "long"
                },
                "Max": {
                  "type": "long"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "CertificateIdentifier": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeCertificatesResult",
        "type": "structure",
        "members": {
          "Certificates": {
            "type": "list",
            "member": {
              "locationName": "Certificate",
              "type": "structure",
              "members": {
                "CertificateIdentifier": {},
                "CertificateType": {},
                "Thumbprint": {},
                "ValidFrom": {
                  "type": "timestamp"
                },
                "ValidTill": {
                  "type": "timestamp"
                },
                "CertificateArn": {}
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBClusterParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterParameterGroupName": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusterParameterGroups": {
            "type": "list",
            "member": {
              "shape": "Sr",
              "locationName": "DBClusterParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "Source": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S3q"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBClusterSnapshotAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterSnapshotAttributesResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshotAttributesResult": {
            "shape": "S3v"
          }
        }
      }
    },
    "DescribeDBClusterSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterIdentifier": {},
          "DBClusterSnapshotIdentifier": {},
          "SnapshotType": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "IncludeShared": {
            "type": "boolean"
          },
          "IncludePublic": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClusterSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusterSnapshots": {
            "type": "list",
            "member": {
              "shape": "Su",
              "locationName": "DBClusterSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBClusters": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterIdentifier": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBClustersResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBClusters": {
            "type": "list",
            "member": {
              "shape": "S1j",
              "locationName": "DBCluster"
            }
          }
        }
      }
    },
    "DescribeDBEngineVersions": {
      "input": {
        "type": "structure",
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "DefaultOnly": {
            "type": "boolean"
          },
          "ListSupportedCharacterSets": {
            "type": "boolean"
          },
          "ListSupportedTimezones": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBEngineVersionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBEngineVersions": {
            "type": "list",
            "member": {
              "locationName": "DBEngineVersion",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBParameterGroupFamily": {},
                "DBEngineDescription": {},
                "DBEngineVersionDescription": {},
                "DefaultCharacterSet": {
                  "shape": "S49"
                },
                "SupportedCharacterSets": {
                  "type": "list",
                  "member": {
                    "shape": "S49",
                    "locationName": "CharacterSet"
                  }
                },
                "ValidUpgradeTarget": {
                  "type": "list",
                  "member": {
                    "locationName": "UpgradeTarget",
                    "type": "structure",
                    "members": {
                      "Engine": {},
                      "EngineVersion": {},
                      "Description": {},
                      "AutoUpgrade": {
                        "type": "boolean"
                      },
                      "IsMajorVersionUpgrade": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "SupportedTimezones": {
                  "type": "list",
                  "member": {
                    "locationName": "Timezone",
                    "type": "structure",
                    "members": {
                      "TimezoneName": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBInstances": {
            "type": "list",
            "member": {
              "shape": "S1y",
              "locationName": "DBInstance"
            }
          }
        }
      }
    },
    "DescribeDBLogFiles": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "FilenameContains": {},
          "FileLastWritten": {
            "type": "long"
          },
          "FileSize": {
            "type": "long"
          },
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBLogFilesResult",
        "type": "structure",
        "members": {
          "DescribeDBLogFiles": {
            "type": "list",
            "member": {
              "locationName": "DescribeDBLogFilesDetails",
              "type": "structure",
              "members": {
                "LogFileName": {},
                "LastWritten": {
                  "type": "long"
                },
                "Size": {
                  "type": "long"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBParameterGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBParameterGroupName": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParameterGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBParameterGroups": {
            "type": "list",
            "member": {
              "shape": "Sz",
              "locationName": "DBParameterGroup"
            }
          }
        }
      }
    },
    "DescribeDBParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Source": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBParametersResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "shape": "S3q"
          },
          "Marker": {}
        }
      }
    },
    "DescribeDBSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSecurityGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSecurityGroups": {
            "type": "list",
            "member": {
              "shape": "Sk",
              "locationName": "DBSecurityGroup"
            }
          }
        }
      }
    },
    "DescribeDBSnapshotAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotAttributesResult",
        "type": "structure",
        "members": {
          "DBSnapshotAttributesResult": {
            "shape": "S4w"
          }
        }
      }
    },
    "DescribeDBSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "SnapshotType": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "IncludeShared": {
            "type": "boolean"
          },
          "IncludePublic": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSnapshotsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSnapshots": {
            "type": "list",
            "member": {
              "shape": "S13",
              "locationName": "DBSnapshot"
            }
          }
        }
      }
    },
    "DescribeDBSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "DBSubnetGroupName": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeDBSubnetGroupsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "DBSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S22",
              "locationName": "DBSubnetGroup"
            }
          }
        }
      }
    },
    "DescribeEngineDefaultClusterParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultClusterParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "shape": "S57"
          }
        }
      }
    },
    "DescribeEngineDefaultParameters": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupFamily"
        ],
        "members": {
          "DBParameterGroupFamily": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEngineDefaultParametersResult",
        "type": "structure",
        "members": {
          "EngineDefaults": {
            "shape": "S57"
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {},
          "Filters": {
            "shape": "S3f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeEventCategoriesResult",
        "type": "structure",
        "members": {
          "EventCategoriesMapList": {
            "type": "list",
            "member": {
              "locationName": "EventCategoriesMap",
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S7"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventSubscriptionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S5",
              "locationName": "EventSubscription"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S7"
          },
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeEventsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "locationName": "Event",
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S7"
                },
                "Date": {
                  "type": "timestamp"
                },
                "SourceArn": {}
              }
            }
          }
        }
      }
    },
    "DescribeOptionGroupOptions": {
      "input": {
        "type": "structure",
        "required": [
          "EngineName"
        ],
        "members": {
          "EngineName": {},
          "MajorEngineVersion": {},
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupOptionsResult",
        "type": "structure",
        "members": {
          "OptionGroupOptions": {
            "type": "list",
            "member": {
              "locationName": "OptionGroupOption",
              "type": "structure",
              "members": {
                "Name": {},
                "Description": {},
                "EngineName": {},
                "MajorEngineVersion": {},
                "MinimumRequiredMinorEngineVersion": {},
                "PortRequired": {
                  "type": "boolean"
                },
                "DefaultPort": {
                  "type": "integer"
                },
                "OptionsDependedOn": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionName"
                  }
                },
                "OptionsConflictsWith": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionConflictName"
                  }
                },
                "Persistent": {
                  "type": "boolean"
                },
                "Permanent": {
                  "type": "boolean"
                },
                "OptionGroupOptionSettings": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionGroupOptionSetting",
                    "type": "structure",
                    "members": {
                      "SettingName": {},
                      "SettingDescription": {},
                      "DefaultValue": {},
                      "ApplyType": {},
                      "AllowedValues": {},
                      "IsModifiable": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "OptionGroupOptionVersions": {
                  "type": "list",
                  "member": {
                    "locationName": "OptionVersion",
                    "type": "structure",
                    "members": {
                      "Version": {},
                      "IsDefault": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOptionGroups": {
      "input": {
        "type": "structure",
        "members": {
          "OptionGroupName": {},
          "Filters": {
            "shape": "S3f"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          },
          "EngineName": {},
          "MajorEngineVersion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOptionGroupsResult",
        "type": "structure",
        "members": {
          "OptionGroupsList": {
            "type": "list",
            "member": {
              "shape": "S17",
              "locationName": "OptionGroup"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeOrderableDBInstanceOptions": {
      "input": {
        "type": "structure",
        "required": [
          "Engine"
        ],
        "members": {
          "Engine": {},
          "EngineVersion": {},
          "DBInstanceClass": {},
          "LicenseModel": {},
          "Vpc": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeOrderableDBInstanceOptionsResult",
        "type": "structure",
        "members": {
          "OrderableDBInstanceOptions": {
            "type": "list",
            "member": {
              "locationName": "OrderableDBInstanceOption",
              "type": "structure",
              "members": {
                "Engine": {},
                "EngineVersion": {},
                "DBInstanceClass": {},
                "LicenseModel": {},
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "shape": "S25",
                    "locationName": "AvailabilityZone"
                  }
                },
                "MultiAZCapable": {
                  "type": "boolean"
                },
                "ReadReplicaCapable": {
                  "type": "boolean"
                },
                "Vpc": {
                  "type": "boolean"
                },
                "SupportsStorageEncryption": {
                  "type": "boolean"
                },
                "StorageType": {},
                "SupportsIops": {
                  "type": "boolean"
                },
                "SupportsEnhancedMonitoring": {
                  "type": "boolean"
                }
              },
              "wrapper": true
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribePendingMaintenanceActions": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceIdentifier": {},
          "Filters": {
            "shape": "S3f"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribePendingMaintenanceActionsResult",
        "type": "structure",
        "members": {
          "PendingMaintenanceActions": {
            "type": "list",
            "member": {
              "shape": "Se",
              "locationName": "ResourcePendingMaintenanceActions"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReservedDBInstances": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstanceId": {},
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstances": {
            "type": "list",
            "member": {
              "shape": "S6a",
              "locationName": "ReservedDBInstance"
            }
          }
        }
      }
    },
    "DescribeReservedDBInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "DBInstanceClass": {},
          "Duration": {},
          "ProductDescription": {},
          "OfferingType": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "S3f"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReservedDBInstancesOfferingsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "ReservedDBInstancesOfferings": {
            "type": "list",
            "member": {
              "locationName": "ReservedDBInstancesOffering",
              "type": "structure",
              "members": {
                "ReservedDBInstancesOfferingId": {},
                "DBInstanceClass": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ProductDescription": {},
                "OfferingType": {},
                "MultiAZ": {
                  "type": "boolean"
                },
                "RecurringCharges": {
                  "shape": "S6c"
                }
              },
              "wrapper": true
            }
          }
        }
      }
    },
    "DescribeSourceRegions": {
      "input": {
        "type": "structure",
        "members": {
          "RegionName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "Filters": {
            "shape": "S3f"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeSourceRegionsResult",
        "type": "structure",
        "members": {
          "Marker": {},
          "SourceRegions": {
            "type": "list",
            "member": {
              "locationName": "SourceRegion",
              "type": "structure",
              "members": {
                "RegionName": {},
                "Endpoint": {},
                "Status": {}
              }
            }
          }
        }
      }
    },
    "DownloadDBLogFilePortion": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "LogFileName"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "LogFileName": {},
          "Marker": {},
          "NumberOfLines": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DownloadDBLogFilePortionResult",
        "type": "structure",
        "members": {
          "LogFileData": {},
          "Marker": {},
          "AdditionalDataPending": {
            "type": "boolean"
          }
        }
      }
    },
    "FailoverDBCluster": {
      "input": {
        "type": "structure",
        "members": {
          "DBClusterIdentifier": {},
          "TargetDBInstanceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "FailoverDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName"
        ],
        "members": {
          "ResourceName": {},
          "Filters": {
            "shape": "S3f"
          }
        }
      },
      "output": {
        "resultWrapper": "ListTagsForResourceResult",
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "Sa"
          }
        }
      }
    },
    "ModifyDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "NewDBClusterIdentifier": {},
          "ApplyImmediately": {
            "type": "boolean"
          },
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "DBClusterParameterGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1h"
          },
          "Port": {
            "type": "integer"
          },
          "MasterUserPassword": {},
          "OptionGroupName": {},
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "ModifyDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "Parameters": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "shape": "S6v",
        "resultWrapper": "ModifyDBClusterParameterGroupResult"
      }
    },
    "ModifyDBClusterSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterSnapshotIdentifier",
          "AttributeName"
        ],
        "members": {
          "DBClusterSnapshotIdentifier": {},
          "AttributeName": {},
          "ValuesToAdd": {
            "shape": "S3y"
          },
          "ValuesToRemove": {
            "shape": "S3y"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBClusterSnapshotAttributeResult",
        "type": "structure",
        "members": {
          "DBClusterSnapshotAttributesResult": {
            "shape": "S3v"
          }
        }
      }
    },
    "ModifyDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "DBInstanceClass": {},
          "DBSubnetGroupName": {},
          "DBSecurityGroups": {
            "shape": "S1w"
          },
          "VpcSecurityGroupIds": {
            "shape": "S1h"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "MasterUserPassword": {},
          "DBParameterGroupName": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "NewDBInstanceIdentifier": {},
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "CACertificateIdentifier": {},
          "Domain": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "MonitoringInterval": {
            "type": "integer"
          },
          "DBPortNumber": {
            "type": "integer"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "MonitoringRoleArn": {},
          "DomainIAMRoleName": {},
          "PromotionTier": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "ModifyDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName",
          "Parameters"
        ],
        "members": {
          "DBParameterGroupName": {},
          "Parameters": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "shape": "S71",
        "resultWrapper": "ModifyDBParameterGroupResult"
      }
    },
    "ModifyDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "EngineVersion": {}
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBSnapshot": {
            "shape": "S13"
          }
        }
      }
    },
    "ModifyDBSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "DBSnapshotIdentifier",
          "AttributeName"
        ],
        "members": {
          "DBSnapshotIdentifier": {},
          "AttributeName": {},
          "ValuesToAdd": {
            "shape": "S3y"
          },
          "ValuesToRemove": {
            "shape": "S3y"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSnapshotAttributeResult",
        "type": "structure",
        "members": {
          "DBSnapshotAttributesResult": {
            "shape": "S4w"
          }
        }
      }
    },
    "ModifyDBSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBSubnetGroupName",
          "SubnetIds"
        ],
        "members": {
          "DBSubnetGroupName": {},
          "DBSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S2o"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyDBSubnetGroupResult",
        "type": "structure",
        "members": {
          "DBSubnetGroup": {
            "shape": "S22"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S7"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyEventSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S5"
          }
        }
      }
    },
    "ModifyOptionGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OptionGroupName"
        ],
        "members": {
          "OptionGroupName": {},
          "OptionsToInclude": {
            "type": "list",
            "member": {
              "locationName": "OptionConfiguration",
              "type": "structure",
              "required": [
                "OptionName"
              ],
              "members": {
                "OptionName": {},
                "Port": {
                  "type": "integer"
                },
                "OptionVersion": {},
                "DBSecurityGroupMemberships": {
                  "shape": "S1w"
                },
                "VpcSecurityGroupMemberships": {
                  "shape": "S1h"
                },
                "OptionSettings": {
                  "type": "list",
                  "member": {
                    "shape": "S1b",
                    "locationName": "OptionSetting"
                  }
                }
              }
            }
          },
          "OptionsToRemove": {
            "type": "list",
            "member": {}
          },
          "ApplyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "ModifyOptionGroupResult",
        "type": "structure",
        "members": {
          "OptionGroup": {
            "shape": "S17"
          }
        }
      }
    },
    "PromoteReadReplica": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "PreferredBackupWindow": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "PromoteReadReplicaDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "PromoteReadReplicaDBClusterResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "PurchaseReservedDBInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedDBInstancesOfferingId"
        ],
        "members": {
          "ReservedDBInstancesOfferingId": {},
          "ReservedDBInstanceId": {},
          "DBInstanceCount": {
            "type": "integer"
          },
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "PurchaseReservedDBInstancesOfferingResult",
        "type": "structure",
        "members": {
          "ReservedDBInstance": {
            "shape": "S6a"
          }
        }
      }
    },
    "RebootDBInstance": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "ForceFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "RebootDBInstanceResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "RemoveRoleFromDBCluster": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "RoleArn"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "RoleArn": {}
        }
      }
    },
    "RemoveSourceIdentifierFromSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SourceIdentifier"
        ],
        "members": {
          "SubscriptionName": {},
          "SourceIdentifier": {}
        }
      },
      "output": {
        "resultWrapper": "RemoveSourceIdentifierFromSubscriptionResult",
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S5"
          }
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceName",
          "TagKeys"
        ],
        "members": {
          "ResourceName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ResetDBClusterParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterParameterGroupName"
        ],
        "members": {
          "DBClusterParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "shape": "S6v",
        "resultWrapper": "ResetDBClusterParameterGroupResult"
      }
    },
    "ResetDBParameterGroup": {
      "input": {
        "type": "structure",
        "required": [
          "DBParameterGroupName"
        ],
        "members": {
          "DBParameterGroupName": {},
          "ResetAllParameters": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "shape": "S71",
        "resultWrapper": "ResetDBParameterGroupResult"
      }
    },
    "RestoreDBClusterFromS3": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "Engine",
          "MasterUsername",
          "MasterUserPassword",
          "SourceEngine",
          "SourceEngineVersion",
          "S3BucketName",
          "S3IngestionRoleArn"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "Sv"
          },
          "BackupRetentionPeriod": {
            "type": "integer"
          },
          "CharacterSetName": {},
          "DatabaseName": {},
          "DBClusterIdentifier": {},
          "DBClusterParameterGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1h"
          },
          "DBSubnetGroupName": {},
          "Engine": {},
          "EngineVersion": {},
          "Port": {
            "type": "integer"
          },
          "MasterUsername": {},
          "MasterUserPassword": {},
          "OptionGroupName": {},
          "PreferredBackupWindow": {},
          "PreferredMaintenanceWindow": {},
          "Tags": {
            "shape": "Sa"
          },
          "StorageEncrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "SourceEngine": {},
          "SourceEngineVersion": {},
          "S3BucketName": {},
          "S3Prefix": {},
          "S3IngestionRoleArn": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBClusterFromS3Result",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "RestoreDBClusterFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "SnapshotIdentifier",
          "Engine"
        ],
        "members": {
          "AvailabilityZones": {
            "shape": "Sv"
          },
          "DBClusterIdentifier": {},
          "SnapshotIdentifier": {},
          "Engine": {},
          "EngineVersion": {},
          "Port": {
            "type": "integer"
          },
          "DBSubnetGroupName": {},
          "DatabaseName": {},
          "OptionGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1h"
          },
          "Tags": {
            "shape": "Sa"
          },
          "KmsKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBClusterFromSnapshotResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "RestoreDBClusterToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "DBClusterIdentifier",
          "SourceDBClusterIdentifier"
        ],
        "members": {
          "DBClusterIdentifier": {},
          "SourceDBClusterIdentifier": {},
          "RestoreToTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "Port": {
            "type": "integer"
          },
          "DBSubnetGroupName": {},
          "OptionGroupName": {},
          "VpcSecurityGroupIds": {
            "shape": "S1h"
          },
          "Tags": {
            "shape": "Sa"
          },
          "KmsKeyId": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBClusterToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBCluster": {
            "shape": "S1j"
          }
        }
      }
    },
    "RestoreDBInstanceFromDBSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "DBInstanceIdentifier",
          "DBSnapshotIdentifier"
        ],
        "members": {
          "DBInstanceIdentifier": {},
          "DBSnapshotIdentifier": {},
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "Tags": {
            "shape": "Sa"
          },
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "Domain": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "DomainIAMRoleName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceFromDBSnapshotResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "RestoreDBInstanceToPointInTime": {
      "input": {
        "type": "structure",
        "required": [
          "SourceDBInstanceIdentifier",
          "TargetDBInstanceIdentifier"
        ],
        "members": {
          "SourceDBInstanceIdentifier": {},
          "TargetDBInstanceIdentifier": {},
          "RestoreTime": {
            "type": "timestamp"
          },
          "UseLatestRestorableTime": {
            "type": "boolean"
          },
          "DBInstanceClass": {},
          "Port": {
            "type": "integer"
          },
          "AvailabilityZone": {},
          "DBSubnetGroupName": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "LicenseModel": {},
          "DBName": {},
          "Engine": {},
          "Iops": {
            "type": "integer"
          },
          "OptionGroupName": {},
          "CopyTagsToSnapshot": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Sa"
          },
          "StorageType": {},
          "TdeCredentialArn": {},
          "TdeCredentialPassword": {},
          "Domain": {},
          "DomainIAMRoleName": {}
        }
      },
      "output": {
        "resultWrapper": "RestoreDBInstanceToPointInTimeResult",
        "type": "structure",
        "members": {
          "DBInstance": {
            "shape": "S1y"
          }
        }
      }
    },
    "RevokeDBSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "required": [
          "DBSecurityGroupName"
        ],
        "members": {
          "DBSecurityGroupName": {},
          "CIDRIP": {},
          "EC2SecurityGroupName": {},
          "EC2SecurityGroupId": {},
          "EC2SecurityGroupOwnerId": {}
        }
      },
      "output": {
        "resultWrapper": "RevokeDBSecurityGroupIngressResult",
        "type": "structure",
        "members": {
          "DBSecurityGroup": {
            "shape": "Sk"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S6"
        },
        "EventCategoriesList": {
          "shape": "S7"
        },
        "Enabled": {
          "type": "boolean"
        },
        "EventSubscriptionArn": {}
      },
      "wrapper": true
    },
    "S6": {
      "type": "list",
      "member": {
        "locationName": "SourceId"
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "locationName": "EventCategory"
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "locationName": "Tag",
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "ResourceIdentifier": {},
        "PendingMaintenanceActionDetails": {
          "type": "list",
          "member": {
            "locationName": "PendingMaintenanceAction",
            "type": "structure",
            "members": {
              "Action": {},
              "AutoAppliedAfterDate": {
                "type": "timestamp"
              },
              "ForcedApplyDate": {
                "type": "timestamp"
              },
              "OptInStatus": {},
              "CurrentApplyDate": {
                "type": "timestamp"
              },
              "Description": {}
            }
          }
        }
      },
      "wrapper": true
    },
    "Sk": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "DBSecurityGroupName": {},
        "DBSecurityGroupDescription": {},
        "VpcId": {},
        "EC2SecurityGroups": {
          "type": "list",
          "member": {
            "locationName": "EC2SecurityGroup",
            "type": "structure",
            "members": {
              "Status": {},
              "EC2SecurityGroupName": {},
              "EC2SecurityGroupId": {},
              "EC2SecurityGroupOwnerId": {}
            }
          }
        },
        "IPRanges": {
          "type": "list",
          "member": {
            "locationName": "IPRange",
            "type": "structure",
            "members": {
              "Status": {},
              "CIDRIP": {}
            }
          }
        },
        "DBSecurityGroupArn": {}
      },
      "wrapper": true
    },
    "Sr": {
      "type": "structure",
      "members": {
        "DBClusterParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {},
        "DBClusterParameterGroupArn": {}
      },
      "wrapper": true
    },
    "Su": {
      "type": "structure",
      "members": {
        "AvailabilityZones": {
          "shape": "Sv"
        },
        "DBClusterSnapshotIdentifier": {},
        "DBClusterIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "VpcId": {},
        "ClusterCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "PercentProgress": {
          "type": "integer"
        },
        "StorageEncrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DBClusterSnapshotArn": {}
      },
      "wrapper": true
    },
    "Sv": {
      "type": "list",
      "member": {
        "locationName": "AvailabilityZone"
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {},
        "DBParameterGroupFamily": {},
        "Description": {},
        "DBParameterGroupArn": {}
      },
      "wrapper": true
    },
    "S13": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBInstanceIdentifier": {},
        "SnapshotCreateTime": {
          "type": "timestamp"
        },
        "Engine": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "Status": {},
        "Port": {
          "type": "integer"
        },
        "AvailabilityZone": {},
        "VpcId": {},
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "MasterUsername": {},
        "EngineVersion": {},
        "LicenseModel": {},
        "SnapshotType": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupName": {},
        "PercentProgress": {
          "type": "integer"
        },
        "SourceRegion": {},
        "SourceDBSnapshotIdentifier": {},
        "StorageType": {},
        "TdeCredentialArn": {},
        "Encrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DBSnapshotArn": {},
        "Timezone": {}
      },
      "wrapper": true
    },
    "S17": {
      "type": "structure",
      "members": {
        "OptionGroupName": {},
        "OptionGroupDescription": {},
        "EngineName": {},
        "MajorEngineVersion": {},
        "Options": {
          "type": "list",
          "member": {
            "locationName": "Option",
            "type": "structure",
            "members": {
              "OptionName": {},
              "OptionDescription": {},
              "Persistent": {
                "type": "boolean"
              },
              "Permanent": {
                "type": "boolean"
              },
              "Port": {
                "type": "integer"
              },
              "OptionVersion": {},
              "OptionSettings": {
                "type": "list",
                "member": {
                  "shape": "S1b",
                  "locationName": "OptionSetting"
                }
              },
              "DBSecurityGroupMemberships": {
                "shape": "S1c"
              },
              "VpcSecurityGroupMemberships": {
                "shape": "S1e"
              }
            }
          }
        },
        "AllowsVpcAndNonVpcInstanceMemberships": {
          "type": "boolean"
        },
        "VpcId": {},
        "OptionGroupArn": {}
      },
      "wrapper": true
    },
    "S1b": {
      "type": "structure",
      "members": {
        "Name": {},
        "Value": {},
        "DefaultValue": {},
        "Description": {},
        "ApplyType": {},
        "DataType": {},
        "AllowedValues": {},
        "IsModifiable": {
          "type": "boolean"
        },
        "IsCollection": {
          "type": "boolean"
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroup",
        "type": "structure",
        "members": {
          "DBSecurityGroupName": {},
          "Status": {}
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupMembership",
        "type": "structure",
        "members": {
          "VpcSecurityGroupId": {},
          "Status": {}
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "locationName": "VpcSecurityGroupId"
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "AllocatedStorage": {
          "type": "integer"
        },
        "AvailabilityZones": {
          "shape": "Sv"
        },
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "CharacterSetName": {},
        "DatabaseName": {},
        "DBClusterIdentifier": {},
        "DBClusterParameterGroup": {},
        "DBSubnetGroup": {},
        "Status": {},
        "PercentProgress": {},
        "EarliestRestorableTime": {
          "type": "timestamp"
        },
        "Endpoint": {},
        "ReaderEndpoint": {},
        "MultiAZ": {
          "type": "boolean"
        },
        "Engine": {},
        "EngineVersion": {},
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "Port": {
          "type": "integer"
        },
        "MasterUsername": {},
        "DBClusterOptionGroupMemberships": {
          "type": "list",
          "member": {
            "locationName": "DBClusterOptionGroup",
            "type": "structure",
            "members": {
              "DBClusterOptionGroupName": {},
              "Status": {}
            }
          }
        },
        "PreferredBackupWindow": {},
        "PreferredMaintenanceWindow": {},
        "ReplicationSourceIdentifier": {},
        "ReadReplicaIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaIdentifier"
          }
        },
        "DBClusterMembers": {
          "type": "list",
          "member": {
            "locationName": "DBClusterMember",
            "type": "structure",
            "members": {
              "DBInstanceIdentifier": {},
              "IsClusterWriter": {
                "type": "boolean"
              },
              "DBClusterParameterGroupStatus": {},
              "PromotionTier": {
                "type": "integer"
              }
            },
            "wrapper": true
          }
        },
        "VpcSecurityGroups": {
          "shape": "S1e"
        },
        "HostedZoneId": {},
        "StorageEncrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DbClusterResourceId": {},
        "DBClusterArn": {},
        "AssociatedRoles": {
          "type": "list",
          "member": {
            "locationName": "DBClusterRole",
            "type": "structure",
            "members": {
              "RoleArn": {},
              "Status": {}
            }
          }
        },
        "ClusterCreateTime": {
          "type": "timestamp"
        }
      },
      "wrapper": true
    },
    "S1w": {
      "type": "list",
      "member": {
        "locationName": "DBSecurityGroupName"
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "DBInstanceIdentifier": {},
        "DBInstanceClass": {},
        "Engine": {},
        "DBInstanceStatus": {},
        "MasterUsername": {},
        "DBName": {},
        "Endpoint": {
          "type": "structure",
          "members": {
            "Address": {},
            "Port": {
              "type": "integer"
            },
            "HostedZoneId": {}
          }
        },
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "PreferredBackupWindow": {},
        "BackupRetentionPeriod": {
          "type": "integer"
        },
        "DBSecurityGroups": {
          "shape": "S1c"
        },
        "VpcSecurityGroups": {
          "shape": "S1e"
        },
        "DBParameterGroups": {
          "type": "list",
          "member": {
            "locationName": "DBParameterGroup",
            "type": "structure",
            "members": {
              "DBParameterGroupName": {},
              "ParameterApplyStatus": {}
            }
          }
        },
        "AvailabilityZone": {},
        "DBSubnetGroup": {
          "shape": "S22"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "DBInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MasterUserPassword": {},
            "Port": {
              "type": "integer"
            },
            "BackupRetentionPeriod": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {},
            "LicenseModel": {},
            "Iops": {
              "type": "integer"
            },
            "DBInstanceIdentifier": {},
            "StorageType": {},
            "CACertificateIdentifier": {},
            "DBSubnetGroupName": {}
          }
        },
        "LatestRestorableTime": {
          "type": "timestamp"
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "ReadReplicaSourceDBInstanceIdentifier": {},
        "ReadReplicaDBInstanceIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBInstanceIdentifier"
          }
        },
        "ReadReplicaDBClusterIdentifiers": {
          "type": "list",
          "member": {
            "locationName": "ReadReplicaDBClusterIdentifier"
          }
        },
        "LicenseModel": {},
        "Iops": {
          "type": "integer"
        },
        "OptionGroupMemberships": {
          "type": "list",
          "member": {
            "locationName": "OptionGroupMembership",
            "type": "structure",
            "members": {
              "OptionGroupName": {},
              "Status": {}
            }
          }
        },
        "CharacterSetName": {},
        "SecondaryAvailabilityZone": {},
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "StatusInfos": {
          "type": "list",
          "member": {
            "locationName": "DBInstanceStatusInfo",
            "type": "structure",
            "members": {
              "StatusType": {},
              "Normal": {
                "type": "boolean"
              },
              "Status": {},
              "Message": {}
            }
          }
        },
        "StorageType": {},
        "TdeCredentialArn": {},
        "DbInstancePort": {
          "type": "integer"
        },
        "DBClusterIdentifier": {},
        "StorageEncrypted": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "DbiResourceId": {},
        "CACertificateIdentifier": {},
        "DomainMemberships": {
          "type": "list",
          "member": {
            "locationName": "DomainMembership",
            "type": "structure",
            "members": {
              "Domain": {},
              "Status": {},
              "FQDN": {},
              "IAMRoleName": {}
            }
          }
        },
        "CopyTagsToSnapshot": {
          "type": "boolean"
        },
        "MonitoringInterval": {
          "type": "integer"
        },
        "EnhancedMonitoringResourceArn": {},
        "MonitoringRoleArn": {},
        "PromotionTier": {
          "type": "integer"
        },
        "DBInstanceArn": {},
        "Timezone": {}
      },
      "wrapper": true
    },
    "S22": {
      "type": "structure",
      "members": {
        "DBSubnetGroupName": {},
        "DBSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "locationName": "Subnet",
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "shape": "S25"
              },
              "SubnetStatus": {}
            }
          }
        },
        "DBSubnetGroupArn": {}
      },
      "wrapper": true
    },
    "S25": {
      "type": "structure",
      "members": {
        "Name": {}
      },
      "wrapper": true
    },
    "S2o": {
      "type": "list",
      "member": {
        "locationName": "SubnetIdentifier"
      }
    },
    "S3f": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "required": [
          "Name",
          "Values"
        ],
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {
              "locationName": "Value"
            }
          }
        }
      }
    },
    "S3q": {
      "type": "list",
      "member": {
        "locationName": "Parameter",
        "type": "structure",
        "members": {
          "ParameterName": {},
          "ParameterValue": {},
          "Description": {},
          "Source": {},
          "ApplyType": {},
          "DataType": {},
          "AllowedValues": {},
          "IsModifiable": {
            "type": "boolean"
          },
          "MinimumEngineVersion": {},
          "ApplyMethod": {}
        }
      }
    },
    "S3v": {
      "type": "structure",
      "members": {
        "DBClusterSnapshotIdentifier": {},
        "DBClusterSnapshotAttributes": {
          "type": "list",
          "member": {
            "locationName": "DBClusterSnapshotAttribute",
            "type": "structure",
            "members": {
              "AttributeName": {},
              "AttributeValues": {
                "shape": "S3y"
              }
            }
          }
        }
      },
      "wrapper": true
    },
    "S3y": {
      "type": "list",
      "member": {
        "locationName": "AttributeValue"
      }
    },
    "S49": {
      "type": "structure",
      "members": {
        "CharacterSetName": {},
        "CharacterSetDescription": {}
      }
    },
    "S4w": {
      "type": "structure",
      "members": {
        "DBSnapshotIdentifier": {},
        "DBSnapshotAttributes": {
          "type": "list",
          "member": {
            "locationName": "DBSnapshotAttribute",
            "type": "structure",
            "members": {
              "AttributeName": {},
              "AttributeValues": {
                "shape": "S3y"
              }
            },
            "wrapper": true
          }
        }
      },
      "wrapper": true
    },
    "S57": {
      "type": "structure",
      "members": {
        "DBParameterGroupFamily": {},
        "Marker": {},
        "Parameters": {
          "shape": "S3q"
        }
      },
      "wrapper": true
    },
    "S6a": {
      "type": "structure",
      "members": {
        "ReservedDBInstanceId": {},
        "ReservedDBInstancesOfferingId": {},
        "DBInstanceClass": {},
        "StartTime": {
          "type": "timestamp"
        },
        "Duration": {
          "type": "integer"
        },
        "FixedPrice": {
          "type": "double"
        },
        "UsagePrice": {
          "type": "double"
        },
        "CurrencyCode": {},
        "DBInstanceCount": {
          "type": "integer"
        },
        "ProductDescription": {},
        "OfferingType": {},
        "MultiAZ": {
          "type": "boolean"
        },
        "State": {},
        "RecurringCharges": {
          "shape": "S6c"
        },
        "ReservedDBInstanceArn": {}
      },
      "wrapper": true
    },
    "S6c": {
      "type": "list",
      "member": {
        "locationName": "RecurringCharge",
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        },
        "wrapper": true
      }
    },
    "S6v": {
      "type": "structure",
      "members": {
        "DBClusterParameterGroupName": {}
      }
    },
    "S71": {
      "type": "structure",
      "members": {
        "DBParameterGroupName": {}
      }
    }
  }
}