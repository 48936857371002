module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-04-13",
    "endpointPrefix": "codecommit",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CodeCommit",
    "serviceFullName": "AWS CodeCommit",
    "signatureVersion": "v4",
    "targetPrefix": "CodeCommit_20150413",
    "uid": "codecommit-2015-04-13"
  },
  "operations": {
    "BatchGetRepositories": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryNames"
        ],
        "members": {
          "repositoryNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "repositoriesNotFound": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "CreateBranch": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "branchName",
          "commitId"
        ],
        "members": {
          "repositoryName": {},
          "branchName": {},
          "commitId": {}
        }
      }
    },
    "CreateRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "repositoryDescription": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryMetadata": {
            "shape": "S6"
          }
        }
      }
    },
    "DeleteRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryId": {}
        }
      }
    },
    "GetBlob": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "blobId"
        ],
        "members": {
          "repositoryName": {},
          "blobId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "content"
        ],
        "members": {
          "content": {
            "type": "blob"
          }
        }
      }
    },
    "GetBranch": {
      "input": {
        "type": "structure",
        "members": {
          "repositoryName": {},
          "branchName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "branch": {
            "type": "structure",
            "members": {
              "branchName": {},
              "commitId": {}
            }
          }
        }
      }
    },
    "GetCommit": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "commitId"
        ],
        "members": {
          "repositoryName": {},
          "commitId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "commit"
        ],
        "members": {
          "commit": {
            "type": "structure",
            "members": {
              "treeId": {},
              "parents": {
                "type": "list",
                "member": {}
              },
              "message": {},
              "author": {
                "shape": "Sz"
              },
              "committer": {
                "shape": "Sz"
              },
              "additionalData": {}
            }
          }
        }
      }
    },
    "GetDifferences": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "afterCommitSpecifier"
        ],
        "members": {
          "repositoryName": {},
          "beforeCommitSpecifier": {},
          "afterCommitSpecifier": {},
          "beforePath": {},
          "afterPath": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "differences": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "beforeBlob": {
                  "shape": "S1c"
                },
                "afterBlob": {
                  "shape": "S1c"
                },
                "changeType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetRepository": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositoryMetadata": {
            "shape": "S6"
          }
        }
      }
    },
    "GetRepositoryTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configurationId": {},
          "triggers": {
            "shape": "S1k"
          }
        }
      }
    },
    "ListBranches": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "branches": {
            "shape": "S1o"
          },
          "nextToken": {}
        }
      }
    },
    "ListRepositories": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "sortBy": {},
          "order": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "repositories": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "repositoryName": {},
                "repositoryId": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "PutRepositoryTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "triggers"
        ],
        "members": {
          "repositoryName": {},
          "triggers": {
            "shape": "S1k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configurationId": {}
        }
      }
    },
    "TestRepositoryTriggers": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "triggers"
        ],
        "members": {
          "repositoryName": {},
          "triggers": {
            "shape": "S1k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "successfulExecutions": {
            "type": "list",
            "member": {}
          },
          "failedExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "trigger": {},
                "failureMessage": {}
              }
            }
          }
        }
      }
    },
    "UpdateDefaultBranch": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName",
          "defaultBranchName"
        ],
        "members": {
          "repositoryName": {},
          "defaultBranchName": {}
        }
      }
    },
    "UpdateRepositoryDescription": {
      "input": {
        "type": "structure",
        "required": [
          "repositoryName"
        ],
        "members": {
          "repositoryName": {},
          "repositoryDescription": {}
        }
      }
    },
    "UpdateRepositoryName": {
      "input": {
        "type": "structure",
        "required": [
          "oldName",
          "newName"
        ],
        "members": {
          "oldName": {},
          "newName": {}
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "accountId": {},
        "repositoryId": {},
        "repositoryName": {},
        "repositoryDescription": {},
        "defaultBranch": {},
        "lastModifiedDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        },
        "cloneUrlHttp": {},
        "cloneUrlSsh": {},
        "Arn": {}
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "name": {},
        "email": {},
        "date": {}
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "blobId": {},
        "path": {},
        "mode": {}
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "destinationArn",
          "events"
        ],
        "members": {
          "name": {},
          "destinationArn": {},
          "customData": {},
          "branches": {
            "shape": "S1o"
          },
          "events": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S1o": {
      "type": "list",
      "member": {}
    }
  }
}