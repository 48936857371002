module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "gamelift-2015-10-01",
    "apiVersion": "2015-10-01",
    "endpointPrefix": "gamelift",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon GameLift",
    "signatureVersion": "v4",
    "targetPrefix": "GameLift"
  },
  "operations": {
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoutingStrategy"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "RoutingStrategy": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "S9"
          }
        }
      }
    },
    "CreateBuild": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "StorageLocation": {
            "shape": "Sd"
          },
          "OperatingSystem": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "Sh"
          },
          "UploadCredentials": {
            "shape": "Sl"
          },
          "StorageLocation": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateFleet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "BuildId",
          "EC2InstanceType"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "BuildId": {},
          "ServerLaunchPath": {},
          "ServerLaunchParameters": {},
          "LogPaths": {
            "shape": "Sn"
          },
          "EC2InstanceType": {},
          "EC2InboundPermissions": {
            "shape": "Sp"
          },
          "NewGameSessionProtectionPolicy": {},
          "RuntimeConfiguration": {
            "shape": "Sv"
          },
          "ResourceCreationLimitPolicy": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetAttributes": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateGameSession": {
      "input": {
        "type": "structure",
        "required": [
          "MaximumPlayerSessionCount"
        ],
        "members": {
          "FleetId": {},
          "AliasId": {},
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "Name": {},
          "GameProperties": {
            "shape": "S15"
          },
          "CreatorId": {},
          "GameSessionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSession": {
            "shape": "S1b"
          }
        }
      }
    },
    "CreatePlayerSession": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId",
          "PlayerId"
        ],
        "members": {
          "GameSessionId": {},
          "PlayerId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSession": {
            "shape": "S1i"
          }
        }
      }
    },
    "CreatePlayerSessions": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId",
          "PlayerIds"
        ],
        "members": {
          "GameSessionId": {},
          "PlayerIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSessions": {
            "shape": "S1o"
          }
        }
      }
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      }
    },
    "DeleteBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      }
    },
    "DeleteFleet": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      }
    },
    "DeleteScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "FleetId"
        ],
        "members": {
          "Name": {},
          "FleetId": {}
        }
      }
    },
    "DescribeAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "Sh"
          }
        }
      }
    },
    "DescribeEC2InstanceLimits": {
      "input": {
        "type": "structure",
        "members": {
          "EC2InstanceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EC2InstanceLimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EC2InstanceType": {},
                "CurrentInstances": {
                  "type": "integer"
                },
                "InstanceLimit": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeFleetAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S22"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetAttributes": {
            "type": "list",
            "member": {
              "shape": "S12"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetCapacity": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S22"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetCapacity": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "InstanceType": {},
                "InstanceCounts": {
                  "type": "structure",
                  "members": {
                    "DESIRED": {
                      "type": "integer"
                    },
                    "MINIMUM": {
                      "type": "integer"
                    },
                    "MAXIMUM": {
                      "type": "integer"
                    },
                    "PENDING": {
                      "type": "integer"
                    },
                    "ACTIVE": {
                      "type": "integer"
                    },
                    "IDLE": {
                      "type": "integer"
                    },
                    "TERMINATING": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetEvents": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ResourceId": {},
                "EventCode": {},
                "Message": {},
                "EventTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetPortSettings": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InboundPermissions": {
            "shape": "Sp"
          }
        }
      }
    },
    "DescribeFleetUtilization": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S22"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetUtilization": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "ActiveServerProcessCount": {
                  "type": "integer"
                },
                "ActiveGameSessionCount": {
                  "type": "integer"
                },
                "CurrentPlayerSessionCount": {
                  "type": "integer"
                },
                "MaximumPlayerSessionCount": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameSessionDetails": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "GameSessionId": {},
          "AliasId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GameSession": {
                  "shape": "S1b"
                },
                "ProtectionPolicy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameSessions": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "GameSessionId": {},
          "AliasId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessions": {
            "shape": "S2r"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "InstanceId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "InstanceId": {},
                "IpAddress": {},
                "OperatingSystem": {},
                "Type": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePlayerSessions": {
      "input": {
        "type": "structure",
        "members": {
          "GameSessionId": {},
          "PlayerId": {},
          "PlayerSessionId": {},
          "PlayerSessionStatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSessions": {
            "shape": "S1o"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRuntimeConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuntimeConfiguration": {
            "shape": "Sv"
          }
        }
      }
    },
    "DescribeScalingPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "Name": {},
                "Status": {},
                "ScalingAdjustment": {
                  "type": "integer"
                },
                "ScalingAdjustmentType": {},
                "ComparisonOperator": {},
                "Threshold": {
                  "type": "double"
                },
                "EvaluationPeriods": {
                  "type": "integer"
                },
                "MetricName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetGameSessionLogUrl": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId"
        ],
        "members": {
          "GameSessionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PreSignedUrl": {}
        }
      }
    },
    "GetInstanceAccess": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "InstanceId"
        ],
        "members": {
          "FleetId": {},
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceAccess": {
            "type": "structure",
            "members": {
              "FleetId": {},
              "InstanceId": {},
              "IpAddress": {},
              "OperatingSystem": {},
              "Credentials": {
                "type": "structure",
                "members": {
                  "UserName": {},
                  "Secret": {}
                },
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "members": {
          "RoutingStrategyType": {},
          "Name": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListBuilds": {
      "input": {
        "type": "structure",
        "members": {
          "Status": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Builds": {
            "type": "list",
            "member": {
              "shape": "Sh"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFleets": {
      "input": {
        "type": "structure",
        "members": {
          "BuildId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S22"
          },
          "NextToken": {}
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "FleetId",
          "ScalingAdjustment",
          "ScalingAdjustmentType",
          "Threshold",
          "ComparisonOperator",
          "EvaluationPeriods",
          "MetricName"
        ],
        "members": {
          "Name": {},
          "FleetId": {},
          "ScalingAdjustment": {
            "type": "integer"
          },
          "ScalingAdjustmentType": {},
          "Threshold": {
            "type": "double"
          },
          "ComparisonOperator": {},
          "EvaluationPeriods": {
            "type": "integer"
          },
          "MetricName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "RequestUploadCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UploadCredentials": {
            "shape": "Sl"
          },
          "StorageLocation": {
            "shape": "Sd"
          }
        }
      }
    },
    "ResolveAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "SearchGameSessions": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "AliasId": {},
          "FilterExpression": {},
          "SortExpression": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessions": {
            "shape": "S2r"
          },
          "NextToken": {}
        }
      }
    },
    "UpdateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {},
          "Name": {},
          "Description": {},
          "RoutingStrategy": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "S9"
          }
        }
      }
    },
    "UpdateBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {},
          "Name": {},
          "Version": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "Sh"
          }
        }
      }
    },
    "UpdateFleetAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "Name": {},
          "Description": {},
          "NewGameSessionProtectionPolicy": {},
          "ResourceCreationLimitPolicy": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateFleetCapacity": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "DesiredInstances": {
            "type": "integer"
          },
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateFleetPortSettings": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "InboundPermissionAuthorizations": {
            "shape": "Sp"
          },
          "InboundPermissionRevocations": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateGameSession": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId"
        ],
        "members": {
          "GameSessionId": {},
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "Name": {},
          "PlayerSessionCreationPolicy": {},
          "ProtectionPolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSession": {
            "shape": "S1b"
          }
        }
      }
    },
    "UpdateRuntimeConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "RuntimeConfiguration"
        ],
        "members": {
          "FleetId": {},
          "RuntimeConfiguration": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuntimeConfiguration": {
            "shape": "Sv"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "Type": {},
        "FleetId": {},
        "Message": {}
      }
    },
    "S9": {
      "type": "structure",
      "members": {
        "AliasId": {},
        "Name": {},
        "Description": {},
        "RoutingStrategy": {
          "shape": "S4"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Key": {},
        "RoleArn": {}
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "BuildId": {},
        "Name": {},
        "Version": {},
        "Status": {},
        "SizeOnDisk": {
          "type": "long"
        },
        "OperatingSystem": {},
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "AccessKeyId": {},
        "SecretAccessKey": {},
        "SessionToken": {}
      },
      "sensitive": true
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FromPort",
          "ToPort",
          "IpRange",
          "Protocol"
        ],
        "members": {
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          },
          "IpRange": {},
          "Protocol": {}
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "ServerProcesses": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "LaunchPath",
              "ConcurrentExecutions"
            ],
            "members": {
              "LaunchPath": {},
              "Parameters": {},
              "ConcurrentExecutions": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "NewGameSessionsPerCreator": {
          "type": "integer"
        },
        "PolicyPeriodInMinutes": {
          "type": "integer"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "FleetId": {},
        "Description": {},
        "Name": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "Status": {},
        "BuildId": {},
        "ServerLaunchPath": {},
        "ServerLaunchParameters": {},
        "LogPaths": {
          "shape": "Sn"
        },
        "NewGameSessionProtectionPolicy": {},
        "OperatingSystem": {},
        "ResourceCreationLimitPolicy": {
          "shape": "Sz"
        }
      }
    },
    "S15": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "GameSessionId": {},
        "Name": {},
        "FleetId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "CurrentPlayerSessionCount": {
          "type": "integer"
        },
        "MaximumPlayerSessionCount": {
          "type": "integer"
        },
        "Status": {},
        "GameProperties": {
          "shape": "S15"
        },
        "IpAddress": {},
        "Port": {
          "type": "integer"
        },
        "PlayerSessionCreationPolicy": {},
        "CreatorId": {}
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "PlayerSessionId": {},
        "PlayerId": {},
        "GameSessionId": {},
        "FleetId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "Status": {},
        "IpAddress": {},
        "Port": {
          "type": "integer"
        }
      }
    },
    "S1o": {
      "type": "list",
      "member": {
        "shape": "S1i"
      }
    },
    "S22": {
      "type": "list",
      "member": {}
    },
    "S2r": {
      "type": "list",
      "member": {
        "shape": "S1b"
      }
    }
  }
}