import {ObjectSerializer} from '../spotdb/db';
import {SpotSerializableObject} from '../spotdb/base';
import {LocaleText} from '../spotdb/localeText';

export class ApiSerializer {

    static fromInteger(val:number, name:string) : Object {
        if (val === null || val === undefined) {
            return null;
        }
        return { [name]: val };
    }
    static setInteger(obj:any, setter: (val:number) => void) {
        if (obj !== null && obj !== undefined) {
            setter(Number.parseInt(obj.toString()));
        }
    }
    static fromFloat(val:number, name:string) : Object {
        if (val === null || val === undefined) {
            return null;
        }
        return { [name]: val };
    }
    static setFloat(obj:any, setter: (val:number) => void) {
        if (obj !== null && obj !== undefined) {
            setter(Number.parseFloat(obj.toString()));
        }
    }
    static fromBool(val:boolean, name:string) : Object {
        if (val === null || val === undefined) {
            return null;
        }
        return { [name]: val };
    }
    static setBool(obj:any, setter: (val:boolean) => void) {
        if (obj !== null && obj !== undefined) {
            setter(obj);
        }
    }
    static fromString(val:string, name:string) : Object {
        if (val === null || val === undefined || val.length === 0) {
            return null;
        }
        return { [name]: val };
    }
    static setString(obj:any, setter: (val:string) => void) {
        if (obj !== null && obj !== undefined) {
            setter(obj);
        }
    }
    static fromStringArray(val:string[], name:string) : Object {
        if (val === null || val === undefined || val.length === 0) {
            return null;
        }
        return { [name]: val };
    }
    static setStringArray(obj:any, setter: (val:string[]) => void) {
        if (obj !== null && obj !== undefined) {
            setter(obj);
        }
    }
    static fromDate(val:Date, name:string) : Object {
        if (val === null || val === undefined) {
            return null;
        }
        return { [name]: val.toISOString() };
    }
    static setDate(obj:any, setter: (val:Date) => void) {
        if (obj !== null && obj !== undefined) {
            setter(new Date(obj));
        }
    }
    static fromObject<T extends SpotSerializableObject>(val:T, name:string) : Object {
        if (val === null || val === undefined) {
            return null;
        }
        return { [name]: val.getAttrs(this) };
    }
    static setObject<T extends SpotSerializableObject>(obj:any, creator:ObjectSerializer<T>, setter: (val:T) => void) {
        if (obj !== null && obj !== undefined) {
            setter(creator.fromAttr(this, obj));
        }
    }
    static fromObjectArray<T extends SpotSerializableObject>(val:T[], name:string) : Object {
        if (val === null || val === undefined || val.length === 0) {
            return null;
        }
        return { [name]: val.map(s => s.getAttrs(this)) };
    }
    static setObjectArray<T extends SpotSerializableObject>(obj:any, creator:ObjectSerializer<T>, setter: (val:T[]) => void) {
        if (obj !== null && obj !== undefined) {
            setter(obj.map(o => creator.fromAttr(this, o)));
        }
    }
    static fromObjectMap<T extends SpotSerializableObject>(val:Map<string,T>, name:string) : Object {
        if (val === null || val === undefined || val.size === 0) {
            return null;
        }
        let m = {};
        for (let [k,v] of val) {
            m[k] = v.getAttrs(this);
        }
        return { [name]: m };
    }
    static setObjectMap<T extends SpotSerializableObject>(obj:any, creator:ObjectSerializer<T>, setter: (val:Map<string,T>) => void) {
        if (obj !== null && obj !== undefined) {
            let m = new Map();
            for (let k of Object.keys(obj)) {
                m.set(k, creator.fromAttr(this, obj[k]));
            }
            setter(m);
        }
    }
    static fromLocaleText(val:LocaleText | string, name:string) : Object {
        if (val === null || val === undefined) {
            return null;
        } else if (val instanceof LocaleText) {
            let a = {};
            for (let [key,value] of val._textMap) {
                a[key] = value;
            }
            return { [name]: a };
        } else if (typeof val === "string") {
            return { [name]: val };
        } else {
            throw new Error("requires a locale map or string.");
        }
    }
    static setLocaleText(obj:any, setter: (val:LocaleText | string) => void) {
        if (obj !== null && obj !== undefined) {
            if (typeof obj === "string") {
                setter(obj);
            } else if (typeof obj === "object") {
                setter(LocaleText.fromObject(obj));
            } else {
                throw new Error("unknown type: can't read locale map or string");
            }
        }
    }
}