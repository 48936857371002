
import {SpotDB,ListResult,AttributeSerializer,FilterExpression} from './db';
import {SpotBaseObject,SpotSerializableObject} from './base';
import {JurisdictionRef,Jurisdiction} from './jurisdiction';
import {LocaleText} from './localeText';

export type CustomType = "DayRange" | "MonthRange" | "TimeRange" | "TariffType";
export type AttributeType = CustomType | "DateTime" | "String" | "Number" | "Boolean" | "Array" | "Structure";

function attributeForType(type:AttributeType) : SignConfigAttribute {
    switch (type) {
        case "DateTime":
            return new SignConfigDateTimeAttribute();
        case "String":
            return new SignConfigStringAttribute();
        case "Number":
            return new SignConfigNumberAttribute();
        default:
            return new SignConfigAttribute();
    }
}

/** attribute definition for data stored in a SignConfig */
export class SignConfigAttribute extends SpotSerializableObject {
    /** Either a map of iso-defined locales to strings, or a single default string */
    description?: LocaleText | string;
    /** Defined type for attributes */
    type: AttributeType;
    /** If type is Array or Structure, this field provides the reference to the structured
     * defined in the SignConfig->structures
     */
    structure?: string;
    /** Is the attribute required to be provided */
    required?: boolean = false;
    /** Defines the order the fields should display in user interfaces */
    order?: number;
    /** Field can be set to only show based on a logic test of another field */
    showDependency?: string;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromLocaleText(this.description, "description"),
            ...s.fromString(this.type, "type"),
            ...s.fromString(this.structure, "structure"),
            ...s.fromBool(this.required, "required"),
            ...s.fromInteger(this.order, "order"),
            ...s.fromString(this.showDependency, "showDependency"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setLocaleText(a.description, (v:string|LocaleText) => this.description = v);
        s.setString(a.type, (v:AttributeType) => this.type = v);
        s.setString(a.structure, (v:string) => this.structure = v);
        s.setBool(a.required, (v:boolean) => this.required = v);
        s.setInteger(a.order, (v:number) => this.order = v);
        s.setString(a.showDependency, (v:string) => this.showDependency = v);
    }

    static fromAttr(s:AttributeSerializer, a:any) : SignConfigAttribute {
        let type:AttributeType;
        s.setString(a.type, (v:AttributeType) => type = v);

        let z = !type ? new SignConfigAttribute() : attributeForType(type);
        z.setAttrs(s, a);
        return z;
    }
}

export type NumberUnits = "Minutes" | "Hours";

/** A SignConfigAttribute of type Number */
export class SignConfigNumberAttribute extends SignConfigAttribute {
    constructor() { super(); this.type = "Number"; }
    /** The type of units for example: Minutes or Hours */
    unit?: NumberUnits;
    /** The minimum value allowed */
    minimum?: number;
    /** The maximum value allowed */
    maximum?: number;
    /** The number of units to step. e.g. stepSize of 2 means 2,4,6,8 */
    stepSize?: number;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromString(this.unit, "unit"),
            ...s.fromInteger(this.minimum, "minimum"),
            ...s.fromInteger(this.maximum, "maximum"),
            ...s.fromInteger(this.stepSize, "stepSize"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setString(a.unit, (v:NumberUnits) => this.unit = v);
        s.setInteger(a.minimum, v => this.minimum = v);
        s.setInteger(a.maximum, v => this.maximum = v);
        s.setInteger(a.stepSize, v => this.stepSize = v);
    }
}

/** A SignConfigAttribute of type DateTime */
export class SignConfigDateTimeAttribute extends SignConfigAttribute {
    constructor() { super(); this.type = "DateTime"; }
    /** Defines the date and time format based upon moment.js format specifications. */
    format?: string;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromString(this.format, "format"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setString(a.format, v => this.format = v);
    }
}

/** A SignConfigAttribute of type String */
export class SignConfigStringAttribute extends SignConfigAttribute {
    constructor() { super(); this.type = "String"; }
    /** minimum length of string allowed */
    minimumLength?: number;
    /** maximum length of string allowed */
    maximumLength?: number;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromInteger(this.minimumLength, "minimumLength"),
            ...s.fromInteger(this.maximumLength, "maximumLength"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setInteger(a.minimumLength, v => this.minimumLength = v);
        s.setInteger(a.maximumLength, v => this.maximumLength = v);
    }
}

function valueForType(type:AttributeType) : ConfigValue {
    switch (type) {
        case "DateTime":
            return new DateTimeValue();
        case "Number":
            return new NumberValue();
        case "String":
            return new StringValue();
        case "Boolean":
            return new BooleanValue();
        case "Array":
            return new ArrayValue();
        case "Structure":
            return new StructureValue();
        case "DayRange":
            return new DayRangeValue();
        case "MonthRange":
            return new MonthRangeValue();
        case "TimeRange":
            return new TimeRangeValue();
        case "TariffType":
            return new TariffTypeValue();
        default:
            throw new Error(`Unable to create a ConfigValue of unknown type '${type}'.`);
    }
}

/** base class representing a specific ConfigValue */
export class ConfigValue extends SpotSerializableObject {
    type: AttributeType;

    getAttrs(s:AttributeSerializer) : any {
        return s.fromString(this.type, "type");
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.type, (v:AttributeType) => this.type = v);
    }

    static fromAttr(s:AttributeSerializer, a:any) : ConfigValue {
        let type:AttributeType;
        s.setString(a.type, (v:AttributeType) => type = v);

        let z = !type ? new ConfigValue() : valueForType(type);
        z.setAttrs(s, a);
        return z;
    }
}

/** ConfigValue of type DateTime */
export class DateTimeValue extends ConfigValue {
    constructor() { super(); this.type = "DateTime"; }
    /** specific date value */
    value?: Date;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromDate(this.value, "value"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setDate(a.value, v => this.value = v);
    }
}

/** ConfigValue of type Boolean */
export class BooleanValue extends ConfigValue {
    constructor() { super(); this.type = "Boolean"; }
    /** specific boolean value */
    value?: boolean;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromBool(this.value, "value"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setBool(a.value, v => this.value = v);
    }
}

/** ConfigValue of type Array (list of config values) */
export class ArrayValue extends ConfigValue {
    constructor() { super(); this.type = "Array"; }
    /** list of specific config values */
    values: ConfigValue[] = [];

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromObjectArray(this.values, "values"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setObjectArray(a.values, ConfigValue, v => this.values = v);
    }
}

/** ConfigValue of type Structure (map of config values) */
export class StructureValue extends ConfigValue {
    constructor() { super(); this.type = "Structure"; }
    /** map (string => value) of specific config values */
    fields: Map<string,ConfigValue> = new Map();

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromObjectMap(this.fields, "fields"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setObjectMap(a.fields, ConfigValue, v => this.fields = v);
    }
}

/** ConfigValue of type Number */
export class NumberValue extends ConfigValue {
    constructor() { super(); this.type = "Number"; }
    /** specific number value */
    value?: number;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromFloat(this.value, "value"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setFloat(a.value, v => this.value = v);
    }
}

/** ConfigValue of type String */
export class StringValue extends ConfigValue {
    constructor() { super(); this.type = "String"; }
    /** specific string value */
    value?: string;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromString(this.value, "value"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setString(a.value, v => this.value = v);
    }
}

export type WeekDay = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

/** ConfigValue of type String */
export class WeekDayOfMonth extends SpotSerializableObject {

    dayNumber: number;
    weekDay: WeekDay;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromInteger(this.dayNumber, "dayNumber"),
            ...s.fromString(this.weekDay, "weekDay"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setInteger(a.dayNumber, v => this.dayNumber = v);
        s.setString(a.weekDay, (v:WeekDay) => this.weekDay = v);
    }

    static fromAttr(s:AttributeSerializer, a:any) : WeekDayOfMonth {
        let z = new WeekDayOfMonth();
        z.setAttrs(s, a);
        return z;
    }

}

/** ConfigValue of type DayRange */
export class DayRangeValue extends ConfigValue {
    constructor() { super(); this.type = "DayRange"; }
    /** DayRange type specifier */
    subtype?: "ALL_DAYS" | "WEEKDAY_OF_WEEK" | "WEEKDAY_OF_MONTH";
    /** Individual days of week,month,etc... */
    weekDay?: WeekDay[];                /** For example: every Monday, Tuesday and Thursday */
    weekDayOfMonth?: WeekDayOfMonth[];  /** For example: 1st and 3rd Wed of each month */

    specialInclusions?: string; /** Holidays, etc... */
    specialExclusions?: string; /** Holidays, etc... */

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromString(this.subtype, "subtype"),
            ...s.fromStringArray(this.weekDay, "weekDay"),
            ...s.fromObjectArray(this.weekDayOfMonth, "weekDayOfMonth"),
            ...s.fromString(this.specialInclusions, "specialInclusions"),
            ...s.fromString(this.specialExclusions, "specialExclusions"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setString(a.subtype, v => this.subtype = v as any);
        s.setStringArray(a.weekDay, (v:WeekDay[]) => this.weekDay = v);
        s.setObjectArray(a.weekDayOfMonth, WeekDayOfMonth, (v:WeekDayOfMonth[]) => this.weekDayOfMonth = v);
        s.setString(a.specialInclusions, v => this.specialInclusions = v);
        s.setString(a.specialExclusions, v => this.specialExclusions = v);
    }
}

export type MonthRangeSubtype = "ALL_MONTHS" | "MONTHS_OF_YEAR";
export type MonthValue = 'January' | 'February' | 'March' | 'April' | 'May' | 'June' | 'July' | 'August' | 'September' | 'October' | 'November' | 'December';

/** ConfigValue of type MonthRange */
export class MonthRangeValue extends ConfigValue {
    constructor() { super(); this.type = "MonthRange"; }
    /** MonthRange type specifier */
    subtype?: MonthRangeSubtype;
    /** Individual months of year,etc... */
    months?: MonthValue[];

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromString(this.subtype, "subtype"),
            ...s.fromStringArray(this.months, "months"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setString(a.subtype, v => this.subtype = v as any);
        s.setStringArray(a.months, (v:MonthValue[]) => this.months = v);
    }
}

/** ConfigValue of type TimeRange */
export class TimeRangeValue extends ConfigValue {
    constructor() { super(); this.type = "TimeRange"; }
    /** TimeRange type specifier */
    subtype?: "STANDARD_RANGE";
    /** start of time range in minutes from midnight */
    startTime?: number;
    /** endTime of time range in minutes from midnight */
    endTime?: number;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromString(this.subtype, "subtype"),
            ...s.fromInteger(this.startTime, "startTime"),
            ...s.fromInteger(this.endTime, "endTime"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setString(a.subtype, v => this.subtype = v as any);
        s.setInteger(a.startTime, v => this.startTime = v);
        s.setInteger(a.endTime, v => this.endTime = v);
    }
}

/** ConfigValue of type TariffType */
export class TariffTypeValue extends ConfigValue {
    constructor() { super(); this.type = "TariffType"; }
    subtype?: "PRO_RATED";
    minimumChargeUnit?: number;
    chargeInterval?: number;
    displayCharge?: number;
    displayChargeUnitSize?: number;
    currency?: string;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...super.getAttrs(s),
            ...s.fromString(this.subtype, "subtype"),
            ...s.fromInteger(this.minimumChargeUnit, "minimumChargeUnit"),
            ...s.fromInteger(this.chargeInterval, "chargeInterval"),
            ...s.fromFloat(this.displayCharge, "displayCharge"),
            ...s.fromInteger(this.displayChargeUnitSize, "displayChargeUnitSize"),
            ...s.fromString(this.currency, "currency"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        super.setAttrs(s, a);
        s.setString(a.subtype, v => this.subtype = v as any);
        s.setInteger(a.minimumChargeUnit, v => this.minimumChargeUnit = v);
        s.setInteger(a.chargeInterval, v => this.chargeInterval = v);
        s.setFloat(a.displayCharge, v => this.displayCharge = v);
        s.setInteger(a.displayChargeUnitSize, v => this.displayChargeUnitSize = v);
        s.setString(a.currency, v => this.currency = v);
    }
}

/** Defined mapping of strings to SignConfigAttributes */
export class SignConfigStructure extends SpotSerializableObject {
    description?: LocaleText | string;
    /** map of strings to SignConfig attribute definitions */
    fields: Map<string,SignConfigAttribute> = new Map();

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromLocaleText(this.description, "description"),
            ...s.fromObjectMap(this.fields, "fields"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setLocaleText(a.description, (v:string|LocaleText) => this.description = v);
        s.setObjectMap(a.fields, SignConfigAttribute, v => this.fields = v);
    }

    static fromAttr(s:AttributeSerializer, a:any) : SignConfigStructure {
        let z = new SignConfigStructure();
        z.setAttrs(s, a);
        return z;
    }
}

/** SignConfig data defines the type of signs available within a jurisdiction.  You can
 * define SignConfigs at the national, state or city level.  It defines what attributes
 * comprise the sign, whether they are required  to be defined or not and the associated
 * scripts (algorithms) that can be run to validate or process sign info for multiple purposes.
 *
 * Signconfig defines both the data required and the associated algorithms necessary to
 * process a particular instance of a sign.
 *
 * A Signconfig has a reference to a jurisdiction – therefore a SignConfiguration
 * can be defined at a city, state or national level.
 *
 * A Signconfig with the same category in any child jurisdiction will override the
 * previous ones defined in the hierarchy.
 *
 * Eg. If a NO_PARKING signconfig was defined at the national level (eg. USA), and a
 * NO_PARKING signconfig defined at the city level (eg. Denver) then the Denver definition
 * of a NO_PARKING sign would override the national one.
 *
 * This allows the majority of signs to be defined at a state or national level whilst
 * considering any specific jurisdiction logic that may be needed in particular localities.
 */
export class SignConfig extends SpotBaseObject {
    static TableName = 'signconfigs';

    constructor(db:SpotDB) {
        super(db, SignConfig.TableName);
    }

    /** the Jurisdiction to which this configuration applies */
    jurisdiction?: JurisdictionRef;
    /** Sign type.  Must be unique within a jurisdiction eg. RESTRICTED_PARKING,
     * NO_STOPPING, DISABLED_PERMIT_ONLY
    */
    category: string;
    /** Dictionary containing display friendly names, with ISO-defined locale as the key. */
    description?: LocaleText;
    /** Configuration attributes defined on this configuration */
    attributes: Map<string, SignConfigAttribute> = new Map();
    /** Structure definitions referenced by configuration attributes. */
    structures: Map<string, SignConfigStructure> = new Map();
    /** Configuration scripts */
    processingScripts: string[] = [];

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromString(this.id, "id"),
            ...s.fromString(this.jurisdiction && this.jurisdiction.id, "jurisdiction"),
            ...s.fromString(this.category, "category"),
            ...s.fromLocaleText(this.description, "description"),
            ...s.fromObjectMap(this.attributes, "attributes"),
            ...s.fromObjectMap(this.structures, "structures"),
            ...s.fromStringArray(this.processingScripts, "processingScripts"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.id, v => this.id = v );
        s.setString(a.jurisdiction, v => this.jurisdiction = new JurisdictionRef(v) );
        s.setString(a.category, v => this.category = v );
        s.setLocaleText(a.description, v => this.description = v as LocaleText );
        s.setObjectMap(a.attributes, SignConfigAttribute, v => this.attributes = v);
        s.setObjectMap(a.structures, SignConfigStructure, v => this.structures = v);
        s.setStringArray(a.processingScripts, v => this.processingScripts = v);
    }

    static fromAttrs(db:SpotDB, a:any) : SignConfig {
        let z = new SignConfig(db);
        z.setAttrs(db.serializer, a);
        return z;
    }

    static async getCategories(db:SpotDB, jurisdictionId:string) : Promise<string[]> {
        let pxy = db.proxy;
        if (!!pxy) {
            return await pxy.proxyGetCategories(jurisdictionId);
        }
        let categories:string[] = [];
        let j = await Jurisdiction.get(db, jurisdictionId);
        while (!!j) {
            let signConfigs = await SignConfig.listByJurisdiction(db, j.id);
            categories.push(...signConfigs.items.map(sc => sc.category));
            j = await j.parent;
        }
        return categories;
    }

    static async getByJurisdiction(db:SpotDB, jurisdictionId:string, category:string) : Promise<SignConfig> {
        let pxy = db.proxy;
        if (!!pxy) {
            return await pxy.proxyGetSignConfigByJurisdiction(jurisdictionId, category);
        }
        let j = await Jurisdiction.get(db, jurisdictionId);
        while (!!j) {
            let signConfigs = await SignConfig.listByJurisdiction(db, j.id);
            for (let sc of signConfigs.items) {
                if (sc.category === category) {
                    return sc;
                }
            }
            j = await j.parent;
        }
        return null;
    }

    /** Load a single SignConfig instance from the database */
    static async get(db:SpotDB, id:string) : Promise<SignConfig> {
        let data = await db.get(this.TableName, id);
        if (!data) {
            return null;
        }
        let j = new this(db);
        j.setAttrs(db.serializer, data);
        return j;
    }

    /** Returns the ids of SignConfigs that exist in the database. */
    static listIds(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<string>> {
        return db.listIds(this.TableName, startAfter, limit);
    }

    /** Returns the SignConfig instances that exist in the database. */
    static list(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<SignConfig>> {
        return db.list(this.TableName, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => SignConfig.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    static listWithFilter(db:SpotDB, filter:FilterExpression, startAfter?:string, limit?: number) : Promise<ListResult<SignConfig>> {
        return db.listWithFilter(this.TableName, filter, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => SignConfig.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Returns the SignConfig instances that exist in the database looked up by jurisdiction. */
    static listByJurisdiction(db:SpotDB, jurisdictionId:string, startAfter?:string, limit?: number) : Promise<ListResult<SignConfig>> {
        return this.listByIndex(db, 'jurisdiction-index', 'jurisdiction', jurisdictionId, startAfter, limit);
    }

    static listByIndex(db:SpotDB, indexName:string, attributeName:string, value:string, startAfter?:string, limit?: number) : Promise<ListResult<SignConfig>> {
        return db.listByIndex(this.TableName, indexName, attributeName, value, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => this.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Delete a group of SignConfig instances from the database. */
    static bulkDelete(db:SpotDB, ids:string[]) : Promise<void> {
        return db.batchDelete(this.TableName, ids);
    }
}