module.exports={
  "pagination": {
    "DescribeApplicationVersions": {
      "result_key": "ApplicationVersions"
    },
    "DescribeApplications": {
      "result_key": "Applications"
    },
    "DescribeConfigurationOptions": {
      "result_key": "Options"
    },
    "DescribeEnvironments": {
      "result_key": "Environments"
    },
    "DescribeEvents": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxRecords",
      "result_key": "Events"
    },
    "ListAvailableSolutionStacks": {
      "result_key": "SolutionStacks"
    }
  }
}
