module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "email-2010-12-01",
    "apiVersion": "2010-12-01",
    "endpointPrefix": "email",
    "protocol": "query",
    "serviceAbbreviation": "Amazon SES",
    "serviceFullName": "Amazon Simple Email Service",
    "signatureVersion": "v4",
    "signingName": "ses",
    "xmlNamespace": "http://ses.amazonaws.com/doc/2010-12-01/"
  },
  "operations": {
    "CloneReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "OriginalRuleSetName"
        ],
        "members": {
          "RuleSetName": {},
          "OriginalRuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "CloneReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateConfigurationSet": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSet"
        ],
        "members": {
          "ConfigurationSet": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateConfigurationSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateConfigurationSetEventDestination": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "EventDestination"
        ],
        "members": {
          "ConfigurationSetName": {},
          "EventDestination": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateConfigurationSetEventDestinationResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateReceiptFilter": {
      "input": {
        "type": "structure",
        "required": [
          "Filter"
        ],
        "members": {
          "Filter": {
            "shape": "So"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateReceiptFilterResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "Rule"
        ],
        "members": {
          "RuleSetName": {},
          "After": {},
          "Rule": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateReceiptRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName"
        ],
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "CreateReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConfigurationSet": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName"
        ],
        "members": {
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteConfigurationSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteConfigurationSetEventDestination": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "EventDestinationName"
        ],
        "members": {
          "ConfigurationSetName": {},
          "EventDestinationName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteConfigurationSetEventDestinationResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "Identity"
        ],
        "members": {
          "Identity": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteIdentityResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteIdentityPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "PolicyName"
        ],
        "members": {
          "Identity": {},
          "PolicyName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteIdentityPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReceiptFilter": {
      "input": {
        "type": "structure",
        "required": [
          "FilterName"
        ],
        "members": {
          "FilterName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReceiptFilterResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleName"
        ],
        "members": {
          "RuleSetName": {},
          "RuleName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReceiptRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName"
        ],
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteVerifiedEmailAddress": {
      "input": {
        "type": "structure",
        "required": [
          "EmailAddress"
        ],
        "members": {
          "EmailAddress": {}
        }
      }
    },
    "DescribeActiveReceiptRuleSet": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "DescribeActiveReceiptRuleSetResult",
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S26"
          },
          "Rules": {
            "shape": "S28"
          }
        }
      }
    },
    "DescribeConfigurationSet": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName"
        ],
        "members": {
          "ConfigurationSetName": {},
          "ConfigurationSetAttributeNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeConfigurationSetResult",
        "type": "structure",
        "members": {
          "ConfigurationSet": {
            "shape": "S5"
          },
          "EventDestinations": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          }
        }
      }
    },
    "DescribeReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleName"
        ],
        "members": {
          "RuleSetName": {},
          "RuleName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReceiptRuleResult",
        "type": "structure",
        "members": {
          "Rule": {
            "shape": "Sw"
          }
        }
      }
    },
    "DescribeReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName"
        ],
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeReceiptRuleSetResult",
        "type": "structure",
        "members": {
          "Metadata": {
            "shape": "S26"
          },
          "Rules": {
            "shape": "S28"
          }
        }
      }
    },
    "GetIdentityDkimAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S2j"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityDkimAttributesResult",
        "type": "structure",
        "required": [
          "DkimAttributes"
        ],
        "members": {
          "DkimAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "DkimEnabled",
                "DkimVerificationStatus"
              ],
              "members": {
                "DkimEnabled": {
                  "type": "boolean"
                },
                "DkimVerificationStatus": {},
                "DkimTokens": {
                  "shape": "S2o"
                }
              }
            }
          }
        }
      }
    },
    "GetIdentityMailFromDomainAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S2j"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityMailFromDomainAttributesResult",
        "type": "structure",
        "required": [
          "MailFromDomainAttributes"
        ],
        "members": {
          "MailFromDomainAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "MailFromDomain",
                "MailFromDomainStatus",
                "BehaviorOnMXFailure"
              ],
              "members": {
                "MailFromDomain": {},
                "MailFromDomainStatus": {},
                "BehaviorOnMXFailure": {}
              }
            }
          }
        }
      }
    },
    "GetIdentityNotificationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S2j"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityNotificationAttributesResult",
        "type": "structure",
        "required": [
          "NotificationAttributes"
        ],
        "members": {
          "NotificationAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "BounceTopic",
                "ComplaintTopic",
                "DeliveryTopic",
                "ForwardingEnabled"
              ],
              "members": {
                "BounceTopic": {},
                "ComplaintTopic": {},
                "DeliveryTopic": {},
                "ForwardingEnabled": {
                  "type": "boolean"
                },
                "HeadersInBounceNotificationsEnabled": {
                  "type": "boolean"
                },
                "HeadersInComplaintNotificationsEnabled": {
                  "type": "boolean"
                },
                "HeadersInDeliveryNotificationsEnabled": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "GetIdentityPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "PolicyNames"
        ],
        "members": {
          "Identity": {},
          "PolicyNames": {
            "shape": "S33"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityPoliciesResult",
        "type": "structure",
        "required": [
          "Policies"
        ],
        "members": {
          "Policies": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "GetIdentityVerificationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S2j"
          }
        }
      },
      "output": {
        "resultWrapper": "GetIdentityVerificationAttributesResult",
        "type": "structure",
        "required": [
          "VerificationAttributes"
        ],
        "members": {
          "VerificationAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "VerificationStatus"
              ],
              "members": {
                "VerificationStatus": {},
                "VerificationToken": {}
              }
            }
          }
        }
      }
    },
    "GetSendQuota": {
      "output": {
        "resultWrapper": "GetSendQuotaResult",
        "type": "structure",
        "members": {
          "Max24HourSend": {
            "type": "double"
          },
          "MaxSendRate": {
            "type": "double"
          },
          "SentLast24Hours": {
            "type": "double"
          }
        }
      }
    },
    "GetSendStatistics": {
      "output": {
        "resultWrapper": "GetSendStatisticsResult",
        "type": "structure",
        "members": {
          "SendDataPoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "DeliveryAttempts": {
                  "type": "long"
                },
                "Bounces": {
                  "type": "long"
                },
                "Complaints": {
                  "type": "long"
                },
                "Rejects": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "ListConfigurationSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListConfigurationSetsResult",
        "type": "structure",
        "members": {
          "ConfigurationSets": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentities": {
      "input": {
        "type": "structure",
        "members": {
          "IdentityType": {},
          "NextToken": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListIdentitiesResult",
        "type": "structure",
        "required": [
          "Identities"
        ],
        "members": {
          "Identities": {
            "shape": "S2j"
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentityPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "Identity"
        ],
        "members": {
          "Identity": {}
        }
      },
      "output": {
        "resultWrapper": "ListIdentityPoliciesResult",
        "type": "structure",
        "required": [
          "PolicyNames"
        ],
        "members": {
          "PolicyNames": {
            "shape": "S33"
          }
        }
      }
    },
    "ListReceiptFilters": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "resultWrapper": "ListReceiptFiltersResult",
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          }
        }
      }
    },
    "ListReceiptRuleSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListReceiptRuleSetsResult",
        "type": "structure",
        "members": {
          "RuleSets": {
            "type": "list",
            "member": {
              "shape": "S26"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListVerifiedEmailAddresses": {
      "output": {
        "resultWrapper": "ListVerifiedEmailAddressesResult",
        "type": "structure",
        "members": {
          "VerifiedEmailAddresses": {
            "shape": "S40"
          }
        }
      }
    },
    "PutIdentityPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "PolicyName",
          "Policy"
        ],
        "members": {
          "Identity": {},
          "PolicyName": {},
          "Policy": {}
        }
      },
      "output": {
        "resultWrapper": "PutIdentityPolicyResult",
        "type": "structure",
        "members": {}
      }
    },
    "ReorderReceiptRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleNames"
        ],
        "members": {
          "RuleSetName": {},
          "RuleNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ReorderReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "SendBounce": {
      "input": {
        "type": "structure",
        "required": [
          "OriginalMessageId",
          "BounceSender",
          "BouncedRecipientInfoList"
        ],
        "members": {
          "OriginalMessageId": {},
          "BounceSender": {},
          "Explanation": {},
          "MessageDsn": {
            "type": "structure",
            "required": [
              "ReportingMta"
            ],
            "members": {
              "ReportingMta": {},
              "ArrivalDate": {
                "type": "timestamp"
              },
              "ExtensionFields": {
                "shape": "S4c"
              }
            }
          },
          "BouncedRecipientInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Recipient"
              ],
              "members": {
                "Recipient": {},
                "RecipientArn": {},
                "BounceType": {},
                "RecipientDsnFields": {
                  "type": "structure",
                  "required": [
                    "Action",
                    "Status"
                  ],
                  "members": {
                    "FinalRecipient": {},
                    "Action": {},
                    "RemoteMta": {},
                    "Status": {},
                    "DiagnosticCode": {},
                    "LastAttemptDate": {
                      "type": "timestamp"
                    },
                    "ExtensionFields": {
                      "shape": "S4c"
                    }
                  }
                }
              }
            }
          },
          "BounceSenderArn": {}
        }
      },
      "output": {
        "resultWrapper": "SendBounceResult",
        "type": "structure",
        "members": {
          "MessageId": {}
        }
      }
    },
    "SendEmail": {
      "input": {
        "type": "structure",
        "required": [
          "Source",
          "Destination",
          "Message"
        ],
        "members": {
          "Source": {},
          "Destination": {
            "type": "structure",
            "members": {
              "ToAddresses": {
                "shape": "S40"
              },
              "CcAddresses": {
                "shape": "S40"
              },
              "BccAddresses": {
                "shape": "S40"
              }
            }
          },
          "Message": {
            "type": "structure",
            "required": [
              "Subject",
              "Body"
            ],
            "members": {
              "Subject": {
                "shape": "S4t"
              },
              "Body": {
                "type": "structure",
                "members": {
                  "Text": {
                    "shape": "S4t"
                  },
                  "Html": {
                    "shape": "S4t"
                  }
                }
              }
            }
          },
          "ReplyToAddresses": {
            "shape": "S40"
          },
          "ReturnPath": {},
          "SourceArn": {},
          "ReturnPathArn": {},
          "Tags": {
            "shape": "S4x"
          },
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "SendEmailResult",
        "type": "structure",
        "required": [
          "MessageId"
        ],
        "members": {
          "MessageId": {}
        }
      }
    },
    "SendRawEmail": {
      "input": {
        "type": "structure",
        "required": [
          "RawMessage"
        ],
        "members": {
          "Source": {},
          "Destinations": {
            "shape": "S40"
          },
          "RawMessage": {
            "type": "structure",
            "required": [
              "Data"
            ],
            "members": {
              "Data": {
                "type": "blob"
              }
            }
          },
          "FromArn": {},
          "SourceArn": {},
          "ReturnPathArn": {},
          "Tags": {
            "shape": "S4x"
          },
          "ConfigurationSetName": {}
        }
      },
      "output": {
        "resultWrapper": "SendRawEmailResult",
        "type": "structure",
        "required": [
          "MessageId"
        ],
        "members": {
          "MessageId": {}
        }
      }
    },
    "SetActiveReceiptRuleSet": {
      "input": {
        "type": "structure",
        "members": {
          "RuleSetName": {}
        }
      },
      "output": {
        "resultWrapper": "SetActiveReceiptRuleSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityDkimEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "DkimEnabled"
        ],
        "members": {
          "Identity": {},
          "DkimEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetIdentityDkimEnabledResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityFeedbackForwardingEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "ForwardingEnabled"
        ],
        "members": {
          "Identity": {},
          "ForwardingEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetIdentityFeedbackForwardingEnabledResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityHeadersInNotificationsEnabled": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "NotificationType",
          "Enabled"
        ],
        "members": {
          "Identity": {},
          "NotificationType": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetIdentityHeadersInNotificationsEnabledResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityMailFromDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Identity"
        ],
        "members": {
          "Identity": {},
          "MailFromDomain": {},
          "BehaviorOnMXFailure": {}
        }
      },
      "output": {
        "resultWrapper": "SetIdentityMailFromDomainResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetIdentityNotificationTopic": {
      "input": {
        "type": "structure",
        "required": [
          "Identity",
          "NotificationType"
        ],
        "members": {
          "Identity": {},
          "NotificationType": {},
          "SnsTopic": {}
        }
      },
      "output": {
        "resultWrapper": "SetIdentityNotificationTopicResult",
        "type": "structure",
        "members": {}
      }
    },
    "SetReceiptRulePosition": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "RuleName"
        ],
        "members": {
          "RuleSetName": {},
          "RuleName": {},
          "After": {}
        }
      },
      "output": {
        "resultWrapper": "SetReceiptRulePositionResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateConfigurationSetEventDestination": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationSetName",
          "EventDestination"
        ],
        "members": {
          "ConfigurationSetName": {},
          "EventDestination": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateConfigurationSetEventDestinationResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateReceiptRule": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetName",
          "Rule"
        ],
        "members": {
          "RuleSetName": {},
          "Rule": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateReceiptRuleResult",
        "type": "structure",
        "members": {}
      }
    },
    "VerifyDomainDkim": {
      "input": {
        "type": "structure",
        "required": [
          "Domain"
        ],
        "members": {
          "Domain": {}
        }
      },
      "output": {
        "resultWrapper": "VerifyDomainDkimResult",
        "type": "structure",
        "required": [
          "DkimTokens"
        ],
        "members": {
          "DkimTokens": {
            "shape": "S2o"
          }
        }
      }
    },
    "VerifyDomainIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "Domain"
        ],
        "members": {
          "Domain": {}
        }
      },
      "output": {
        "resultWrapper": "VerifyDomainIdentityResult",
        "type": "structure",
        "required": [
          "VerificationToken"
        ],
        "members": {
          "VerificationToken": {}
        }
      }
    },
    "VerifyEmailAddress": {
      "input": {
        "type": "structure",
        "required": [
          "EmailAddress"
        ],
        "members": {
          "EmailAddress": {}
        }
      }
    },
    "VerifyEmailIdentity": {
      "input": {
        "type": "structure",
        "required": [
          "EmailAddress"
        ],
        "members": {
          "EmailAddress": {}
        }
      },
      "output": {
        "resultWrapper": "VerifyEmailIdentityResult",
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {}
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "Name",
        "MatchingEventTypes"
      ],
      "members": {
        "Name": {},
        "Enabled": {
          "type": "boolean"
        },
        "MatchingEventTypes": {
          "type": "list",
          "member": {}
        },
        "KinesisFirehoseDestination": {
          "type": "structure",
          "required": [
            "IAMRoleARN",
            "DeliveryStreamARN"
          ],
          "members": {
            "IAMRoleARN": {},
            "DeliveryStreamARN": {}
          }
        },
        "CloudWatchDestination": {
          "type": "structure",
          "required": [
            "DimensionConfigurations"
          ],
          "members": {
            "DimensionConfigurations": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "DimensionName",
                  "DimensionValueSource",
                  "DefaultDimensionValue"
                ],
                "members": {
                  "DimensionName": {},
                  "DimensionValueSource": {},
                  "DefaultDimensionValue": {}
                }
              }
            }
          }
        }
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "Name",
        "IpFilter"
      ],
      "members": {
        "Name": {},
        "IpFilter": {
          "type": "structure",
          "required": [
            "Policy",
            "Cidr"
          ],
          "members": {
            "Policy": {},
            "Cidr": {}
          }
        }
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "Enabled": {
          "type": "boolean"
        },
        "TlsPolicy": {},
        "Recipients": {
          "type": "list",
          "member": {}
        },
        "Actions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "S3Action": {
                "type": "structure",
                "required": [
                  "BucketName"
                ],
                "members": {
                  "TopicArn": {},
                  "BucketName": {},
                  "ObjectKeyPrefix": {},
                  "KmsKeyArn": {}
                }
              },
              "BounceAction": {
                "type": "structure",
                "required": [
                  "SmtpReplyCode",
                  "Message",
                  "Sender"
                ],
                "members": {
                  "TopicArn": {},
                  "SmtpReplyCode": {},
                  "StatusCode": {},
                  "Message": {},
                  "Sender": {}
                }
              },
              "WorkmailAction": {
                "type": "structure",
                "required": [
                  "OrganizationArn"
                ],
                "members": {
                  "TopicArn": {},
                  "OrganizationArn": {}
                }
              },
              "LambdaAction": {
                "type": "structure",
                "required": [
                  "FunctionArn"
                ],
                "members": {
                  "TopicArn": {},
                  "FunctionArn": {},
                  "InvocationType": {}
                }
              },
              "StopAction": {
                "type": "structure",
                "required": [
                  "Scope"
                ],
                "members": {
                  "Scope": {},
                  "TopicArn": {}
                }
              },
              "AddHeaderAction": {
                "type": "structure",
                "required": [
                  "HeaderName",
                  "HeaderValue"
                ],
                "members": {
                  "HeaderName": {},
                  "HeaderValue": {}
                }
              },
              "SNSAction": {
                "type": "structure",
                "required": [
                  "TopicArn"
                ],
                "members": {
                  "TopicArn": {},
                  "Encoding": {}
                }
              }
            }
          }
        },
        "ScanEnabled": {
          "type": "boolean"
        }
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "Name": {},
        "CreatedTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S28": {
      "type": "list",
      "member": {
        "shape": "Sw"
      }
    },
    "S2j": {
      "type": "list",
      "member": {}
    },
    "S2o": {
      "type": "list",
      "member": {}
    },
    "S33": {
      "type": "list",
      "member": {}
    },
    "S40": {
      "type": "list",
      "member": {}
    },
    "S4c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "S4t": {
      "type": "structure",
      "required": [
        "Data"
      ],
      "members": {
        "Data": {},
        "Charset": {}
      }
    },
    "S4x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    }
  }
}