module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "events-2015-10-07",
    "apiVersion": "2015-10-07",
    "endpointPrefix": "events",
    "jsonVersion": "1.1",
    "serviceFullName": "Amazon CloudWatch Events",
    "signatureVersion": "v4",
    "targetPrefix": "AWSEvents",
    "protocol": "json"
  },
  "operations": {
    "DeleteRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DescribeRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Arn": {},
          "EventPattern": {},
          "ScheduleExpression": {},
          "State": {},
          "Description": {},
          "RoleArn": {}
        }
      }
    },
    "DisableRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "EnableRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "ListRuleNamesByTarget": {
      "input": {
        "type": "structure",
        "required": [
          "TargetArn"
        ],
        "members": {
          "TargetArn": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListRules": {
      "input": {
        "type": "structure",
        "members": {
          "NamePrefix": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "EventPattern": {},
                "State": {},
                "Description": {},
                "ScheduleExpression": {},
                "RoleArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTargetsByRule": {
      "input": {
        "type": "structure",
        "required": [
          "Rule"
        ],
        "members": {
          "Rule": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Targets": {
            "shape": "Sp"
          },
          "NextToken": {}
        }
      }
    },
    "PutEvents": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Time": {
                  "type": "timestamp"
                },
                "Source": {},
                "Resources": {
                  "type": "list",
                  "member": {}
                },
                "DetailType": {},
                "Detail": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "PutRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "ScheduleExpression": {},
          "EventPattern": {},
          "State": {},
          "Description": {},
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleArn": {}
        }
      }
    },
    "PutTargets": {
      "input": {
        "type": "structure",
        "required": [
          "Rule",
          "Targets"
        ],
        "members": {
          "Rule": {},
          "Targets": {
            "shape": "Sp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "FailedEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "RemoveTargets": {
      "input": {
        "type": "structure",
        "required": [
          "Rule",
          "Ids"
        ],
        "members": {
          "Rule": {},
          "Ids": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "FailedEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "TestEventPattern": {
      "input": {
        "type": "structure",
        "required": [
          "EventPattern",
          "Event"
        ],
        "members": {
          "EventPattern": {},
          "Event": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Result": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id",
          "Arn"
        ],
        "members": {
          "Id": {},
          "Arn": {},
          "Input": {},
          "InputPath": {}
        }
      }
    }
  },
  "examples": {}
}