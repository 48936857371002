module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-08-04",
    "endpointPrefix": "firehose",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Firehose",
    "serviceFullName": "Amazon Kinesis Firehose",
    "signatureVersion": "v4",
    "targetPrefix": "Firehose_20150804",
    "uid": "firehose-2015-08-04"
  },
  "operations": {
    "CreateDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "S3DestinationConfiguration": {
            "shape": "S3",
            "deprecated": true
          },
          "ExtendedS3DestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "BucketARN"
            ],
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "BufferingHints": {
                "shape": "S7"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Sb"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sf"
              },
              "ProcessingConfiguration": {
                "shape": "Sk"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "S3"
              }
            }
          },
          "RedshiftDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "ClusterJDBCURL",
              "CopyCommand",
              "Username",
              "Password",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "Sv"
              },
              "Username": {
                "shape": "Sz"
              },
              "Password": {
                "shape": "S10"
              },
              "RetryOptions": {
                "shape": "S11"
              },
              "S3Configuration": {
                "shape": "S3"
              },
              "ProcessingConfiguration": {
                "shape": "Sk"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "S3"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sf"
              }
            }
          },
          "ElasticsearchDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "DomainARN",
              "IndexName",
              "TypeName",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S19"
              },
              "RetryOptions": {
                "shape": "S1c"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "S3"
              },
              "ProcessingConfiguration": {
                "shape": "Sk"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sf"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryStreamARN": {}
        }
      }
    },
    "DeleteDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartDestinationId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamDescription"
        ],
        "members": {
          "DeliveryStreamDescription": {
            "type": "structure",
            "required": [
              "DeliveryStreamName",
              "DeliveryStreamARN",
              "DeliveryStreamStatus",
              "VersionId",
              "Destinations",
              "HasMoreDestinations"
            ],
            "members": {
              "DeliveryStreamName": {},
              "DeliveryStreamARN": {},
              "DeliveryStreamStatus": {},
              "VersionId": {},
              "CreateTimestamp": {
                "type": "timestamp"
              },
              "LastUpdateTimestamp": {
                "type": "timestamp"
              },
              "Destinations": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "DestinationId"
                  ],
                  "members": {
                    "DestinationId": {},
                    "S3DestinationDescription": {
                      "shape": "S1t"
                    },
                    "ExtendedS3DestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "BucketARN",
                        "BufferingHints",
                        "CompressionFormat",
                        "EncryptionConfiguration"
                      ],
                      "members": {
                        "RoleARN": {},
                        "BucketARN": {},
                        "Prefix": {},
                        "BufferingHints": {
                          "shape": "S7"
                        },
                        "CompressionFormat": {},
                        "EncryptionConfiguration": {
                          "shape": "Sb"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sf"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sk"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S1t"
                        }
                      }
                    },
                    "RedshiftDestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "ClusterJDBCURL",
                        "CopyCommand",
                        "Username",
                        "S3DestinationDescription"
                      ],
                      "members": {
                        "RoleARN": {},
                        "ClusterJDBCURL": {},
                        "CopyCommand": {
                          "shape": "Sv"
                        },
                        "Username": {
                          "shape": "Sz"
                        },
                        "RetryOptions": {
                          "shape": "S11"
                        },
                        "S3DestinationDescription": {
                          "shape": "S1t"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sk"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S1t"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sf"
                        }
                      }
                    },
                    "ElasticsearchDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "RoleARN": {},
                        "DomainARN": {},
                        "IndexName": {},
                        "TypeName": {},
                        "IndexRotationPeriod": {},
                        "BufferingHints": {
                          "shape": "S19"
                        },
                        "RetryOptions": {
                          "shape": "S1c"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S1t"
                        },
                        "ProcessingConfiguration": {
                          "shape": "Sk"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sf"
                        }
                      }
                    }
                  }
                }
              },
              "HasMoreDestinations": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "ListDeliveryStreams": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartDeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamNames",
          "HasMoreDeliveryStreams"
        ],
        "members": {
          "DeliveryStreamNames": {
            "type": "list",
            "member": {}
          },
          "HasMoreDeliveryStreams": {
            "type": "boolean"
          }
        }
      }
    },
    "PutRecord": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Record"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Record": {
            "shape": "S22"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RecordId"
        ],
        "members": {
          "RecordId": {}
        }
      }
    },
    "PutRecordBatch": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Records"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Records": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FailedPutCount",
          "RequestResponses"
        ],
        "members": {
          "FailedPutCount": {
            "type": "integer"
          },
          "RequestResponses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecordId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "UpdateDestination": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "CurrentDeliveryStreamVersionId",
          "DestinationId"
        ],
        "members": {
          "DeliveryStreamName": {},
          "CurrentDeliveryStreamVersionId": {},
          "DestinationId": {},
          "S3DestinationUpdate": {
            "shape": "S2f",
            "deprecated": true
          },
          "ExtendedS3DestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "BufferingHints": {
                "shape": "S7"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Sb"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sf"
              },
              "ProcessingConfiguration": {
                "shape": "Sk"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S2f"
              }
            }
          },
          "RedshiftDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "Sv"
              },
              "Username": {
                "shape": "Sz"
              },
              "Password": {
                "shape": "S10"
              },
              "RetryOptions": {
                "shape": "S11"
              },
              "S3Update": {
                "shape": "S2f"
              },
              "ProcessingConfiguration": {
                "shape": "Sk"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S2f"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sf"
              }
            }
          },
          "ElasticsearchDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S19"
              },
              "RetryOptions": {
                "shape": "S1c"
              },
              "S3Update": {
                "shape": "S2f"
              },
              "ProcessingConfiguration": {
                "shape": "Sk"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sf"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "BufferingHints": {
          "shape": "S7"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Sb"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sf"
        }
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "SizeInMBs": {
          "type": "integer"
        },
        "IntervalInSeconds": {
          "type": "integer"
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "NoEncryptionConfig": {},
        "KMSEncryptionConfig": {
          "type": "structure",
          "required": [
            "AWSKMSKeyARN"
          ],
          "members": {
            "AWSKMSKeyARN": {}
          }
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "LogGroupName": {},
        "LogStreamName": {}
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Processors": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "Parameters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "ParameterName",
                    "ParameterValue"
                  ],
                  "members": {
                    "ParameterName": {},
                    "ParameterValue": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "DataTableName"
      ],
      "members": {
        "DataTableName": {},
        "DataTableColumns": {},
        "CopyOptions": {}
      }
    },
    "Sz": {
      "type": "string",
      "sensitive": true
    },
    "S10": {
      "type": "string",
      "sensitive": true
    },
    "S11": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "IntervalInSeconds": {
          "type": "integer"
        },
        "SizeInMBs": {
          "type": "integer"
        }
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN",
        "BufferingHints",
        "CompressionFormat",
        "EncryptionConfiguration"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "BufferingHints": {
          "shape": "S7"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Sb"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sf"
        }
      }
    },
    "S22": {
      "type": "structure",
      "required": [
        "Data"
      ],
      "members": {
        "Data": {
          "type": "blob"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "BufferingHints": {
          "shape": "S7"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Sb"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sf"
        }
      }
    }
  }
}