
import {SpotDB,ListResult,AttributeSerializer,FilterExpression} from './db';
import {SpotBaseObject} from './base';
import {SignConfig} from './signconfig';

/** reference to an instance of a Jurisdiction */
export class JurisdictionRef {
    constructor(id:string) {
        this.id = id;
    }
    id: string;

    private _queried = false;
    private _inst:Jurisdiction;

    /** read the referenced Jurisdiction instance from the database */
    async jurisdiction(db:SpotDB) : Promise<Jurisdiction> {
        if (!this._inst && !this._queried) {
            this._inst = await Jurisdiction.get(db, this.id);
            this._queried = true;
        }
        return this._inst;
    }
}

export type JurisdictionType = "OTHER" | "SUB-CITY" | "CITY" | "CITY-DISTRICT" | "COUNTY" | "STATE-DISTRICT" | "STATE" | "COUNTRY";

/**
 * Jurisdiction data defines the operating context for a particular area – common attributes,
 * language, timezone and holiday information etc.  A jurisdiction is a mandatory requirement
 * for most other data structures.
 *
 * A jurisdiction is part of a a parent-child relationship.
 *
 * This allows a jurisdiction to inherit attributes from parent jurisdictions
 * such are those typical in City > State > Country relationships.
 *
 * In the example shown, the Denver jurisdiction defines the school holiday periods
 * for the city, but inherits public holiday information from the combination of state
 * and national jurisdiction configurations.
 *
 * The City jurisdiction also inherits the timezone and locale information from the
 * State and the base currency from the National jurisdiction
 */
export class Jurisdiction extends SpotBaseObject {
    static TableName = 'jurisdictions';

    constructor(db:SpotDB) {
        super(db, Jurisdiction.TableName);
    }

    /** Reference to a parent Jurisdiction if defined within a hierarchy */
    parentJurisdiction?: JurisdictionRef;
    /** Display friendly name */
    name?: string;
    /** Display-friendly abbreviation */
    abbreviation?: string;
    /** The locality of the jurisdiction */
    type?: JurisdictionType;

    subtype?: string;
    osmId?: number;     /** open street map id */
    placeId?: number;

    get parent() : Promise<Jurisdiction> {
        return !!this.parentJurisdiction ? this.parentJurisdiction.jurisdiction(this._db) : Promise.resolve(null);
    }

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromString(this.id, "id"),
            ...s.fromString(this.name, "name"),
            ...s.fromString(this.abbreviation, "abbreviation"),
            ...s.fromString(this.parentJurisdiction && this.parentJurisdiction.id, "parentJurisdiction"),
            ...s.fromString(this.type, "type"),
            ...s.fromString(this.subtype, "subtype"),
            ...s.fromInteger(this.osmId, "osmId"),
            ...s.fromInteger(this.placeId, "placeId"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.id, (v:string) => this.id = v);
        s.setString(a.name, (v:string) => this.name = v);
        s.setString(a.abbreviation, (v:string) => this.abbreviation = v);
        s.setString(a.parentJurisdiction, (v:string) => this.parentJurisdiction = new JurisdictionRef(v));
        s.setString(a.type, (v:JurisdictionType) => this.type = v);
        s.setString(a.subtype, v => this.subtype = v);
        s.setInteger(a.osmId, v => this.osmId = v);
        s.setInteger(a.placeId, v => this.placeId = v);
    }

    getCategories() : Promise<string[]> {
        return SignConfig.getCategories(this._db, this.id);
    }

    getSignConfig(category: string) : Promise<SignConfig> {
        return SignConfig.getByJurisdiction(this._db, this.id, category);
    }

    static fromAttrs(db:SpotDB, a:any) : Jurisdiction {
        let z = new Jurisdiction(db);
        z.setAttrs(db.serializer, a);
        return z;
    }

    /** Load a single Jurisdiction instance from the database */
    static async get(db:SpotDB, id:string) : Promise<Jurisdiction> {
        let data = await db.get(this.TableName, id);
        if (!data) {
            return null;
        }
        let j = new this(db);
        j.setAttrs(db.serializer, data);
        return j;
    }

    /** Returns the ids of Jurisdictions that exist in the database. */
    static listIds(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<string>> {
        return db.listIds(this.TableName, startAfter, limit);
    }

    /** Returns the Jurisdiction instances that exist in the database. */
    static list(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<Jurisdiction>> {
        return db.list(this.TableName, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => Jurisdiction.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    static listWithFilter(db:SpotDB, filter:FilterExpression, startAfter?:string, limit?: number) : Promise<ListResult<Jurisdiction>> {
        return db.listWithFilter(this.TableName, filter, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => Jurisdiction.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Returns the Jurisdiction instances that exist in the database looked up by parentJurisdiction. */
    static listByParentJurisdiction(db:SpotDB, jurisdictionId:string, startAfter?:string, limit?: number) : Promise<ListResult<Jurisdiction>> {
        return this.listByIndex(db, 'parentJurisdiction-name-index', 'parentJurisdiction', jurisdictionId, startAfter, limit);
    }

    static listByIndex(db:SpotDB, indexName:string, attributeName:string, value:string, startAfter?:string, limit?: number) : Promise<ListResult<Jurisdiction>> {
        return db.listByIndex(this.TableName, indexName, attributeName, value, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => this.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Delete a group of Jurisdiction instances from the database. */
    static bulkDelete(db:SpotDB, ids:string[]) : Promise<void> {
        return db.batchDelete(this.TableName, ids);
    }
}