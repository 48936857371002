module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "route53domains-2014-05-15",
    "apiVersion": "2014-05-15",
    "endpointPrefix": "route53domains",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Route 53 Domains",
    "signatureVersion": "v4",
    "targetPrefix": "Route53Domains_v20140515"
  },
  "operations": {
    "CheckDomainAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "IdnLangCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Availability"
        ],
        "members": {
          "Availability": {}
        }
      }
    },
    "DeleteTagsForDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "TagsToDelete"
        ],
        "members": {
          "DomainName": {},
          "TagsToDelete": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableDomainAutoRenew": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableDomainTransferLock": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "EnableDomainAutoRenew": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableDomainTransferLock": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "GetContactReachabilityStatus": {
      "input": {
        "type": "structure",
        "members": {
          "domainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainName": {},
          "status": {}
        }
      }
    },
    "GetDomainDetail": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainName",
          "Nameservers",
          "AdminContact",
          "RegistrantContact",
          "TechContact"
        ],
        "members": {
          "DomainName": {},
          "Nameservers": {
            "shape": "So"
          },
          "AutoRenew": {
            "type": "boolean"
          },
          "AdminContact": {
            "shape": "Su"
          },
          "RegistrantContact": {
            "shape": "Su"
          },
          "TechContact": {
            "shape": "Su"
          },
          "AdminPrivacy": {
            "type": "boolean"
          },
          "RegistrantPrivacy": {
            "type": "boolean"
          },
          "TechPrivacy": {
            "type": "boolean"
          },
          "RegistrarName": {},
          "WhoIsServer": {},
          "RegistrarUrl": {},
          "AbuseContactEmail": {},
          "AbuseContactPhone": {},
          "RegistryDomainId": {},
          "CreationDate": {
            "type": "timestamp"
          },
          "UpdatedDate": {
            "type": "timestamp"
          },
          "ExpirationDate": {
            "type": "timestamp"
          },
          "Reseller": {},
          "DnsSec": {},
          "StatusList": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetDomainSuggestions": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "SuggestionCount",
          "OnlyAvailable"
        ],
        "members": {
          "DomainName": {},
          "SuggestionCount": {
            "type": "integer"
          },
          "OnlyAvailable": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuggestionsList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "Availability": {}
              }
            }
          }
        }
      }
    },
    "GetOperationDetail": {
      "input": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OperationId": {},
          "Status": {},
          "Message": {},
          "DomainName": {},
          "Type": {},
          "SubmittedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListDomains": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Domains"
        ],
        "members": {
          "Domains": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DomainName"
              ],
              "members": {
                "DomainName": {},
                "AutoRenew": {
                  "type": "boolean"
                },
                "TransferLock": {
                  "type": "boolean"
                },
                "Expiry": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextPageMarker": {}
        }
      }
    },
    "ListOperations": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Operations"
        ],
        "members": {
          "Operations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OperationId",
                "Status",
                "Type",
                "SubmittedDate"
              ],
              "members": {
                "OperationId": {},
                "Status": {},
                "Type": {},
                "SubmittedDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextPageMarker": {}
        }
      }
    },
    "ListTagsForDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagList"
        ],
        "members": {
          "TagList": {
            "shape": "S24"
          }
        }
      }
    },
    "RegisterDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "DurationInYears",
          "AdminContact",
          "RegistrantContact",
          "TechContact"
        ],
        "members": {
          "DomainName": {},
          "IdnLangCode": {},
          "DurationInYears": {
            "type": "integer"
          },
          "AutoRenew": {
            "type": "boolean"
          },
          "AdminContact": {
            "shape": "Su"
          },
          "RegistrantContact": {
            "shape": "Su"
          },
          "TechContact": {
            "shape": "Su"
          },
          "PrivacyProtectAdminContact": {
            "type": "boolean"
          },
          "PrivacyProtectRegistrantContact": {
            "type": "boolean"
          },
          "PrivacyProtectTechContact": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "RenewDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "CurrentExpiryYear"
        ],
        "members": {
          "DomainName": {},
          "DurationInYears": {
            "type": "integer"
          },
          "CurrentExpiryYear": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "ResendContactReachabilityEmail": {
      "input": {
        "type": "structure",
        "members": {
          "domainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainName": {},
          "emailAddress": {},
          "isAlreadyVerified": {
            "type": "boolean"
          }
        }
      }
    },
    "RetrieveDomainAuthCode": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AuthCode"
        ],
        "members": {
          "AuthCode": {
            "shape": "S2h"
          }
        }
      }
    },
    "TransferDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "DurationInYears",
          "AdminContact",
          "RegistrantContact",
          "TechContact"
        ],
        "members": {
          "DomainName": {},
          "IdnLangCode": {},
          "DurationInYears": {
            "type": "integer"
          },
          "Nameservers": {
            "shape": "So"
          },
          "AuthCode": {
            "shape": "S2h"
          },
          "AutoRenew": {
            "type": "boolean"
          },
          "AdminContact": {
            "shape": "Su"
          },
          "RegistrantContact": {
            "shape": "Su"
          },
          "TechContact": {
            "shape": "Su"
          },
          "PrivacyProtectAdminContact": {
            "type": "boolean"
          },
          "PrivacyProtectRegistrantContact": {
            "type": "boolean"
          },
          "PrivacyProtectTechContact": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateDomainContact": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "AdminContact": {
            "shape": "Su"
          },
          "RegistrantContact": {
            "shape": "Su"
          },
          "TechContact": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateDomainContactPrivacy": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "AdminPrivacy": {
            "type": "boolean"
          },
          "RegistrantPrivacy": {
            "type": "boolean"
          },
          "TechPrivacy": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateDomainNameservers": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "Nameservers"
        ],
        "members": {
          "DomainName": {},
          "FIAuthKey": {},
          "Nameservers": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "OperationId"
        ],
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateTagsForDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "TagsToUpdate": {
            "shape": "S24"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ViewBilling": {
      "input": {
        "type": "structure",
        "members": {
          "Start": {
            "type": "timestamp"
          },
          "End": {
            "type": "timestamp"
          },
          "Marker": {},
          "MaxItems": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextPageMarker": {},
          "BillingRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "Operation": {},
                "InvoiceId": {},
                "BillDate": {
                  "type": "timestamp"
                },
                "Price": {
                  "type": "double"
                }
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "So": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "GlueIps": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "FirstName": {},
        "LastName": {},
        "ContactType": {},
        "OrganizationName": {},
        "AddressLine1": {},
        "AddressLine2": {},
        "City": {},
        "State": {},
        "CountryCode": {},
        "ZipCode": {},
        "PhoneNumber": {},
        "Email": {},
        "Fax": {},
        "ExtraParams": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Value"
            ],
            "members": {
              "Name": {},
              "Value": {}
            }
          }
        }
      },
      "sensitive": true
    },
    "S24": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S2h": {
      "type": "string",
      "sensitive": true
    }
  }
}