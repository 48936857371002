module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-05-15",
    "endpointPrefix": "cloudformation",
    "protocol": "query",
    "serviceFullName": "AWS CloudFormation",
    "signatureVersion": "v4",
    "uid": "cloudformation-2010-05-15",
    "xmlNamespace": "http://cloudformation.amazonaws.com/doc/2010-05-15/"
  },
  "operations": {
    "CancelUpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {}
        }
      }
    },
    "ContinueUpdateRollback": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RoleARN": {},
          "ResourcesToSkip": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ContinueUpdateRollbackResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "ChangeSetName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "Sd"
          },
          "Capabilities": {
            "shape": "Si"
          },
          "ResourceTypes": {
            "shape": "Sk"
          },
          "RoleARN": {},
          "NotificationARNs": {
            "shape": "Sm"
          },
          "Tags": {
            "shape": "So"
          },
          "ChangeSetName": {},
          "ClientToken": {},
          "Description": {},
          "ChangeSetType": {}
        }
      },
      "output": {
        "resultWrapper": "CreateChangeSetResult",
        "type": "structure",
        "members": {
          "Id": {},
          "StackId": {}
        }
      }
    },
    "CreateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Sd"
          },
          "DisableRollback": {
            "type": "boolean"
          },
          "TimeoutInMinutes": {
            "type": "integer"
          },
          "NotificationARNs": {
            "shape": "Sm"
          },
          "Capabilities": {
            "shape": "Si"
          },
          "ResourceTypes": {
            "shape": "Sk"
          },
          "RoleARN": {},
          "OnFailure": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "Tags": {
            "shape": "So"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "DeleteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RetainResources": {
            "type": "list",
            "member": {}
          },
          "RoleARN": {}
        }
      }
    },
    "DescribeAccountLimits": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "AccountLimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Value": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeChangeSetResult",
        "type": "structure",
        "members": {
          "ChangeSetName": {},
          "ChangeSetId": {},
          "StackId": {},
          "StackName": {},
          "Description": {},
          "Parameters": {
            "shape": "Sd"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "ExecutionStatus": {},
          "Status": {},
          "StatusReason": {},
          "NotificationARNs": {
            "shape": "Sm"
          },
          "Capabilities": {
            "shape": "Si"
          },
          "Tags": {
            "shape": "So"
          },
          "Changes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "ResourceChange": {
                  "type": "structure",
                  "members": {
                    "Action": {},
                    "LogicalResourceId": {},
                    "PhysicalResourceId": {},
                    "ResourceType": {},
                    "Replacement": {},
                    "Scope": {
                      "type": "list",
                      "member": {}
                    },
                    "Details": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Target": {
                            "type": "structure",
                            "members": {
                              "Attribute": {},
                              "Name": {},
                              "RequiresRecreation": {}
                            }
                          },
                          "Evaluation": {},
                          "ChangeSource": {},
                          "CausingEntity": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackEvents": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackEventsResult",
        "type": "structure",
        "members": {
          "StackEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackId",
                "EventId",
                "StackName",
                "Timestamp"
              ],
              "members": {
                "StackId": {},
                "EventId": {},
                "StackName": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "ResourceProperties": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourceResult",
        "type": "structure",
        "members": {
          "StackResourceDetail": {
            "type": "structure",
            "required": [
              "LogicalResourceId",
              "ResourceType",
              "LastUpdatedTimestamp",
              "ResourceStatus"
            ],
            "members": {
              "StackName": {},
              "StackId": {},
              "LogicalResourceId": {},
              "PhysicalResourceId": {},
              "ResourceType": {},
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "ResourceStatus": {},
              "ResourceStatusReason": {},
              "Description": {},
              "Metadata": {}
            }
          }
        }
      }
    },
    "DescribeStackResources": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "PhysicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "Timestamp",
                "ResourceStatus"
              ],
              "members": {
                "StackName": {},
                "StackId": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "Description": {}
              }
            }
          }
        }
      }
    },
    "DescribeStacks": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStacksResult",
        "type": "structure",
        "members": {
          "Stacks": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "Description": {},
                "Parameters": {
                  "shape": "Sd"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "StackStatus": {},
                "StackStatusReason": {},
                "DisableRollback": {
                  "type": "boolean"
                },
                "NotificationARNs": {
                  "shape": "Sm"
                },
                "TimeoutInMinutes": {
                  "type": "integer"
                },
                "Capabilities": {
                  "shape": "Si"
                },
                "Outputs": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "OutputKey": {},
                      "OutputValue": {},
                      "Description": {}
                    }
                  }
                },
                "RoleARN": {},
                "Tags": {
                  "shape": "So"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "EstimateTemplateCost": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "resultWrapper": "EstimateTemplateCostResult",
        "type": "structure",
        "members": {
          "Url": {}
        }
      }
    },
    "ExecuteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "ExecuteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "GetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "GetStackPolicyResult",
        "type": "structure",
        "members": {
          "StackPolicyBody": {}
        }
      }
    },
    "GetTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "ChangeSetName": {},
          "TemplateStage": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateResult",
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "StagesAvailable": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetTemplateSummary": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateSummaryResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "ParameterType": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {},
                "ParameterConstraints": {
                  "type": "structure",
                  "members": {
                    "AllowedValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "Si"
          },
          "CapabilitiesReason": {},
          "ResourceTypes": {
            "shape": "Sk"
          },
          "Version": {},
          "Metadata": {},
          "DeclaredTransforms": {
            "shape": "S3k"
          }
        }
      }
    },
    "ListChangeSets": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListChangeSetsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "ChangeSetName": {},
                "ExecutionStatus": {},
                "Status": {},
                "StatusReason": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListExports": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListExportsResult",
        "type": "structure",
        "members": {
          "Exports": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExportingStackId": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListImports": {
      "input": {
        "type": "structure",
        "required": [
          "ExportName"
        ],
        "members": {
          "ExportName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListImportsResult",
        "type": "structure",
        "members": {
          "Imports": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListStackResources": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResourceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "LastUpdatedTimestamp",
                "ResourceStatus"
              ],
              "members": {
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "LastUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStacks": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StackStatusFilter": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ListStacksResult",
        "type": "structure",
        "members": {
          "StackSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "TemplateDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "DeletionTime": {
                  "type": "timestamp"
                },
                "StackStatus": {},
                "StackStatusReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "SetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {}
        }
      }
    },
    "SignalResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId",
          "UniqueId",
          "Status"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "UniqueId": {},
          "Status": {}
        }
      }
    },
    "UpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "StackPolicyDuringUpdateBody": {},
          "StackPolicyDuringUpdateURL": {},
          "Parameters": {
            "shape": "Sd"
          },
          "Capabilities": {
            "shape": "Si"
          },
          "ResourceTypes": {
            "shape": "Sk"
          },
          "RoleARN": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "NotificationARNs": {
            "shape": "Sm"
          },
          "Tags": {
            "shape": "So"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "ValidateTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {}
        }
      },
      "output": {
        "resultWrapper": "ValidateTemplateResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {}
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "Si"
          },
          "CapabilitiesReason": {},
          "DeclaredTransforms": {
            "shape": "S3k"
          }
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ParameterKey": {},
          "ParameterValue": {},
          "UsePreviousValue": {
            "type": "boolean"
          }
        }
      }
    },
    "Si": {
      "type": "list",
      "member": {}
    },
    "Sk": {
      "type": "list",
      "member": {}
    },
    "Sm": {
      "type": "list",
      "member": {}
    },
    "So": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S3k": {
      "type": "list",
      "member": {}
    }
  }
}