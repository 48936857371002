module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-06",
    "endpointPrefix": "ssm",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon SSM",
    "serviceFullName": "Amazon Simple Systems Manager (SSM)",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonSSM",
    "uid": "ssm-2014-11-06"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelCommand": {
      "input": {
        "type": "structure",
        "required": [
          "CommandId"
        ],
        "members": {
          "CommandId": {},
          "InstanceIds": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateActivation": {
      "input": {
        "type": "structure",
        "required": [
          "IamRole"
        ],
        "members": {
          "Description": {},
          "DefaultInstanceName": {},
          "IamRole": {},
          "RegistrationLimit": {
            "type": "integer"
          },
          "ExpirationDate": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActivationId": {},
          "ActivationCode": {}
        }
      }
    },
    "CreateAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {},
          "InstanceId": {},
          "Parameters": {
            "shape": "Sq"
          },
          "Targets": {
            "shape": "Su"
          },
          "ScheduleExpression": {},
          "OutputLocation": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S16"
          }
        }
      }
    },
    "CreateAssociationBatch": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "shape": "S1j",
              "locationName": "entries"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "type": "list",
            "member": {
              "shape": "S16",
              "locationName": "AssociationDescription"
            }
          },
          "Failed": {
            "type": "list",
            "member": {
              "locationName": "FailedCreateAssociationEntry",
              "type": "structure",
              "members": {
                "Entry": {
                  "shape": "S1j"
                },
                "Message": {},
                "Fault": {}
              }
            }
          }
        }
      }
    },
    "CreateDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Content",
          "Name"
        ],
        "members": {
          "Content": {},
          "Name": {},
          "DocumentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentDescription": {
            "shape": "S1u"
          }
        }
      }
    },
    "CreateMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Schedule",
          "Duration",
          "Cutoff",
          "AllowUnassociatedTargets"
        ],
        "members": {
          "Name": {},
          "Schedule": {},
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {}
        }
      }
    },
    "CreatePatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "GlobalFilters": {
            "shape": "S2m"
          },
          "ApprovalRules": {
            "shape": "S2s"
          },
          "ApprovedPatches": {
            "shape": "S2w"
          },
          "RejectedPatches": {
            "shape": "S2w"
          },
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {}
        }
      }
    },
    "DeleteActivation": {
      "input": {
        "type": "structure",
        "required": [
          "ActivationId"
        ],
        "members": {
          "ActivationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "InstanceId": {},
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {}
        }
      }
    },
    "DeleteParameter": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {}
        }
      }
    },
    "DeregisterManagedInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterPatchBaselineForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId",
          "PatchGroup"
        ],
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      }
    },
    "DeregisterTargetFromMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "WindowTargetId"
        ],
        "members": {
          "WindowId": {},
          "WindowTargetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "WindowTargetId": {}
        }
      }
    },
    "DeregisterTaskFromMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "WindowTaskId"
        ],
        "members": {
          "WindowId": {},
          "WindowTaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "WindowTaskId": {}
        }
      }
    },
    "DescribeActivations": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FilterKey": {},
                "FilterValues": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActivationList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ActivationId": {},
                "Description": {},
                "DefaultInstanceName": {},
                "IamRole": {},
                "RegistrationLimit": {
                  "type": "integer"
                },
                "RegistrationsCount": {
                  "type": "integer"
                },
                "ExpirationDate": {
                  "type": "timestamp"
                },
                "Expired": {
                  "type": "boolean"
                },
                "CreatedDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "InstanceId": {},
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S16"
          }
        }
      }
    },
    "DescribeAutomationExecutions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutomationExecutionMetadataList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutomationExecutionId": {},
                "DocumentName": {},
                "DocumentVersion": {},
                "AutomationExecutionStatus": {},
                "ExecutionStartTime": {
                  "type": "timestamp"
                },
                "ExecutionEndTime": {
                  "type": "timestamp"
                },
                "ExecutedBy": {},
                "LogFile": {},
                "Outputs": {
                  "shape": "S4h"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAvailablePatches": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S4m"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Patches": {
            "type": "list",
            "member": {
              "shape": "S4u"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Document": {
            "shape": "S1u"
          }
        }
      }
    },
    "DescribeDocumentPermission": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "PermissionType"
        ],
        "members": {
          "Name": {},
          "PermissionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "shape": "S5b"
          }
        }
      }
    },
    "DescribeEffectiveInstanceAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "InstanceId": {},
                "Content": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeEffectivePatchesForPatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EffectivePatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Patch": {
                  "shape": "S4u"
                },
                "PatchStatus": {
                  "type": "structure",
                  "members": {
                    "DeploymentStatus": {},
                    "ApprovalDate": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstanceAssociationsStatus": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceAssociationStatusInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "Name": {},
                "DocumentVersion": {},
                "InstanceId": {},
                "ExecutionDate": {
                  "type": "timestamp"
                },
                "Status": {},
                "DetailedStatus": {},
                "ExecutionSummary": {},
                "ErrorCode": {},
                "OutputUrl": {
                  "type": "structure",
                  "members": {
                    "S3OutputUrl": {
                      "type": "structure",
                      "members": {
                        "OutputUrl": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstanceInformation": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceInformationFilterList": {
            "type": "list",
            "member": {
              "locationName": "InstanceInformationFilter",
              "type": "structure",
              "required": [
                "key",
                "valueSet"
              ],
              "members": {
                "key": {},
                "valueSet": {
                  "shape": "S61"
                }
              }
            }
          },
          "Filters": {
            "type": "list",
            "member": {
              "locationName": "InstanceInformationStringFilter",
              "type": "structure",
              "required": [
                "Key",
                "Values"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "shape": "S61"
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceInformationList": {
            "type": "list",
            "member": {
              "locationName": "InstanceInformation",
              "type": "structure",
              "members": {
                "InstanceId": {},
                "PingStatus": {},
                "LastPingDateTime": {
                  "type": "timestamp"
                },
                "AgentVersion": {},
                "IsLatestVersion": {
                  "type": "boolean"
                },
                "PlatformType": {},
                "PlatformName": {},
                "PlatformVersion": {},
                "ActivationId": {},
                "IamRole": {},
                "RegistrationDate": {
                  "type": "timestamp"
                },
                "ResourceType": {},
                "Name": {},
                "IPAddress": {},
                "ComputerName": {},
                "AssociationStatus": {},
                "LastAssociationExecutionDate": {
                  "type": "timestamp"
                },
                "LastSuccessfulAssociationExecutionDate": {
                  "type": "timestamp"
                },
                "AssociationOverview": {
                  "type": "structure",
                  "members": {
                    "DetailedStatus": {},
                    "InstanceAssociationStatusAggregatedCount": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "integer"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstancePatchStates": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sb"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstancePatchStates": {
            "type": "list",
            "member": {
              "shape": "S6l"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstancePatchStatesForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "PatchGroup"
        ],
        "members": {
          "PatchGroup": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key",
                "Values",
                "Type"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                },
                "Type": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstancePatchStates": {
            "type": "list",
            "member": {
              "shape": "S6l"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstancePatches": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "Filters": {
            "shape": "S4m"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Patches": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Title",
                "KBId",
                "Classification",
                "Severity",
                "State",
                "InstalledTime"
              ],
              "members": {
                "Title": {},
                "KBId": {},
                "Classification": {},
                "Severity": {},
                "State": {},
                "InstalledTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowExecutionTaskInvocations": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId",
          "TaskId"
        ],
        "members": {
          "WindowExecutionId": {},
          "TaskId": {},
          "Filters": {
            "shape": "S7f"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionTaskInvocationIdentities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowExecutionId": {},
                "TaskExecutionId": {},
                "InvocationId": {},
                "ExecutionId": {},
                "Parameters": {
                  "type": "string",
                  "sensitive": true
                },
                "Status": {},
                "StatusDetails": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "OwnerInformation": {
                  "shape": "S6n"
                },
                "WindowTargetId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowExecutionTasks": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId"
        ],
        "members": {
          "WindowExecutionId": {},
          "Filters": {
            "shape": "S7f"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionTaskIdentities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowExecutionId": {},
                "TaskExecutionId": {},
                "Status": {},
                "StatusDetails": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "TaskArn": {},
                "TaskType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Filters": {
            "shape": "S7f"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "WindowExecutionId": {},
                "Status": {},
                "StatusDetails": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowTargets": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Filters": {
            "shape": "S7f"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Targets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "WindowTargetId": {},
                "ResourceType": {},
                "Targets": {
                  "shape": "Su"
                },
                "OwnerInformation": {
                  "shape": "S6n"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindowTasks": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Filters": {
            "shape": "S7f"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "WindowTaskId": {},
                "TaskArn": {},
                "Type": {},
                "Targets": {
                  "shape": "Su"
                },
                "TaskParameters": {
                  "shape": "S8d"
                },
                "Priority": {
                  "type": "integer"
                },
                "LoggingInfo": {
                  "shape": "S8j"
                },
                "ServiceRoleArn": {},
                "MaxConcurrency": {},
                "MaxErrors": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMaintenanceWindows": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S7f"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowIdentities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WindowId": {},
                "Name": {},
                "Enabled": {
                  "type": "boolean"
                },
                "Duration": {
                  "type": "integer"
                },
                "Cutoff": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeParameters": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Values"
              ],
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "KeyId": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "LastModifiedUser": {},
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePatchBaselines": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S4m"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineIdentities": {
            "type": "list",
            "member": {
              "shape": "S96"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePatchGroupState": {
      "input": {
        "type": "structure",
        "required": [
          "PatchGroup"
        ],
        "members": {
          "PatchGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "integer"
          },
          "InstancesWithInstalledPatches": {
            "type": "integer"
          },
          "InstancesWithInstalledOtherPatches": {
            "type": "integer"
          },
          "InstancesWithMissingPatches": {
            "type": "integer"
          },
          "InstancesWithFailedPatches": {
            "type": "integer"
          },
          "InstancesWithNotApplicablePatches": {
            "type": "integer"
          }
        }
      }
    },
    "DescribePatchGroups": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Mappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PatchGroup": {},
                "BaselineIdentity": {
                  "shape": "S96"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAutomationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "AutomationExecutionId"
        ],
        "members": {
          "AutomationExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutomationExecution": {
            "type": "structure",
            "members": {
              "AutomationExecutionId": {},
              "DocumentName": {},
              "DocumentVersion": {},
              "ExecutionStartTime": {
                "type": "timestamp"
              },
              "ExecutionEndTime": {
                "type": "timestamp"
              },
              "AutomationExecutionStatus": {},
              "StepExecutions": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "StepName": {},
                    "Action": {},
                    "ExecutionStartTime": {
                      "type": "timestamp"
                    },
                    "ExecutionEndTime": {
                      "type": "timestamp"
                    },
                    "StepStatus": {},
                    "ResponseCode": {},
                    "Inputs": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    },
                    "Outputs": {
                      "shape": "S4h"
                    },
                    "Response": {},
                    "FailureMessage": {}
                  }
                }
              },
              "Parameters": {
                "shape": "S4h"
              },
              "Outputs": {
                "shape": "S4h"
              },
              "FailureMessage": {}
            }
          }
        }
      }
    },
    "GetCommandInvocation": {
      "input": {
        "type": "structure",
        "required": [
          "CommandId",
          "InstanceId"
        ],
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "PluginName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "Comment": {},
          "DocumentName": {},
          "PluginName": {},
          "ResponseCode": {
            "type": "integer"
          },
          "ExecutionStartDateTime": {},
          "ExecutionElapsedTime": {},
          "ExecutionEndDateTime": {},
          "Status": {},
          "StatusDetails": {},
          "StandardOutputContent": {},
          "StandardOutputUrl": {},
          "StandardErrorContent": {},
          "StandardErrorUrl": {}
        }
      }
    },
    "GetDefaultPatchBaseline": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {}
        }
      }
    },
    "GetDeployablePatchSnapshotForInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "SnapshotId"
        ],
        "members": {
          "InstanceId": {},
          "SnapshotId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {},
          "SnapshotId": {},
          "SnapshotDownloadUrl": {}
        }
      }
    },
    "GetDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "DocumentVersion": {},
          "Content": {},
          "DocumentType": {}
        }
      }
    },
    "GetInventory": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sa4"
          },
          "ResultAttributes": {
            "type": "list",
            "member": {
              "locationName": "ResultAttribute",
              "type": "structure",
              "required": [
                "TypeName"
              ],
              "members": {
                "TypeName": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entities": {
            "type": "list",
            "member": {
              "locationName": "Entity",
              "type": "structure",
              "members": {
                "Id": {},
                "Data": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "structure",
                    "required": [
                      "TypeName",
                      "SchemaVersion",
                      "Content"
                    ],
                    "members": {
                      "TypeName": {},
                      "SchemaVersion": {},
                      "CaptureTime": {},
                      "ContentHash": {},
                      "Content": {
                        "shape": "San"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetInventorySchema": {
      "input": {
        "type": "structure",
        "members": {
          "TypeName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Schemas": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TypeName",
                "Attributes"
              ],
              "members": {
                "TypeName": {},
                "Version": {},
                "Attributes": {
                  "type": "list",
                  "member": {
                    "locationName": "Attribute",
                    "type": "structure",
                    "required": [
                      "Name",
                      "DataType"
                    ],
                    "members": {
                      "Name": {},
                      "DataType": {}
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "Name": {},
          "Schedule": {},
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "Enabled": {
            "type": "boolean"
          },
          "CreatedDate": {
            "type": "timestamp"
          },
          "ModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetMaintenanceWindowExecution": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId"
        ],
        "members": {
          "WindowExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionId": {},
          "TaskIds": {
            "type": "list",
            "member": {}
          },
          "Status": {},
          "StatusDetails": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetMaintenanceWindowExecutionTask": {
      "input": {
        "type": "structure",
        "required": [
          "WindowExecutionId",
          "TaskId"
        ],
        "members": {
          "WindowExecutionId": {},
          "TaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowExecutionId": {},
          "TaskExecutionId": {},
          "TaskArn": {},
          "ServiceRole": {},
          "Type": {},
          "TaskParameters": {
            "type": "list",
            "member": {
              "shape": "S8d"
            },
            "sensitive": true
          },
          "Priority": {
            "type": "integer"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "Status": {},
          "StatusDetails": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetParameterHistory": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "WithDecryption": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "KeyId": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "LastModifiedUser": {},
                "Description": {},
                "Value": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetParameters": {
      "input": {
        "type": "structure",
        "required": [
          "Names"
        ],
        "members": {
          "Names": {
            "shape": "Sbf"
          },
          "WithDecryption": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "Value": {}
              }
            }
          },
          "InvalidParameters": {
            "shape": "Sbf"
          }
        }
      }
    },
    "GetPatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "Name": {},
          "GlobalFilters": {
            "shape": "S2m"
          },
          "ApprovalRules": {
            "shape": "S2s"
          },
          "ApprovedPatches": {
            "shape": "S2w"
          },
          "RejectedPatches": {
            "shape": "S2w"
          },
          "PatchGroups": {
            "type": "list",
            "member": {}
          },
          "CreatedDate": {
            "type": "timestamp"
          },
          "ModifiedDate": {
            "type": "timestamp"
          },
          "Description": {}
        }
      }
    },
    "GetPatchBaselineForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "PatchGroup"
        ],
        "members": {
          "PatchGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      }
    },
    "ListAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationFilterList": {
            "type": "list",
            "member": {
              "locationName": "AssociationFilter",
              "type": "structure",
              "required": [
                "key",
                "value"
              ],
              "members": {
                "key": {},
                "value": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "type": "list",
            "member": {
              "locationName": "Association",
              "type": "structure",
              "members": {
                "Name": {},
                "InstanceId": {},
                "AssociationId": {},
                "DocumentVersion": {},
                "Targets": {
                  "shape": "Su"
                },
                "LastExecutionDate": {
                  "type": "timestamp"
                },
                "Overview": {
                  "shape": "S1c"
                },
                "ScheduleExpression": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCommandInvocations": {
      "input": {
        "type": "structure",
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sby"
          },
          "Details": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CommandInvocations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CommandId": {},
                "InstanceId": {},
                "InstanceName": {},
                "Comment": {},
                "DocumentName": {},
                "RequestedDateTime": {
                  "type": "timestamp"
                },
                "Status": {},
                "StatusDetails": {},
                "TraceOutput": {},
                "StandardOutputUrl": {},
                "StandardErrorUrl": {},
                "CommandPlugins": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Status": {},
                      "StatusDetails": {},
                      "ResponseCode": {
                        "type": "integer"
                      },
                      "ResponseStartDateTime": {
                        "type": "timestamp"
                      },
                      "ResponseFinishDateTime": {
                        "type": "timestamp"
                      },
                      "Output": {},
                      "StandardOutputUrl": {},
                      "StandardErrorUrl": {},
                      "OutputS3Region": {},
                      "OutputS3BucketName": {},
                      "OutputS3KeyPrefix": {}
                    }
                  }
                },
                "ServiceRole": {},
                "NotificationConfig": {
                  "shape": "Scb"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCommands": {
      "input": {
        "type": "structure",
        "members": {
          "CommandId": {},
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sby"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Commands": {
            "type": "list",
            "member": {
              "shape": "Scj"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocumentVersions": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "DocumentVersion": {},
                "CreatedDate": {
                  "type": "timestamp"
                },
                "IsDefaultVersion": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocuments": {
      "input": {
        "type": "structure",
        "members": {
          "DocumentFilterList": {
            "type": "list",
            "member": {
              "locationName": "DocumentFilter",
              "type": "structure",
              "required": [
                "key",
                "value"
              ],
              "members": {
                "key": {},
                "value": {}
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentIdentifiers": {
            "type": "list",
            "member": {
              "locationName": "DocumentIdentifier",
              "type": "structure",
              "members": {
                "Name": {},
                "Owner": {},
                "PlatformTypes": {
                  "shape": "S28"
                },
                "DocumentVersion": {},
                "DocumentType": {},
                "SchemaVersion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInventoryEntries": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "TypeName"
        ],
        "members": {
          "InstanceId": {},
          "TypeName": {},
          "Filters": {
            "shape": "Sa4"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TypeName": {},
          "InstanceId": {},
          "SchemaVersion": {},
          "CaptureTime": {},
          "Entries": {
            "shape": "San"
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S4"
          }
        }
      }
    },
    "ModifyDocumentPermission": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "PermissionType"
        ],
        "members": {
          "Name": {},
          "PermissionType": {},
          "AccountIdsToAdd": {
            "shape": "S5b"
          },
          "AccountIdsToRemove": {
            "shape": "S5b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutInventory": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Items"
        ],
        "members": {
          "InstanceId": {},
          "Items": {
            "type": "list",
            "member": {
              "locationName": "Item",
              "type": "structure",
              "required": [
                "TypeName",
                "SchemaVersion",
                "CaptureTime"
              ],
              "members": {
                "TypeName": {},
                "SchemaVersion": {},
                "CaptureTime": {},
                "ContentHash": {},
                "Content": {
                  "shape": "San"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutParameter": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Value",
          "Type"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Value": {},
          "Type": {},
          "KeyId": {},
          "Overwrite": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RegisterDefaultPatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {}
        }
      }
    },
    "RegisterPatchBaselineForPatchGroup": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId",
          "PatchGroup"
        ],
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "PatchGroup": {}
        }
      }
    },
    "RegisterTargetWithMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "ResourceType",
          "Targets"
        ],
        "members": {
          "WindowId": {},
          "ResourceType": {},
          "Targets": {
            "shape": "Su"
          },
          "OwnerInformation": {
            "shape": "S6n"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowTargetId": {}
        }
      }
    },
    "RegisterTaskWithMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId",
          "Targets",
          "TaskArn",
          "ServiceRoleArn",
          "TaskType",
          "MaxConcurrency",
          "MaxErrors"
        ],
        "members": {
          "WindowId": {},
          "Targets": {
            "shape": "Su"
          },
          "TaskArn": {},
          "ServiceRoleArn": {},
          "TaskType": {},
          "TaskParameters": {
            "shape": "S8d"
          },
          "Priority": {
            "type": "integer"
          },
          "MaxConcurrency": {},
          "MaxErrors": {},
          "LoggingInfo": {
            "shape": "S8j"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowTaskId": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SendCommand": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Sb"
          },
          "Targets": {
            "shape": "Su"
          },
          "DocumentName": {},
          "DocumentHash": {},
          "DocumentHashType": {},
          "TimeoutSeconds": {
            "type": "integer"
          },
          "Comment": {},
          "Parameters": {
            "shape": "Sq"
          },
          "OutputS3Region": {},
          "OutputS3BucketName": {},
          "OutputS3KeyPrefix": {},
          "MaxConcurrency": {},
          "MaxErrors": {},
          "ServiceRoleArn": {},
          "NotificationConfig": {
            "shape": "Scb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Command": {
            "shape": "Scj"
          }
        }
      }
    },
    "StartAutomationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentName"
        ],
        "members": {
          "DocumentName": {},
          "DocumentVersion": {},
          "Parameters": {
            "shape": "S4h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutomationExecutionId": {}
        }
      }
    },
    "StopAutomationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "AutomationExecutionId"
        ],
        "members": {
          "AutomationExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {},
          "Parameters": {
            "shape": "Sq"
          },
          "DocumentVersion": {},
          "ScheduleExpression": {},
          "OutputLocation": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S16"
          }
        }
      }
    },
    "UpdateAssociationStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "InstanceId",
          "AssociationStatus"
        ],
        "members": {
          "Name": {},
          "InstanceId": {},
          "AssociationStatus": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationDescription": {
            "shape": "S16"
          }
        }
      }
    },
    "UpdateDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Content",
          "Name"
        ],
        "members": {
          "Content": {},
          "Name": {},
          "DocumentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentDescription": {
            "shape": "S1u"
          }
        }
      }
    },
    "UpdateDocumentDefaultVersion": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "DocumentVersion"
        ],
        "members": {
          "Name": {},
          "DocumentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "type": "structure",
            "members": {
              "Name": {},
              "DefaultVersion": {}
            }
          }
        }
      }
    },
    "UpdateMaintenanceWindow": {
      "input": {
        "type": "structure",
        "required": [
          "WindowId"
        ],
        "members": {
          "WindowId": {},
          "Name": {},
          "Schedule": {},
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WindowId": {},
          "Name": {},
          "Schedule": {},
          "Duration": {
            "type": "integer"
          },
          "Cutoff": {
            "type": "integer"
          },
          "AllowUnassociatedTargets": {
            "type": "boolean"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateManagedInstanceRole": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "IamRole"
        ],
        "members": {
          "InstanceId": {},
          "IamRole": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdatePatchBaseline": {
      "input": {
        "type": "structure",
        "required": [
          "BaselineId"
        ],
        "members": {
          "BaselineId": {},
          "Name": {},
          "GlobalFilters": {
            "shape": "S2m"
          },
          "ApprovalRules": {
            "shape": "S2s"
          },
          "ApprovedPatches": {
            "shape": "S2w"
          },
          "RejectedPatches": {
            "shape": "S2w"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaselineId": {},
          "Name": {},
          "GlobalFilters": {
            "shape": "S2m"
          },
          "ApprovalRules": {
            "shape": "S2s"
          },
          "ApprovedPatches": {
            "shape": "S2w"
          },
          "RejectedPatches": {
            "shape": "S2w"
          },
          "CreatedDate": {
            "type": "timestamp"
          },
          "ModifiedDate": {
            "type": "timestamp"
          },
          "Description": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sq": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Su": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "S3Location": {
          "type": "structure",
          "members": {
            "OutputS3Region": {},
            "OutputS3BucketName": {},
            "OutputS3KeyPrefix": {}
          }
        }
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "Name": {},
        "InstanceId": {},
        "Date": {
          "type": "timestamp"
        },
        "LastUpdateAssociationDate": {
          "type": "timestamp"
        },
        "Status": {
          "shape": "S18"
        },
        "Overview": {
          "shape": "S1c"
        },
        "DocumentVersion": {},
        "Parameters": {
          "shape": "Sq"
        },
        "AssociationId": {},
        "Targets": {
          "shape": "Su"
        },
        "ScheduleExpression": {},
        "OutputLocation": {
          "shape": "S10"
        },
        "LastExecutionDate": {
          "type": "timestamp"
        },
        "LastSuccessfulExecutionDate": {
          "type": "timestamp"
        }
      }
    },
    "S18": {
      "type": "structure",
      "required": [
        "Date",
        "Name",
        "Message"
      ],
      "members": {
        "Date": {
          "type": "timestamp"
        },
        "Name": {},
        "Message": {},
        "AdditionalInfo": {}
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "Status": {},
        "DetailedStatus": {},
        "AssociationStatusAggregatedCount": {
          "type": "map",
          "key": {},
          "value": {
            "type": "integer"
          }
        }
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {},
        "InstanceId": {},
        "Parameters": {
          "shape": "Sq"
        },
        "DocumentVersion": {},
        "Targets": {
          "shape": "Su"
        },
        "ScheduleExpression": {},
        "OutputLocation": {
          "shape": "S10"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "Sha1": {},
        "Hash": {},
        "HashType": {},
        "Name": {},
        "Owner": {},
        "CreatedDate": {
          "type": "timestamp"
        },
        "Status": {},
        "DocumentVersion": {},
        "Description": {},
        "Parameters": {
          "type": "list",
          "member": {
            "locationName": "DocumentParameter",
            "type": "structure",
            "members": {
              "Name": {},
              "Type": {},
              "Description": {},
              "DefaultValue": {}
            }
          }
        },
        "PlatformTypes": {
          "shape": "S28"
        },
        "DocumentType": {},
        "SchemaVersion": {},
        "LatestVersion": {},
        "DefaultVersion": {}
      }
    },
    "S28": {
      "type": "list",
      "member": {
        "locationName": "PlatformType"
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "PatchFilters"
      ],
      "members": {
        "PatchFilters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Key",
              "Values"
            ],
            "members": {
              "Key": {},
              "Values": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S2s": {
      "type": "structure",
      "required": [
        "PatchRules"
      ],
      "members": {
        "PatchRules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "PatchFilterGroup",
              "ApproveAfterDays"
            ],
            "members": {
              "PatchFilterGroup": {
                "shape": "S2m"
              },
              "ApproveAfterDays": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S2w": {
      "type": "list",
      "member": {}
    },
    "S4h": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "S4m": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S4u": {
      "type": "structure",
      "members": {
        "Id": {},
        "ReleaseDate": {
          "type": "timestamp"
        },
        "Title": {},
        "Description": {},
        "ContentUrl": {},
        "Vendor": {},
        "ProductFamily": {},
        "Product": {},
        "Classification": {},
        "MsrcSeverity": {},
        "KbNumber": {},
        "MsrcNumber": {},
        "Language": {}
      }
    },
    "S5b": {
      "type": "list",
      "member": {
        "locationName": "AccountId"
      }
    },
    "S61": {
      "type": "list",
      "member": {
        "locationName": "InstanceInformationFilterValue"
      }
    },
    "S6l": {
      "type": "structure",
      "required": [
        "InstanceId",
        "PatchGroup",
        "BaselineId",
        "OperationStartTime",
        "OperationEndTime",
        "Operation"
      ],
      "members": {
        "InstanceId": {},
        "PatchGroup": {},
        "BaselineId": {},
        "SnapshotId": {},
        "OwnerInformation": {
          "shape": "S6n"
        },
        "InstalledCount": {
          "type": "integer"
        },
        "InstalledOtherCount": {
          "type": "integer"
        },
        "MissingCount": {
          "type": "integer"
        },
        "FailedCount": {
          "type": "integer"
        },
        "NotApplicableCount": {
          "type": "integer"
        },
        "OperationStartTime": {
          "type": "timestamp"
        },
        "OperationEndTime": {
          "type": "timestamp"
        },
        "Operation": {}
      }
    },
    "S6n": {
      "type": "string",
      "sensitive": true
    },
    "S7f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S8d": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Values": {
            "type": "list",
            "member": {
              "type": "string",
              "sensitive": true
            },
            "sensitive": true
          }
        },
        "sensitive": true
      },
      "sensitive": true
    },
    "S8j": {
      "type": "structure",
      "required": [
        "S3BucketName",
        "S3Region"
      ],
      "members": {
        "S3BucketName": {},
        "S3KeyPrefix": {},
        "S3Region": {}
      }
    },
    "S96": {
      "type": "structure",
      "members": {
        "BaselineId": {},
        "BaselineName": {},
        "BaselineDescription": {},
        "DefaultBaseline": {
          "type": "boolean"
        }
      }
    },
    "Sa4": {
      "type": "list",
      "member": {
        "locationName": "InventoryFilter",
        "type": "structure",
        "required": [
          "Key",
          "Values"
        ],
        "members": {
          "Key": {},
          "Values": {
            "type": "list",
            "member": {
              "locationName": "FilterValue"
            }
          },
          "Type": {}
        }
      }
    },
    "San": {
      "type": "list",
      "member": {
        "type": "map",
        "key": {},
        "value": {}
      }
    },
    "Sbf": {
      "type": "list",
      "member": {}
    },
    "Sby": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Scb": {
      "type": "structure",
      "members": {
        "NotificationArn": {},
        "NotificationEvents": {
          "type": "list",
          "member": {}
        },
        "NotificationType": {}
      }
    },
    "Scj": {
      "type": "structure",
      "members": {
        "CommandId": {},
        "DocumentName": {},
        "Comment": {},
        "ExpiresAfter": {
          "type": "timestamp"
        },
        "Parameters": {
          "shape": "Sq"
        },
        "InstanceIds": {
          "shape": "Sb"
        },
        "Targets": {
          "shape": "Su"
        },
        "RequestedDateTime": {
          "type": "timestamp"
        },
        "Status": {},
        "StatusDetails": {},
        "OutputS3Region": {},
        "OutputS3BucketName": {},
        "OutputS3KeyPrefix": {},
        "MaxConcurrency": {},
        "MaxErrors": {},
        "TargetCount": {
          "type": "integer"
        },
        "CompletedCount": {
          "type": "integer"
        },
        "ErrorCount": {
          "type": "integer"
        },
        "ServiceRole": {},
        "NotificationConfig": {
          "shape": "Scb"
        }
      }
    }
  }
}