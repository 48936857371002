
import {SpotDB,ListResult,generateID,AttributeSerializer} from './db';

/** Base class for objects that support serialization to the database or api */
export class SpotSerializableObject {

    /** Serializes object state to the serializer form. */
    getAttrs(s:AttributeSerializer) : any {
        throw new Error('Must be implemented in derived class.');
    }

    /** Initializes this object instance with data provided by a serializer */
    setAttrs(s:AttributeSerializer, a:any) {
        throw new Error('Must be implemented in derived class.');
    }
}

/** Base class for objects that support storage in a collection */
export class SpotBaseObject extends SpotSerializableObject {
    /** @param tablename    The name of the table in DynamoDB (specified in the constructor of the derived object).
     */
    constructor(db:SpotDB, tablename:string) {
        super();
        this._db = db;
        this._tablename = tablename;
    }
    protected _db:SpotDB;
    private _tablename:string;

    /** unique id of the object when stored in DynamoDB, generated automatically if not provided */
    id: string;

    /** Save this object to DynamoDB */
    async save() : Promise<void> {
        if (!this.id) {
            this.id = generateID();
        }
        await this._db.put(this._tablename, this.id, this.getAttrs(this._db.serializer));
    }

    /** Delete this object from DynamoDB */
    async delete() : Promise<void> {
        await this._db.delete(this._tablename, this.id);
    }

}