module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-02-16",
    "endpointPrefix": "inspector",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Inspector",
    "signatureVersion": "v4",
    "targetPrefix": "InspectorService",
    "uid": "inspector-2016-02-16"
  },
  "operations": {
    "AddAttributesToFindings": {
      "input": {
        "type": "structure",
        "required": [
          "findingArns",
          "attributes"
        ],
        "members": {
          "findingArns": {
            "shape": "S2"
          },
          "attributes": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "failedItems"
        ],
        "members": {
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "CreateAssessmentTarget": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetName",
          "resourceGroupArn"
        ],
        "members": {
          "assessmentTargetName": {},
          "resourceGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTargetArn"
        ],
        "members": {
          "assessmentTargetArn": {}
        }
      }
    },
    "CreateAssessmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArn",
          "assessmentTemplateName",
          "durationInSeconds",
          "rulesPackageArns"
        ],
        "members": {
          "assessmentTargetArn": {},
          "assessmentTemplateName": {},
          "durationInSeconds": {
            "type": "integer"
          },
          "rulesPackageArns": {
            "shape": "Sj"
          },
          "userAttributesForFindings": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn"
        ],
        "members": {
          "assessmentTemplateArn": {}
        }
      }
    },
    "CreateResourceGroup": {
      "input": {
        "type": "structure",
        "required": [
          "resourceGroupTags"
        ],
        "members": {
          "resourceGroupTags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resourceGroupArn"
        ],
        "members": {
          "resourceGroupArn": {}
        }
      }
    },
    "DeleteAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {}
        }
      }
    },
    "DeleteAssessmentTarget": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArn"
        ],
        "members": {
          "assessmentTargetArn": {}
        }
      }
    },
    "DeleteAssessmentTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn"
        ],
        "members": {
          "assessmentTemplateArn": {}
        }
      }
    },
    "DescribeAssessmentRuns": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArns"
        ],
        "members": {
          "assessmentRunArns": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRuns",
          "failedItems"
        ],
        "members": {
          "assessmentRuns": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "assessmentTemplateArn",
                "state",
                "durationInSeconds",
                "rulesPackageArns",
                "userAttributesForFindings",
                "createdAt",
                "stateChangedAt",
                "dataCollected",
                "stateChanges",
                "notifications"
              ],
              "members": {
                "arn": {},
                "name": {},
                "assessmentTemplateArn": {},
                "state": {},
                "durationInSeconds": {
                  "type": "integer"
                },
                "rulesPackageArns": {
                  "type": "list",
                  "member": {}
                },
                "userAttributesForFindings": {
                  "shape": "S4"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "startedAt": {
                  "type": "timestamp"
                },
                "completedAt": {
                  "type": "timestamp"
                },
                "stateChangedAt": {
                  "type": "timestamp"
                },
                "dataCollected": {
                  "type": "boolean"
                },
                "stateChanges": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "stateChangedAt",
                      "state"
                    ],
                    "members": {
                      "stateChangedAt": {
                        "type": "timestamp"
                      },
                      "state": {}
                    }
                  }
                },
                "notifications": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "date",
                      "event",
                      "error"
                    ],
                    "members": {
                      "date": {
                        "type": "timestamp"
                      },
                      "event": {},
                      "message": {},
                      "error": {
                        "type": "boolean"
                      },
                      "snsTopicArn": {},
                      "snsPublishStatusCode": {}
                    }
                  }
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeAssessmentTargets": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArns"
        ],
        "members": {
          "assessmentTargetArns": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTargets",
          "failedItems"
        ],
        "members": {
          "assessmentTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "resourceGroupArn",
                "createdAt",
                "updatedAt"
              ],
              "members": {
                "arn": {},
                "name": {},
                "resourceGroupArn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeAssessmentTemplates": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArns"
        ],
        "members": {
          "assessmentTemplateArns": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTemplates",
          "failedItems"
        ],
        "members": {
          "assessmentTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "assessmentTargetArn",
                "durationInSeconds",
                "rulesPackageArns",
                "userAttributesForFindings",
                "createdAt"
              ],
              "members": {
                "arn": {},
                "name": {},
                "assessmentTargetArn": {},
                "durationInSeconds": {
                  "type": "integer"
                },
                "rulesPackageArns": {
                  "shape": "Sj"
                },
                "userAttributesForFindings": {
                  "shape": "S4"
                },
                "createdAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeCrossAccountAccessRole": {
      "output": {
        "type": "structure",
        "required": [
          "roleArn",
          "valid",
          "registeredAt"
        ],
        "members": {
          "roleArn": {},
          "valid": {
            "type": "boolean"
          },
          "registeredAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeFindings": {
      "input": {
        "type": "structure",
        "required": [
          "findingArns"
        ],
        "members": {
          "findingArns": {
            "shape": "Sv"
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "findings",
          "failedItems"
        ],
        "members": {
          "findings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "attributes",
                "userAttributes",
                "createdAt",
                "updatedAt"
              ],
              "members": {
                "arn": {},
                "schemaVersion": {
                  "type": "integer"
                },
                "service": {},
                "serviceAttributes": {
                  "type": "structure",
                  "required": [
                    "schemaVersion"
                  ],
                  "members": {
                    "schemaVersion": {
                      "type": "integer"
                    },
                    "assessmentRunArn": {},
                    "rulesPackageArn": {}
                  }
                },
                "assetType": {},
                "assetAttributes": {
                  "type": "structure",
                  "required": [
                    "schemaVersion"
                  ],
                  "members": {
                    "schemaVersion": {
                      "type": "integer"
                    },
                    "agentId": {},
                    "autoScalingGroup": {},
                    "amiId": {},
                    "hostname": {},
                    "ipv4Addresses": {
                      "type": "list",
                      "member": {}
                    }
                  }
                },
                "id": {},
                "title": {},
                "description": {},
                "recommendation": {},
                "severity": {},
                "numericSeverity": {
                  "type": "double"
                },
                "confidence": {
                  "type": "integer"
                },
                "indicatorOfCompromise": {
                  "type": "boolean"
                },
                "attributes": {
                  "shape": "S24"
                },
                "userAttributes": {
                  "shape": "S4"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeResourceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "resourceGroupArns"
        ],
        "members": {
          "resourceGroupArns": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "resourceGroups",
          "failedItems"
        ],
        "members": {
          "resourceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "tags",
                "createdAt"
              ],
              "members": {
                "arn": {},
                "tags": {
                  "shape": "Sm"
                },
                "createdAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeRulesPackages": {
      "input": {
        "type": "structure",
        "required": [
          "rulesPackageArns"
        ],
        "members": {
          "rulesPackageArns": {
            "shape": "Sv"
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "rulesPackages",
          "failedItems"
        ],
        "members": {
          "rulesPackages": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "name",
                "version",
                "provider"
              ],
              "members": {
                "arn": {},
                "name": {},
                "version": {},
                "provider": {},
                "description": {}
              }
            }
          },
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "GetTelemetryMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "telemetryMetadata"
        ],
        "members": {
          "telemetryMetadata": {
            "shape": "S2i"
          }
        }
      }
    },
    "ListAssessmentRunAgents": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {},
          "filter": {
            "type": "structure",
            "required": [
              "agentHealths",
              "agentHealthCodes"
            ],
            "members": {
              "agentHealths": {
                "type": "list",
                "member": {}
              },
              "agentHealthCodes": {
                "type": "list",
                "member": {}
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRunAgents"
        ],
        "members": {
          "assessmentRunAgents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "agentId",
                "assessmentRunArn",
                "agentHealth",
                "agentHealthCode",
                "telemetryMetadata"
              ],
              "members": {
                "agentId": {},
                "assessmentRunArn": {},
                "agentHealth": {},
                "agentHealthCode": {},
                "agentHealthDetails": {},
                "autoScalingGroup": {},
                "telemetryMetadata": {
                  "shape": "S2i"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAssessmentRuns": {
      "input": {
        "type": "structure",
        "members": {
          "assessmentTemplateArns": {
            "shape": "S2y"
          },
          "filter": {
            "type": "structure",
            "members": {
              "namePattern": {},
              "states": {
                "type": "list",
                "member": {}
              },
              "durationRange": {
                "shape": "S32"
              },
              "rulesPackageArns": {
                "shape": "S33"
              },
              "startTimeRange": {
                "shape": "S34"
              },
              "completionTimeRange": {
                "shape": "S34"
              },
              "stateChangeTimeRange": {
                "shape": "S34"
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRunArns"
        ],
        "members": {
          "assessmentRunArns": {
            "shape": "S36"
          },
          "nextToken": {}
        }
      }
    },
    "ListAssessmentTargets": {
      "input": {
        "type": "structure",
        "members": {
          "filter": {
            "type": "structure",
            "members": {
              "assessmentTargetNamePattern": {}
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTargetArns"
        ],
        "members": {
          "assessmentTargetArns": {
            "shape": "S36"
          },
          "nextToken": {}
        }
      }
    },
    "ListAssessmentTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "assessmentTargetArns": {
            "shape": "S2y"
          },
          "filter": {
            "type": "structure",
            "members": {
              "namePattern": {},
              "durationRange": {
                "shape": "S32"
              },
              "rulesPackageArns": {
                "shape": "S33"
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentTemplateArns"
        ],
        "members": {
          "assessmentTemplateArns": {
            "shape": "S36"
          },
          "nextToken": {}
        }
      }
    },
    "ListEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "resourceArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "subscriptions"
        ],
        "members": {
          "subscriptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "resourceArn",
                "topicArn",
                "eventSubscriptions"
              ],
              "members": {
                "resourceArn": {},
                "topicArn": {},
                "eventSubscriptions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "event",
                      "subscribedAt"
                    ],
                    "members": {
                      "event": {},
                      "subscribedAt": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListFindings": {
      "input": {
        "type": "structure",
        "members": {
          "assessmentRunArns": {
            "shape": "S2y"
          },
          "filter": {
            "type": "structure",
            "members": {
              "agentIds": {
                "type": "list",
                "member": {}
              },
              "autoScalingGroups": {
                "type": "list",
                "member": {}
              },
              "ruleNames": {
                "type": "list",
                "member": {}
              },
              "severities": {
                "type": "list",
                "member": {}
              },
              "rulesPackageArns": {
                "shape": "S33"
              },
              "attributes": {
                "shape": "S24"
              },
              "userAttributes": {
                "shape": "S24"
              },
              "creationTimeRange": {
                "shape": "S34"
              }
            }
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "findingArns"
        ],
        "members": {
          "findingArns": {
            "shape": "S36"
          },
          "nextToken": {}
        }
      }
    },
    "ListRulesPackages": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "rulesPackageArns"
        ],
        "members": {
          "rulesPackageArns": {
            "shape": "S36"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "S3w"
          }
        }
      }
    },
    "PreviewAgents": {
      "input": {
        "type": "structure",
        "required": [
          "previewAgentsArn"
        ],
        "members": {
          "previewAgentsArn": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "agentPreviews"
        ],
        "members": {
          "agentPreviews": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "agentId"
              ],
              "members": {
                "agentId": {},
                "autoScalingGroup": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "RegisterCrossAccountAccessRole": {
      "input": {
        "type": "structure",
        "required": [
          "roleArn"
        ],
        "members": {
          "roleArn": {}
        }
      }
    },
    "RemoveAttributesFromFindings": {
      "input": {
        "type": "structure",
        "required": [
          "findingArns",
          "attributeKeys"
        ],
        "members": {
          "findingArns": {
            "shape": "S2"
          },
          "attributeKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "failedItems"
        ],
        "members": {
          "failedItems": {
            "shape": "S9"
          }
        }
      }
    },
    "SetTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S3w"
          }
        }
      }
    },
    "StartAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTemplateArn"
        ],
        "members": {
          "assessmentTemplateArn": {},
          "assessmentRunName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {}
        }
      }
    },
    "StopAssessmentRun": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentRunArn"
        ],
        "members": {
          "assessmentRunArn": {}
        }
      }
    },
    "SubscribeToEvent": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "event",
          "topicArn"
        ],
        "members": {
          "resourceArn": {},
          "event": {},
          "topicArn": {}
        }
      }
    },
    "UnsubscribeFromEvent": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "event",
          "topicArn"
        ],
        "members": {
          "resourceArn": {},
          "event": {},
          "topicArn": {}
        }
      }
    },
    "UpdateAssessmentTarget": {
      "input": {
        "type": "structure",
        "required": [
          "assessmentTargetArn",
          "assessmentTargetName",
          "resourceGroupArn"
        ],
        "members": {
          "assessmentTargetArn": {},
          "assessmentTargetName": {},
          "resourceGroupArn": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S4": {
      "type": "list",
      "member": {
        "shape": "S5"
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "key"
      ],
      "members": {
        "key": {},
        "value": {}
      }
    },
    "S9": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "failureCode",
          "retryable"
        ],
        "members": {
          "failureCode": {},
          "retryable": {
            "type": "boolean"
          }
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {}
    },
    "S24": {
      "type": "list",
      "member": {
        "shape": "S5"
      }
    },
    "S2i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "messageType",
          "count"
        ],
        "members": {
          "messageType": {},
          "count": {
            "type": "long"
          },
          "dataSize": {
            "type": "long"
          }
        }
      }
    },
    "S2y": {
      "type": "list",
      "member": {}
    },
    "S32": {
      "type": "structure",
      "members": {
        "minSeconds": {
          "type": "integer"
        },
        "maxSeconds": {
          "type": "integer"
        }
      }
    },
    "S33": {
      "type": "list",
      "member": {}
    },
    "S34": {
      "type": "structure",
      "members": {
        "beginDate": {
          "type": "timestamp"
        },
        "endDate": {
          "type": "timestamp"
        }
      }
    },
    "S36": {
      "type": "list",
      "member": {}
    },
    "S3w": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    }
  }
}