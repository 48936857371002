module.exports={
  "pagination": {
    "ListHealthChecks": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "NextMarker",
      "result_key": "HealthChecks"
    },
    "ListHostedZones": {
      "input_token": "Marker",
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": "NextMarker",
      "result_key": "HostedZones"
    },
    "ListResourceRecordSets": {
      "input_token": [
        "StartRecordName",
        "StartRecordType",
        "StartRecordIdentifier"
      ],
      "limit_key": "MaxItems",
      "more_results": "IsTruncated",
      "output_token": [
        "NextRecordName",
        "NextRecordType",
        "NextRecordIdentifier"
      ],
      "result_key": "ResourceRecordSets"
    }
  }
}