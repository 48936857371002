import * as AWS from 'aws-sdk';
import {SpotDB,ListResult,AttributeSerializer,FilterExpression,generateID} from './db';
import {config} from './config';
import {SpotBaseObject,SpotSerializableObject} from './base';
import {JurisdictionRef} from './jurisdiction';
import {ConfigValue} from './signconfig';
import * as Geohash from 'latlon-geohash';

/** Represents an individual street sign */
export class Sign extends SpotSerializableObject {
    /** String token reference to corresponding SignConfig object */
    category?: string;
    /** True if sign affects left side */
    directionLeft: boolean = false;
    /** True if sign affects right side */
    directionRight: boolean = false;
    /** Attributes as defined within the corresponding SignConfig object */
    attributes: Map<string,ConfigValue> = new Map();
    /** A container of text information about the object */
    notes: string[] = [];
    /** sign image hash value */
    imageHash?: string;

    signedImagePutUrl(db:SpotDB) : Promise<string> {
        if (!this.imageHash) {
            this.imageHash = generateID();
        }
        return Sign.signedImagePutUrl(db, this.imageHash);
    }

    static signedImagePutUrl(db:SpotDB, imageHash: string) : Promise<string> {
        let pxy = db.proxy;
        if (!!pxy) {
            return pxy.proxyGetSignedPutUrl(imageHash);
        }
        let params = {
            ACL: 'public-read',
            Bucket: config.image_bucket,
            Key: Sign.imageKeyOriginal(imageHash)
        };
        let s3 = new AWS.S3({
          region: config.image_region,
          signatureVersion: 'v4'
        });
        return Promise.resolve(s3.getSignedUrl('putObject', params));
    }

    get imageKeyOriginal() : string {
        if (!this.imageHash) {
            this.imageHash = generateID();
        }
        return Sign.imageKeyOriginal(this.imageHash);
    }

    static imageKeyOriginal(imageHash:string) : string {
        return `${config.spot_environment}/parking-signs/${imageHash}-original.jpg`;
    }

    get imageUrl() : string {
        if (!this.imageHash) {
            this.imageHash = generateID();
        }
        return `http://${config.image_bucket}.s3.amazonaws.com/${this.imageKeyOriginal}`;
    }

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromString(this.category, "category"),
            ...s.fromBool(this.directionLeft, "directionLeft"),
            ...s.fromBool(this.directionRight, "directionRight"),
            ...s.fromObjectMap(this.attributes, "attributes"),
            ...s.fromStringArray(this.notes, "notes"),
            ...s.fromString(this.imageHash, "imageHash"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.category, v => this.category = v);
        s.setBool(a.directionLeft, v => this.directionLeft = v);
        s.setBool(a.directionRight, v => this.directionRight = v);
        s.setObjectMap(a.attributes, ConfigValue, v => this.attributes = v);
        s.setStringArray(a.notes, v => this.notes = v);
        s.setString(a.imageHash, v => this.imageHash = v);
    }

    static fromAttr(s:AttributeSerializer, a:any) : Sign {
        let z = new Sign();
        z.setAttrs(s,a);
        return z;
    }

}

/** Represents a reference to a Datapoint in the database */
export class DatapointRef {
    constructor(id:string) {
        this.id = id;
    }
    id: string;

    private _queried = false;
    private _inst:Datapoint;

    /** retrieves an instance of the referenced Datapoint from the database */
    async datapoint(db:SpotDB) : Promise<Datapoint> {
        if (!this._inst && !this._queried) {
            this._inst = await Datapoint.get(db, this.id);
            this._queried = true;
        }
        return this._inst;
    }
}

/** Wraps a Sign object with additional priority context */
export class SignPriority extends SpotSerializableObject {

    /** The priority of the sign when processing (higher number overrides lower numbers) */
    priority?: number;
    /** A sign object */
    sign?: Sign;

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromInteger(this.priority, "priority"),
            ...s.fromObject(this.sign, "sign"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setInteger(a.priority, v => this.priority = v);
        s.setObject(a.sign, Sign, v => this.sign = v);
    }

    static fromAttr(s:AttributeSerializer, a:any) : SignPriority {
        let z = new SignPriority();
        z.setAttrs(s, a);
        return z;
    }
}

export type DatapointStatus = "DATA_COLLECTED" | "PREPROCESSED";

/** A DataPoint is a location specific reference point.  Onstreet parking signs, meters
 * and other forms of parking information are captured and defined (interpreted) within
 * a data point.
 *
 * Each individual sign has a defined category – this references a signConfig object which is
 * defined somewhere within the jurisdiction hierarchy.
 *
 * Attributes as defined within the SignConfig object are defined within the Sign object.
 *
 * A sign is configured with a priority.  This defines the order of processing (higher
 * numbers indicate ability to override signs with lower numbers).  This is useful where
 * you have multiple signs that conflict each other (sets precedence).
 */
export class Datapoint extends SpotBaseObject {
    static TableName = 'datapoints';

    constructor(db:SpotDB) {
        super(db, Datapoint.TableName);
    }

    /** References the Jurisdiction that contains this Datapoint */
    jurisdiction?: JurisdictionRef;
    /** Data collection reference id */
    rawSignId?: number;
    /** Data collection photo url */
    rawSignUrl?: string;
    /** Latitude reference */
    latitude?: number;
    /** Longitude reference */
    longitude?: number;
    /** An array of interpreted signs */
    signs: SignPriority[] = [];
    /** Completion status of the Datapoint */
    status?: DatapointStatus;

    get geohash() : string {
        if (isNaN(this.latitude) || isNaN(this.longitude)) {
            return null;
        }
        return Geohash.encode(this.latitude, this.longitude, 12);
    }

    getAttrs(s:AttributeSerializer) : any {
        return {
            ...s.fromString(this.id, "id"),
            ...s.fromString(this.geohash, "geohash"),
            ...s.fromString(this.jurisdiction && this.jurisdiction.id, "jurisdiction"),
            ...s.fromInteger(this.rawSignId, "rawSignId"),
            ...s.fromString(this.rawSignUrl, "rawSignUrl"),
            ...s.fromFloat(this.latitude, "latitude"),
            ...s.fromFloat(this.longitude, "longitude"),
            ...s.fromObjectArray(this.signs, "signs"),
            ...s.fromString(this.status, "status"),
        };
    }

    setAttrs(s:AttributeSerializer, a:any) {
        s.setString(a.id, v => this.id = v);
        s.setString(a.jurisdiction, v => {
            let id = typeof v === 'object' ? (v as any).id : v;
            this.jurisdiction = new JurisdictionRef(id);
        });
        s.setInteger(a.rawSignId, v => this.rawSignId = v);
        s.setString(a.rawSignUrl, v => this.rawSignUrl = v);
        s.setFloat(a.latitude, v => this.latitude = v);
        s.setFloat(a.longitude, v => this.longitude = v);
        s.setObjectArray(a.signs, SignPriority, v => this.signs = v);
        s.setString(a.status, (v:DatapointStatus) => this.status = v );
    }

    static fromAttrs(db:SpotDB, a:any) : Datapoint {
        let z = new Datapoint(db);
        z.setAttrs(db.serializer, a);
        return z;
    }

    /** Load a single Datapoint instance from the database */
    static async get(db:SpotDB, id:string) : Promise<Datapoint> {
        let data = await db.get(this.TableName, id);
        if (!data) {
            return null;
        }
        let j = new this(db);
        j.setAttrs(db.serializer, data);
        return j;
    }

    /** Returns the ids of Datapoints that exist in the database. */
    static listIds(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<string>> {
        return db.listIds(this.TableName, startAfter, limit);
    }

    /** Returns the Datapoint instances that exist in the database. */
    static list(db:SpotDB, startAfter?:string, limit?: number) : Promise<ListResult<Datapoint>> {
        return db.list(this.TableName, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => Datapoint.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Returns the Datapoint instances that exist in the database. */
    static listWithFilter(db:SpotDB, filter:FilterExpression, startAfter?:string, limit?: number) : Promise<ListResult<Datapoint>> {
        return db.listWithFilter(this.TableName, filter, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => Datapoint.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Lists Datapoints filtered by status */
    static listByStatus(db:SpotDB, status:DatapointStatus, startAfter?:string, limit?: number) : Promise<ListResult<Datapoint>> {
        let filt = {
            expression: '#ST = :s',
            attributeValues: { '#ST': 'status' },
            filterValues: { ':s': status },
        };
        return this.listWithFilter(db, filt, startAfter, limit);
    }

    static listByIndex(db:SpotDB, indexName:string, attributeName:string, value:string, startAfter?:string, limit?: number) : Promise<ListResult<Datapoint>> {
        return db.listByIndex(this.TableName, indexName, attributeName, value, startAfter, limit).then(res => {
            return {
                items: res.items.map(i => this.fromAttrs(db, i)),
                lastKey: res.lastKey,
            }
        });
    }

    /** Delete a group of Datapoint instances from the database. */
    static bulkDelete(db:SpotDB, ids:string[]) : Promise<void> {
        return db.batchDelete(this.TableName, ids);
    }
}
