module.exports={
  "version": "2.0",
  "metadata": {
    "apiVersion": "2011-01-01",
    "endpointPrefix": "autoscaling",
    "protocol": "query",
    "serviceFullName": "Auto Scaling",
    "signatureVersion": "v4",
    "uid": "autoscaling-2011-01-01",
    "xmlNamespace": "http://autoscaling.amazonaws.com/doc/2011-01-01/"
  },
  "operations": {
    "AttachInstances": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {}
        }
      }
    },
    "AttachLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TargetGroupARNs"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TargetGroupARNs": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "resultWrapper": "AttachLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {}
      }
    },
    "AttachLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "LoadBalancerNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LoadBalancerNames": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "AttachLoadBalancersResult",
        "type": "structure",
        "members": {}
      }
    },
    "CompleteLifecycleAction": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName",
          "LifecycleActionResult"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleActionToken": {},
          "LifecycleActionResult": {},
          "InstanceId": {}
        }
      },
      "output": {
        "resultWrapper": "CompleteLifecycleActionResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "MinSize",
          "MaxSize"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LaunchConfigurationName": {},
          "InstanceId": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          },
          "DefaultCooldown": {
            "type": "integer"
          },
          "AvailabilityZones": {
            "shape": "Sn"
          },
          "LoadBalancerNames": {
            "shape": "Sa"
          },
          "TargetGroupARNs": {
            "shape": "S6"
          },
          "HealthCheckType": {},
          "HealthCheckGracePeriod": {
            "type": "integer"
          },
          "PlacementGroup": {},
          "VPCZoneIdentifier": {},
          "TerminationPolicies": {
            "shape": "Sr"
          },
          "NewInstancesProtectedFromScaleIn": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "Su"
          }
        }
      }
    },
    "CreateLaunchConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchConfigurationName"
        ],
        "members": {
          "LaunchConfigurationName": {},
          "ImageId": {},
          "KeyName": {},
          "SecurityGroups": {
            "shape": "S11"
          },
          "ClassicLinkVPCId": {},
          "ClassicLinkVPCSecurityGroups": {
            "shape": "S12"
          },
          "UserData": {},
          "InstanceId": {},
          "InstanceType": {},
          "KernelId": {},
          "RamdiskId": {},
          "BlockDeviceMappings": {
            "shape": "S14"
          },
          "InstanceMonitoring": {
            "shape": "S1d"
          },
          "SpotPrice": {},
          "IamInstanceProfile": {},
          "EbsOptimized": {
            "type": "boolean"
          },
          "AssociatePublicIpAddress": {
            "type": "boolean"
          },
          "PlacementTenancy": {}
        }
      }
    },
    "CreateOrUpdateTags": {
      "input": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "Su"
          }
        }
      }
    },
    "DeleteAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ForceDelete": {
            "type": "boolean"
          }
        }
      }
    },
    "DeleteLaunchConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchConfigurationName"
        ],
        "members": {
          "LaunchConfigurationName": {}
        }
      }
    },
    "DeleteLifecycleHook": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteLifecycleHookResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNotificationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TopicARN"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TopicARN": {}
        }
      }
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {}
        }
      }
    },
    "DeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionName": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "Tags"
        ],
        "members": {
          "Tags": {
            "shape": "Su"
          }
        }
      }
    },
    "DescribeAccountLimits": {
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "MaxNumberOfAutoScalingGroups": {
            "type": "integer"
          },
          "MaxNumberOfLaunchConfigurations": {
            "type": "integer"
          },
          "NumberOfAutoScalingGroups": {
            "type": "integer"
          },
          "NumberOfLaunchConfigurations": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeAdjustmentTypes": {
      "output": {
        "resultWrapper": "DescribeAdjustmentTypesResult",
        "type": "structure",
        "members": {
          "AdjustmentTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AdjustmentType": {}
              }
            }
          }
        }
      }
    },
    "DescribeAutoScalingGroups": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupNames": {
            "shape": "S22"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeAutoScalingGroupsResult",
        "type": "structure",
        "required": [
          "AutoScalingGroups"
        ],
        "members": {
          "AutoScalingGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AutoScalingGroupName",
                "MinSize",
                "MaxSize",
                "DesiredCapacity",
                "DefaultCooldown",
                "AvailabilityZones",
                "HealthCheckType",
                "CreatedTime"
              ],
              "members": {
                "AutoScalingGroupName": {},
                "AutoScalingGroupARN": {},
                "LaunchConfigurationName": {},
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                },
                "DefaultCooldown": {
                  "type": "integer"
                },
                "AvailabilityZones": {
                  "shape": "Sn"
                },
                "LoadBalancerNames": {
                  "shape": "Sa"
                },
                "TargetGroupARNs": {
                  "shape": "S6"
                },
                "HealthCheckType": {},
                "HealthCheckGracePeriod": {
                  "type": "integer"
                },
                "Instances": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "InstanceId",
                      "AvailabilityZone",
                      "LifecycleState",
                      "HealthStatus",
                      "LaunchConfigurationName",
                      "ProtectedFromScaleIn"
                    ],
                    "members": {
                      "InstanceId": {},
                      "AvailabilityZone": {},
                      "LifecycleState": {},
                      "HealthStatus": {},
                      "LaunchConfigurationName": {},
                      "ProtectedFromScaleIn": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "SuspendedProcesses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ProcessName": {},
                      "SuspensionReason": {}
                    }
                  }
                },
                "PlacementGroup": {},
                "VPCZoneIdentifier": {},
                "EnabledMetrics": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {},
                      "Granularity": {}
                    }
                  }
                },
                "Status": {},
                "Tags": {
                  "shape": "S2f"
                },
                "TerminationPolicies": {
                  "shape": "Sr"
                },
                "NewInstancesProtectedFromScaleIn": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutoScalingInstances": {
      "input": {
        "type": "structure",
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAutoScalingInstancesResult",
        "type": "structure",
        "members": {
          "AutoScalingInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceId",
                "AutoScalingGroupName",
                "AvailabilityZone",
                "LifecycleState",
                "HealthStatus",
                "LaunchConfigurationName",
                "ProtectedFromScaleIn"
              ],
              "members": {
                "InstanceId": {},
                "AutoScalingGroupName": {},
                "AvailabilityZone": {},
                "LifecycleState": {},
                "HealthStatus": {},
                "LaunchConfigurationName": {},
                "ProtectedFromScaleIn": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAutoScalingNotificationTypes": {
      "output": {
        "resultWrapper": "DescribeAutoScalingNotificationTypesResult",
        "type": "structure",
        "members": {
          "AutoScalingNotificationTypes": {
            "shape": "S2m"
          }
        }
      }
    },
    "DescribeLaunchConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "LaunchConfigurationNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLaunchConfigurationsResult",
        "type": "structure",
        "required": [
          "LaunchConfigurations"
        ],
        "members": {
          "LaunchConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LaunchConfigurationName",
                "ImageId",
                "InstanceType",
                "CreatedTime"
              ],
              "members": {
                "LaunchConfigurationName": {},
                "LaunchConfigurationARN": {},
                "ImageId": {},
                "KeyName": {},
                "SecurityGroups": {
                  "shape": "S11"
                },
                "ClassicLinkVPCId": {},
                "ClassicLinkVPCSecurityGroups": {
                  "shape": "S12"
                },
                "UserData": {},
                "InstanceType": {},
                "KernelId": {},
                "RamdiskId": {},
                "BlockDeviceMappings": {
                  "shape": "S14"
                },
                "InstanceMonitoring": {
                  "shape": "S1d"
                },
                "SpotPrice": {},
                "IamInstanceProfile": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "EbsOptimized": {
                  "type": "boolean"
                },
                "AssociatePublicIpAddress": {
                  "type": "boolean"
                },
                "PlacementTenancy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLifecycleHookTypes": {
      "output": {
        "resultWrapper": "DescribeLifecycleHookTypesResult",
        "type": "structure",
        "members": {
          "LifecycleHookTypes": {
            "shape": "S2m"
          }
        }
      }
    },
    "DescribeLifecycleHooks": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LifecycleHookNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLifecycleHooksResult",
        "type": "structure",
        "members": {
          "LifecycleHooks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LifecycleHookName": {},
                "AutoScalingGroupName": {},
                "LifecycleTransition": {},
                "NotificationTargetARN": {},
                "RoleARN": {},
                "NotificationMetadata": {},
                "HeartbeatTimeout": {
                  "type": "integer"
                },
                "GlobalTimeout": {
                  "type": "integer"
                },
                "DefaultResult": {}
              }
            }
          }
        }
      }
    },
    "DescribeLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {
          "LoadBalancerTargetGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerTargetGroupARN": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeLoadBalancersResult",
        "type": "structure",
        "members": {
          "LoadBalancers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LoadBalancerName": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMetricCollectionTypes": {
      "output": {
        "resultWrapper": "DescribeMetricCollectionTypesResult",
        "type": "structure",
        "members": {
          "Metrics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Metric": {}
              }
            }
          },
          "Granularities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Granularity": {}
              }
            }
          }
        }
      }
    },
    "DescribeNotificationConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupNames": {
            "shape": "S22"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeNotificationConfigurationsResult",
        "type": "structure",
        "required": [
          "NotificationConfigurations"
        ],
        "members": {
          "NotificationConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "TopicARN": {},
                "NotificationType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePolicies": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupName": {},
          "PolicyNames": {
            "type": "list",
            "member": {}
          },
          "PolicyTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribePoliciesResult",
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "PolicyName": {},
                "PolicyARN": {},
                "PolicyType": {},
                "AdjustmentType": {},
                "MinAdjustmentStep": {
                  "shape": "S3p"
                },
                "MinAdjustmentMagnitude": {
                  "type": "integer"
                },
                "ScalingAdjustment": {
                  "type": "integer"
                },
                "Cooldown": {
                  "type": "integer"
                },
                "StepAdjustments": {
                  "shape": "S3s"
                },
                "MetricAggregationType": {},
                "EstimatedInstanceWarmup": {
                  "type": "integer"
                },
                "Alarms": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "AlarmName": {},
                      "AlarmARN": {}
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingActivities": {
      "input": {
        "type": "structure",
        "members": {
          "ActivityIds": {
            "type": "list",
            "member": {}
          },
          "AutoScalingGroupName": {},
          "MaxRecords": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeScalingActivitiesResult",
        "type": "structure",
        "required": [
          "Activities"
        ],
        "members": {
          "Activities": {
            "shape": "S41"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingProcessTypes": {
      "output": {
        "resultWrapper": "DescribeScalingProcessTypesResult",
        "type": "structure",
        "members": {
          "Processes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProcessName"
              ],
              "members": {
                "ProcessName": {}
              }
            }
          }
        }
      }
    },
    "DescribeScheduledActions": {
      "input": {
        "type": "structure",
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionNames": {
            "type": "list",
            "member": {}
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeScheduledActionsResult",
        "type": "structure",
        "members": {
          "ScheduledUpdateGroupActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AutoScalingGroupName": {},
                "ScheduledActionName": {},
                "ScheduledActionARN": {},
                "Time": {
                  "type": "timestamp"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "Recurrence": {},
                "MinSize": {
                  "type": "integer"
                },
                "MaxSize": {
                  "type": "integer"
                },
                "DesiredCapacity": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "NextToken": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeTagsResult",
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S2f"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTerminationPolicyTypes": {
      "output": {
        "resultWrapper": "DescribeTerminationPolicyTypesResult",
        "type": "structure",
        "members": {
          "TerminationPolicyTypes": {
            "shape": "Sr"
          }
        }
      }
    },
    "DetachInstances": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachInstancesResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S41"
          }
        }
      }
    },
    "DetachLoadBalancerTargetGroups": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TargetGroupARNs"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TargetGroupARNs": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachLoadBalancerTargetGroupsResult",
        "type": "structure",
        "members": {}
      }
    },
    "DetachLoadBalancers": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "LoadBalancerNames"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LoadBalancerNames": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "resultWrapper": "DetachLoadBalancersResult",
        "type": "structure",
        "members": {}
      }
    },
    "DisableMetricsCollection": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Metrics": {
            "shape": "S4r"
          }
        }
      }
    },
    "EnableMetricsCollection": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "Granularity"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "Metrics": {
            "shape": "S4r"
          },
          "Granularity": {}
        }
      }
    },
    "EnterStandby": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "EnterStandbyResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S41"
          }
        }
      }
    },
    "ExecutePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {},
          "HonorCooldown": {
            "type": "boolean"
          },
          "MetricValue": {
            "type": "double"
          },
          "BreachThreshold": {
            "type": "double"
          }
        }
      }
    },
    "ExitStandby": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {}
        }
      },
      "output": {
        "resultWrapper": "ExitStandbyResult",
        "type": "structure",
        "members": {
          "Activities": {
            "shape": "S41"
          }
        }
      }
    },
    "PutLifecycleHook": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleTransition": {},
          "RoleARN": {},
          "NotificationTargetARN": {},
          "NotificationMetadata": {},
          "HeartbeatTimeout": {
            "type": "integer"
          },
          "DefaultResult": {}
        }
      },
      "output": {
        "resultWrapper": "PutLifecycleHookResult",
        "type": "structure",
        "members": {}
      }
    },
    "PutNotificationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "TopicARN",
          "NotificationTypes"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "TopicARN": {},
          "NotificationTypes": {
            "shape": "S2m"
          }
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "PolicyName",
          "AdjustmentType"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "PolicyName": {},
          "PolicyType": {},
          "AdjustmentType": {},
          "MinAdjustmentStep": {
            "shape": "S3p"
          },
          "MinAdjustmentMagnitude": {
            "type": "integer"
          },
          "ScalingAdjustment": {
            "type": "integer"
          },
          "Cooldown": {
            "type": "integer"
          },
          "MetricAggregationType": {},
          "StepAdjustments": {
            "shape": "S3s"
          },
          "EstimatedInstanceWarmup": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "PutScalingPolicyResult",
        "type": "structure",
        "members": {
          "PolicyARN": {}
        }
      }
    },
    "PutScheduledUpdateGroupAction": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "ScheduledActionName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "ScheduledActionName": {},
          "Time": {
            "type": "timestamp"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Recurrence": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          }
        }
      }
    },
    "RecordLifecycleActionHeartbeat": {
      "input": {
        "type": "structure",
        "required": [
          "LifecycleHookName",
          "AutoScalingGroupName"
        ],
        "members": {
          "LifecycleHookName": {},
          "AutoScalingGroupName": {},
          "LifecycleActionToken": {},
          "InstanceId": {}
        }
      },
      "output": {
        "resultWrapper": "RecordLifecycleActionHeartbeatResult",
        "type": "structure",
        "members": {}
      }
    },
    "ResumeProcesses": {
      "input": {
        "shape": "S58"
      }
    },
    "SetDesiredCapacity": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName",
          "DesiredCapacity"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "DesiredCapacity": {
            "type": "integer"
          },
          "HonorCooldown": {
            "type": "boolean"
          }
        }
      }
    },
    "SetInstanceHealth": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "HealthStatus"
        ],
        "members": {
          "InstanceId": {},
          "HealthStatus": {},
          "ShouldRespectGracePeriod": {
            "type": "boolean"
          }
        }
      }
    },
    "SetInstanceProtection": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds",
          "AutoScalingGroupName",
          "ProtectedFromScaleIn"
        ],
        "members": {
          "InstanceIds": {
            "shape": "S2"
          },
          "AutoScalingGroupName": {},
          "ProtectedFromScaleIn": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "SetInstanceProtectionResult",
        "type": "structure",
        "members": {}
      }
    },
    "SuspendProcesses": {
      "input": {
        "shape": "S58"
      }
    },
    "TerminateInstanceInAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ShouldDecrementDesiredCapacity"
        ],
        "members": {
          "InstanceId": {},
          "ShouldDecrementDesiredCapacity": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "TerminateInstanceInAutoScalingGroupResult",
        "type": "structure",
        "members": {
          "Activity": {
            "shape": "S42"
          }
        }
      }
    },
    "UpdateAutoScalingGroup": {
      "input": {
        "type": "structure",
        "required": [
          "AutoScalingGroupName"
        ],
        "members": {
          "AutoScalingGroupName": {},
          "LaunchConfigurationName": {},
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          },
          "DesiredCapacity": {
            "type": "integer"
          },
          "DefaultCooldown": {
            "type": "integer"
          },
          "AvailabilityZones": {
            "shape": "Sn"
          },
          "HealthCheckType": {},
          "HealthCheckGracePeriod": {
            "type": "integer"
          },
          "PlacementGroup": {},
          "VPCZoneIdentifier": {},
          "TerminationPolicies": {
            "shape": "Sr"
          },
          "NewInstancesProtectedFromScaleIn": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sr": {
      "type": "list",
      "member": {}
    },
    "Su": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "Key": {},
          "Value": {},
          "PropagateAtLaunch": {
            "type": "boolean"
          }
        }
      }
    },
    "S11": {
      "type": "list",
      "member": {}
    },
    "S12": {
      "type": "list",
      "member": {}
    },
    "S14": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DeviceName"
        ],
        "members": {
          "VirtualName": {},
          "DeviceName": {},
          "Ebs": {
            "type": "structure",
            "members": {
              "SnapshotId": {},
              "VolumeSize": {
                "type": "integer"
              },
              "VolumeType": {},
              "DeleteOnTermination": {
                "type": "boolean"
              },
              "Iops": {
                "type": "integer"
              },
              "Encrypted": {
                "type": "boolean"
              }
            }
          },
          "NoDevice": {
            "type": "boolean"
          }
        }
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S22": {
      "type": "list",
      "member": {}
    },
    "S2f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "ResourceType": {},
          "Key": {},
          "Value": {},
          "PropagateAtLaunch": {
            "type": "boolean"
          }
        }
      }
    },
    "S2m": {
      "type": "list",
      "member": {}
    },
    "S3p": {
      "type": "integer",
      "deprecated": true
    },
    "S3s": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScalingAdjustment"
        ],
        "members": {
          "MetricIntervalLowerBound": {
            "type": "double"
          },
          "MetricIntervalUpperBound": {
            "type": "double"
          },
          "ScalingAdjustment": {
            "type": "integer"
          }
        }
      }
    },
    "S41": {
      "type": "list",
      "member": {
        "shape": "S42"
      }
    },
    "S42": {
      "type": "structure",
      "required": [
        "ActivityId",
        "AutoScalingGroupName",
        "Cause",
        "StartTime",
        "StatusCode"
      ],
      "members": {
        "ActivityId": {},
        "AutoScalingGroupName": {},
        "Description": {},
        "Cause": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "StatusCode": {},
        "StatusMessage": {},
        "Progress": {
          "type": "integer"
        },
        "Details": {}
      }
    },
    "S4r": {
      "type": "list",
      "member": {}
    },
    "S58": {
      "type": "structure",
      "required": [
        "AutoScalingGroupName"
      ],
      "members": {
        "AutoScalingGroupName": {},
        "ScalingProcesses": {
          "type": "list",
          "member": {}
        }
      }
    }
  }
}