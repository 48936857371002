module.exports={
  "version": "2.0",
  "metadata": {
    "uid": "iot-2015-05-28",
    "apiVersion": "2015-05-28",
    "endpointPrefix": "iot",
    "serviceFullName": "AWS IoT",
    "signatureVersion": "v4",
    "signingName": "execute-api",
    "protocol": "rest-json"
  },
  "operations": {
    "AcceptCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/accept-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      }
    },
    "AttachPrincipalPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      }
    },
    "AttachThingPrincipal": {
      "http": {
        "method": "PUT",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/cancel-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      }
    },
    "CreateCertificateFromCsr": {
      "http": {
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateSigningRequest"
        ],
        "members": {
          "certificateSigningRequest": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {}
        }
      }
    },
    "CreateKeysAndCertificate": {
      "http": {
        "requestUri": "/keys-and-certificate"
      },
      "input": {
        "type": "structure",
        "members": {
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {},
          "keyPair": {
            "type": "structure",
            "members": {
              "PublicKey": {},
              "PrivateKey": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "CreatePolicy": {
      "http": {
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {}
        }
      }
    },
    "CreatePolicyVersion": {
      "http": {
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {},
          "setAsDefault": {
            "location": "querystring",
            "locationName": "setAsDefault",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateThing": {
      "http": {
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "Sw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingName": {},
          "thingArn": {}
        }
      }
    },
    "CreateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "thingTypeProperties": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeArn": {}
        }
      }
    },
    "CreateTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "S1b"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "DeleteCACertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      }
    },
    "DeletePolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      }
    },
    "DeletePolicyVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "DeleteRegistrationCode": {
      "http": {
        "method": "DELETE",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThing": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThingType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTopicRule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        },
        "required": [
          "ruleName"
        ]
      }
    },
    "DeprecateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}/deprecate"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "undoDeprecate": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCACertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "autoRegistrationStatus": {}
            }
          }
        }
      }
    },
    "DescribeCertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "caCertificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "previousOwnedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "transferData": {
                "type": "structure",
                "members": {
                  "transferMessage": {},
                  "rejectReason": {},
                  "transferDate": {
                    "type": "timestamp"
                  },
                  "acceptDate": {
                    "type": "timestamp"
                  },
                  "rejectDate": {
                    "type": "timestamp"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/endpoint"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "endpointAddress": {}
        }
      }
    },
    "DescribeThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "defaultClientId": {},
          "thingName": {},
          "thingTypeName": {},
          "attributes": {
            "shape": "Sx"
          },
          "version": {
            "type": "long"
          }
        }
      }
    },
    "DescribeThingType": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeProperties": {
            "shape": "S14"
          },
          "thingTypeMetadata": {
            "shape": "S3u"
          }
        }
      }
    },
    "DetachPrincipalPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      }
    },
    "DetachThingPrincipal": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/disable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "EnableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/enable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "GetLoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "logLevel": {}
        }
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "defaultVersionId": {}
        }
      }
    },
    "GetPolicyVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyName": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "GetRegistrationCode": {
      "http": {
        "method": "GET",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "registrationCode": {}
        }
      }
    },
    "GetTopicRule": {
      "http": {
        "method": "GET",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ruleArn": {},
          "rule": {
            "type": "structure",
            "members": {
              "ruleName": {},
              "sql": {},
              "description": {},
              "createdAt": {
                "type": "timestamp"
              },
              "actions": {
                "shape": "S1e"
              },
              "ruleDisabled": {
                "type": "boolean"
              },
              "awsIotSqlVersion": {}
            }
          }
        }
      }
    },
    "ListCACertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "status": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "S4r"
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificatesByCA": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-by-ca/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificateId"
        ],
        "members": {
          "caCertificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "S4r"
          },
          "nextMarker": {}
        }
      }
    },
    "ListOutgoingCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-out-going"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "outgoingCertificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "transferredTo": {},
                "transferDate": {
                  "type": "timestamp"
                },
                "transferMessage": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "S51"
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicyPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/policy-principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "header",
            "locationName": "x-amzn-iot-policy"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "S55"
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicyVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "versionId": {},
                "isDefaultVersion": {
                  "type": "boolean"
                },
                "createDate": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListPrincipalPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/principal-policies"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "S51"
          },
          "nextMarker": {}
        }
      }
    },
    "ListPrincipalThings": {
      "http": {
        "method": "GET",
        "requestUri": "/principals/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListThingPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "S55"
          }
        }
      }
    },
    "ListThingTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingTypeName": {},
                "thingTypeProperties": {
                  "shape": "S14"
                },
                "thingTypeMetadata": {
                  "shape": "S3u"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThings": {
      "http": {
        "method": "GET",
        "requestUri": "/things"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "attributeName": {
            "location": "querystring",
            "locationName": "attributeName"
          },
          "attributeValue": {
            "location": "querystring",
            "locationName": "attributeValue"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingName": {},
                "thingTypeName": {},
                "attributes": {
                  "shape": "Sx"
                },
                "version": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTopicRules": {
      "http": {
        "method": "GET",
        "requestUri": "/rules"
      },
      "input": {
        "type": "structure",
        "members": {
          "topic": {
            "location": "querystring",
            "locationName": "topic"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ruleDisabled": {
            "location": "querystring",
            "locationName": "ruleDisabled",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ruleArn": {},
                "ruleName": {},
                "topicPattern": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "ruleDisabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "RegisterCACertificate": {
      "http": {
        "requestUri": "/cacertificate"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificate",
          "verificationCertificate"
        ],
        "members": {
          "caCertificate": {},
          "verificationCertificate": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "allowAutoRegistration": {
            "location": "querystring",
            "locationName": "allowAutoRegistration",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterCertificate": {
      "http": {
        "requestUri": "/certificate/register"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificatePem"
        ],
        "members": {
          "certificatePem": {},
          "caCertificatePem": {},
          "setAsActive": {
            "deprecated": true,
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RejectCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/reject-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "rejectReason": {}
        }
      }
    },
    "ReplaceTopicRule": {
      "http": {
        "method": "PATCH",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "S1b"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "SetDefaultPolicyVersion": {
      "http": {
        "method": "PATCH",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "SetLoggingOptions": {
      "http": {
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingOptionsPayload"
        ],
        "members": {
          "loggingOptionsPayload": {
            "type": "structure",
            "required": [
              "roleArn"
            ],
            "members": {
              "roleArn": {},
              "logLevel": {}
            }
          }
        },
        "payload": "loggingOptionsPayload"
      }
    },
    "TransferCertificate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/transfer-certificate/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "targetAwsAccount"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "targetAwsAccount": {
            "location": "querystring",
            "locationName": "targetAwsAccount"
          },
          "transferMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "transferredCertificateArn": {}
        }
      }
    },
    "UpdateCACertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          },
          "newAutoRegistrationStatus": {
            "location": "querystring",
            "locationName": "newAutoRegistrationStatus"
          }
        }
      }
    },
    "UpdateCertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "newStatus"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          }
        }
      }
    },
    "UpdateThing": {
      "http": {
        "method": "PATCH",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "Sw"
          },
          "expectedVersion": {
            "type": "long"
          },
          "removeThingType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sw": {
      "type": "structure",
      "members": {
        "attributes": {
          "shape": "Sx"
        },
        "merge": {
          "type": "boolean"
        }
      }
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S14": {
      "type": "structure",
      "members": {
        "thingTypeDescription": {},
        "searchableAttributes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1b": {
      "type": "structure",
      "required": [
        "sql",
        "actions"
      ],
      "members": {
        "sql": {},
        "description": {},
        "actions": {
          "shape": "S1e"
        },
        "ruleDisabled": {
          "type": "boolean"
        },
        "awsIotSqlVersion": {}
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "dynamoDB": {
            "type": "structure",
            "required": [
              "tableName",
              "roleArn",
              "hashKeyField",
              "hashKeyValue"
            ],
            "members": {
              "tableName": {},
              "roleArn": {},
              "operation": {},
              "hashKeyField": {},
              "hashKeyValue": {},
              "hashKeyType": {},
              "rangeKeyField": {},
              "rangeKeyValue": {},
              "rangeKeyType": {},
              "payloadField": {}
            }
          },
          "dynamoDBv2": {
            "type": "structure",
            "members": {
              "roleArn": {},
              "putItem": {
                "type": "structure",
                "required": [
                  "tableName"
                ],
                "members": {
                  "tableName": {}
                }
              }
            }
          },
          "lambda": {
            "type": "structure",
            "required": [
              "functionArn"
            ],
            "members": {
              "functionArn": {}
            }
          },
          "sns": {
            "type": "structure",
            "required": [
              "targetArn",
              "roleArn"
            ],
            "members": {
              "targetArn": {},
              "roleArn": {},
              "messageFormat": {}
            }
          },
          "sqs": {
            "type": "structure",
            "required": [
              "roleArn",
              "queueUrl"
            ],
            "members": {
              "roleArn": {},
              "queueUrl": {},
              "useBase64": {
                "type": "boolean"
              }
            }
          },
          "kinesis": {
            "type": "structure",
            "required": [
              "roleArn",
              "streamName"
            ],
            "members": {
              "roleArn": {},
              "streamName": {},
              "partitionKey": {}
            }
          },
          "republish": {
            "type": "structure",
            "required": [
              "roleArn",
              "topic"
            ],
            "members": {
              "roleArn": {},
              "topic": {}
            }
          },
          "s3": {
            "type": "structure",
            "required": [
              "roleArn",
              "bucketName",
              "key"
            ],
            "members": {
              "roleArn": {},
              "bucketName": {},
              "key": {},
              "cannedAcl": {}
            }
          },
          "firehose": {
            "type": "structure",
            "required": [
              "roleArn",
              "deliveryStreamName"
            ],
            "members": {
              "roleArn": {},
              "deliveryStreamName": {},
              "separator": {}
            }
          },
          "cloudwatchMetric": {
            "type": "structure",
            "required": [
              "roleArn",
              "metricNamespace",
              "metricName",
              "metricValue",
              "metricUnit"
            ],
            "members": {
              "roleArn": {},
              "metricNamespace": {},
              "metricName": {},
              "metricValue": {},
              "metricUnit": {},
              "metricTimestamp": {}
            }
          },
          "cloudwatchAlarm": {
            "type": "structure",
            "required": [
              "roleArn",
              "alarmName",
              "stateReason",
              "stateValue"
            ],
            "members": {
              "roleArn": {},
              "alarmName": {},
              "stateReason": {},
              "stateValue": {}
            }
          },
          "elasticsearch": {
            "type": "structure",
            "required": [
              "roleArn",
              "endpoint",
              "index",
              "type",
              "id"
            ],
            "members": {
              "roleArn": {},
              "endpoint": {},
              "index": {},
              "type": {},
              "id": {}
            }
          }
        }
      }
    },
    "S3u": {
      "type": "structure",
      "members": {
        "deprecated": {
          "type": "boolean"
        },
        "deprecationDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        }
      }
    },
    "S4r": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "status": {},
          "creationDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "S51": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {}
        }
      }
    },
    "S55": {
      "type": "list",
      "member": {}
    }
  },
  "examples": {}
}